import { Observable, pipe, retry, timer } from 'rxjs';

export const DEFAULT_RETRY_COUNT_FOR_BACKOFF = 6;

/**
 * Helper function for retrying an observable (typically an api call).
 *
 * Reference:
 * https://angular.io/guide/practical-observable-usage#exponential-backoff
 *
 * When the source observable throws an error it will be retried exponentially
 * longer based off how many times it has been previously retried.
 * If the source observable has been retried more than maxRetries times it will
 * instead throw the error.
 *
 * With the default values maxRetries = 6, interval = 2000, the request will
 * time out after about one minute. Increasing either of these values will
 * increase this max timeout exponentially.
 */
export const retryWithBackoff = <T>(
  maxRetries = DEFAULT_RETRY_COUNT_FOR_BACKOFF,
  interval = 2000
) =>
  pipe<Observable<T>, Observable<T>>(
    retry({
      count: maxRetries,
      delay: (_error, retryIndex) => {
        const delay = Math.pow(2, retryIndex - 1) * interval;
        return timer(delay);
      },
    })
  );
