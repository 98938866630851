<app-left-navigation></app-left-navigation>
<div class="account-sidebar">
  <div class="account-sidebar-header">
    <h3>Accounts</h3>
    <a
      attr.aria-describedby="new-account-tip"
      class="js-new-account-button"
      [routerLink]="['/accounts/new']"
      ><button class="button button__secondary button__small button__no-margin">
        New client
      </button></a
    >

    <ng-container *ngIf="onlyOneAccountQuoted">
      <div class="tool-tip tool-tip__static js-account-flag">Quote another business</div>
    </ng-container>
  </div>

  <div class="account-sidebar-main">
    <app-insured-account-list [activeAccountId]="accountId" [accountList]="accountList">
    </app-insured-account-list>
    <div *ngIf="loadingAccountList">
      <ul class="account-sidebar-loading">
        <li class="account-sidebar-loading-li-placeholder"></li>
        <li class="account-sidebar-loading-li-placeholder"></li>
        <li class="account-sidebar-loading-li-placeholder"></li>
        <li class="account-sidebar-loading-li-placeholder"></li>
        <li class="account-sidebar-loading-li-placeholder"></li>
        <li class="account-sidebar-loading-li-placeholder"></li>
        <li class="account-sidebar-loading-li-placeholder"></li>
      </ul>
    </div>
  </div>
  <div style="margin: auto">
    <app-incentives-new-users [clear]="true"></app-incentives-new-users>
  </div>
</div>

<div class="account-show-page">
  <div class="account-main">
    <div class="account-main-map" [class.account-main-map__hide]="loading">
      <a href="https://www.google.com/maps/place/{{ model.gMapsAddress() }}" target="_blank">
        <img id="googleMapImg" class="map-image" src="{{ googleMapSrc }}" alt="" />
      </a>
    </div>
    <div *ngIf="loading">
      <div class="placeholder-map"></div>
      <div class="placeholder-h1"></div>
      <div class="placeholder-account-number"></div>
      <ul>
        <li class="placeholder-contact-item"></li>
        <li class="placeholder-contact-item"></li>
        <li class="placeholder-contact-item"></li>
      </ul>
    </div>
    <ng-container *ngIf="!loading">
      <div class="account-info">
        <h1 class="account-name-heading">{{ model.companyName }}</h1>
        <div class="account">
          <div class="account-number">
            Acct # <span>{{ model.id }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="showSafetyReviewAlert" class="safety-review-alert">
        <app-dialog-box type="warning" header="Action Required">
          <p>
            This account's workers' compensation policy requires a safety review to maintain
            coverage.
            <br />
            <br />
            Please remind this policyholder to schedule their safety review and complete this
            required process when they are contacted by Builders Insurance or one of their safety
            expert partners.
            <br />
            <br />
            You can use
            <a
              class="dialog-text-link"
              [href]="safetyReviewEmailTemplateUrl"
              target="_blank"
              data-amplitude-input-name="account_safety_review_email_template"
              >this email template</a
            >
            to inform the policyholder and share this
            <span
              ><a
                class="dialog-text-link"
                [href]="safetyReviewOverviewPolicyholdersUrl"
                target="_blank"
                data-amplitude-input-name="account_safety_review_overview_policyholders"
                >safety review overview</a
              ></span
            >, which explains what to expect.
          </p>
        </app-dialog-box>
      </div>
      <p class="contact-bullet account-classcode js-account-naics" *ngIf="naicsDescription">
        <span class="icon-shop contact-bullet-icon" aria-label="Classcode"></span>
        <span class="gray-text">{{ naicsDescription }}</span>
      </p>
      <div class="safety-review-alert js-missing-account-naics" *ngIf="!naicsDescription">
        <app-dialog-box type="warning" header="Action Required">
          <p>
            This account was added through an automated process and requires the business
            description and phone number to be updated in order to display policy or quote related
            information.
            <br />
            <br />
            Please
            <span>
              <a
                class="dialog-text-link js-edit-account-link"
                (click)="showEditAccount()"
                (keyup.enter)="showEditAccount()"
              >
                edit
              </a>
            </span>
            this account, select the appropriate <strong>Description of business</strong>, update
            the <strong>insured phone number</strong>, and save your changes.
          </p>
        </app-dialog-box>
      </div>
      <p class="contact-bullet">
        <span class="icon-location contact-bullet-icon" aria-label="Address"></span>
        <a
          class="gray-text"
          href="https://www.google.com/maps/place/{{ model.gMapsAddress() }}"
          target="_blank"
        >
          {{ model.address() }}
        </a>
      </p>
      <div *ngIf="hasBoundedBop">
        <p class="contact-bullet">
          <span class="icon-call contact-bullet-icon" aria-label="Phone"></span>
          <a class="gray-text" href="tel:{{ model.phoneNumber }}">{{
            model.phoneNumber | phone
          }}</a>
        </p>
        <p class="contact-bullet">
          <span class="icon-paper-plane contact-bullet-icon" aria-label="Email"></span>
          <a class="gray-text" href="mailto:{{ model.emailAddress }}">{{ model.emailAddress }}</a>
        </p>
        <p class="contact-bullet" *ngIf="model.additionalEmailAddress">
          <span class="icon-paper-plane contact-bullet-icon" aria-label="Additional Email"></span>
          <a class="gray-text" href="mailto:{{ model.additionalEmailAddress }}">{{
            model.additionalEmailAddress
          }}</a>
        </p>
      </div>
      <div class="edit-account">
        <ul class="link-list">
          <li>
            <a
              *ngIf="isEditEnabled"
              class="
                js-account-full-edit-button
                button button__secondary button__no-margin button__full-width
              "
              (click)="showEditAccount()"
              (keyup.enter)="showEditAccount()"
            >
              Edit account
            </a>
          </li>
          <li>
            <a
              *ngIf="!isEditEnabled"
              class="
                js-account-email-edit-button
                button button__secondary button__no-margin button__full-width
              "
              (click)="displayEditContactsModal()"
              (keyup.enter)="displayEditContactsModal()"
            >
              Edit contact information
            </a>
          </li>
          <li>
            <a
              *ngIf="showViewInvoicesButton() | async"
              class="
                js-account-invoice-button
                button button__secondary button__no-margin button__full-width
              "
              attr.data-amplitude-input-name="view invoices (insured list)"
              [routerLink]="getInvoicesLink()"
              target="_blank"
            >
              View account invoices
            </a>
          </li>
          <li>
            <app-dashboard-insured-loss-runs-upload
              *ngIf="displayLossRunsUpload()"
              [model]="model"
              [user]="user"
            >
            </app-dashboard-insured-loss-runs-upload>
          </li>
        </ul>
      </div>
      <div class="make-request" *ngIf="displayMakeRequest()">
        <h3 class="h3 h3__support">Make a request</h3>
        <ul class="link-list">
          <div class="js-endorse-self-service-link" *ngIf="displaySelfServiceEndorsementLink()">
            <li>
              <a
                (click)="createPolicyChange()"
                (keyup.enter)="createPolicyChange()"
                data-amplitude-input-name="account_make_request_endorse_self_service"
                >Endorse a policy</a
              >
            </li>
            <ul class="make-request-sublist">
              <li>
                <a
                  (click)="createPolicyChange('additionalInsureds')"
                  (keyup.enter)="createPolicyChange('additionalInsureds')"
                  data-amplitude-input-name="account_make_request_endorse_ai"
                  >Add / update an additional insured</a
                >
              </li>
              <li>
                <a
                  (click)="createPolicyChange('coverages')"
                  (keyup.enter)="createPolicyChange('coverages')"
                  data-amplitude-input-name="account_make_request_endorse_coverage"
                  >Change coverage</a
                >
              </li>
              <li>
                <a
                  (click)="createPolicyChange('locations')"
                  (keyup.enter)="createPolicyChange('locations')"
                  data-amplitude-input-name="account_make_request_endorse_location"
                  >Add / update location</a
                >
              </li>
            </ul>
          </div>
          <div class="js-endorse-support-link" *ngIf="!displaySelfServiceEndorsementLink()">
            <li>
              <a
                routerLink="/support"
                [queryParams]="getSupportPrefillParams()"
                data-amplitude-input-name="account_make_request_endorse_support"
                >Endorse a policy</a
              >
            </li>
          </div>
          <li>
            <a
              routerLink="/support/form/request-cancellation"
              [queryParams]="getSupportPrefillParams()"
              data-amplitude-input-name="account_make_request_cancellation"
              >Request cancellation</a
            >
          </li>
          <li>
            <a
              routerLink="/support/form/request-bor"
              [queryParams]="getSupportPrefillParams()"
              data-amplitude-input-name="account_make_request_bor"
              >Request a broker of record change</a
            >
          </li>
          <li>
            <a
              routerLink="/support/form/request-lrs-policy"
              [queryParams]="getSupportPrefillParams()"
              data-amplitude-input-name="account_make_request_lrs"
              >Request loss runs</a
            >
          </li>
          <li>
            <a
              routerLink="/support"
              [queryParams]="getSupportPrefillParams()"
              data-amplitude-input-name="account_make_request_more"
              >More...</a
            >
          </li>
        </ul>
      </div>
    </ng-container>
  </div>

  <router-outlet></router-outlet>
</div>

<app-insured-account-edit-form
  [accountId]="accountId"
  [open]="showEditAccountModal"
  (closeModal)="hideEditAccount($event)"
>
</app-insured-account-edit-form>

<app-modal
  [open]="editContactsModalIsDisplayed"
  modalType="flyout"
  modalClass="app-modal-content__left-align"
  (handleModalClose)="displayEditContactsModal()"
>
  <h1>Contact Information</h1>
  <form (ngSubmit)="editAccount()" novalidate>
    <h3>Insured contact information</h3>
    <app-form-field-text
      inputId="emailAddress"
      [submitted]="submitted"
      [form]="form"
      labelText="Insured email"
      nameOfFormControl="emailAddress"
      appRemoveWhitespaceMask
    >
    </app-form-field-text>
    <app-form-field-text
      inputId="additionalEmailAddress"
      [form]="form"
      [submitted]="submitted"
      labelText="Secondary insured email"
      specifierText="(optional)"
      nameOfFormControl="additionalEmailAddress"
      appRemoveWhitespaceMask
    >
    </app-form-field-text>

    <app-form-field-text
      inputId="phoneNumber"
      [form]="form"
      [submitted]="submitted"
      appPhoneMask
      labelText="Insured mobile number"
      nameOfFormControl="phoneNumber"
    >
    </app-form-field-text>

    <app-broker-contacts
      (delete)="removeContact($event)"
      (add)="addBrokerContact()"
      [contacts]="getAllPossibleContacts()"
      [form]="form.get('brokerContacts')"
      [isUpdating]="true"
      [submitted]="submitted"
    >
    </app-broker-contacts>

    <app-dialog-box
      *ngIf="editContactInfoError"
      header="Failed to edit contact"
      type="danger"
      class="dialog-text__full-width"
    >
      {{ editContactInfoError }}
    </app-dialog-box>

    <div class="nav-button-group nav-button-group__left form-footer">
      <button type="submit" class="button button__primary js-submit-edit-contacts-modal">
        <div class="button-loader" *ngIf="submitting">
          <svg class="circle-spinner">
            <circle
              cx="11"
              cy="11"
              r="9"
              stroke-width="2"
              stroke-dasharray="0,1000"
              d="M5 40 l215 0"
              fill="transparent"
            />
          </svg>
        </div>
        Save
      </button>
    </div>
  </form>
  <small class="gray-text">
    <sup>1</sup>
    The insured consents to receive text message communications to the mobile phone number provided
    on this form. The insured understands that it may be charged message and data rates by its
    wireless carrier. Such messages may be generated by an automated messaging system and it may
    opt-out of this service by replying STOP to any message.
  </small>
</app-modal>
<div class="quoting-game-container" *ngIf="isQuoteInProgress()">
  <app-quoting-game [finish$]="getQuoteSuccessSubject()"></app-quoting-game>
</div>

<app-help-center-marketing-modal
  [open]="showHelpCenterMarketingModal"
  (closeHelpModal)="displayHelpCenterMarketingModal($event, false)"
></app-help-center-marketing-modal>
