import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  GUIDELINES,
  ADDITIONAL_GUIDES,
  ATTUNE_WC_GUIDELINES,
  ATTUNE_WC_ADDITIONAL_GUIDES,
} from '../../models/support-constants';
import { InformService } from 'app/core/services/inform.service';
import { SentryService } from 'app/core/services/sentry.service';
import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from 'app/core/services/feature-flag.service';
import { cloneDeep } from 'lodash';
import { CUSTOMER_CARE_HOURS } from 'app/constants';
import { DocumentService } from '../../../documents/services/document.service';

@Component({
  selector: 'app-support-page.app-page.app-page__support',
  templateUrl: './support-page.component.html',
  styles: [],
})
export class SupportPageComponent implements OnInit, OnDestroy {
  guidelines: { fileName: string; guidelineLink: string }[] = [];
  additionalGuides = ADDITIONAL_GUIDES;
  showHelpCenterComponent = true;
  sub = new Subscription();
  customerCareHours = CUSTOMER_CARE_HOURS;

  constructor(
    private sentryService: SentryService,
    private informService: InformService,
    private documentService: DocumentService,
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}

  ngOnInit() {
    // Determine if the main Help Center component should display on page load
    this.displayHelpCenterComponent(this.router.routerState.snapshot.url);

    this.sub.add(
      this.router.events
        .pipe(
          tap((event) => {
            if (event instanceof NavigationEnd) {
              this.displayHelpCenterComponent(this.router.routerState.snapshot.url);
            }
          })
        )
        .subscribe()
    );

    this.guidelines = cloneDeep(GUIDELINES);

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP)
        .subscribe((enabled) => {
          if (enabled) {
            this.guidelines.splice(2, 0, ATTUNE_WC_GUIDELINES);
            this.additionalGuides.splice(1, 0, ATTUNE_WC_ADDITIONAL_GUIDES);
          }
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * The display logic for the Help Center component and any of the Support subpages
   * needs to be manually toggled based on the page urls, so that the authenticated
   * Help Center component can display on the same router level as the unauthenticated
   * Help Center component. This can be refactored in the future, but as of now,
   * using a `router-outlet` for all pages of the authenticated Help Center / Support
   * pages causes bugs.
   */
  displayHelpCenterComponent(url: string) {
    const isSupportSubpage = /\/enhancement\-library/.test(url) || /\/news/.test(url);
    this.showHelpCenterComponent = !isSupportSubpage;
  }

  downloadGuideline(file: typeof GUIDELINES[number]) {
    this.sub.add(
      this.documentService
        .getDocument(file.guidelineLink, `${file.fileName}.pdf`, 'pdf')
        .subscribe({
          error: () => {
            this.informService.infoToast(
              'There was an error downloading this file. Please contact our Customer Care Team.'
            );
          },
        })
    );
  }
}
