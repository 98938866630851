import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { cloneDeep, isEmpty } from 'lodash';
import { Router } from '@angular/router';

import { ATTUNE_WC_GUIDELINES, GUIDELINES } from 'app/features/support/models/support-constants';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { PolicyCountResponse } from 'app/bop/guidewire/typings';
import { NaicsService } from '../../../../shared/services/naics.service';
import { ClassCodeSelection } from '../../../digital-carrier/models/types';
import { UserService } from '../../../../core/services/user.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { CyberBindsRewardsService } from '../../../../shared/services/cyber-binds-rewards.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
  JSON_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import { BindGoalsConfig } from '../../components/activity-incentive-cyber-binds/activity-incentive-cyber-binds.component';
import { BillingApiService } from '../../../../shared/insured-account/billing-api.service';
import {
  BANK_DETAILS_REQUIRED_FINANCE_USER_MESSAGE,
  BANK_DETAILS_REQUIRED_HEADER_TEXT,
  BANK_DETAILS_REQUIRED_NON_FINANCE_USER_MESSAGE,
  CommissionPayableDetails,
} from '../../../../shared/settings/commissions-constants';
import { User } from '../../../../shared/models/user';
import { DocumentService } from '../../../documents/services/document.service';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { FOUNDATIONAL_PLUS_INFO_PAGE, getV3RolloverFaqs } from '../../models/constants';
import { AttuneBopFortegraEnabledStates } from 'app/shared/services/typings';

@Component({
  selector: 'app-activity-overview-page.policies-overview-page',
  templateUrl: 'activity-overview-page.component.html',
})
export class ActivityOverviewPageComponent implements OnInit, OnDestroy {
  constructor(
    private documentService: DocumentService,
    private informService: InformService,
    private insuredAccountService: InsuredAccountService,
    private userService: UserService,
    private cyberBindsRewardsService: CyberBindsRewardsService,
    private featureFlagService: FeatureFlagService,
    private naicsService: NaicsService,
    private billingApiService: BillingApiService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  private sub: Subscription = new Subscription();
  POLICY_PAGE_LENGTH = 5;
  policyCount: PolicyCountResponse;
  guidelines: { fileName: string; guidelineLink: string }[] = [];
  highlightedProduct: 'COALITION_CYBER' | 'ATTUNE_WC' | null = null;
  showWcPromo = false;
  attuneWcIsEnabled = false;
  // Default to true since we want !isAdpWcUser to evaluate to false at first
  isAdpWcUser = true;
  FOUNDATIONAL_PLUS_INFO_PAGE = FOUNDATIONAL_PLUS_INFO_PAGE;

  dialogHeader: string;
  dialogBody: string;

  cyberRewardsConfig: BindGoalsConfig | null = null;

  // Fortegra
  isFortegraEnabled = false;
  v3RolloverFAQs: Faq[];

  ngOnInit() {
    this.sub.add(
      this.insuredAccountService.getPolicyCount().subscribe((policyCount) => {
        this.policyCount = policyCount;
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.COALITION_CYBER_REWARDS_PROGRESS_BAR)
        .pipe(
          filter((isEnabled) => !!isEnabled),
          switchMap(() => this.userService.getUser()),
          switchMap((user) => {
            return this.cyberBindsRewardsService.getCyberBindsRewardsConfig(user.producer);
          })
        )
        .subscribe((config) => (this.cyberRewardsConfig = config))
    );

    this.sub.add(
      this.featureFlagService
        .getJsonFlagValue<AttuneBopFortegraEnabledStates>(
          JSON_FLAG_NAMES.ATTUNE_BOP_FORTEGRA_ENABLED_STATES
        )
        .subscribe((result) => {
          this.isFortegraEnabled = result !== null && !isEmpty(result.releaseDates);
        })
    );

    this.guidelines = cloneDeep(GUIDELINES);

    const attuneWcIsEnabled$ = this.featureFlagService.guaranteeIsEnabled(
      BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP
    );

    this.sub.add(
      attuneWcIsEnabled$.subscribe((enabled) => {
        this.showWcPromo = enabled || false;
        if (enabled) {
          this.guidelines.splice(1, 0, ATTUNE_WC_GUIDELINES);
        }
      })
    );

    this.sub.add(
      this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ADP_WC_ACCESS).subscribe((enabled) => {
        // We default to treating users as ADP since this suppresses promo cards
        this.isAdpWcUser = typeof enabled === 'boolean' ? enabled : true;
      })
    );

    const cyberIsAvailable$ = this.naicsService.getProductAvailability().pipe(
      map((productAvailability) => {
        return productAvailability?.some(({ pasSource, product, classCodeSelection }) => {
          return (
            pasSource === 'coalition' &&
            product === 'cyber_admitted' &&
            classCodeSelection !== ClassCodeSelection.NONE
          );
        });
      })
    );

    this.sub.add(
      forkJoin([cyberIsAvailable$, attuneWcIsEnabled$.pipe(take(1))]).subscribe(
        ([cyberIsAvailable, attuneWcIsEnabled]) => {
          if (attuneWcIsEnabled) {
            this.highlightedProduct = 'ATTUNE_WC';
          }
          if (cyberIsAvailable && !attuneWcIsEnabled) {
            this.highlightedProduct = 'COALITION_CYBER';
          }
        }
      )
    );

    this.sub.add(this.getPayableStatus());
    this.v3RolloverFAQs = this.getRolloverFaqs();
  }

  downloadGuideline(file: { fileName: string; guidelineLink: string }) {
    this.sub.add(
      this.documentService
        .getDocument(file.guidelineLink, `${file.fileName}.pdf`, 'pdf')
        .subscribe({
          error: () => {
            this.informService.infoToast(
              'There was an error downloading this file. Please contact our Customer Care Team.'
            );
          },
        })
    );
  }

  getPayableStatus() {
    return combineLatest([
      this.userService.getUser(),
      this.billingApiService.getPayableStatus(),
    ]).subscribe(([user, commissionPayableDetails]: [User, CommissionPayableDetails]) => {
      if (
        commissionPayableDetails?.hasDirectCommission === true &&
        commissionPayableDetails.errorRetrievingIsPayableInfo === false &&
        commissionPayableDetails.isPayable === false
      ) {
        this.dialogHeader = BANK_DETAILS_REQUIRED_HEADER_TEXT;
        const userIsFinanceAdmin = commissionPayableDetails.financeUsers.some(
          (financeUser) => financeUser.toLowerCase() === user.userName.toLowerCase()
        );
        this.dialogBody = userIsFinanceAdmin
          ? BANK_DETAILS_REQUIRED_FINANCE_USER_MESSAGE
          : BANK_DETAILS_REQUIRED_NON_FINANCE_USER_MESSAGE(commissionPayableDetails.financeUsers);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getRolloverFaqs() {
    const baseUrl = window.location.origin;
    return getV3RolloverFaqs(`${baseUrl}/accounts`);
  }
}
