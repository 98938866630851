import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  UntypedFormArray,
  FormGroup,
  FormArray,
} from '@angular/forms';
import { US_DATE_MASK, US_DATE_SINGLE_DIGIT_MASK } from '../../constants';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as libphonenumber from 'google-libphonenumber';
import { Subscription } from 'rxjs';
import { URL_PATTTERN_VALIDATION } from 'app/constants';
import { longestCommonSubsequence } from './search';
import { ComponentFixture } from '@angular/core/testing';
import { FormFieldDropdownSearchComponent } from '../form-dsl/components/form-field-dropdown-search/form-field-dropdown-search.component';
import { numberToMoneyString, parseMoney } from './number-format-helpers';
import { FEINPlaceholdersWithoutHyphen } from 'app/workers-comp/shared/constants';
import {
  FUNCTIONAL_REPLACEMENT_COST_CUTOFF_YEAR,
  FUNCTIONAL_REPLACEMENT_COST_STATE_EXCEPTIONS,
} from 'app/features/attune-bop/models/constants';

export const shouldShowInvalid = (
  path: string | string[],
  formGroup: AbstractControl,
  submitted: boolean
): boolean => {
  if (!submitted) {
    return false;
  }
  const field = formGroup.get(path);
  return field?.invalid || !!_.get(formGroup.errors, path);
};

export const getControl = (formGroup: UntypedFormGroup, path: string): UntypedFormControl => {
  return formGroup.get(path) as UntypedFormControl;
};

export const getFormGroup = (form: UntypedFormGroup, path: string): UntypedFormGroup => {
  return form.get(path) as UntypedFormGroup;
};

export const getFormArray = (form: UntypedFormGroup, path: string): UntypedFormArray => {
  return form.get(path) as UntypedFormArray;
};

export const removeAllFromFormArray = (formArray: UntypedFormArray) => {
  while (formArray.length > 0) {
    formArray.removeAt(0);
  }
};

export const getNum = (formGroup: UntypedFormGroup, key: string): number => {
  const c: number = getControl(formGroup, key).value as number;
  return Number(c);
};

export const getString = (formGroup: UntypedFormGroup, key: string): string => {
  const c = getControl(formGroup, key).value as string;
  return String(c);
};

interface DateValueObject {
  day: string;
  month: string;
  year: string;
}

const dateValueToMoment = (value: string | DateValueObject): moment.Moment => {
  if (value instanceof Object) {
    const { day, month, year } = value as DateValueObject;
    return moment(`${month}/${day}/${year}`, [US_DATE_MASK, US_DATE_SINGLE_DIGIT_MASK]);
  }
  return moment(value, [US_DATE_MASK, US_DATE_SINGLE_DIGIT_MASK]);
};

// START Form Validators
export const validatorWithMessage = (validator: ValidatorFn, message: string) => {
  const validatorWithMessageHelper: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const validatedControl = validator(control);
    if (validatedControl) {
      validatedControl.error = {};
      validatedControl.error.validationMessage = message;
      return validatedControl;
    }
    return null;
  };
  return validatorWithMessageHelper;
};

export const dateValidator = (control: AbstractControl): ValidationErrors | null => {
  const USE_STRICT_PARSING = true;
  const date: { month: string; day: string; year: string } = control.value;
  const formattedDate = `${date.month}/${date.day}/${date.year}`;
  const isValid: boolean = moment(
    formattedDate,
    [US_DATE_MASK, US_DATE_SINGLE_DIGIT_MASK],
    USE_STRICT_PARSING
  ).isValid();

  return isValid ? null : { invalidDate: { value: date } };
};

export const minDateExceededValidator = (
  aMoment?: moment.MomentInput,
  unit?: moment.unitOfTime.StartOf
): ValidatorFn => {
  const anchorMoment = moment(aMoment);
  return (control: AbstractControl): ValidationErrors | null => {
    const utcOffset = anchorMoment.utcOffset() / 60;
    const controlValueWithOffset = dateValueToMoment(control.value).utcOffset(utcOffset, true);

    const isSameOrAfter = controlValueWithOffset.isSameOrAfter(anchorMoment, unit);
    return isSameOrAfter ? null : { minDateExceeded: { value: control.value } };
  };
};

export const maxDateExceededValidator = (maxDate: moment.MomentInput): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const maxDateExceeded = dateValueToMoment(control.value).isAfter(maxDate);
    return maxDateExceeded ? { maxDateExceeded: { value: control.value } } : null;
  };
};

export const dateIsInPastValidator: ValidatorFn = (dateControl) => {
  const date = dateControl.value;

  if (!date) {
    return null;
  }

  const dateMoment = moment.utc(date, [US_DATE_MASK, US_DATE_SINGLE_DIGIT_MASK]);
  const today = moment.utc();
  if (!dateMoment.isBefore(today, 'date')) {
    return {
      dateIsNotInPast: {
        value: date,
        validationMessage: 'Please enter a date in the past.',
      },
    };
  }

  return null;
};

export const renewalEffectiveDateValidator = (
  previousPolicyEndDateMoment: moment.Moment
): ValidatorFn => {
  return (dateControl: UntypedFormControl) => {
    const date = dateControl.value;
    if (!date) {
      return null;
    }

    const dateMoment = moment.utc(date, US_DATE_MASK);
    if (dateMoment.isSame(previousPolicyEndDateMoment, 'day')) {
      return null;
    }

    return {
      renewalEffectiveDate: {
        value: dateControl.value,
        validationMessage: `The effective date must match the previous policy's end date: ${previousPolicyEndDateMoment.format(
          US_DATE_MASK
        )}. Please contact our Customer Care team with any questions.`,
      },
    };
  };
};

export const feinValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (control.value !== null) {
    const digits = control.value.replace(/\D+/g, '');
    if (digits.length !== 9) {
      return { invalidFein: { value: control.value } };
    } else if (FEINPlaceholdersWithoutHyphen.includes(digits)) {
      return {
        placeholderFein: {
          value: control.value,
        },
      };
    }
  }

  return null;
};

export const numberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const regex: RegExp = /\D+/g;
  if (control.value !== null && regex.test(control.value)) {
    return { invalidNumber: { value: control.value } };
  }

  return null;
};

export const createMinLengthValidator = (minLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value !== '') {
      if (String(control.value).length < minLength) {
        return { minLength: { value: control.value } };
      }
    }

    return null;
  };
};

export const rangeValidator = (min: number, max?: number, isFloat?: boolean): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === '') {
      return null;
    }

    const stringifiedInput = String(value).replace(/\$|,/g, '');
    const parsedInput = isFloat ? parseFloat(stringifiedInput) : parseInt(stringifiedInput, 10);

    if (isNaN(parsedInput) || parsedInput < min || ((max === 0 || max) && parsedInput > max)) {
      return {
        outOfRange: {
          value: value,
          validationMessage: 'Enter value within valid range',
        },
      };
    }

    return null;
  };
};

export const phoneValidator = (phoneControl: AbstractControl): ValidationErrors | null => {
  if (phoneControl.value !== '') {
    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const phoneNumber = '' + phoneControl.value + '';
      const pNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
      const isValidNumber = phoneUtil.isValidNumber(pNumber);

      if (isValidNumber) {
        return null;
      }
    } catch (e) {
      return { invalidPhone: { value: phoneControl.value } };
    }

    return { invalidPhone: { value: phoneControl.value } };
  } else {
    return null;
  }
};

export const phoneValidatorWithMessage = (message: string): ValidatorFn => {
  return validatorWithMessage(phoneValidator, message);
};

export const urlValidator = Validators.pattern(URL_PATTTERN_VALIDATION);

export const zipCodeValidator = Validators.pattern(/^\d{5}$/);

/*
 * These ranges represent valid zip codes in US states & territories
 * for example in Puerto Rico valid zip codes start with 006**, 007**, 009**
 */
const stateZipRanges: Record<string, ([string] | [string, string])[]> = {
  PR: [['006', '007'], ['009']],
  VI: [['008']],
  MA: [['010', '027']],
  RI: [['028', '029']],
  NH: [['030', '038']],
  ME: [['039', '049']],
  VT: [
    ['050', '054'],
    ['056', '059'],
  ],
  CT: [['060', '069']],
  NJ: [['070', '089']],
  NY: [['100', '149']],
  PA: [
    ['150', '191'],
    ['193', '196'],
  ],
  DE: [['197', '199']],
  DC: [['200']],
  MD: [
    ['206', '212'],
    ['214', '219'],
  ],
  VA: [['201'], ['220', '246']],
  WV: [['247', '268']],
  NC: [['270', '289']],
  SC: [['290', '299']],
  GA: [['300', '319'], ['398']],
  FL: [['320', '339'], ['341', '342'], ['344'], ['346', '347'], ['349']],
  AL: [
    ['350', '352'],
    ['354', '369'],
  ],
  TN: [
    ['370', '374'],
    ['376', '385'],
  ],
  MS: [['386', '397']],
  KY: [
    ['400', '418'],
    ['420', '427'],
  ],
  OH: [['430', '458']],
  IN: [['460', '479']],
  MI: [['480', '499']],
  IA: [
    ['500', '516'],
    ['520', '528'],
  ],
  WI: [
    ['530', '532'],
    ['534', '535'],
    ['537', '549'],
  ],
  MN: [
    ['550', '551'],
    ['553', '567'],
  ],
  SD: [['570', '577']],
  ND: [['580', '588']],
  MT: [['590', '599']],
  IL: [
    ['600', '620'],
    ['622', '629'],
  ],
  MO: [
    ['630', '631'],
    ['633', '641'],
    ['644', '648'],
    ['650', '658'],
  ],
  KS: [
    ['660', '662'],
    ['664', '679'],
  ],
  NE: [
    ['680', '681'],
    ['683', '693'],
  ],
  LA: [
    ['700', '701'],
    ['703', '708'],
    ['710', '714'],
  ],
  AR: [['716', '729']],
  OK: [
    ['730', '731'],
    ['734', '741'],
    ['743', '749'],
  ],
  TX: [['750', '770'], ['772', '799'], ['885']],
  CO: [['800', '816']],
  WY: [['820', '831']],
  ID: [['832', '838']],
  UT: [
    ['840', '841'],
    ['843', '847'],
  ],
  AZ: [
    ['850', '853'],
    ['855', '857'],
    ['859', '860'],
    ['863', '865'],
  ],
  NM: [
    ['870', '871'],
    ['873', '875'],
    ['877', '884'],
  ],
  NV: [
    ['889', '891'],
    ['893', '895'],
    ['897', '898'],
  ],
  CA: [
    ['900', '908'],
    ['910', '928'],
    ['930', '937'],
    ['939', '961'],
  ],
  HI: [['967', '968']],
  GU: [['969']],
  OR: [['970', '979']],
  WA: [
    ['980', '986'],
    ['988', '994'],
  ],
  AK: [['995', '999']],
};

export const zipCodeInState = (zip: string, state: string): boolean => {
  const prefix = zip.substr(0, 3);
  for (const range of stateZipRanges[state]) {
    if (range.length === 1) {
      if (range[0] === prefix) {
        return true;
      }
    } else {
      for (let i = parseInt(range[0], 10); i <= parseInt(range[1], 10); i++) {
        if (i.toString(10).padStart(3, '0') === prefix) {
          return true;
        }
      }
    }
  }
  return false;
};

export const validateCurrencyIsNotEmpty: ValidatorFn = (control) => {
  if (control.value === '$' || control.value === '') {
    return {
      currencyValueHasNoNumbers: {
        validationMessage: 'Please enter a dollar amount.',
      },
    };
  }

  return null;
};

export const validateCurrencyGreaterThanValue = (minValue: number): ValidatorFn => {
  return (formControl) => {
    const value: string | null = formControl.value;
    let isGreaterThanMin = false;

    if (value && value.length > 1) {
      isGreaterThanMin = parseMoney(value) > minValue;
    }

    if (!isGreaterThanMin) {
      return {
        currencyValueNotGreaterThanZero: {
          value: value,
          validationMessage: `Please enter a value greater than ${numberToMoneyString(minValue)}.`,
        },
      };
    }

    return null;
  };
};

export const validateCurrencyGreaterThanZero = validateCurrencyGreaterThanValue(0);

export const percentValidator: ValidatorFn = (control) => {
  let percent = control.value;

  if (!percent) {
    return null;
  }

  percent = Number(percent);
  if (percent < 0 || percent > 100) {
    return {
      percentIsInvalid: {
        validationMessage: 'Please enter a numeric value between 0 and 100.',
      },
    };
  }

  return null;
};

export const validatePercentLessThanOrEqualToOneHundred: ValidatorFn = (formControl) => {
  const value: string | null = formControl.value;
  let isLessThanOrEqualTo = false;

  if (value) {
    isLessThanOrEqualTo = Number(value) <= 100;
  }

  if (!isLessThanOrEqualTo) {
    return {
      percentValueNotLessThanOneHundred: {
        value: value,
        validationMessage: 'Please enter a value less than or equal to 100.',
      },
    };
  }

  return null;
};
// END Form Validators

export const subscribeToControlValueChanges = (
  formControl: UntypedFormControl,
  onValueChange: Function
): Subscription => {
  return formControl.valueChanges.subscribe((value) => {
    onValueChange(value);
  });
};

// This function maps a cursor position from one string to another. It can be used
// to manually set a user's cursor position on an input event, when the input value is
// being transformed and updated.
export const calculateCursorPosition = (cursorPosition: number, input: string, output: string) => {
  if (input === output) {
    return cursorPosition;
  }
  // Calculate the longest common subsequence (LCS) string between input and output
  const lcs = longestCommonSubsequence(input, output);
  // Map the cursor position from the input string to the LCS string
  let i = 0,
    j = 0;
  while (i < cursorPosition) {
    if (lcs[j] === input[i]) {
      j++;
    }
    i++;
  }

  const lcsCursorPosition = j;
  // If cursor position is at the end of the LCS,
  // we can assume it will be at the end of the output string
  if (lcsCursorPosition === lcs.length) {
    return output.length;
  }

  // Map the cursor position from the LCS string to output string
  i = 0;
  j = 0;
  while (j < lcsCursorPosition) {
    if (lcs[j] === output[i]) {
      j++;
    }
    i++;
  }
  return i;
};

// Returns a tree of any errors in control and children of control
export const allErrorsRecursively = (control: AbstractControl): Record<string, any> => {
  if (control instanceof FormGroup) {
    const childErrors = _.mapValues(control.controls, (childControl) => {
      return allErrorsRecursively(childControl);
    });
    const allErrors = control.errors ? { ...childErrors, ...control.errors } : childErrors;

    // Prune { controlName: null, ... } keys
    return _.omitBy(allErrors, _.isEmpty);
  } else if (control instanceof FormArray) {
    const childControlErrors = control.controls
      .map((childControl) => {
        return allErrorsRecursively(childControl);
      })
      .filter((childErrors) => _.isEmpty(childErrors));

    const parentErrors = control.errors || {};
    const childErrors = childControlErrors.length ? { childControlErrors } : {};

    return { ...parentErrors, ...childErrors };
  } else {
    return control.errors || {};
  }
};

export function requireCheckboxesToBeCheckedValidator(
  minRequired = 1,
  validationMessage = 'Please select at least one option.'
): ValidatorFn {
  return function validate(formGroup: AbstractControl) {
    const countChecked = (control: AbstractControl): number => {
      if (control instanceof UntypedFormControl) {
        return control.value === true ? 1 : 0;
      } else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        return Object.values(control.controls)
          .map(countChecked)
          .reduce((count, c) => count + c, 0);
      }
      return 0;
    };
    const checked = countChecked(formGroup);
    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: {
          validationMessage: validationMessage,
        },
      };
    }

    return null;
  };
}

export const isFormControlDirtyValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.dirty) {
    return { formControlNotEdited: { value: true } };
  }

  return null;
};

// Returns the first error within a form group and nested controls
export const getFirstError = (control: AbstractControl): string | null => {
  if (control.errors) {
    return Object.values(control.errors)[0];
  }
  if (control instanceof FormGroup) {
    const allControlErrors = _.map(control.controls, (nestedControl) => {
      return getFirstError(nestedControl);
    });
    return allControlErrors.find((error) => !!error) || null;
  } else if (control instanceof FormArray) {
    const allControlErrors = control.controls.map((nestedControl) => {
      return getFirstError(nestedControl);
    });
    return allControlErrors.find((error) => !!error) || null;
  }

  return null;
};

export const getControlValidationMessageFromForm = (
  form: FormGroup,
  nameOfFormControl: string
): null | string => {
  return form?.errors && form.errors[nameOfFormControl]?.validationMessage;
};

export const getValidationMessageFromControl = (control: AbstractControl): null | string => {
  const errors = control.errors;

  // Note: if control has a validation error message, return that, otherwise search children.
  if (errors) {
    const allErrorTypes = Object.keys(errors);
    // Find the first error message
    const controlErrorMessage = allErrorTypes.reduce((errorMessage, errorType) => {
      if (errorMessage) {
        return errorMessage;
      }
      return errors[errorType].validationMessage;
    }, null);
    if (controlErrorMessage) {
      return controlErrorMessage;
    }
  }

  if (control instanceof UntypedFormArray) {
    return control.controls.reduce((errorMessage: null | string, childControl) => {
      if (errorMessage) {
        return errorMessage;
      }
      return getValidationMessageFromControl(childControl);
    }, null);
  }

  if (control instanceof UntypedFormGroup) {
    const controls = Object.values(control.controls);
    return controls.reduce((errorMessage: null | string, childControl) => {
      if (errorMessage) {
        return errorMessage;
      }
      return getValidationMessageFromControl(childControl);
    }, null);
  }

  if (!(control instanceof UntypedFormControl)) {
    console.warn('Unrecognized form control type', control);
  }
  return null;
};

/**
 * Helper to carefully enable/disable controls via enable (true = enabled, false = disabled).
 * Return values:
 *
 *          true IFF control was enabled in this call
 *          false IFF control was disabled in this call
 *          null IFF enabled/disabled status already matched the enable parameter
 */
export const enableDisableControl = (control: AbstractControl, enable: boolean): boolean | null => {
  if (enable && control.disabled) {
    control.enable();
    return true;
  } else if (!enable && control.enabled) {
    control.disable();
    return false;
  }

  return null;
};

/**
 * Helper to patch controls. If the control was initially disabled, it is disabled again after the patch.
 * Parent controls will not be enabled.
 */
export const patchControl = (
  control: UntypedFormArray | UntypedFormControl | UntypedFormGroup,
  value: any
) => {
  const wasEnabled = control.enabled;
  control.enable({ onlySelf: true });
  control.patchValue(value);
  enableDisableControl(control, wasEnabled);
};

/**
 * Clamps a NUMERIC control to the range provided in sortedOptions.
 */
export const clampControl = (
  control: UntypedFormArray | UntypedFormControl | UntypedFormGroup,
  sortedOptions: number[]
) => {
  const currentValue = control.value;
  if (sortedOptions.includes(currentValue)) {
    return;
  }

  if (isNaN(Number(currentValue)) || currentValue === null) {
    control.setValue(sortedOptions[0]);
  }

  let idx = 0;
  const valueToPatch = sortedOptions[idx];
  while (
    valueToPatch < currentValue &&
    idx + 1 < sortedOptions.length &&
    sortedOptions[idx + 1] <= currentValue
  ) {
    idx++;
  }
  control.setValue(sortedOptions[idx]);
};

export const getAddress = (locationDetails: Address): Address => {
  const { addressLine1, addressLine2, zip, state, city } = locationDetails;
  return {
    addressLine1: addressLine1 || '',
    addressLine2: addressLine2 || null,
    zip: zip || '',
    state: state || '',
    city: city || '',
  };
};

// Dropdown helper methods
export const fillOutTypeahead = (
  fixture: ComponentFixture<FormFieldDropdownSearchComponent>,
  searchTerm: string,
  searchInputId: string
) => {
  const typeaheadInput = fixture.debugElement.nativeElement.querySelector(
    `#${searchInputId}-search-typeahead`
  );
  typeaheadInput.value = searchTerm;
  typeaheadInput.dispatchEvent(new Event('input'));
  fixture.detectChanges();
};

export const getTypeaheadResults = (
  fixture: ComponentFixture<FormFieldDropdownSearchComponent>
) => {
  return fixture.nativeElement.querySelector('ngb-typeahead-window.dropdown-menu');
};

export const hasLocationRequiringFunctionalReplacementCostWarning = (form: FormGroup): boolean => {
  const locationIndex = form.get('locations')?.value.length - 1;
  const state = form.get('locations')?.value[locationIndex].locationDetails.state;
  if (!FUNCTIONAL_REPLACEMENT_COST_STATE_EXCEPTIONS.includes(state)) {
    const fiftyYearsAgo = moment().add(-FUNCTIONAL_REPLACEMENT_COST_CUTOFF_YEAR, 'year').year();
    const yearBuilt = Number(
      form.get('locations')?.value[locationIndex].buildings[0].exposure.yearBuilt
    );

    if (yearBuilt < fiftyYearsAgo) {
      return true;
    }
  }

  return false;
};
