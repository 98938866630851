import * as _ from 'lodash';
import * as moment from 'moment';

import { QuoteSummary } from 'app/shared/models/quote-summary';
import {
  AccountPolicyPeriod,
  AccountDetails,
  AccountDetailsResponse,
  GwAccountPolicy,
} from 'app/bop/guidewire/typings';
import {
  BackendAccountRequestAccount,
  BackendAccountRequestPayload,
} from 'app/shared/consumer/typings';
import { environment } from 'environments/environment';
import { WC_PRODUCT_NAME } from 'app/features/invoices/models/invoices-constants';
import { isBopV1, isBopV2, isBopV3 } from '../../attune-bop/models/constants';

export const BOP_PRODUCT_NAME = 'Businessowners (v7)';
export const MXS_PRODUCT_NAME = 'Commercial Excess Liability Policy';
export const HAB_PRODUCT_NAME = 'Habitational';

const THRESHOLD_FOR_RENEWAL_QUOTE_DISPLAY_DAYS = 62;

// Note: quoted is also displayed, however, we only display quoted when the previous
//       term is 60 days from expiration, which makes it a special case
export const VISIBLE_POLICY_PERIOD_STATUSES_LOWER = [
  'expiring soon',
  'in force',
  'expired',
  'binding',
  'scheduled',
  'canceled',
  'non-renewing',
  'non-renewed',
  'not-taking',
  'not-taken',
];

if (environment.showNewRenewalsUI) {
  VISIBLE_POLICY_PERIOD_STATUSES_LOWER.push('quoted');
}

export const ENDORSABLE_STATUSES = ['In Force', 'Scheduled'];

// Cancellation transactions that have been withdrawn/rescinded will have this as their Status (not: TermDisplayStatus_ATN)
export const WITHDRAWN_RESCINDED_STATUSES = ['Rescinded', 'Withdrawn'];

export interface AccountPolicyTerm {
  productName: string;
  policyEffectiveDate: moment.Moment;
  policyExpirationDate: moment.Moment;
  policyNumber: string;
  status: string;
  totalCost: number | null;
  id: string;
  termNumber: string;
  updatedAt: moment.Moment;
}

export interface AccountPolicy {
  productName: string;
  policyNumber: string;
  id: string;
  terms: AccountPolicyTerm[];
  uwCompanyCode: string;
}

export interface InsuredAccountSummary {
  id: string;
  companyName: string;
  address: string;
}

export interface InsuredAccountAttributes {
  companyName: string;
  organizationType: string;
  phoneNumber: string;
  emailAddress: string;
  additionalEmailAddress?: string;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zip: string;
  description: string | null;
  doingBusinessAs: string;
  website: string | null;
  ofacAlertPresent?: boolean;
  naicsCode: NaicsCode | null;
  insuredContacts?: InsuredContact[];
  inspectionContacts?: {
    addressId?: string;
    email: string;
    name?: string;
    phone: string;
  }[];
  createdByUser?: string | null;
  sampleAccount?: boolean;
  fein?: string;
  hasSafetyReviewAlert?: boolean;
}

// TODO: this is the same as AccountPolicy 👆🏽 and we should clean this up
export interface QuoteOverviewItem {
  id: string;
  policyNumber: string;
  productName: string;
  terms: AccountPolicyTerm[];
  uwCompanyCode?: UwCompanyCode;
}

type SavedAccountAttributes = {
  bopQuotes: QuoteSummary[];
  bopV1Policies: QuoteOverviewItem[];
  bopV2Policies: QuoteOverviewItem[];
  bopV3Policies: QuoteOverviewItem[];
  attuneWcPolicies: QuoteOverviewItem[];
  habQuotes: QuoteSummary[];
  withdrawnBopPlusQuotes: QuoteSummary[];
  inForceQuotes: QuoteSummary[];
  policiesWithTerms: AccountPolicy[];
  producerCodesStruct: BackendProducerCodesStruct;
  attuneWcQuotes: QuoteSummary[];
} & InsuredAccountAttributes;

export interface NaicsCode {
  code: string;
  description: string;
  hash: string;
}

export class InsuredAccount implements InsuredAccountAttributes {
  id = '';
  companyName = '';
  organizationType = '';
  phoneNumber = '';
  emailAddress = '';
  additionalEmailAddress?: string;
  addressLine1 = '';
  addressLine2: string | null;
  city = '';
  state = '';
  zip = '';
  description = '';
  doingBusinessAs = '';
  website = '';
  bopQuotes: QuoteSummary[] = [];
  attuneWcQuotes: QuoteSummary[] = [];
  bopV1Policies: QuoteOverviewItem[] = [];
  bopV2Policies: QuoteOverviewItem[] = [];
  bopV3Policies: QuoteOverviewItem[] = [];
  attuneWcPolicies: QuoteOverviewItem[] = [];
  inForceQuotes: QuoteSummary[] = [];
  withdrawnBopPlusQuotes: QuoteSummary[] = [];
  habQuotes: QuoteSummary[] = [];
  policiesWithTerms: AccountPolicy[] = [];
  ofacAlertPresent = false;
  producerCodesStruct: BackendProducerCodesStruct = {
    Entry: [],
  };
  quoteRequestHistoriesHUSA: null;
  naicsCode: NaicsCode | null;
  insuredContacts?: InsuredContact[];
  inspectionContacts?: {
    addressId?: string;
    email: string;
    name?: string;
    phone: string;
  }[];
  createdByUser: string | null;
  sampleAccount?: boolean;
  fein?: string;
  hasSafetyReviewAlert = false;

  constructor(
    insuredAccountAttributes?:
      | Nullable<Partial<InsuredAccountAttributes>>
      | Partial<SavedAccountAttributes>,
    id?: string
  ) {
    if (id) {
      this.id = id;
    }

    Object.assign(this, insuredAccountAttributes);
  }

  public static fromBackendApiPayload(
    payload: AccountDetailsResponse | BackendAccountRequestPayload
  ): InsuredAccount {
    return this.fromIndividualBackendApiAccountPayload(payload.return.Account);
  }

  public static fromV3ApiPayload(payload: AccountDetails): InsuredAccount {
    const result = this.fromIndividualBackendApiAccountPayload(payload);
    // Manually replace any undefined values with blank strings, to match pre-V3 behavior
    result.additionalEmailAddress = result.additionalEmailAddress || '';
    result.addressLine2 = result.addressLine2 || '';
    result.companyName = result.companyName || '';
    result.description = result.description || '';
    result.doingBusinessAs = result.doingBusinessAs || '';
    result.emailAddress = result.emailAddress || '';
    result.fein = result.fein || '';
    result.website = result.website || '';

    return result;
  }

  public static fromAccountsListBackendApiPayload(
    payload: BackendListAccountsPayload
  ): Array<InsuredAccount> {
    return _.map(payload.accounts, (entry: BackendListAccount) => {
      return this.fromIndividualBackendApiListAccountPayload(entry);
    });
  }

  private static fromIndividualBackendApiAccountPayload(
    account: AccountDetails | BackendAccountRequestAccount
  ): InsuredAccount {
    const accountHolderContact = account.AccountHolderContact;
    const fein: string | undefined =
      _.get(account, 'AccountHolderContact.FEINOfficialID') || undefined;
    const primaryAddress = accountHolderContact.PrimaryAddress;
    const bopQuotesWithExcess = InsuredAccount.bopQuotesWithExcess(<AccountDetails>account);
    const attuneWcQuotes = InsuredAccount.attuneWcQuotes(<AccountDetails>account);
    const inForceQuotes = InsuredAccount.inForceQuotes(<AccountDetails>account);
    const withdrawnBopPlusQuotes = InsuredAccount.withdrawnBopPlusQuotes(<AccountDetails>account);

    const habQuotes = InsuredAccount.habQuotes(<AccountDetails>account);
    const naicsCode = _.get(account, 'NAICSCodes_ATTN.Entry[0]');

    // Account API V2 bound policies
    const policiesWithTerms = InsuredAccount.mapPoliciesWithTerms(<AccountDetails>account);

    const inForce = policiesWithTerms.map((policy) => {
      return {
        id: policy.id,
        policyNumber: policy.policyNumber,
        productName: policy.productName,
        uwCompanyCode: policy.uwCompanyCode,
        terms: policy.terms.map((term) => {
          let soon = false;
          if (
            term.policyExpirationDate.diff(Date.now(), 'days') <= 95 &&
            term.status === 'In Force'
          ) {
            soon = true;
          }
          return {
            ...term,
            status: soon ? 'Expiring Soon' : term.status,
          } as AccountPolicyTerm;
        }),
      };
    });

    const hasSafetyReviewAlert = InsuredAccount.hasSafetyReviewAlert(<AccountDetails>account);
    if (!primaryAddress) {
      throw new Error(`Missing primary address on insured account ${account.AccountNumber}`);
    }
    return new InsuredAccount(
      {
        additionalEmailAddress: accountHolderContact.EmailAddress2,
        addressLine1: primaryAddress.AddressLine1,
        addressLine2: primaryAddress.AddressLine2,
        city: primaryAddress.City,
        companyName: accountHolderContact.Name,
        description: account.BusOpsDesc,
        doingBusinessAs: account.DoesBusinessAs_HUSA,
        emailAddress: accountHolderContact.EmailAddress1,
        insuredContacts: InsuredAccount.insuredContactsFromPayload(<AccountDetails>account),
        naicsCode: naicsCode
          ? {
              code: naicsCode.Code,
              description: naicsCode.Description,
              hash: naicsCode.Hash,
            }
          : null,
        ofacAlertPresent: account.OFACStatus_HUSA === 'YES',
        organizationType: account.AccountOrgType,
        phoneNumber: accountHolderContact.WorkPhone,
        policiesWithTerms: policiesWithTerms,
        producerCodesStruct: account.ProducerCodes,
        bopQuotes: bopQuotesWithExcess,
        attuneWcQuotes: attuneWcQuotes,
        bopV1Policies: inForce.filter(
          ({ uwCompanyCode }) => uwCompanyCode && isBopV1(uwCompanyCode)
        ),
        bopV2Policies: inForce.filter(
          ({ uwCompanyCode }) => uwCompanyCode && isBopV2(uwCompanyCode)
        ),
        bopV3Policies: inForce.filter(
          ({ uwCompanyCode }) => uwCompanyCode && isBopV3(uwCompanyCode)
        ),
        attuneWcPolicies: inForce.filter(({ productName }) => productName === "Workers' Comp Line"),
        inForceQuotes: inForceQuotes,
        withdrawnBopPlusQuotes: withdrawnBopPlusQuotes,
        habQuotes: habQuotes,
        sampleAccount: account.SampleAccount_ATTN || false,
        state: primaryAddress.State,
        website: account.WebsiteURL_ATTN,
        zip: primaryAddress.PostalCode,
        fein: fein,
        hasSafetyReviewAlert,
      },
      account.AccountNumber
    );
  }

  private static fromIndividualBackendApiListAccountPayload(
    account: BackendListAccount
  ): InsuredAccount {
    const accountHolderContact = account.contact;
    const primaryAddress = accountHolderContact.PrimaryAddress;
    const naicsCode = _.get(account, 'naicsCodes.Entry[0]');

    return new InsuredAccount(
      {
        addressLine1: primaryAddress.AddressLine1,
        addressLine2: primaryAddress.AddressLine2,
        city: primaryAddress.City,
        companyName: accountHolderContact.Name,
        createdByUser: account.createdByUser,
        description: account.description,
        doingBusinessAs: account.doesBusinessAsHUSA,
        emailAddress: accountHolderContact.EmailAddress1,
        naicsCode: naicsCode
          ? {
              code: naicsCode.Code,
              description: naicsCode.Description,
              hash: naicsCode.Hash,
            }
          : null,
        ofacAlertPresent: account.blockedOFAC === 'YES',
        organizationType: account.organizationType,
        phoneNumber: accountHolderContact.WorkPhone,
        sampleAccount: account.sampleAccount || false,
        state: primaryAddress.State,
        website: account.website,
        zip: primaryAddress.PostalCode,
      },
      account.accountNumber
    );
  }

  private static bopQuotesWithExcess(account: AccountDetails) {
    const sourceQuotes: BackendQuoteEntry[] = [];
    const excessQuotes: BackendQuoteEntry[] = [];

    if (account.AllPoliciesSummary_HUSA) {
      account.AllPoliciesSummary_HUSA.Entry.forEach((sourcePolicy: BackendQuoteEntry) => {
        if (
          sourcePolicy.Product.DisplayName === BOP_PRODUCT_NAME &&
          // What is the significance of these values? Should this be an enum?
          ['Draft', 'Quoted', 'Declined', 'NotTaken', 'Expired'].includes(
            sourcePolicy.LatestPeriodStatus_HUSA
          )
        ) {
          sourceQuotes.push(sourcePolicy);
        } else if (
          sourcePolicy.Product.DisplayName === MXS_PRODUCT_NAME &&
          ['Quoted', 'Declined', 'NotTaken', 'Expired'].includes(
            sourcePolicy.LatestPeriodStatus_HUSA
          )
        ) {
          excessQuotes.push(sourcePolicy);
        }
      });
    }
    return InsuredAccount.quotesFromPayload(sourceQuotes, excessQuotes);
  }

  private static attuneWcQuotes(account: AccountDetails) {
    let attuneWcQuotes: BackendQuoteEntry[] = [];

    if (account.AllPoliciesSummary_HUSA) {
      attuneWcQuotes = account.AllPoliciesSummary_HUSA.Entry.filter(
        (sourcePolicy: BackendQuoteEntry) => {
          return (
            sourcePolicy.Product.DisplayName === WC_PRODUCT_NAME &&
            // What is the significance of these values? Should this be an enum?
            ['Draft', 'Quoted', 'Declined', 'NotTaken', 'Expired'].includes(
              sourcePolicy.LatestPeriodStatus_HUSA
            )
          );
        }
      );
    }
    return InsuredAccount.quotesFromPayload(attuneWcQuotes);
  }

  private static inForceQuotes(account: AccountDetails) {
    const sourceQuotes: BackendQuoteEntry[] = [];
    const excessQuotes: BackendQuoteEntry[] = [];

    if (account.AllPoliciesSummary_HUSA) {
      account.AllPoliciesSummary_HUSA.Entry.forEach((sourcePolicy: BackendQuoteEntry) => {
        if (
          sourcePolicy.Product.DisplayName === BOP_PRODUCT_NAME &&
          sourcePolicy.LatestPeriodStatus_HUSA === 'In Force'
        ) {
          sourceQuotes.push(sourcePolicy);
        } else if (
          sourcePolicy.Product.DisplayName === MXS_PRODUCT_NAME &&
          sourcePolicy.LatestPeriodStatus_HUSA === 'In Force'
        ) {
          excessQuotes.push(sourcePolicy);
        }
      });
    }
    return InsuredAccount.quotesFromPayload(sourceQuotes, excessQuotes);
  }

  private static withdrawnBopPlusQuotes(account: AccountDetails) {
    const withdrawnQuotes: BackendQuoteEntry[] = [];

    if (account.AllPoliciesSummary_HUSA) {
      account.AllPoliciesSummary_HUSA.Entry.forEach((sourcePolicy: BackendQuoteEntry) => {
        if (
          sourcePolicy.Product.DisplayName === BOP_PRODUCT_NAME &&
          isBopV2(sourcePolicy.UWCompanyCode) &&
          sourcePolicy.LatestPeriodStatus_HUSA === 'Withdrawn' &&
          sourcePolicy.Job &&
          sourcePolicy.Job.CreateUser &&
          sourcePolicy.Job.CreateUser.ExternalUser === false
        ) {
          withdrawnQuotes.push(sourcePolicy);
        }
      });
    }
    return InsuredAccount.quotesFromPayload(withdrawnQuotes, []);
  }

  private static habQuotes(account: AccountDetails) {
    let sourceQuotes: BackendQuoteEntry[] = [];
    const policiesSummary = account.AllPoliciesSummary_HUSA;

    if (policiesSummary) {
      sourceQuotes = policiesSummary.Entry.filter((sourcePolicy) => {
        return (
          sourcePolicy.Product.DisplayName === HAB_PRODUCT_NAME &&
          ['Draft', 'Quoted', 'Declined', 'NotTaken', 'Expired'].includes(
            sourcePolicy.LatestPeriodStatus_HUSA
          )
        );
      });
    }

    return InsuredAccount.quotesFromPayload(sourceQuotes);
  }

  private static mapPoliciesWithTerms(account: AccountDetails): {
    policyNumber: string;
    productName: string;
    id: string;
    terms: AccountPolicyTerm[];
    uwCompanyCode: UwCompanyCode;
  }[] {
    const policies: GwAccountPolicy[] | undefined = account.Policies?.Entry;

    if (!policies) {
      return [];
    }

    const boundExpiredOrCanceledPolicies = policies.filter((backendPolicy: GwAccountPolicy) => {
      return _.some(
        _.get(backendPolicy, 'PortalViewableNewTermPeriods.Entry', []),
        (term: AccountPolicyPeriod) => {
          return (
            ['binding', 'in force', 'canceled', 'scheduled', 'expired'].includes(
              term.TermDisplayStatus_ATN.toLowerCase()
            ) && term.PolicyNumber
          );
        }
      );
    });

    return boundExpiredOrCanceledPolicies.map((backendPolicy: GwAccountPolicy) => {
      const backendPolicyTerms: AccountPolicyPeriod[] =
        backendPolicy.PortalViewableNewTermPeriods?.Entry || [];
      const terms = backendPolicyTerms.map((backendTerm: AccountPolicyPeriod) => {
        let totalCost;
        if (
          ['canceled', 'non-renewing', 'non-renewed'].includes(
            backendTerm.TermDisplayStatus_ATN.toLowerCase()
          )
        ) {
          totalCost = null;
        } else if (!backendTerm.TermTotalCost_ATN) {
          throw new Error(
            'Error while looping through bound policies. TermTotalCost_ATN is unexpectedly null. accountDetailsResponse = ' +
              JSON.stringify(account)
          );
        } else {
          totalCost = backendTerm.TermTotalCost_ATN.Amount;
        }
        return {
          productName: backendTerm.LineBusinessType,
          policyEffectiveDate: moment.utc(backendTerm.PeriodStart),
          policyExpirationDate: moment.utc(backendTerm.PeriodEnd),
          policyNumber: backendTerm.PolicyNumber,
          status: backendTerm.TermDisplayStatus_ATN,
          totalCost: totalCost,
          id: backendTerm.Job.JobNumber,
          termNumber: backendTerm.TermNumber,
          updatedAt: moment(backendTerm.Job.UpdateTime),
          uwCompanyCode: backendTerm.UWCompanyCode,
        };
      });

      const processedTerms = _.orderBy(
        _.filter(terms, (term) => {
          if (environment.showNewRenewalsUI && term.status.toLowerCase() === 'quoted') {
            // Note: Only display quoted renewals during the (currently) 60 days before the previous policy expires, and not before then
            const displayQuotedStartDate = term.policyEffectiveDate
              .clone()
              .subtract(THRESHOLD_FOR_RENEWAL_QUOTE_DISPLAY_DAYS, 'days');
            if (moment.utc().isAfter(displayQuotedStartDate)) {
              return true;
            } else {
              return false;
            }
            // Fall through to the regular status handling here
          }
          if (VISIBLE_POLICY_PERIOD_STATUSES_LOWER.includes(term.status.toLowerCase())) {
            return true;
          }
          return false;
        }),
        'policyEffectiveDate',
        'desc'
      );
      const latestTerm = processedTerms[0];
      const inForceTerm = _.find(
        processedTerms,
        (term) => term.status.toLowerCase() === 'in force'
      );

      return {
        productName: latestTerm.productName,
        policyNumber: inForceTerm?.policyNumber || latestTerm.policyNumber,
        id: inForceTerm?.id || latestTerm.id,
        terms: processedTerms,
        uwCompanyCode: inForceTerm?.uwCompanyCode || latestTerm.uwCompanyCode,
      };
    });
  }

  private static quotesFromPayload(
    sourceQuotes: BackendQuoteEntry[],
    excessQuotes: BackendQuoteEntry[] = []
  ): QuoteSummary[] {
    return sourceQuotes.map((sourceQuote: BackendQuoteEntry): QuoteSummary => {
      let excessQuote: BackendQuoteEntry | null = null;

      if (sourceQuote.Job.LinkedJobNumber_ATN) {
        // Has associated Excess Policy
        excessQuote =
          _.find(excessQuotes, {
            Job: { JobNumber: sourceQuote.Job.LinkedJobNumber_ATN },
          }) || null;
      }

      let totalCost = sourceQuote.TotalCostRPT || 0;
      if (excessQuote) {
        totalCost += excessQuote.TotalCostRPT || 0;
      }

      return {
        excessPremium: excessQuote ? excessQuote.TotalCostRPT : null,
        hasExcessPolicy: !!excessQuote,
        id: sourceQuote.Job.JobNumber,
        policyEffectiveDate: moment.utc(sourceQuote.PeriodStart), // NB always represent policyEffectiveDate/policyExpirationDate as UTC dates
        policyExpirationDate: moment.utc(sourceQuote.PeriodEnd),
        policyNumber: sourceQuote.PolicyNumber || '',
        productName: sourceQuote.Product.DisplayName,
        status: sourceQuote.LatestPeriodStatus_HUSA,
        subType: sourceQuote.Job.Subtype || 'Submission',
        timeLastUpdated: moment(sourceQuote.Job.UpdateTime),
        creator: sourceQuote.Job ? sourceQuote.Job.CreateUser : null,
        // Repeated "undefined" values required her to satisfy some TypeScript weirdness
        // Once we have optional chaining enabled, this should be simplified
        linkedJobNumber: sourceQuote.Job
          ? sourceQuote.Job.LinkedJobNumber_ATN || undefined
          : undefined,
        totalCost: totalCost,
        uwCompanyCode: sourceQuote.UWCompanyCode,
        AutoBindRollOver_ATTN: sourceQuote.AutoBindRollOver_ATTN,
      };
    });
  }

  private static insuredContactsFromPayload(acount: AccountDetails) {
    // We filter out any contacts in GW missing the required fields for Insured Contacts.
    return acount.InsuredContacts?.Entry.map((contact) => {
      return {
        email: contact.Contact?.EmailAddress1,
        firstName: contact.Contact?.['entity-Person']?.FirstName,
        lastName: contact.Contact?.['entity-Person']?.LastName,
        phone: contact.Contact?.WorkPhone,
        roles: contact.Roles?.Entry.map((role) => role.Subtype),
      };
    }).filter((contact): contact is InsuredContact => {
      return !!(
        contact.email &&
        contact.firstName &&
        contact.lastName &&
        contact.phone &&
        contact.roles
      );
    });
  }

  private static hasSafetyReviewAlert(account: AccountDetails) {
    // In force, first term WC policy with effective date less than 90 days ago
    const inForceWcPolicy = account.AllPoliciesSummary_HUSA?.Entry.find((policy) => {
      return (
        policy.Product.DisplayName === WC_PRODUCT_NAME &&
        policy.LatestPeriodStatus_HUSA === 'In Force' &&
        moment.utc(policy.PeriodStart).isAfter(moment.utc().subtract(90, 'days'))
      );
    });
    const isFirstTermPolicy = account.Policies?.Entry.some((policy: GwAccountPolicy) => {
      const policyInfo = _.get(policy, 'PortalViewableNewTermPeriods.Entry');
      return policyInfo?.some((policyPeriod: AccountPolicyPeriod) => {
        return (
          policyPeriod.PolicyNumber === inForceWcPolicy?.PolicyNumber &&
          policyPeriod.TermNumber === '1' &&
          policyPeriod.TermDisplayStatus_ATN === 'In Force'
        );
      });
    });

    return isFirstTermPolicy && inForceWcPolicy?.WCALine?.SafetyReview_ATTN;
  }

  public static fromTermPolicyPayload(payload: any) {
    return payload.return.PolicyPeriod;
  }

  public address() {
    const address2Str = _.isEmpty(this.addressLine2) ? '' : ' ' + this.addressLine2;
    return `${this.addressLine1} ${address2Str}, ${this.city}, ${this.state} ${this.zip}`;
  }

  public gMapsAddress() {
    return encodeURIComponent(this.address());
  }

  public getProducerCodes(): string[] {
    if (_.isNil(this.producerCodesStruct)) {
      return [];
    }

    const userProducerCodes: string[] = this.producerCodesStruct.Entry.map(
      (producerCodeWrapper) => producerCodeWrapper.ProducerCode.Code
    );

    return userProducerCodes;
  }
}
