// Libraries
import { Component, isDevMode, OnInit, OnDestroy } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Components
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';

// Services
import { V3QuoteService } from 'app/shared/services/v3-quote-service';
import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';

// Typings
import { HabFormLocation, HabFormLocationSummary, PartialHabForm } from 'app/hab/typings';

// Models
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';

// Constants
import {
  AppError,
  DECLINED_ERROR,
  UNKNOWN_ERROR_WITHOUT_RETRY,
} from 'app/shared/quote-error-modal/errors';

@Component({
  selector: 'app-hab.app-page.app-page__form',
  templateUrl: './hab-quote-form.component.html',
  providers: [HabQuoteFormService],
})
export class HabQuoteFormComponent
  extends FormDslSteppedFormBaseComponent<HabQuoteFormService>
  implements OnInit, OnDestroy
{
  constructor(
    protected amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    public formService: HabQuoteFormService,
    protected insuredAccountService: InsuredAccountService,
    protected route: ActivatedRoute,
    protected router: Router,
    public v3QuoteService: V3QuoteService
  ) {
    super(formService, route, router);
  }

  // Flags
  public isDevMode: boolean = isDevMode();
  public isLoadingAccount = true;
  public isQuoteInProgress = false;
  public shouldDisplayQuoteErrorModal = false;
  public waitingForEligibilityCheck = false;

  public accountId: string;
  public errors: string[] = [];
  public errorType: AppError = UNKNOWN_ERROR_WITHOUT_RETRY;
  public form: UntypedFormGroup;
  public insAccount$: BehaviorSubject<InsuredAccount> = new BehaviorSubject(new InsuredAccount());
  public quote: QSQuoteSubmission | null = null;
  public guidewireId: string | null = null;
  private sub: Subscription = new Subscription();

  ngOnInit() {
    super.ngOnInit();

    this.accountId = this.route.snapshot.params['accountId'];
    const isFromAccountCreate = this.route.snapshot.queryParams['from-account-create'];

    this.formService.setAccountStepVisibility(isFromAccountCreate);

    this.sub.add(
      this.insuredAccountService.get(this.accountId).subscribe((insuredAccount: InsuredAccount) => {
        this.isLoadingAccount = false;
        this.insAccount$.next(insuredAccount);
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  public onIncrementedStep(nextStep: RouteFormStep): void {
    const buildingFormPathRegex = /locations\.(\d+)\.buildings\.(\d+)/;
    const nextStepFormPath: string | null = nextStep.formPath;
    /**
     * Only check eligibility for the loss history page and the building pages.
     */
    if (
      nextStepFormPath !== null &&
      !buildingFormPathRegex.test(nextStepFormPath) &&
      nextStepFormPath !== 'additionalCoverages'
    ) {
      this.navigateToCurrentStep();
      return;
    }

    this.waitingForEligibilityCheck = true;
    const visitedSteps = this.formService.getStepsUptoGivenStep(nextStep);
    // Build up a form to pass to the requestHabQuote function that only includes fields that have been filled in with values so far (so there won't be any null fields)
    // The partial quote endpoint in service quote expects a payload that doesn't have values for fields that haven't been filled in yet, and it will return whether or not the form so far is eligible or not
    const formSoFar: PartialHabForm = {};
    for (let i = 0; i < visitedSteps.length; i++) {
      const formPath = visitedSteps[i].formPath;
      if (
        formPath === 'guidelines' ||
        formPath === 'lossHistory' ||
        formPath === 'additionalCoverages' ||
        formPath === 'generalLiability'
      ) {
        formSoFar[formPath] = this.form.value[formPath];
      } else if (formPath === 'policyDetails') {
        formSoFar.policyDetails = this.form.value.policyDetails;
        formSoFar.locations = this.form.value.policyDetails.locationSummaries.map(
          (locationSummary: HabFormLocationSummary): HabFormLocation => {
            return {
              address: locationSummary.address,
              features: locationSummary.features,
            };
          }
        );
      } else if (formPath && formPath.includes('buildings')) {
        const matchedResults = formPath.match(buildingFormPathRegex);
        if (matchedResults === null) {
          this.sentryService.notify(
            `Unexpected formPath string found: ${formPath}.  Expected formPath to match pattern locations.#.buildings.#`,
            {
              severity: 'error',
            }
          );
          this.errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
          this.shouldDisplayQuoteErrorModal = true;
          return;
        }
        const locationIndex = parseInt(matchedResults[1], 10);
        const buildingIndex = parseInt(matchedResults[2], 10);
        if (
          isNaN(locationIndex) ||
          isNaN(buildingIndex) ||
          locationIndex > this.form.value['locations'].length ||
          buildingIndex > this.form.value['locations'][locationIndex]['buildings'].length
        ) {
          this.sentryService.notify(
            `Error parsing formPath ${formPath}.  Location index = ${locationIndex}.  Building index = ${buildingIndex}.  Form data = ${this.form.value}`,
            {
              severity: 'error',
            }
          );
          this.errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
          this.shouldDisplayQuoteErrorModal = true;
          return;
        }

        const locations = formSoFar.locations;
        if (locations === undefined) {
          this.sentryService.notify(
            `locations unexpectedly doesn't have a value.  Value should have been assigned from policy details page`,
            {
              severity: 'error',
            }
          );
          this.errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
          this.shouldDisplayQuoteErrorModal = true;
          return;
        }
        const curLocation = locations[locationIndex];
        if (curLocation.buildings === undefined) {
          curLocation.buildings = [];
        }
        curLocation.buildings.push(
          this.form.value['locations'][locationIndex]['buildings'][buildingIndex]
        );
      }
    }
    this.v3QuoteService
      .requestHabQuote({ form: formSoFar, isPartialQuote: true, guidewireId: this.guidewireId })
      .pipe(switchMap((quote: QSQuoteSubmissionResponse) => this.checkQuotingSuccess(quote)))
      .subscribe((success: boolean) => {
        if (!success) {
          this.doneLoading(2000).subscribe(() => {
            this.waitingForEligibilityCheck = false;
            this.handleFailedQuote();
          });
        } else {
          this.waitingForEligibilityCheck = false;
          this.guidewireId = this.quote ? this.quote.data.id : null;
          this.navigateToCurrentStep();
        }
      });
  }

  public populateIneligibleLocation(): void {
    console.warn('To be implemented');
  }

  public populateKnownGoodHappyPathValues(): void {
    this.formService.fillInHappyPath();
  }

  public showEligibilityCriteriaRail(): boolean {
    const currentStepName = this.currentStep().slug;

    // Matches: /location/{{at least one number}}/building/{{at least one number}}
    const buildingStepRegex: RegExp = /location\/\d+\/building\/\d+/;

    return currentStepName === 'policy-details' || buildingStepRegex.test(currentStepName);
  }

  public handleNavigateToSlug(slug: string) {
    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep(), step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  public checkQuotingSuccess(response: QSQuoteSubmissionResponse): Observable<boolean> {
    if (!response || !Object.prototype.hasOwnProperty.call(response, 'results')) {
      this.sentryService.notify('Received bad response from GW while submitting hab quote', {
        severity: 'error',
        metaData: {
          response,
        },
      });
      this.quote = null;
      return observableOf(false);
    }
    return observableOf(response).pipe(
      tap((quote: QSQuoteSubmissionResponse) => {
        this.quote = quote.results[0];
      }),
      map((quote: QSQuoteSubmissionResponse) => {
        return !Object.prototype.hasOwnProperty.call(quote.results[0].data, 'errors');
      })
    );
  }

  public loadInitialData(): void {}

  public sendForm(): Observable<QSQuoteSubmissionResponse> {
    return this.insuredAccountService
      .updateOrgTypeIfNecessary(this.form.value.policyDetails.organizationType)
      .pipe(
        switchMap(() => {
          return this.v3QuoteService.requestHabQuote({
            form: this.form.value,
            isPartialQuote: false,
            guidewireId: this.guidewireId,
          });
        }),
        catchError((error) => {
          this.quoting = false;
          this.errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
          this.shouldDisplayQuoteErrorModal = true;
          return [];
        })
      );
  }

  public handleSuccessfulQuote(): void {
    this.quoting = false;
    // We're asserting that quote is non-null because quote wouldn't be a success otherwise
    this.insuredAccountService.cachebust();
    this.router.navigate([
      'accounts',
      this.accountId,
      'hab',
      'policies',
      (this.quote as QSQuoteSubmission).data.id,
    ]);
  }

  public handleFailedQuote(): void {
    if (this.quote === null) {
      this.errorType = UNKNOWN_ERROR_WITHOUT_RETRY;
      this.shouldDisplayQuoteErrorModal = true;
      return;
    }
    const errorDetails = this.quote.data as QSQuoteSubmissionErrorData;
    const errors: string[] = errorDetails.errors;

    this.amplitudeService.track({
      eventName: 'hab_v3_error',
      detail: JSON.stringify(errors),
      useLegacyEventName: true,
    });
    this.errorType = _.cloneDeep(DECLINED_ERROR(1));
    this.errorType.body = `Please refer to our guidelines to learn more`;
    this.errors = errors;

    if (this.errors.length > 0) {
      this.amplitudeService.track({
        eventName: 'hab_v3_error_message',
        detail: JSON.stringify(errors),
        useLegacyEventName: true,
      });
    }

    this.shouldDisplayQuoteErrorModal = true;
  }

  public closeQuoteErrorModal(result: { close: boolean; retry: boolean }): void {
    if (!result.close && !result.retry) {
      this.insuredAccountService.cachebust();
      this.router.navigate(['accounts', this.accountId]);
    }
    if (result.close) {
      this.shouldDisplayQuoteErrorModal = false;
    }
    if (result.retry) {
      this.formService.stepForward();
    }

    this.errors = [];
  }
}
