interface NonDirectPayCommissionDetails {
  hasDirectCommission: false;
}

interface DirectPayCommissionDetails {
  hasDirectCommission: true;
  isPayable: boolean;
  financeUsers: string[];
  errorRetrievingIsPayableInfo: false;
}

interface DirectCommissionDataWithIsPayableError {
  hasDirectCommission: true;
  financeUsers: string[];
  errorRetrievingIsPayableInfo: true;
}

export type CommissionPayableDetails =
  | NonDirectPayCommissionDetails
  | DirectPayCommissionDetails
  | DirectCommissionDataWithIsPayableError;

export const BANK_DETAILS_REQUIRED_HEADER_TEXT = 'Bank Details Required for Commission Payouts';
export const BANK_DETAILS_REQUIRED_FINANCE_USER_MESSAGE =
  'To enable commission payouts owed to your agency now and/or in the future, please update your bank and taxation details in the <a href="/settings/finances">Financial Info</a> page.';

export const BANK_DETAILS_REQUIRED_NON_FINANCE_USER_MESSAGE = (emails: string[]) =>
  `To enable commission payouts owed now and/or in the future, your agency’s finance user(s)
  must provide recipient bank and taxation details to Attune. ${
    emails.length
      ? `Your agency’s finance user(s) are:
  <ul class="bulleted-list">
    ${emails.map((email) => `<li>${email}</li>`).join('')}
  </ul>
`
      : ''
  } `;

export const COMMISSION_PAYOUT_DETAILS_NOT_AVAILABLE_HEADER_TEXT = 'No commission data available';
export const COMMISSION_PAYOUT_DETAILS_NOT_AVAILABLE_MESSAGE = (org: string) =>
  `Your agency is configured to receive commission payments and information from your parent organization, ${org}.  For more information about your commission setup and payouts, please reach out directly to your parent organization. If you do not know who to reach out to, please contact Attune’s Customer Support Team <a href="/support">here.</a>`;
export const COMMISSION_PAYOUT_DETAILS_HIDDEN_HEADER_TEXT = 'Commission payout details hidden';
export const COMMISSION_PAYOUT_DETAILS_NON_FINANCE_USER_MESSAGE = (emails: string[]) =>
  `Please note that sensitive financial data (commission statements and payout amounts) is only visible to Finance Users. ${
    emails.length
      ? `The following users at your agency are Finance Users:
  <ul class="bulleted-list">
    ${emails.map((email) => `<li>${email}</li>`).join('')}
  </ul>
  If you believe that you should be a Finance User, please reach out to our Customer Support Team <a href="/support">here.</a>`
      : ''
  } `;
