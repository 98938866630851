import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { mapValues, values } from 'lodash';
import { Observable, BehaviorSubject, of as observableOf, forkJoin, take } from 'rxjs';
import { flatMap, catchError, map, switchMap } from 'rxjs/operators';

import { API_V3_BASE, API_V4_BASE, V4_PRODUCT_AVAILABILITY_API_URI } from 'app/constants';
import { shouldGetBopV2, shouldGetBopV3 } from 'app/shared/helpers/account-helpers';
import { SentryService } from 'app/core/services/sentry.service';
import { ProductAvailability } from 'app/features/digital-carrier/models/types';
import { CyberMarketingGroup } from '../../features/coalition/models/cyber-typings.model';
import { environment } from 'environments/environment';
import { FeatureFlagService, JSON_FLAG_NAMES } from 'app/core/services/feature-flag.service';
import { AttuneBopFortegraEnabledStates } from 'app/shared/services/typings';

const NAICS_CODES_URI = `${API_V3_BASE}/naics/codes`;
const NAICS_PRODUCT_URI = `${API_V3_BASE}/naics/product`;
const NAICS_PRODUCT_DATA_URI = `${NAICS_PRODUCT_URI}/data`;
const NAICS_MAPPING_URI = `${API_V3_BASE}/naics/mapping`;
export const NAICS_ELIGIBILITY_URI = `${API_V3_BASE}/naics/eligibility`;

// These are tags set in the AskKodiak UI, within a kodiak product's detailed view.
export enum AskKodiakTag {
  BOP = 'ProdGuidelinesV2',
  BOP_V2 = 'ProdGuidelinesBOPV2',
  BOP_V3 = 'ProdGuidelinesBOPV3', // Fortegra, -OB1mDehRQejOn1xoEdI
  HAB = 'ProdHabGuidelines',
  MAPPINGS = 'ProdNaicsToIso',
  WC_EMPLOYERS = 'ProdEmployers',
  ATTUNE_WC_STAGING = 'AttuneWcStaging',
  ATTUNE_WC_PRODUCTION = 'AttuneWcProduction',
}
// Carrier-managed AskKodiak Product IDs
export enum AskKodiakProductID {
  HX_Gl = '-NCLBTc5vwuIWsv-G6WR',
  HX_Pl = '-NCLBqdSxoztDvgriA2W',
  LM_BOP = '-KVvsoO76_cEIvlBCNLl',
  LM_CPSP = '-MhtKYa2DTMJLBlPD1A0',
  CL_A = '-MtnZhaJ1wfI0qDPLgJ0',
}
// Custom classification IDS for mapping api
enum AskKodiakClassificationID {
  COALITION_CYBER = 'co-cyb',
  ACCREDITED_BOP = 'accrd_BOP',
  FORTEGRA_BOP = 'fortegra_BOP',
  NCCI_WC = 'ncci-wc',
}

export interface Industry {
  displayName: string;
  id: number;
}

export const DEFAULT_ELIGIBILITY: ProductEligibility = {
  isBopEligible: true,
  isAttuneWcEligible: false,
  isEmployersWcEligible: true,
  isHabEligible: false,
  isGlEligible: true,
  isPlEligible: true,
  isLibertyMutualBopEligible: false,
  isLibertyMutualCpspEligible: false,
  isCyberEligible: false,
};

interface ObjString {
  [key: string]: string;
}

export interface NaicsEligibility {
  isEligible: boolean;
}

export interface ProductEligibility {
  isBopEligible: boolean;
  isAttuneWcEligible: boolean;
  isEmployersWcEligible: boolean;
  isHabEligible: boolean;
  isGlEligible: boolean;
  isPlEligible: boolean;
  isLibertyMutualBopEligible: boolean;
  isLibertyMutualCpspEligible: boolean;
  isCyberEligible: boolean;
}

@Injectable()
export class NaicsService {
  // This subject will emit updates to the NAICS code of the currently selected account in the UI (account edit flow).
  public updateProductEligibility: BehaviorSubject<{
    accountId: string;
    naicsCode: NaicsCode;
    stateCode: string;
  } | null> = new BehaviorSubject(null);

  private kodiakProductTagsWithIds: BehaviorSubject<ObjString> = new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private featureFlagService: FeatureFlagService
  ) {
    this.getProductTagsWithIds().subscribe((results) => {
      this.kodiakProductTagsWithIds.next(results);
    });
  }

  getProductTagsWithIds(): Observable<ObjString> {
    return this.http.get<ObjString>(`${NAICS_PRODUCT_URI}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting NAICS product tags with Ids.', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getProductID(productTag: AskKodiakTag | AskKodiakProductID): Observable<string> {
    // in the case of a Hiscox product, the product id is known - no need to get id via a tag
    const productID = values(AskKodiakProductID).includes(productTag as AskKodiakProductID)
      ? productTag
      : this.kodiakProductTagsWithIds.value[productTag];

    if (productID) {
      return observableOf(productID);
    } else {
      return this.getProductTagsWithIds().pipe(
        flatMap((results) => {
          this.kodiakProductTagsWithIds.next(results);
          if (!results[productTag]) {
            throw new Error(
              `Ask Kodiak has no product ID associated to requested product tag: ${productTag}`
            );
          }
          return observableOf(results[productTag]);
        })
      );
    }
  }

  getProduct(
    naicsHash: string,
    state: string,
    productTag: AskKodiakTag | AskKodiakProductID
  ): Observable<AskKodiakProductData> {
    return <Observable<AskKodiakProductData>>this.getProductID(productTag).pipe(
      flatMap((productID) => {
        return this.http.get<AskKodiakProductData>(
          `${NAICS_PRODUCT_DATA_URI}/${productID}?state=${state}&naicsHash=${naicsHash}`
        );
      }),
      catchError((error) => {
        this.sentryService.notify('Error getting NAICS product.', {
          severity: 'error',
          metaData: {
            naicsHash,
            state,
            productTag,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getGuidelines(
    bopVersion: BopVersion,
    naicsHash: string,
    state: string
  ): Observable<AskKodiakProductData> {
    let tag: AskKodiakTag;
    switch (bopVersion) {
      case 3:
        tag = AskKodiakTag.BOP_V3;
        break;
      case 2:
        tag = AskKodiakTag.BOP_V2;
        break;
      case 1:
      default:
        tag = AskKodiakTag.BOP;
        break;
    }
    return this.getProduct(naicsHash, state, tag);
  }

  getNaicsToIsoMappings(naicsHash: string, state: string): Observable<AskKodiakISOSuggestions> {
    return this.getProduct(naicsHash, state, AskKodiakTag.MAPPINGS).pipe(
      flatMap((data: AskKodiakProductData) => {
        return <Observable<AskKodiakISOSuggestions>>observableOf({
          categories: data.highlights || [],
          classifications: data.guidelines || [],
        });
      })
    );
  }

  searchCodes(searchTerm: string): Observable<NaicsCode[]> {
    return this.http.get<NaicsCode[]>(`${NAICS_CODES_URI}/${encodeURIComponent(searchTerm)}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Error searching for NAICS codes.', {
          severity: 'error',
          metaData: {
            searchTerm,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getEmployersWcEligibility(naicsHash: string, stateCode?: string): Observable<NaicsEligibility> {
    return this.getEligibility(naicsHash, AskKodiakTag.WC_EMPLOYERS, stateCode);
  }

  /* A naics mapping is a mapping form a standard naics code to all codes in the classification
      that correspond the that naics code.
   */
  getNaicsMapping(naicsHash: string, classificationID: AskKodiakClassificationID): Observable<any> {
    return this.http.get<any>(`${NAICS_MAPPING_URI}/${classificationID}/${naicsHash}`).pipe(
      catchError((error) => {
        this.sentryService.notify('Error getting NAICS mapping.', {
          severity: 'error',
          metaData: {
            naicsHash,
            classificationID,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      })
    );
  }

  getCyberLiabilityNaicsMapping(naicsHash: string): Observable<Industry[]> {
    return this.getNaicsMapping(naicsHash, AskKodiakClassificationID.COALITION_CYBER).pipe(
      map((responseData) => responseData.mappings)
    );
  }

  getAccreditedBopNaicsMapping(naicsHash: string): Observable<Industry[]> {
    return this.getNaicsMapping(naicsHash, AskKodiakClassificationID.ACCREDITED_BOP).pipe(
      map((responseData) => responseData.mappings)
    );
  }

  getNcciWcNaicsMapping(naicsHash: string): Observable<Industry[]> {
    return this.getNaicsMapping(naicsHash, AskKodiakClassificationID.NCCI_WC).pipe(
      map((responseData) => responseData.mappings)
    );
  }

  private getEligibility(
    naicsHash: string,
    tagName: AskKodiakTag | AskKodiakProductID,
    stateCode?: string
  ): Observable<NaicsEligibility> {
    return this.getProductID(tagName).pipe(
      flatMap((productID) => {
        let params = new HttpParams();
        if (stateCode) {
          params = params.set('stateCode', stateCode);
        }

        return this.http.get<NaicsEligibility>(
          `${NAICS_ELIGIBILITY_URI}/${productID}/${naicsHash}`,
          {
            params: params,
          }
        );
      }),
      catchError((error) => {
        this.sentryService.notify('Error getting NAICS eligibility.', {
          severity: 'error',
          metaData: {
            naicsHash,
            tagName,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        // TODO: we might want to revisit this assumption
        return observableOf({ isEligible: true }); // If anything goes wrong with AskKodiak, we'll assume the account can quote.
      })
    );
  }

  private getBopEligibilityRequest(naicsHash: string, stateCode?: string) {
    return this.fortegraIsEnabled(stateCode).pipe(
      take(1),
      switchMap((isEnabled: boolean) => {
        // Before a state selection is made, default to bop+ product tag for eligibility
        const bopProductTagToUse =
          stateCode && !shouldGetBopV2(stateCode) ? AskKodiakTag.BOP : AskKodiakTag.BOP_V2;
        // unless Fortegra is enabled
        const finalBopProductTagToUse = isEnabled ? AskKodiakTag.BOP_V3 : bopProductTagToUse;
        return this.getEligibility(naicsHash, finalBopProductTagToUse, stateCode);
      })
    );
  }

  public getProductAvailability(isQuoteFlow: boolean = false): Observable<ProductAvailability[]> {
    // As other insurance products are moved into Product Availability, they
    // will need to be added here.
    const NO_PRODUCT_AVAILABILITY = [
      {
        classCodeSelection: 'NONE',
        product: 'bop',
        pasSource: 'liberty_mutual',
      },
      {
        classCodeSelection: 'NONE',
        product: 'cpsp',
        pasSource: 'liberty_mutual',
      },
      {
        classCodeSelection: 'NONE',
        product: 'flood',
        pasSource: 'neptune',
      },
      {
        classCodeSelection: 'NONE',
        product: 'cyber_admitted',
        pasSource: 'coalition',
      },
      {
        classCodeSelection: 'NONE',
        product: 'cyber_surplus',
        pasSource: 'coalition',
      },
      {
        classCodeSelection: 'NONE',
        product: 'gl',
        pasSource: 'hiscox',
      },
      {
        classCodeSelection: 'NONE',
        product: 'pl',
        pasSource: 'hiscox',
      },
      {
        classCodeSelection: 'NONE',
        product: 'bop',
        pasSource: 'attune_gw',
      },
    ] as ProductAvailability[];

    const fetchClassCodes$ = this.http
      .get<ProductAvailability[]>(V4_PRODUCT_AVAILABILITY_API_URI)
      .pipe(
        catchError((error) => {
          // TODO: Make this namespace DCP later on when we create DCP Sentry alerts
          this.sentryService.notify('Liberty Mutual: error getting product availability', {
            severity: 'error',
            metaData: {
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          // Note: when called from quote form, throwing error to show modal
          // otherwise, returing no product availability
          if (isQuoteFlow) {
            throw error;
          } else {
            return observableOf(NO_PRODUCT_AVAILABILITY);
          }
        })
      );

    return fetchClassCodes$;
  }

  public fortegraIsEnabled(state: string | undefined) {
    if (!state) {
      return observableOf(false);
    }

    return this.featureFlagService
      .getJsonFlagValue<AttuneBopFortegraEnabledStates | null>(
        JSON_FLAG_NAMES.ATTUNE_BOP_FORTEGRA_ENABLED_STATES
      )
      .pipe(
        map((attuneBopFrontegraEnabledStates: AttuneBopFortegraEnabledStates) =>
          attuneBopFrontegraEnabledStates
            ? shouldGetBopV3(attuneBopFrontegraEnabledStates, state)
            : false
        )
      );
  }

  public getProductEligibility(
    naicsHash: string,
    stateCode?: string
  ): Observable<ProductEligibility> {
    const bopEligibilityReq$ = this.getBopEligibilityRequest(naicsHash, stateCode);

    const getAttuneWcEligibility$ = this.getEligibility(
      naicsHash,
      this.getAttuneWCProductTag(),
      stateCode
    );

    const getEmployersWcEligibility$ = this.getEligibility(
      naicsHash,
      AskKodiakTag.WC_EMPLOYERS,
      stateCode
    );

    const getHabEligibility$ = this.getEligibility(naicsHash, AskKodiakTag.HAB, stateCode);

    const getGlEligibility$ = this.getEligibility(naicsHash, AskKodiakProductID.HX_Gl, stateCode);

    const getPlEligibility$ = this.getEligibility(naicsHash, AskKodiakProductID.HX_Pl, stateCode);

    const getLibertyMutualBopEligibility$ = this.getEligibility(
      naicsHash,
      AskKodiakProductID.LM_BOP,
      stateCode
    );

    const getLibertyMutualCpspEligibility$ = this.getEligibility(
      naicsHash,
      AskKodiakProductID.LM_CPSP,
      stateCode
    );

    const getCyberAdmittedEligibility$ = this.getEligibility(
      naicsHash,
      AskKodiakProductID.CL_A,
      stateCode
    );

    return forkJoin({
      isBopEligible: bopEligibilityReq$,
      isAttuneWcEligible: getAttuneWcEligibility$,
      isEmployersWcEligible: getEmployersWcEligibility$,
      isHabEligible: getHabEligibility$,
      isGlEligible: getGlEligibility$,
      isPlEligible: getPlEligibility$,
      isLibertyMutualBopEligible: getLibertyMutualBopEligibility$,
      isLibertyMutualCpspEligible: getLibertyMutualCpspEligibility$,
      isCyberEligible: getCyberAdmittedEligibility$,
    }).pipe(
      map((productEligibilities): ProductEligibility => {
        const eligibilities = mapValues(productEligibilities, ({ isEligible }) => isEligible);
        return {
          ...eligibilities,
          isGlEligible: eligibilities.isGlEligible,
          isPlEligible: eligibilities.isPlEligible,
        };
      })
    );
  }

  public getCyberMarketingGroup(naicsCode: string) {
    const naicsCyberMarketingGroup = `${API_V4_BASE}/naics/${naicsCode}/cyber-marketing-group-content`;
    return this.http.get<{ marketingGroup: CyberMarketingGroup }>(naicsCyberMarketingGroup).pipe(
      catchError((error) => {
        this.sentryService.notify('Cyber: error getting cyber marketing group', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  public getPremiumIndicator(naicsHashCode: string, state: string) {
    const premiumIndicationUrl = `${API_V4_BASE}/naics/${AskKodiakClassificationID.COALITION_CYBER}/${naicsHashCode}/${state}/premium-indicator`;

    return this.http.get<{ premiumIndicator: number }>(premiumIndicationUrl).pipe(
      catchError((error) => {
        this.sentryService.notify('Cyber: error getting cyber marketing group', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  /**
   * Abstracted in a method to allow for easier unit testing.
   * @returns the 'stage' environment variable.
   */
  public getEnvironmentStage() {
    return environment.stage;
  }

  private getAttuneWCProductTag(): AskKodiakTag {
    // Enables testing new state launches end-to-end with account creation in lower environments.
    return this.getEnvironmentStage() === 'production'
      ? AskKodiakTag.ATTUNE_WC_PRODUCTION
      : AskKodiakTag.ATTUNE_WC_STAGING;
  }
}
