import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { isNil, isArray, mapValues, get, find, some, flatten, isEmpty, cloneDeep } from 'lodash';

import {
  DEFAULT_FORM_CONFIG,
  formInfoBySlug,
  fieldFormats,
  HelpFormCategoryNames,
  HelpFormFieldNames,
  HelpFormInquiryType,
  HelpFormSlug,
  HelpFormTitle,
  HELP_CENTER_FORMS,
  INBOUND_INQUIRY_TYPE_FIELD_ID_PRODUCTION,
  INBOUND_INQUIRY_TYPE_FIELD_ID_SANDBOX,
  HelpCenterCategory,
  HelpCenterForm,
  HelpCenterSection,
  UIFormFieldNames,
  UI_FIELD_NAME_TO_ZENDESK_FIELD_ID_PRODUCTION,
  UI_FIELD_NAME_TO_ZENDESK_FIELD_ID_SANDBOX,
  UI_FORM_NAME_TO_ZENDESK_FORM_ID_PRODUCTION,
  UI_FORM_NAME_TO_ZENDESK_FORM_ID_SANDBOX,
  uploadFieldNames,
  HelpCenterFormStructure,
  HelpCenterCategoryName,
  UI_ADDRESS_NAME_TO_ZENDESK_FIELD_ID_PRODUCTION,
  UI_ADDRESS_NAME_TO_ZENDESK_FIELD_ID_SANDBOX,
  MESSAGE_FIELD_ID_SANDBOX,
  MESSAGE_FIELD_ID_PRODUCTION,
  SlugFormMapping,
} from '../../models/support-help-center-constants';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Observable,
  merge,
  of as observableOf,
  combineLatest,
  zip as zipObservables,
  Subscription,
  BehaviorSubject,
} from 'rxjs';
import {
  allErrorsRecursively,
  getControl,
  getFormGroup,
  getValidationMessageFromControl,
} from '../../../../shared/helpers/form-helpers';
import {
  formTreeToFormGroup,
  setFormDependencies,
} from '../../../../shared/form-dsl/services/form-dsl-service-base';
import {
  FormDslNode,
  MultiInputNode,
} from '../../../../shared/form-dsl/constants/form-dsl-typings';
import { environment } from '../../../../../environments/environment';
import { AmplitudeService } from '../../../../core/services/amplitude.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_V3_BASE } from '../../../../constants';
import { catchError, debounceTime, filter, first, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { validateEmailAddress } from '../../../attune-bop/models/form-validators';
import { SimpleValidator } from 'app/features/attune-bop/models/form-validators';
import { WcQuoteService } from 'app/workers-comp/employers/services/workers-comp-quote.service';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import {
  HiscoxBackendPricedQuote,
  HiscoxPricedQuote,
} from 'app/features/hiscox/models/hiscox-priced-quote';
import { HISCOX_PRODUCTS } from 'app/features/hiscox/models/hiscox-types';
import {
  AccountPolicyTerm,
  ENDORSABLE_STATUSES,
  InsuredAccount,
} from 'app/features/insured-account/models/insured-account.model';
import {
  WcPricedQuote,
  WcQuoteWithDocuments,
} from 'app/workers-comp/employers/models/wc-priced-quote';

import { AttuneBopEndorseQuoteService } from 'app/features/attune-bop/services/attune-bop-endorse-quote.service';
import { AccountNumberSearchSuccess } from 'app/bop/guidewire/typings';
import { EXTRA_DATA_FIELD_NAME } from 'app/shared/form-dsl/components/form-dsl-form/form-dsl-form.component';
import { AdditionalInsuredType } from 'app/features/attune-bop/models/bop-additional-insured-business';
import { ADDITIONAL_INSURED_TYPES_MAPPING } from 'app/features/support/models/support-constants';
import { evaluatorFuncs, EvaluatorName } from 'app/shared/form-dsl/constants/form-dsl-constants';
import { UserService } from 'app/core/services/user.service';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
  JSON_FLAG_NAMES,
} from 'app/core/services/feature-flag.service';
import { AttuneBopFortegraEnabledStates } from 'app/shared/services/typings';
import { ATTUNE_BOP_UW_COMPANY_TO_BOP_VERSION } from 'app/features/attune-bop/models/constants';

interface ZendeskRequestBody {
  subject: string;
  comment: { html_body: string };
  collaborators?: string[];
  requester: { name: string; email: string };
  custom_fields: { id: number; value: string | number | undefined }[];
  tags: string[];
  ticket_form_id: number;
}

interface HelpFormInfo {
  slug: HelpFormSlug;
  formConfig: FormDslNode[];
  formTitle: HelpFormTitle;
  subject: string;
  ticketComment: string;
  ticketFormId: number;
  inquiryType: HelpFormInquiryType;
  path: HelpFormCategoryNames[];
  type: 'fillableForm' | 'infoForm';
  validators: SimpleValidator[];
}

export enum SubmitStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

enum GuidewireProductName {
  BOP = 'Businessowners Line (v7)',
  WC = "Workers' Comp Line",
}

type HelpCenterFormValues = {
  [k in HelpFormFieldNames | UIFormFieldNames]?: string | number | Address | FileUpload[];
};

const aiTypeLabels: Record<AdditionalInsuredType, string> = {
  [AdditionalInsuredType.BUILDING_OWNER]: 'Building Owner - Additional Insured',
  [AdditionalInsuredType.CO_OWNER]: 'Co-Owner of Insured Premises',
  [AdditionalInsuredType.CONTRACT_AGREEMENT_PERMIT]: 'Contract, Agreement Or Permit',
  [AdditionalInsuredType.CONTROLLING_INTEREST]: 'Controlling Interest',
  [AdditionalInsuredType.DESIGNATED_PERSON]: 'Designated Person or Organization',
  [AdditionalInsuredType.LESSOR_OF_EQUIPMENT]: 'Lessor of Leased Equipment',
  [AdditionalInsuredType.LOSS_PAYABLE]: 'Loss Payable',
  [AdditionalInsuredType.LOSS_OF_RENTAL_VALUE]:
    'Loss of rental value - Landlord as Designated Payee',
  [AdditionalInsuredType.MANAGER_OF_PREMISES]: 'Managers or Lessors of Premises',
  [AdditionalInsuredType.MORTGAGE_HOLDER]: 'Mortgage Holder',
  [AdditionalInsuredType.MORTGAGEE]: 'Mortgagee, Assignee or Receiver',
  [AdditionalInsuredType.OWNERS_OF_LAND]:
    'Owners or Other Interests From Whom Land Has Been leased',
  [AdditionalInsuredType.OWNERS_COMPLETED_OPS]:
    'Owners, lessees or contractors - Completed Operations',
  [AdditionalInsuredType.OWNERS_SCHEDULED_PERSON]:
    'Owners, lessees or contractors - Scheduled Person or Organization',
  [AdditionalInsuredType.VENDORS]: 'Vendors',
  [AdditionalInsuredType.ENGINEERS]: 'Engineers, Architects or Surveyors',
  [AdditionalInsuredType.ENGINEERS_NOT_ENGAGED]:
    'Engineers, Architects or Surveyors Not Engaged by the Named Insured',
  [AdditionalInsuredType.GRANTOR_OF_FRANCHISE]: 'Grantor of Franchise',
  [AdditionalInsuredType.OWNERS]:
    'Owners, Lessees or Contractors - With Additional Insured Requirement in Construction Contract',
  [AdditionalInsuredType.STATE_SUBDIVISIONS_AUTHORIZATIONS]:
    'State or Political Subdivision - Permits or Authorizations',
  [AdditionalInsuredType.STATE_SUBDIVISIONS]:
    'State or Political Subdivisions - Permits Relating to Premises',
};

@Component({
  selector: 'app-support-help-center-form',
  templateUrl: './support-help-center-form.component.html',
})
export class SupportHelpCenterFormComponent implements OnInit, OnDestroy {
  @Input() authenticated = false;
  @Output() ticketResponse = new EventEmitter<{ status: SubmitStatus; email: string }>();
  formStructure: HelpCenterFormStructure = HELP_CENTER_FORMS;
  categorySelectLabel: string;
  availableCategoryTitles: { [optionGroup: string]: string[] };
  subcategorySelectLabel: string;
  availableSubcategoryTitles: { [key: string]: string };
  categorySelectChoices: { [key: string]: HelpCenterCategory | HelpCenterForm };
  subcategorySelectChoices: { [key: string]: HelpCenterForm };
  form = new UntypedFormGroup({});
  hasInnerForm = false;
  helpForms: { [key in HelpFormSlug]: HelpFormInfo };
  selectedForm: HelpFormInfo | null = null;
  prefillValues: { [key in HelpFormFieldNames]?: any } = {};
  accountNumber: string;
  accountPolicyData: Record<string, string>[] = [];
  private sub = new Subscription();
  accountData: InsuredAccount | null;
  accountFromPolicyData: InsuredAccount | null;
  currentDraft: BaseQuote | null;
  extraData: Record<string, Record<string, any>> = {};
  policySearchResult: string | null;
  policySearchNumber: string;

  userAccountMatches: InsuredAccount[] = [];
  draftSubscription: Subscription;
  draftPolicyNumber: string;
  isAttuneWcEnabled = false;

  // Fortegra
  isFortegraEnabled = false;

  // Data for state validator
  attuneBopV3EnabledStates$ = new BehaviorSubject<AttuneBopFortegraEnabledStates | null>(null);
  insuredAccount$ = new BehaviorSubject<InsuredAccount | null>(null);
  private _bopExposure$ = new BehaviorSubject<Pick<BopExposureInfo, 'bopVersion'>>({
    bopVersion: null,
  });

  formTree: FormDslNode[] = [];

  readonly formNameToZendeskId: { [key in HelpFormCategoryNames]?: number };
  readonly fieldNameToZendeskId: { [key in HelpFormFieldNames]: number };
  readonly fieldNameToZendeskAddress: {
    [key in HelpFormFieldNames]?: { [T in keyof Required<Address>]: number };
  };
  readonly inquiryTypeFieldId: number;
  readonly messageFieldId: number;

  defaultFormConfig: FormDslNode[];
  formInfoBySlug: SlugFormMapping;

  categorySelect$: Observable<any>;
  subcategorySelect$: Observable<any>;

  loadingResponse = false;
  submitted = false;

  constructor(
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private hiscoxQuoteService: HiscoxQuoteService,
    private wcQuoteService: WcQuoteService,
    private insuredAccountService: InsuredAccountService,
    private endorseQuoteService: AttuneBopEndorseQuoteService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private featureFlagService: FeatureFlagService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.formNameToZendeskId =
      environment.stage === 'production'
        ? UI_FORM_NAME_TO_ZENDESK_FORM_ID_PRODUCTION
        : UI_FORM_NAME_TO_ZENDESK_FORM_ID_SANDBOX;
    this.fieldNameToZendeskId =
      environment.stage === 'production'
        ? UI_FIELD_NAME_TO_ZENDESK_FIELD_ID_PRODUCTION
        : UI_FIELD_NAME_TO_ZENDESK_FIELD_ID_SANDBOX;
    this.fieldNameToZendeskAddress =
      environment.stage === 'production'
        ? UI_ADDRESS_NAME_TO_ZENDESK_FIELD_ID_PRODUCTION
        : UI_ADDRESS_NAME_TO_ZENDESK_FIELD_ID_SANDBOX;
    this.inquiryTypeFieldId =
      environment.stage === 'production'
        ? INBOUND_INQUIRY_TYPE_FIELD_ID_PRODUCTION
        : INBOUND_INQUIRY_TYPE_FIELD_ID_SANDBOX;
    this.messageFieldId =
      environment.stage === 'production' ? MESSAGE_FIELD_ID_PRODUCTION : MESSAGE_FIELD_ID_SANDBOX;
  }

  setBopV3EnabledStates(v3EnabledStates: AttuneBopFortegraEnabledStates | null) {
    this.attuneBopV3EnabledStates$.next(v3EnabledStates);
  }

  setupInsuredAccount(account: InsuredAccount) {
    this.insuredAccount$.next(account);
  }

  ngOnInit() {
    this.defaultFormConfig = cloneDeep(DEFAULT_FORM_CONFIG);
    this.formInfoBySlug = cloneDeep(
      formInfoBySlug(this._bopExposure$, this.attuneBopV3EnabledStates$, this.insuredAccount$)
    );
    this.buildForm();
    this.addQueryParamsToPrefill(this.route);
    this.setCategorySelectValues();

    this.helpForms = this.generateForms();

    this.categorySelect$ = this.getCategorySelect().valueChanges;
    this.subcategorySelect$ = this.getSubcategorySelect().valueChanges;

    this.getSubcategorySelect().disable();
    this.sub.add(
      this.categorySelect$.subscribe((value) => {
        const selectedChoice = this.categorySelectChoices[value];

        this.subcategorySelectChoices = {};
        if (selectedChoice) {
          const category = selectedChoice as HelpCenterCategory;
          if (category.children) {
            // Clear the existing subcategory selection and form
            this.getSubcategorySelect().patchValue(null);
            this.getSubcategorySelect().enable();
            this.populateForm(null);

            this.subcategorySelectLabel = category.subcategoryQuestion;
            this.availableSubcategoryTitles = {};

            category.children.forEach((child: HelpCenterForm) => {
              this.availableSubcategoryTitles[child.title] = child.title;
              this.subcategorySelectChoices[child.title] = child;
            });
          } else {
            const form = selectedChoice as HelpCenterForm;
            this.getSubcategorySelect().disable();

            if (form) {
              this.setMessageControl(form.showMessage);
              this.populateForm(form.slug);
            }
          }
        }
      })
    );

    this.sub.add(
      this.subcategorySelect$.subscribe((value) => {
        const formToSelect = this.subcategorySelectChoices[value];

        if (formToSelect) {
          this.setMessageControl(formToSelect.showMessage);
          this.populateForm(formToSelect.slug);
        } else {
          this.populateForm(null);
        }
      })
    );

    this.setCategoriesFromRoute(this.route);

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP)
        .subscribe((enabled) => {
          this.isAttuneWcEnabled = enabled || false;
          this.generateForms();
        })
    );

    this.sub.add(
      this.featureFlagService
        .getJsonFlagValue<AttuneBopFortegraEnabledStates>(
          JSON_FLAG_NAMES.ATTUNE_BOP_FORTEGRA_ENABLED_STATES
        )
        .subscribe((result) => {
          this.setBopV3EnabledStates(result);
          this.isFortegraEnabled = result !== null && !isEmpty(result.releaseDates);
          this.generateForms();
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.draftSubscription) {
      this.draftSubscription.unsubscribe();
    }
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    this.submitted = true;

    if (!this.form.valid) {
      const allErrors = allErrorsRecursively(this.form);
      if (!isEmpty(allErrors)) {
        let errorFields = Object.keys(allErrors).filter((field) => field !== 'innerForm');
        if (allErrors.innerForm) {
          errorFields = errorFields.concat(Object.keys(allErrors.innerForm));
        }

        this.amplitudeService.track({
          eventName: 'validation_error',
          detail: errorFields.join(','),
        });
      }

      return;
    }

    this.loadingResponse = true;
    this.submitRequest();
  }

  setCategorySelectValues() {
    this.availableCategoryTitles = {};
    this.categorySelectChoices = {};

    this.formStructure.sections.forEach((section: HelpCenterSection) => {
      this.availableCategoryTitles[section.title] = section.children.map((child) => {
        // In addition to returning the list of children, populate categorySelectChoices with child form/category objects
        this.categorySelectChoices[child.title] = child;
        return child.title;
      });
    });

    this.categorySelectLabel = this.formStructure.question;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      categorySelect: ['', Validators.required],
      subcategorySelect: ['', Validators.required],
      message: '',
      userEmail: ['', [Validators.required, validateEmailAddress]],
    });
  }

  populateForm(formSlug: HelpFormSlug | null) {
    this.submitted = false;
    if (this.form.contains('innerForm')) {
      this.addInnerFormValuesToPrefill();
      this.form.removeControl('innerForm');
    }

    if (formSlug) {
      const formInfo = this.helpForms[formSlug];
      if (formInfo) {
        this.selectedForm = formInfo;
        this.formTree = formInfo.formConfig;
        this.form.addControl(
          'innerForm',
          this.formBuilder.group(formTreeToFormGroup(this.formTree, this.formBuilder))
        );

        this.getInnerForm().addControl(EXTRA_DATA_FIELD_NAME, new UntypedFormControl());
        this.getInnerForm().patchValue({ [EXTRA_DATA_FIELD_NAME]: this.extraData });
        this.getInnerForm().setValidators(formInfo.validators);
        setFormDependencies(this.getInnerForm(), this.formTree);
        this.hasInnerForm = true;
        this.patchInnerFormWithPrefillValues();
        this.updateExtraData();

        const accountNumberControl = this.getInnerForm().get(HelpFormFieldNames.Account);
        const lobControl = this.getInnerForm().get(HelpFormFieldNames.LineOfBusiness);
        const policyNumberControl = this.getInnerForm().get(HelpFormFieldNames.Policy);
        const aiUpdateControl = this.getInnerForm().get(HelpFormFieldNames.AiIdToUpdate);

        if (this.authenticated && accountNumberControl) {
          this.sub.add(
            merge(
              accountNumberControl.valueChanges,
              observableOf(accountNumberControl.value)
            ).subscribe((accountNumber) => {
              if (!!accountNumber && accountNumber.length === 10) {
                this.insuredAccountService.search(accountNumber).subscribe((res) => {
                  this.userAccountMatches = res;
                  this.updateExtraData();
                });
              }
            })
          );
        }
        if (this.authenticated && accountNumberControl && lobControl && policyNumberControl) {
          const valueObservables = [
            merge(accountNumberControl.valueChanges, observableOf(accountNumberControl.value)),
            merge(policyNumberControl.valueChanges, observableOf(policyNumberControl.value)),
            merge(lobControl.valueChanges, observableOf(lobControl.value)),
          ];

          if (aiUpdateControl) {
            valueObservables.push(
              merge(aiUpdateControl.valueChanges, observableOf(aiUpdateControl.value))
            );
          }
          this.sub.add(
            combineLatest(valueObservables)
              .pipe(debounceTime(200))
              .subscribe(([accountNumber, policyNumber, lineOfBusiness, aiToUpdate]) => {
                if (!!accountNumber && accountNumber.length === 10) {
                  this.updateAccountData(accountNumber, lineOfBusiness);
                } else if (!!policyNumber && policyNumber.length >= 16) {
                  this.updatePolicyData(policyNumber, accountNumber);
                }
                this.updateExtraData();
                if (aiToUpdate) {
                  this.fillUpdateTypeAndRelatedEntity(aiToUpdate);
                }
              })
          );
        }

        this.amplitudeService.track({
          eventName: 'help_center_form_open',
          detail: formInfo.inquiryType,
        });
        this.updateExtraData();
      } else {
        this.sentryService.notify(`Help Center: Cannot find form slug ${formSlug}`);
      }
    } else {
      this.selectedForm = null;
      this.formTree = [];
      this.hasInnerForm = false;
    }
  }

  generateForms() {
    return mapValues(this.formInfoBySlug, (formInfo, slug: HelpFormSlug): HelpFormInfo => {
      const type = formInfo.type;
      const validators = get(formInfo, 'validators', []);
      const formConfig = get(formInfo, 'formConfig', this.defaultFormConfig);
      const formTitle = formInfo.formTitle;
      const subject = `Help Center Submission: ${formInfo.formTitle}`;
      const ticketComment = `Help Center Submission: ${formInfo.formTitle}`;
      // This will be updated in the future with more specific forms.
      // For now, use e.g. the 'Agency' form for the 'Agency' path and the 'Agency/Commission' path
      const topLevelForm = formInfo.path[0] as HelpFormCategoryNames;
      const ticketFormId = this.formNameToZendeskId[topLevelForm] as number;
      const inquiryType = formInfo.inquiryType;
      const path = formInfo.path;

      const line = formConfig.find(
        (node) => node.nameOfFormControl === HelpFormFieldNames.LineOfBusiness
      ) as MultiInputNode;
      if (this.isAttuneWcEnabled && line?.options && !line?.options?.attune_work_comp) {
        line.options = {
          attune_work_comp: 'Workers Comp (Attune)',
          ...line.options,
        };
      }
      // Adds option to drop down if product is enabled
      if (this.isFortegraEnabled && line?.options && !line?.options?.bop_fortegra) {
        line.options = {
          bop_fortegra: "Businessowners' (Fortegra)",
          ...line.options,
        };
      }

      return {
        slug,
        formConfig,
        formTitle,
        subject,
        ticketComment,
        ticketFormId,
        inquiryType,
        path,
        type,
        validators,
      };
    });
  }

  submitRequest() {
    if (!this.selectedForm) {
      return;
    }
    const userEmail = this.getUserEmail().value as string;
    const userName = userEmail.substring(0, userEmail.lastIndexOf('@'));
    const { subject, ticketComment, inquiryType, ticketFormId } = this.selectedForm;

    // Retrieve custom fields, custom tags, and attachments
    const { customFields, attachments } = this.parseFormValues(this.getInnerForm().value);
    const customTags = this.getCustomTags();

    // Add message body to ticket comment
    let ticketMessage = `<p>${ticketComment}</p>`;
    const message = this.getMessage().value;
    if (message) {
      ticketMessage += `<p><strong>Message from broker: </strong>${message}</p>`;
      customFields.push({
        id: this.messageFieldId,
        value: message,
      });
    }

    const zendeskRequestBody: ZendeskRequestBody = {
      subject: subject,
      comment: { html_body: ticketMessage },
      requester: { name: userName, email: userEmail },
      custom_fields: [
        ...customFields,
        {
          id: this.inquiryTypeFieldId,
          value: inquiryType,
        },
      ],
      tags: ['help_center_request', ...customTags],
      ticket_form_id: ticketFormId,
    };

    this.amplitudeService.track({
      eventName: 'help_center_form_submit_attempt',
      detail: inquiryType,
    });

    return this.sub.add(
      this.submitZendeskTicketRequest(zendeskRequestBody, attachments).subscribe((response) => {
        if (response) {
          this.ticketResponse.emit({
            status: SubmitStatus.SUCCESS,
            email: zendeskRequestBody.requester.email,
          });
          this.amplitudeService.track({
            eventName: 'help_center_form_submit_success',
            detail: inquiryType,
          });
        } else {
          this.ticketResponse.emit({
            status: SubmitStatus.FAILED,
            email: zendeskRequestBody.requester.email,
          });
          this.amplitudeService.track({
            eventName: 'help_center_form_submit_error',
            detail: inquiryType,
          });
        }

        this.resetForm();
      })
    );
  }

  updateDraft() {
    if (this.accountNumber) {
      if (!this.accountData) {
        return;
      }

      const flattenedTerms = flatten(
        this.accountData.policiesWithTerms.map((policies) => policies.terms)
      );
      const activeBopPolicy = flattenedTerms.find(
        (term) =>
          ['In Force', 'Scheduled'].includes(term.status) &&
          term.productName === GuidewireProductName.BOP
      );
      if (activeBopPolicy) {
        if (this.policySearchNumber && this.policySearchResult !== this.accountNumber) {
          this.updateExtraData();
          return;
        } else {
          this.createDraft(activeBopPolicy.policyNumber, activeBopPolicy.termNumber);
        }
      } else if (this.policySearchNumber) {
        this.updateExtraData();
      }
    } else if (this.policySearchNumber && this.accountFromPolicyData) {
      const flattenedTerms = flatten(
        this.accountFromPolicyData.policiesWithTerms.map((policies) => policies.terms)
      );
      const matchingPolicy = flattenedTerms.find(
        (term) => term.policyNumber === this.policySearchNumber
      );

      if (matchingPolicy) {
        this.createDraft(matchingPolicy.policyNumber, matchingPolicy.termNumber);
      }
    }
  }

  createDraft(policyNumber: string, termNumber: string) {
    if (this.draftSubscription) {
      this.draftSubscription.unsubscribe();
    }

    this.updateExtraData();
    this.currentDraft = null;
    this.draftPolicyNumber = policyNumber;

    this.draftSubscription = this.endorseQuoteService
      .createPolicyChangeCached(policyNumber, termNumber.toString(), moment())
      .subscribe((result) => {
        if (result) {
          this.handleDraftCreation(result.jobNumber);
        }
      });
  }

  updateExtraData() {
    const newExtraData: Record<string, Record<string, any>> = {};

    const accountNumberControl = this.getInnerForm()?.get(
      HelpFormFieldNames.Account
    ) as UntypedFormControl;
    if (!accountNumberControl) {
      return;
    }

    const accountNumber = accountNumberControl.value;

    if (this.currentDraft) {
      if (this.currentDraft.attachments) {
        const aiOptions: Record<string, string> = {};

        this.currentDraft.attachments.additionalInsuredBusinesses.forEach((ai, i) => {
          // guidewireId is only blank for AIs in the process of being added
          // in lower environments we've seen AIs without name/address and don't want to display them in this list
          if (ai.guidewireId && ai.businessName && ai.address) {
            const aiAddress = ai.address ? this.formatAddressString(ai.address) : '';
            const typeLabel = aiTypeLabels[ai.type as AdditionalInsuredType];
            const aiText = `${ai.businessName}, ${aiAddress} - ${typeLabel}`;
            aiOptions[aiText] = ai.guidewireId;
          }
        });
        if (Object.keys(aiOptions).length) {
          newExtraData.additionalInsuredList = {
            options: aiOptions,
          };
        }
      }

      if (this.currentDraft.locations) {
        const locationOptions: Record<string, string> = {};
        const buildingOptions: Record<string, string> = {};

        this.currentDraft.locations.forEach((loc) => {
          const { address, buildings, guidewireId } = loc;
          if (address && guidewireId) {
            const locAddress = this.formatAddressString(address);
            locationOptions[locAddress] = guidewireId;
            if (buildings) {
              const multipleBuildingsForLocation = buildings.length > 1;
              buildings.forEach((building, buildingIndex) => {
                if (building.guidewireId) {
                  const buildingKey = multipleBuildingsForLocation
                    ? `${locAddress} (building ${buildingIndex + 1})`
                    : locAddress;
                  buildingOptions[buildingKey] = building.guidewireId;
                }
              });
            }
          }
        });
        if (Object.keys(locationOptions).length) {
          newExtraData.additionalInsuredLocations = {
            options: locationOptions,
          };
        }
        if (Object.keys(buildingOptions).length) {
          newExtraData.additionalInsuredBuildings = {
            options: buildingOptions,
          };
        }
      }
    } else {
      newExtraData.noAdditionalInsuredWarning = {
        cssClass: 'form-hide',
      };
    }

    const hasContradictoryAccount =
      this.policySearchNumber && accountNumber && this.policySearchResult !== accountNumber;
    let hasContradictoryPolicy = false;

    if (this.accountData) {
      const flattenedTerms = flatten(
        this.accountData.policiesWithTerms.map((policies) => policies.terms)
      );

      const policyFormValue = this.getInnerForm().getRawValue()[HelpFormFieldNames.Policy];
      // Check every valid term for a matching policy number
      hasContradictoryPolicy = !flattenedTerms
        .filter((term) => ['In Force', 'Scheduled'].includes(term.status))
        .some((term) => term.policyNumber === policyFormValue);
    }
    if (!hasContradictoryAccount && !hasContradictoryPolicy) {
      newExtraData.accountMismatchWarning = {
        cssClass: 'form-hide',
      };
    }

    if (!this.userAccountMatches.length) {
      newExtraData.forbiddenAccountWarning = {
        cssClass: 'dialog-text__margin-bottom',
      };
    }

    this.extraData = newExtraData;

    this.getInnerForm().patchValue({ [EXTRA_DATA_FIELD_NAME]: newExtraData });
  }

  formatAddressString(address: Address): string {
    const addressItems = [address.addressLine1];
    if (address.addressLine2) {
      addressItems.push(address.addressLine2);
    }
    addressItems.push(address.city, address.state);
    return addressItems.join(', ');
  }

  handleDraftCreation(jobNumber: string) {
    this.sub.add(
      this.endorseQuoteService.retrievePolicyChange(jobNumber).subscribe((draft) => {
        this.currentDraft = draft;
        if (this.currentDraft?.productUwCompany) {
          this._bopExposure$.next({
            bopVersion: ATTUNE_BOP_UW_COMPANY_TO_BOP_VERSION[this.currentDraft.productUwCompany],
          });
        }
        this.updateExtraData();
      })
    );
  }

  getEffectiveDateTag() {
    const today = moment.utc();
    const innerForm = this.getInnerForm();
    const effectiveDateControl =
      getControl(innerForm, HelpFormFieldNames.EffectiveDate) ||
      getControl(innerForm, HelpFormFieldNames.NewEffectiveDate);
    if (!effectiveDateControl) {
      return [];
    }
    const effectiveDate = moment.utc(effectiveDateControl.value);
    if (today.isSameOrBefore(effectiveDate, 'day')) {
      return ['current_future_date'];
    }
    if (today.diff(effectiveDate, 'day') < 30) {
      return ['backdated_lessthan_30'];
    }
    return ['backdated_morethan_30'];
  }

  getSalesAndPayrollTags() {
    const innerForm = this.getInnerForm();
    const salesControl = getControl(innerForm, HelpFormFieldNames.ExposuresProjectedSales);
    const payrollControl = getControl(innerForm, HelpFormFieldNames.ExposuresPayroll);
    const tags: any[] = [];

    if (salesControl?.value) {
      tags.push('update_sales');
    }
    if (payrollControl?.value) {
      tags.push('update_payroll');
    }

    return tags;
  }

  getCustomTags() {
    const category = this.getCategorySelect().value;
    const subcategory = this.getSubcategorySelect().value;
    let tags: any[] = [];

    if ([HelpCenterCategoryName.Endorse, HelpCenterCategoryName.Cancel].includes(category)) {
      tags = [...tags, ...this.getEffectiveDateTag()];
    }
    if (subcategory === HelpCenterCategoryName.UpdateSalesPayroll) {
      tags = [...tags, ...this.getSalesAndPayrollTags()];
    }

    return tags;
  }

  parseFormValues(formValues: HelpCenterFormValues) {
    const fields = Object.keys(formValues);

    const zendeskFields = fields.filter((field) => {
      return (
        Object.prototype.hasOwnProperty.call(this.fieldNameToZendeskId, field) ||
        Object.prototype.hasOwnProperty.call(this.fieldNameToZendeskAddress, field)
      );
    }) as HelpFormFieldNames[];

    const uploadFields = fields.filter((field) => {
      return uploadFieldNames.includes(field as UIFormFieldNames);
    }) as UIFormFieldNames[];

    const customFields: ZendeskRequestBody['custom_fields'] = [];
    const attachments: ZendeskAttachment[] = [];

    // Create all of the custom field objects for Zendesk form fields.
    zendeskFields.forEach((zendeskField) => {
      const fieldValue = formValues[zendeskField];
      // If we are here, the field has already been validated and we are submitting.
      if (isNil(fieldValue) || fieldValue === '') {
        // Do not send any empty values to Zendesk.
        return;
      }

      // For existing entities (Additional Insureds), we want to include both the Guidewire ID and entity name
      // This allows the ticket to be compatible with both automated and manual servicing
      if (zendeskField === HelpFormFieldNames.AiIdToUpdate && this.currentDraft) {
        const matchingAi = this.currentDraft?.attachments?.additionalInsuredBusinesses.find(
          (ai) => ai.guidewireId === fieldValue
        );
        if (matchingAi) {
          customFields.push({
            id: this.fieldNameToZendeskId[HelpFormFieldNames.AiNameToUpdate],
            value: matchingAi.businessName,
          });
        } else {
          this.sentryService.notify(
            'An AI ID was selected that does not match any AI on the current policy',
            {
              severity: 'error',
              metaData: {
                aiId: fieldValue,
                accountNumber: this.accountNumber,
              },
            }
          );
        }
      }

      let value: string | number;
      if (fieldFormats[zendeskField] === 'ADDRESS') {
        const { addressLine1, addressLine2, city, state, zip } = formValues[
          zendeskField
        ] as Address;
        value = `${addressLine1} ${addressLine2 || ''} ${city}, ${state} ${zip}`;
      } else if (fieldFormats[zendeskField] === 'ADDRESS_GROUP') {
        const { addressLine1, addressLine2, city, state, zip } = formValues[
          zendeskField
        ] as Address;

        const addressMapping =
          this.fieldNameToZendeskAddress[zendeskField as unknown as HelpFormFieldNames];

        if (!addressMapping) {
          return;
        }

        if (addressLine1) {
          customFields.push({ id: addressMapping.addressLine1, value: addressLine1 });
        }
        if (addressLine2) {
          customFields.push({ id: addressMapping.addressLine2, value: addressLine2 });
        }
        if (city) {
          customFields.push({ id: addressMapping.city, value: city });
        }
        if (state) {
          customFields.push({ id: addressMapping.state, value: state });
        }
        if (zip) {
          customFields.push({ id: addressMapping.zip, value: zip });
        }

        return;
      } else if (fieldFormats[zendeskField] === 'DATE') {
        value = moment.utc(fieldValue as string).format('YYYY-MM-DD');
      } else {
        value = fieldValue as string | number;
      }

      const id = this.fieldNameToZendeskId[zendeskField];
      customFields.push({ id, value });
    });

    // Create attachment objects for any file upload components in the form.
    uploadFields.forEach((fieldName) => {
      const files = formValues[fieldName] as FileUpload[];
      files.forEach((file) => {
        if (file.s3url) {
          attachments.push({
            s3url: file.s3url,
            description: fieldName,
          });
        } else {
          this.sentryService.notify(
            'Failed to include uploaded file in ticket request - file has no S3 URL',
            {
              severity: 'error',
              metaData: file,
            }
          );
        }
      });
    });

    return { customFields, attachments };
  }

  submitZendeskTicketRequest(
    zendeskRequestBody: ZendeskRequestBody,
    attachments: ZendeskAttachment[]
  ) {
    const ticketRequest = { request: zendeskRequestBody };
    const payload = { ticketRequest, attachments };
    return this.http.post(`${API_V3_BASE}/create-help-center-ticket`, payload).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to create Zendesk ticket via Help Center', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  resetForm() {
    this.submitted = false;
    this.loadingResponse = false;

    // Calling reset on a FormGroup sets each item in the array of a FormArray to null as opposed
    // to setting it to an empty array. Calling clear first on the FormArray will empty it correctly
    // This clears all nested form arrays recursively.
    this.clearFormArraysRecursively(this.getInnerForm());

    this.getInnerForm().reset({ onlySelf: true });
    this.getCategorySelect().patchValue(null);
    this.getSubcategorySelect().patchValue(null);
    this.getSubcategorySelect().disable();
    this.getMessage().patchValue(null);
    this.getUserEmail().patchValue(null);

    this.populateForm(null);
  }

  addQueryParamsToPrefill(route: ActivatedRoute) {
    const queryParams = route.firstChild
      ? route.firstChild.snapshot.queryParams
      : route.snapshot?.queryParams;

    Object.entries(queryParams || {}).forEach(([formField, formValue]) => {
      if (Object.values(HelpFormFieldNames).some((val) => val === formField)) {
        this.prefillValues[formField as HelpFormFieldNames] = decodeURIComponent(formValue);
      } else {
        this.sentryService.notify(`Invalid help center query parameter - ${formField}`);
      }
    });
  }

  addInnerFormValuesToPrefill() {
    const innerForm = this.getInnerForm();
    if (innerForm) {
      Object.keys(innerForm.controls).forEach((controlKey) => {
        const control = getControl(innerForm, controlKey);
        const shouldPrefill = control && control.value && !isArray(control.value);
        if (shouldPrefill) {
          this.prefillValues[controlKey as HelpFormFieldNames] = control.value;
        }
      });
    }
  }

  patchInnerFormWithPrefillValues() {
    const innerForm = this.getInnerForm();
    if (innerForm) {
      Object.entries(this.prefillValues).forEach(([prefillField, prefillValue]) => {
        const control = getControl(innerForm, prefillField);
        if (control) {
          control.patchValue(prefillValue);
        }
      });
    }
  }

  setCategoriesFromRoute(route: ActivatedRoute) {
    const routeParams = route.firstChild
      ? route.firstChild.snapshot.params
      : route.snapshot?.params;
    const formSlug: HelpFormSlug = routeParams?.formId;
    if (formSlug) {
      try {
        // Find a section that contains our form within a subcategory
        let section = find(this.formStructure.sections, {
          children: [{ children: [{ slug: formSlug }] }],
        });

        if (section) {
          const category = find(section.children, {
            children: [{ slug: formSlug }],
          }) as HelpCenterCategory;

          if (!category) {
            throw new Error('Cannot find form category');
          }

          this.getCategorySelect().patchValue(category.title);
          const subcategory = category.children.find((item) => item.slug === formSlug);

          if (!subcategory) {
            throw new Error('Cannot find form subcategory.');
          }

          this.getSubcategorySelect().patchValue(subcategory.title);
        } else {
          // Find a section that contains our form as a top-level category
          section = find(this.formStructure.sections, {
            children: [{ slug: formSlug }],
          });

          if (!section) {
            // In this case, we didn't find a section with our form as either a category or subcategory
            throw new Error('Cannot find request section');
          }

          const form = section.children.find((item) => {
            const requestForm = item as HelpCenterForm;
            if (requestForm.slug) {
              return requestForm.slug === formSlug;
            }

            return false;
          });

          if (!form) {
            throw new Error('Cannot find request category form.');
          }

          this.getCategorySelect().patchValue(form.title);
        }
      } catch (error) {
        this.sentryService.notify(`Help Center: Cannot route to help center request form.`, {
          severity: 'error',
          metaData: {
            formSlug,
            underlyingErrorMessage: error && error.message,
          },
        });
      }
    }
  }

  loadAccountInfo(accountNumber: string) {
    // Pipe logic for getting BOP account is reused from dashboard component
    return this.insuredAccountService.get(accountNumber).pipe(
      filter((insuredAccount: InsuredAccount) => String(insuredAccount.id) === accountNumber),
      first(),
      shareReplay()
    );
  }

  updatePolicyData(policyNumber: string, accountNumber: string) {
    if (policyNumber === this.draftPolicyNumber) {
      return;
    }

    this.policySearchResult = null;
    this.accountFromPolicyData = null;
    this.updateExtraData();

    if (policyNumber && this.policySearchNumber !== policyNumber) {
      this.policySearchNumber = policyNumber;
      this.sub.add(
        this.insuredAccountService.getAccountByPolicyNumber(policyNumber).subscribe((result) => {
          const successResult = result as AccountNumberSearchSuccess;
          if (successResult && successResult.getAccountNumberResponse) {
            this.policySearchResult = successResult.getAccountNumberResponse.return;
            this.loadAccountFromPolicy();
          }
        })
      );
    }
  }

  loadAccountFromPolicy() {
    if (this.policySearchResult) {
      this.sub.add(
        this.loadAccountInfo(this.policySearchResult).subscribe((accountDetails) => {
          this.setupInsuredAccount(accountDetails);
          this.accountFromPolicyData = accountDetails;
          this.updateDraft();
        })
      );
    }
  }

  updateAccountData(accountNumber: string, lineOfBusiness: string) {
    if (accountNumber !== this.accountNumber || !this.accountPolicyData) {
      this.accountNumber = accountNumber;

      const glV4Quote = this.hiscoxQuoteService.getQuote(HISCOX_PRODUCTS.gl, accountNumber);
      const plV4Quote = this.hiscoxQuoteService.getQuote(HISCOX_PRODUCTS.pl, accountNumber);

      const wcQuote = this.wcQuoteService.getQuote(accountNumber);
      const gwAccountInfo = this.loadAccountInfo(accountNumber);

      this.accountData = null;

      this.sub.add(
        zipObservables(gwAccountInfo, wcQuote, glV4Quote, plV4Quote)
          .pipe(
            catchError((e) => observableOf([])),
            filter(
              ([insuredAccount]: [
                InsuredAccount,
                WcQuoteWithDocuments,
                HiscoxBackendPricedQuote,
                HiscoxBackendPricedQuote
              ]) => {
                return accountNumber === insuredAccount.id.toString();
              }
            )
          )
          .subscribe(
            ([gwAccountResponse, wcQuoteResponse, glV4QuoteResponse, plV4QuoteResponse]) => {
              this.accountPolicyData = [];
              this.accountData = gwAccountResponse;
              this.updateDraft();

              let glPricedQuote;
              if (glV4QuoteResponse) {
                glPricedQuote = new HiscoxPricedQuote(glV4QuoteResponse);
              }

              if (glPricedQuote) {
                if (glPricedQuote.isBound()) {
                  this.accountPolicyData.push({
                    lineOfBusiness: 'general_liability',
                    policyNumber: glPricedQuote.policyNumber || '',
                  });
                }
              }

              let plPricedQuote;
              if (plV4QuoteResponse) {
                plPricedQuote = new HiscoxPricedQuote(plV4QuoteResponse);
              }

              if (plPricedQuote) {
                if (plPricedQuote.isBound() || plPricedQuote.isBound()) {
                  this.accountPolicyData.push({
                    lineOfBusiness: 'professional_liability',
                    policyNumber: plPricedQuote.policyNumber || '',
                  });
                }
              }

              const wcQuoteData = wcQuoteResponse ? wcQuoteResponse.quote : null;
              if (wcQuoteData) {
                const wcPricedQuote = new WcPricedQuote(wcQuoteData);
                if (wcPricedQuote.isBound()) {
                  this.accountPolicyData.push({
                    lineOfBusiness: 'wc',
                    policyNumber: wcPricedQuote.policyNumber,
                  });
                }
              }

              if (gwAccountResponse) {
                const bopPolicyNumber = this.getPolicyNumber(
                  gwAccountResponse,
                  GuidewireProductName.BOP
                );
                if (bopPolicyNumber) {
                  const isBopV3Policy = this.accountData.bopV3Policies.some(
                    ({ policyNumber }) => policyNumber === bopPolicyNumber
                  );
                  this.accountPolicyData.push({
                    lineOfBusiness: isBopV3Policy
                      ? 'bop_fortegra'
                      : 'businessowners____excess__accredited__i.e._bop_',
                    policyNumber: bopPolicyNumber,
                  });
                }

                const wcPolicyNumber = this.getPolicyNumber(
                  gwAccountResponse,
                  GuidewireProductName.WC
                );
                if (wcPolicyNumber) {
                  this.accountPolicyData.push({
                    lineOfBusiness: 'attune_work_comp',
                    policyNumber: wcPolicyNumber,
                  });
                }
              }
              this.fillPolicyAndLob(lineOfBusiness);
            }
          )
      );
    } else {
      this.fillPolicyAndLob(lineOfBusiness);
    }
  }

  getPolicyNumber(
    insuredAccount: InsuredAccount,
    productName: GuidewireProductName
  ): string | null {
    let policyNumber = null;

    const bopEndorsablePolicies = insuredAccount.policiesWithTerms.filter((policy) => {
      const endorsable = some(policy.terms, (term: AccountPolicyTerm) => {
        return ENDORSABLE_STATUSES.includes(term.status);
      });
      return policy.productName === productName && endorsable;
    });

    for (const policy of bopEndorsablePolicies) {
      const scheduledTerm = find(policy.terms, (term) => term.status === 'Scheduled');

      // If there's a bound bop policy with a scheduled term, use that policy for autofill (indicates renewal/rollover policy)
      if (scheduledTerm) {
        policyNumber = scheduledTerm.policyNumber;
        break;
      }

      policyNumber = policy.policyNumber;
    }

    return policyNumber;
  }

  fillUpdateTypeAndRelatedEntity(aiToUpdate: string) {
    const aiUpdateType = this.getInnerForm().get(HelpFormFieldNames.AiUpdateType);
    const aiUpdateBuilding = this.getInnerForm().get(HelpFormFieldNames.AiUpdateBuilding);
    const aiUpdateLocation = this.getInnerForm().get(HelpFormFieldNames.AiUpdateLocation);
    const matchingAi = this.currentDraft?.attachments?.additionalInsuredBusinesses.find(
      (ai) => ai.guidewireId === aiToUpdate
    );
    if (aiUpdateType && matchingAi) {
      Object.entries(ADDITIONAL_INSURED_TYPES_MAPPING).find(([k, v]) => {
        if (v === (matchingAi.type as AdditionalInsuredType)) {
          aiUpdateType.patchValue(k);
          const isBuildingAiType = evaluatorFuncs[EvaluatorName.IS_AI_PLACEMENT_BUILDING]([
            k,
            this.extraData,
          ]);
          const isLocationAiType = evaluatorFuncs[EvaluatorName.IS_AI_PLACEMENT_LOCATION]([
            k,
            this.extraData,
          ]);
          if (aiUpdateBuilding && isBuildingAiType) {
            aiUpdateBuilding.patchValue(matchingAi.relatedEntityId);
          }
          if (aiUpdateLocation && isLocationAiType) {
            aiUpdateLocation.patchValue(matchingAi.relatedEntityId);
          }
        }
      });
    }
  }

  fillPolicyAndLob(lineOfBusiness: string) {
    const lineOfBusinessControl = this.getInnerForm().get(HelpFormFieldNames.LineOfBusiness);
    const policyNumberControl = this.getInnerForm().get(HelpFormFieldNames.Policy);

    if (policyNumberControl && policyNumberControl.dirty) {
      return;
    }

    if (lineOfBusinessControl && policyNumberControl) {
      if (lineOfBusiness) {
        const policyData = find(
          this.accountPolicyData,
          (data) => data.lineOfBusiness === lineOfBusiness
        );
        if (policyData) {
          const policyNumber = policyData.policyNumber;
          if (policyNumber) {
            this.amplitudeService.trackWithOverride({
              eventName: 'help_center_form_policy_lob_autofill',
              detail: `policy_autofill`,
              payloadOverride: {
                lineOfBusiness: lineOfBusiness,
                policyNumber: policyNumber,
              },
            });
            // fixme: verify that the emitEvent setting doesn't break validation
            policyNumberControl.patchValue(policyNumber.toString(), { emitEvent: false });
          }
        }
      } else {
        if (this.accountPolicyData.length === 1) {
          const lobValue = this.accountPolicyData[0].lineOfBusiness;
          const policyNumber = this.accountPolicyData[0].policyNumber;

          if (lobValue && policyNumber) {
            this.amplitudeService.trackWithOverride({
              eventName: 'help_center_form_policy_lob_autofill',
              detail: 'policy_and_lob_autofill',
              payloadOverride: {
                lineOfBusiness: lobValue,
                policyNumber: policyNumber,
              },
            });
            policyNumberControl.patchValue(policyNumber.toString(), { emitEvent: false });
            if (!lineOfBusinessControl.dirty) {
              lineOfBusinessControl.patchValue(lobValue);
            }
          }
        }
      }
    }
  }

  setMessageControl(showMessage: boolean) {
    if (showMessage) {
      this.getMessage().enable();
    } else {
      this.getMessage().patchValue(null);
      this.getMessage().disable();
    }
  }

  isFormValid() {
    return this.form.valid;
  }

  getInnerForm() {
    return getFormGroup(this.form, 'innerForm');
  }

  getCategorySelect() {
    return getControl(this.form, 'categorySelect');
  }

  getSubcategorySelect() {
    return getControl(this.form, 'subcategorySelect');
  }

  getMessage() {
    return getControl(this.form, 'message');
  }

  getUserEmail() {
    return getControl(this.form, 'userEmail');
  }

  clearFormArraysRecursively(formField: UntypedFormGroup) {
    if (!formField?.controls) {
      return;
    }

    if (typeof formField.controls === 'object') {
      for (const currFormField of Object.values(formField.controls)) {
        if (Array.isArray((currFormField as UntypedFormArray).controls)) {
          (currFormField as UntypedFormArray).clear();
        } else if (typeof (currFormField as UntypedFormGroup).controls === 'object') {
          this.clearFormArraysRecursively(currFormField as UntypedFormGroup);
        }
      }
    }
  }

  getValidationMessage() {
    const innerForm = this.getInnerForm();
    const stateControl = innerForm.get('businessAddress.state');

    return stateControl
      ? getValidationMessageFromControl(stateControl)
      : 'Please fill out all required fields.';
  }
}
