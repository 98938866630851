import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { isEmpty, get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { US_DATE_SINGLE_DIGIT_MASK, US_DATE_MASK } from 'app/constants';
import {
  BOP_V2_CYBER_SPLIT_STATES,
  BOP_V2_EPLI_SPLIT_STATES,
  BOP_V2_UW_COMPANY,
  ATTUNE_BOP_UW_COMPANY_BY_VERSION_AND_STATE,
  UPDATE_LESSORS_RISK_DOCUMENTS,
  isBopVersion2or3,
} from 'app/features/attune-bop/models/constants';
import { BopPolicy, SubgrogationWaiverName } from 'app/features/attune-bop/models/bop-policy';
import { BopAdditionalInsuredBusiness } from 'app/features/attune-bop/models/bop-additional-insured-business';
import { BopPricedQuote } from 'app/features/attune-bop/models/bop-priced-quote';
import { GWService } from 'app/bop/services/gw.service';
import {
  HabFormBuilding,
  HabFormLocation,
  HabFormLoss,
  HabSubmissionLocation,
  HabQuoteSubmission,
  PartialHabForm,
} from 'app/hab/typings';
import { determineLimitForEmployeeDishonesty } from 'app/features/attune-bop/models/coverages';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import {
  BopEndorsementForm,
  CoveredBuilding,
} from 'app/features/attune-bop/models/bop-endorsement';
import { SentryService } from 'app/core/services/sentry.service';
import { environment } from 'environments/environment';
@Injectable()
export class V3QuoteService {
  constructor(
    private gwService: GWService,
    private insuredAccountService: InsuredAccountService,
    private http: HttpClient,
    private sentryService: SentryService
  ) {}

  public requestQuote(
    policy: BopPolicy,
    parentQuoteId?: string | null
  ): Observable<BopPricedQuote | ErrorResponse> {
    if (this.insuredAccountService.insuredSubject.getValue().id === '') {
      return observableOf({ error: {}, status: 500 });
    }
    const insuredAccount: InsuredAccount = this.insuredAccountService.insuredSubject.getValue();
    const payload = this.translateToBopPayload(policy, insuredAccount);
    return this.gwService.requestQuoteViaQuoteService(payload, parentQuoteId).pipe(
      map((response) => {
        return this.mapToPricedBopQuote(response);
      }),
      catchError((error: ErrorResponse) => observableOf(error)) // HttpErrorResponse
    );
  }

  public requestHabQuote(habQuoteInfo: {
    form: PartialHabForm;
    isPartialQuote: boolean;
    guidewireId: string | null;
  }): Observable<QSQuoteSubmissionResponse> {
    const insuredAccount: InsuredAccount = this.insuredAccountService.insuredSubject.getValue();
    const payload: HabQuoteSubmission = this.translateToHabPayload(
      habQuoteInfo.form,
      insuredAccount,
      habQuoteInfo.guidewireId
    );

    const errorHandler = catchError((resp: HttpErrorResponse) => {
      if (resp.status === 422) {
        // TODO: More robustly handle schema errors.
        return observableOf({
          count: 1,
          results: [
            {
              data: {
                id: '',
                errors: ['Our systems are currently unable to process your quote.'],
              },
              message: 'Schema Error',
              status: 'Failed',
            },
          ],
        });
      }
      return observableOf(resp.error);
    });

    if (habQuoteInfo.isPartialQuote) {
      return this.gwService.requestPartialHabQuote(payload).pipe(errorHandler);
    } else {
      return this.gwService.requestFinishedHabQuote(payload).pipe(errorHandler);
    }
  }

  public updateLessorsRiskDocuments(payload: {
    bopQuote: BopPolicy | BopEndorsementForm;
    accountId: string;
    quoteNumber: string;
    tsRequestId: string;
  }) {
    // TODO (NY) - remove feature flag once this is in prod for 2 weeks.
    if (!environment.lroFlowEnabled) {
      return observableOf(null);
    }

    const { accountId, bopQuote, tsRequestId, quoteNumber } = payload;
    const buildingsWithDocuments = this.createLessorsRiskAttachments(bopQuote);
    if (buildingsWithDocuments.length > 0) {
      // Returned value is unused.
      return this.http
        .put<any>(UPDATE_LESSORS_RISK_DOCUMENTS, {
          accountNumber: accountId,
          tsRequestId: tsRequestId,
          quoteNumber: quoteNumber,
          lroBuildings: buildingsWithDocuments,
        })
        .pipe(
          catchError((error) => {
            this.sentryService.notify('Unable to update BOP lessors risk documents.', {
              severity: 'error',
              metaData: {
                accountId,
                tsRequestId: tsRequestId,
                quoteNumber: quoteNumber,
                buildingsWithDocuments: buildingsWithDocuments,
                underlyingErrorMessage: error && error.message,
                underlyingError: error,
              },
            });
            return observableOf(null);
          })
        );
    }
    // If there are no documents to upload we return an unused-null
    return observableOf(null);
  }

  public translateToBopPayload(quote: BopPolicy, insuredAccount: InsuredAccount): BaseQuote {
    return {
      requestUuid: quote.tsRequestId,
      locations: this.constructLocationsPayload(quote.locations),
      account: this.constructAccountPayload(insuredAccount, {
        baseState: quote.policyInfo.baseState,
        organizationType: quote.policyInfo.organizationType,
        yearsInBusiness: quote.policyInfo.yearsInBusiness,
        periodStart: quote.periodStart(),
      }),
      policy: this.constructPolicyPayload(quote),
      productId: 'BOP',
      productUwCompany: quote.policyInfo.meToo
        ? BOP_V2_UW_COMPANY
        : ATTUNE_BOP_UW_COMPANY_BY_VERSION_AND_STATE(
            quote.policyInfo.bopVersion,
            quote.policyInfo.baseState
          ),
      attachments: this.constructAttachmentsPayload(quote),
      irpmVersion: quote.policyInfo.irpmVersion || undefined,
      ratingModelVersion: quote.policyInfo.ratingModelVersion || undefined,
      irpmQuestions: this.constructIrpmQuestionsPayload(quote),
      eligibilityUWQuestions: quote.liabilityCoverages.eligibilityUWQuestions,
      agentName: quote.policyInfo.agentName || undefined,
      agentLicenseNumber: quote.policyInfo.agentLicenseNumber || undefined,
    };
  }

  public translateToHabPayload(
    quote: PartialHabForm,
    insuredAccount: InsuredAccount,
    guidewireId: string | null
  ): HabQuoteSubmission {
    const habQuoteSubmission: HabQuoteSubmission = {
      requestUuid: uuidv4(),
      account: {
        ...this.constructAccountPayload(insuredAccount, {
          organizationType: quote.policyDetails && quote.policyDetails.organizationType,
        }),
        id: String(insuredAccount.id),
      },
      productId: 'HABITATIONAL',
    };
    if (quote.guidelines) {
      habQuoteSubmission.guidelines = quote.guidelines;
    }
    if (quote.locations) {
      let buildingCoverageCoinsurance: number | undefined;
      let buildingCoverageDeductible: number | undefined;
      let businessIncomeCoinsurancePercent: number | undefined;
      if (quote.policyDetails) {
        // TODO: Add these blankets as separate items once the backend supports blankets.
        const buildingCoverageBlanket = quote.policyDetails.buildingCoverageBlanket;
        if (buildingCoverageBlanket) {
          buildingCoverageCoinsurance = parseInt(
            buildingCoverageBlanket.buildingCoverageCoinsurance,
            10
          );
          buildingCoverageDeductible = parseInt(
            buildingCoverageBlanket.buildingCoverageDeductible,
            10
          );
        }

        const businessIncomeBlanket = quote.policyDetails.businessIncomeBlanket;
        if (businessIncomeBlanket) {
          businessIncomeCoinsurancePercent = parseInt(businessIncomeBlanket.coinsurancePercent, 10);
        }
      }

      habQuoteSubmission.locations = quote.locations.map(
        (habLoc: HabFormLocation): HabSubmissionLocation => {
          const habSubmissionLocation: HabSubmissionLocation = {
            address: habLoc.address,
          };
          if (habLoc.buildings) {
            habSubmissionLocation.buildings = habLoc.buildings.map(
              (habBuilding: HabFormBuilding) => ({
                businessIncomeAndExtraExpense: habBuilding.businessIncomeAndExtraExpense
                  ? {
                      incomeLimit: parseMaskedInt(
                        habBuilding.businessIncomeAndExtraExpense.incomeLimit
                      ),
                      coinsurancePercent:
                        businessIncomeCoinsurancePercent ||
                        parseInt(
                          habBuilding.businessIncomeAndExtraExpense.coinsurancePercent as string,
                          10
                        ),
                    }
                  : undefined,
                constructionType: habBuilding.constructionType,
                contentsReplacementCost: habBuilding.contentsReplacementCost
                  ? {
                      replacementCostLimit: parseMaskedInt(
                        habBuilding.contentsReplacementCost.replacementCostLimit
                      ),
                      deductible: parseInt(habBuilding.contentsReplacementCost.deductible, 10),
                      coinsurancePercent: parseInt(
                        habBuilding.contentsReplacementCost.coinsurancePercent,
                        10
                      ),
                    }
                  : undefined,
                coverageCoinsurance:
                  buildingCoverageCoinsurance ||
                  parseInt(habBuilding.buildingCoverageCoinsurance as string, 10),
                coverageDeductible:
                  buildingCoverageDeductible ||
                  parseInt(habBuilding.buildingCoverageDeductible as string, 10),
                electricPlumbingHVACUpdated: habBuilding.electricPlumbingHVACUpdated,
                isFullySprinklered: habBuilding.isFullySprinklered,
                numStories: parseInt(habBuilding.numStories, 10),
                numUnits: parseInt(habBuilding.numUnits, 10),
                occupiedAreaSqFt: parseMaskedInt(habBuilding.squareFeet),
                replacementCost: parseMaskedInt(habBuilding.replacementCost),
                roofShape: habBuilding.roofShape,
                roofType: habBuilding.roofType,
                smokeDetectorType: habBuilding.smokeDetectorType,
                windDeductible: {
                  minimumPerOccurrence: null,
                  percentage: 'NotApplicable',
                },
                yearBuilt: parseInt(habBuilding.yearBuilt, 10),
                yearRoofUpdated: habBuilding.yearRoofUpdated
                  ? parseInt(habBuilding.yearRoofUpdated, 10)
                  : undefined,
              })
            );
          }
          if (habLoc.features) {
            habSubmissionLocation.features = {
              ...habLoc.features,
              paidParkingSquareFootage: habLoc.features.paidParkingSquareFootage
                ? parseMaskedInt(habLoc.features.paidParkingSquareFootage)
                : undefined,
              numberOfPools: habLoc.features.numberOfPools
                ? parseMaskedInt(habLoc.features.numberOfPools)
                : undefined,
            };
          }
          return habSubmissionLocation;
        }
      );
    }

    if (quote.lossHistory && quote.lossHistory.losses) {
      habQuoteSubmission.lossHistory = quote.lossHistory.losses.map((lossRun: HabFormLoss) => {
        let carrierName;
        let carrierIsUnknown;
        if (lossRun.carrier.name === 'Other' && lossRun.otherCarrier) {
          carrierName = lossRun.otherCarrier;
          carrierIsUnknown = true;
        } else {
          carrierName = lossRun.carrier.name;
          carrierIsUnknown = false;
        }
        return {
          locationNum: parseInt(lossRun.locationNum, 10),
          carrierIsUnknown: carrierIsUnknown,
          carrierId: lossRun.carrier.publicId,
          carrierName: carrierName,
          claimStatus: lossRun.claimStatus,
          lossType: lossRun.lossType,
          lossDate: moment(lossRun.lossDate, US_DATE_SINGLE_DIGIT_MASK).format('Y-MM-DD'),
          totalLossIncurred: parseMaskedInt(lossRun.totalLossIncurred),
        };
      });
    }
    if (quote.policyDetails) {
      habQuoteSubmission.policy = {
        doesInsuredUsePropertyManagementFirm:
          quote.policyDetails.doesInsuredUsePropertyManagementFirm,
        effectiveDate: moment(quote.policyDetails.effectiveDate, US_DATE_SINGLE_DIGIT_MASK).format(
          'Y-MM-DD'
        ),
      };
    }

    const addlCoverages = quote.additionalCoverages;
    if (addlCoverages) {
      habQuoteSubmission.additionalCoverages = {
        belowGroundWaterAndSewerDrainBackupLimit: parseInt(
          addlCoverages.belowGroundWaterAndBackupOfSewerAndDrainLimit,
          10
        ),
        employeeTheftLimit: parseInt(addlCoverages.employeeTheftLimit, 10),
        forgeryOrAlterationLimit: parseInt(addlCoverages.forgeryOrAlterationLimit, 10),
        ordinanceOrLawDemolitionCost: parseInt(addlCoverages.ordinanceOrLawDemolitionCost, 10),
        ordinanceOrLawIncreasedConstructionCost: parseInt(
          addlCoverages.ordinanceOrLawIncreasedCostofConstruction,
          10
        ),
      };
    }

    const glForm = quote.generalLiability;
    if (glForm) {
      habQuoteSubmission.generalLiability = {
        aggregateLimit: parseInt(glForm.aggregateLimit, 10),
        perOccurrenceLimit: parseInt(glForm.perOccurrenceLimit, 10),
        assaultAndBattery: glForm.assaultAndBattery
          ? {
              aggregateLimit: parseInt(glForm.assaultAndBattery.aggregateLimit, 10),
              occurrenceLimit: parseInt(glForm.assaultAndBattery.occurrenceLimit, 10),
            }
          : undefined,
        employeeBenefitsLiability: glForm.employeeBenefitsLiability
          ? {
              aggregateLimit: parseInt(glForm.employeeBenefitsLiability.aggregateLimit, 10),
              retroactiveDate: moment(
                glForm.employeeBenefitsLiability.retroactiveDate,
                US_DATE_SINGLE_DIGIT_MASK
              ).format('Y-MM-DD'),
            }
          : undefined,
        hiredNonOwnedAutoLiability: glForm.hiredNonOwnedAutoLiability
          ? {
              numDrivers: parseInt(glForm.hiredNonOwnedAutoLiability.numDrivers, 10),
            }
          : undefined,
      };
    }
    if (guidewireId !== null) {
      habQuoteSubmission.guidewireId = guidewireId;
    }
    return habQuoteSubmission;
  }

  public translateToPolicyChangePayload(
    form: BopEndorsementForm,
    policyNumber: string,
    zendeskTags: string[] = []
  ): PolicyChangePayload {
    const optins = form.endorsementRequests.typeOfEndorsementOptIn;
    // Flag to indicate whether the payload will result in Guidewire changes.
    let includesAutomatedChanges = false;
    let attachments: QSAttachments | undefined;
    const coverages: Coverage[] = [];

    // We only want to include additional insureds in the payload if the user has optedIn for this type of endorsement.
    if (optins.additionalInsureds) {
      includesAutomatedChanges = true;
      attachments = this.constructAttachmentsPayload({
        additionalCoverages: {
          additionalInsuredBusinesses: form.additionalInsureds.additionalInsuredBusinesses,
        },
      });

      if (!attachments) {
        /**
         *  If the user has optedIn to modify Additional Insureds and constructAttachmentsPayload returns undefined,
         *  we can remove all of the AIs by passing an empty array.
         * */
        attachments = { additionalInsuredBusinesses: [] };
      }
    }

    if (optins.waiverOfSubrogation) {
      includesAutomatedChanges = true;
      coverages.push(
        this.constructWaiverOfSubrogationCoverage(
          form.waiverOfSubrogation.waiversOptIn,
          form.waiverOfSubrogation.waiversOfSubrogation
        )
      );
    }

    const effectiveDate = moment
      .utc(form.endorsementRequests.effectiveDate, US_DATE_MASK)
      .format('Y-MM-DD');

    const payload: PolicyChangePayload = {
      endorsement: {
        effectiveDate: effectiveDate,
      },
      endorsementTypes: Object.keys(optins).filter((optinKey) => (optins as any)[optinKey]),
      policyNumber,
    };

    if (attachments) {
      payload.attachments = attachments;
    }

    if (coverages.length > 0) {
      payload.policy = {
        coverages: coverages,
        effectiveDate: effectiveDate,
      };
    }

    if (optins.account && form.account) {
      includesAutomatedChanges = true;

      payload.account = {
        address: form.account.address,
        doingBusinessAs: form.account.doingBusinessAs,
        id: form.account.id,
      };
    }

    if (optins.coverages) {
      const endorsedBuildings = this.constructBuildingEndorsementPayload(form);
      if (endorsedBuildings.length) {
        includesAutomatedChanges = true;

        payload.coverages = {
          exposures: {
            coveredBuildings: endorsedBuildings,
          },
        };
      }
    }

    if (optins.namedInsureds) {
      if (form.namedInsureds) {
        includesAutomatedChanges = true;
        if (form.namedInsureds.typeOfChange === 'add_additional') {
          payload.additionalNamedInsureds = [
            {
              name: form.namedInsureds.additionalName,
              type: 'Company',
              relationship: 'Not given',
              address: {
                ...form.namedInsureds.additionalAddress,
                businessType: 'business',
              },
            },
          ];
        }
        if (form.namedInsureds.typeOfChange === 'update_primary') {
          payload.primaryNamedInsured = {
            name: form.namedInsureds.primaryName,
            type: 'Company',
            address: {
              ...form.namedInsureds.primaryAddress,
              businessType: 'business',
            },
          };
        }
      }
    }

    if (optins.locations && form.locations) {
      includesAutomatedChanges = true;
      payload.locations = this.constructLocationsPayload(form.locations);
    }

    payload.includesAutomatedChanges = includesAutomatedChanges;

    const s3Uploads = this.getAttachmentsForZendeskTicket(form);

    // Zendesk non-automated endorsements.

    if (form.review.brokerEmailAddress) {
      payload.zendeskEndorsements = {
        brokerEmailAddress: form.review.brokerEmailAddress,
        reasonForBackdating: form.endorsementRequests.reasonForBackdating
          ? form.endorsementRequests.reasonForBackdating
          : undefined,
        premiumIncreaseIsSmall: form.review.premiumIncreaseIsSmall,
        bppLimitChangeOutsideThreshold: form.review.bppLimitChangeOutsideThreshold,
        buildingLimitChangeOutsideThreshold: form.review.buildingLimitChangeOutsideThreshold,
        namedInsuredChange: optins.namedInsureds,
        policyChangeFailed: form.review.policyChangeFailed,
        policyChangeTimedOut: form.review.policyChangeTimedOut,
        quoteWasDeclined: form.review.quoteWasDeclined,
        attachments: s3Uploads,
        otherEndorsement: form.otherEndorsements
          ? form.otherEndorsements.zendeskEndorsement
          : undefined,
        coverages: form.coverages ? form.coverages : undefined,
        policyInfo: form.policyInfo ? form.policyInfo : undefined,
        tagsForZendeskTicket: zendeskTags.length ? zendeskTags : undefined,
      };

      // If the quote failed, was declined, or returned with a premium increase of <= $10,
      // we have already quoted; it is not necessary to do it again.
      if (
        form.review.policyChangeFailed ||
        form.review.quoteWasDeclined ||
        form.review.premiumIncreaseIsSmall
      ) {
        payload.skipQuote = true;
      }
    }

    return payload;
  }

  public getAttachmentsForZendeskTicket = (form: BopEndorsementForm) => {
    const nkllFiles = get(form, 'endorsementRequests.noKnownLossLetterFiles', []);
    const coveredBuildings = get(form, 'coverages.exposures.coveredBuildings', []);
    const locations: BopLocation[] = get(form, 'locations', []);
    const nkllAttachments = this.createEndorsementAttachments(nkllFiles, 'No Known Loss Letter(s)');

    const buildingAttachments: ZendeskAttachment[] = coveredBuildings.reduce(
      (endorsementAttachments: ZendeskAttachment[], building: CoveredBuilding) => {
        const { address, bppLimitChangeFiles, buildingLimitChangeFiles, buildingIndex } = building;
        const filesToAttach = [];

        if (bppLimitChangeFiles && bppLimitChangeFiles.length) {
          const fileDescription = `BPP Limit change doc(s) for ${address.addressLine1} (Building # ${buildingIndex})`;
          const bppLimitAttachments = this.createEndorsementAttachments(
            bppLimitChangeFiles,
            fileDescription
          );
          filesToAttach.push(...bppLimitAttachments);
        }

        if (buildingLimitChangeFiles && buildingLimitChangeFiles.length) {
          const fileDescription = `Building Limit change doc(s) for ${address.addressLine1} (Building # ${buildingIndex})`;
          const buildingLimitAttachments = this.createEndorsementAttachments(
            buildingLimitChangeFiles,
            fileDescription
          );
          filesToAttach.push(...buildingLimitAttachments);
        }

        return endorsementAttachments.concat(filesToAttach);
      },
      []
    );

    // We need the # of existing locations to offset the location # for newly added locations.
    const existingLocationCount =
      form?.policyInfo?.locationAddresses.reduce((prev, curr) => {
        if (Object.prototype.hasOwnProperty.call(curr, 'originalLocationNumber')) {
          return prev + 1;
        }
        return prev;
      }, 0) || 0;

    const lessorsRiskAttachments: ZendeskAttachment[] = locations.reduce(
      (
        endorsementAttachments: ZendeskAttachment[],
        currLocation: BopLocation,
        locationIndex: number
      ) => {
        const filesToAttach: ZendeskAttachment[] = [];
        currLocation?.buildings?.forEach((building, buildingIndex) => {
          if (building?.exposure?.lessorsRisk && building?.lessorsRisk?.rentRollFiles) {
            const fileDescription = `Lessors risk rent roll for location ${
              locationIndex + existingLocationCount + 1
            } - building ${buildingIndex + 1}.`;
            const attachments = this.createEndorsementAttachments(
              building.lessorsRisk.rentRollFiles,
              fileDescription
            );
            filesToAttach.push(...attachments);
          }
        });
        return endorsementAttachments.concat(filesToAttach);
      },
      []
    );

    return [...nkllAttachments, ...buildingAttachments, ...lessorsRiskAttachments];
  };

  private createLessorsRiskAttachments = (
    bopQuote: BopPolicy | BopEndorsementForm
  ): QSLessorsRiskBuilding[] => {
    const allBuildingsWithDocuments: QSLessorsRiskBuilding[] = [];
    let existingLocationIndex = 0;

    if ('endorsementRequests' in bopQuote && bopQuote?.policyInfo?.locationAddresses) {
      // For endorsements we want to increment the existing location count so that the location number is correct.
      bopQuote?.policyInfo?.locationAddresses.forEach((loc, index) => {
        if (Object.prototype.hasOwnProperty.call(loc, 'originalLocationNumber')) {
          existingLocationIndex += 1;
        }
      });
    }

    bopQuote?.locations?.forEach((location, locIndex: number) => {
      location.buildings.forEach((building, buildingIndex: number) => {
        const buildingWithDocuments: QSLessorsRiskBuilding = {
          // existingLocationIndex will be 0 unless this is an endorsement.
          locationNumber: String(existingLocationIndex + locIndex + 1),
          buildingNumber: String(buildingIndex + 1),
          documents: [],
        };
        if (building.exposure.lessorsRisk && building.lessorsRisk) {
          building.lessorsRisk.rentRollFiles
            .filter((file) => !!file.s3Key)
            .forEach((file) => {
              buildingWithDocuments.documents.push({
                s3Key: file.s3Key as string,
                s3Bucket: file.s3Bucket as string,
                originalFilename: file.name as string,
              });
            });
        }

        if (buildingWithDocuments.documents.length > 0) {
          allBuildingsWithDocuments.push(buildingWithDocuments);
        }
      });
    });
    return allBuildingsWithDocuments;
  };

  private createEndorsementAttachments = (
    files: FileUpload[],
    description: string
  ): ZendeskAttachment[] => {
    return files
      .map((file) => ({
        description: description,
        s3url: file.s3url || '',
      }))
      .filter((attachment) => !!attachment.s3url);
  };

  private constructAttachmentsPayload(quote: DeepPartial<BopPolicy>): QSAttachments | undefined {
    const additionalInsuredBusinesses = get(
      quote,
      'additionalCoverages.additionalInsuredBusinesses'
    );
    if (!additionalInsuredBusinesses || isEmpty(additionalInsuredBusinesses)) {
      return undefined;
    }

    const additionalInsureds: QSBopAdditionalInsured[] = additionalInsuredBusinesses.map(
      (addlInsured: BopAdditionalInsuredBusiness): QSBopAdditionalInsured => {
        const [_location, locationNum, buildingNum] = (addlInsured.relatedLocation as string).split(
          '-'
        );
        const zeroIndexedLocationNum: number = parseInt(locationNum, 10) - 1;
        const zeroIndexedBuildingNum: number = parseInt(buildingNum, 10) - 1;
        const address = addlInsured.address
          ? {
              addressLine1: addlInsured.address.addressLine1,
              addressLine2: addlInsured.address.addressLine2 || '',
              city: addlInsured.address.city,
              state: addlInsured.address.state,
              zip: addlInsured.address.zip,
            }
          : undefined;
        return {
          additionalInformation: addlInsured.additionalInformation,
          address: address,
          addressType: addlInsured.addressType,
          buildingDescription: addlInsured.buildingDescription,
          buildingNum: zeroIndexedBuildingNum,
          businessName: addlInsured.businessName,
          contractNumber: addlInsured.contractNumber,
          limitOfInsurance: addlInsured.limitOfInsurance
            ? String(parseMaskedInt(addlInsured.limitOfInsurance))
            : undefined,
          locationNum: zeroIndexedLocationNum,
          paragraph: addlInsured.paragraph,
          type: addlInsured.type,
          guidewireId: addlInsured.guidewireId || undefined,
          relatedEntityId: addlInsured.relatedEntityId || undefined,
        };
      }
    );

    return {
      additionalInsuredBusinesses: additionalInsureds,
    };
  }

  private constructLocationsPayload(locations: BopLocation[]): QSLocation[] {
    return locations.map((bopLocation: BopLocation): QSLocation => {
      const locationDetails = bopLocation.locationDetails;
      return {
        address: this.extractAddress(locationDetails),
        annualRevenue: parseMaskedInt(locationDetails.annualRevenue),
        buildings: bopLocation.buildings.map((bopBuilding: BopBuilding): QSBuilding => {
          const {
            businessIncomeAndExtraExpensesIndemnityInMonths,
            debrisRemoval,
            equipmentBreakdownCoverageOptedIn,
            ordinanceLawCoverageOptedIn,
            ordinanceLawCoverageValue,
            spoilage,
            utilityServicesDirectDamage,
            utilityServicesTimeElement,
            windCoverageOptedIn,
            windDeductible,
            windDeductiblePercent,
            windLossMitigation,
            windLossMitigationPresent,
          } = bopBuilding.coverage;
          const {
            buildingLimit,
            businessType,
            classification,
            constructionType,
            electricPlumbingHVACUpdated,
            hasAutomaticSprinklerSystem,
            lessorsRisk,
            limitForBusinessPersonalProperty,
            payroll,
            percentSalesPersonalDevices,
            roofUpdated,
            squareFootage,
            storyCount,
            totalSales,
            yearBuilt,
          } = bopBuilding.exposure;

          return {
            coverage: {
              businessIncomeAndExtraExpensesIndemnityInMonths: parseMaskedInt(
                (<unknown>businessIncomeAndExtraExpensesIndemnityInMonths) as string
              ),
              debrisRemoval: parseMaskedInt((<unknown>debrisRemoval) as string),
              equipmentBreakdownCoverage: equipmentBreakdownCoverageOptedIn,
              ordinanceAndLawCoverage: ordinanceLawCoverageOptedIn
                ? parseMaskedInt((<unknown>ordinanceLawCoverageValue) as string)
                : undefined,
              utilityServicesDirectDamage: parseMaskedInt(
                (<unknown>utilityServicesDirectDamage) as string
              ),
              utilityServicesTimeElement: parseMaskedInt(
                (<unknown>utilityServicesTimeElement) as string
              ),
              windCoverage: windCoverageOptedIn
                ? ({
                    windDeductible: parseMaskedInt((<unknown>windDeductible) as string) || null,
                    windDeductiblePercent: windDeductiblePercent,
                  } as WindBuildingCoverage)
                : undefined,
              windLossMitigation: windLossMitigationPresent
                ? (windLossMitigation as WindLossMitigationBuildingCoverage)
                : undefined,
              spoilageCoverage: Number(spoilage) > 0 ? Number(spoilage) : undefined,
            },
            exposure: {
              alcoholSales:
                classification.alcoholSales !== undefined
                  ? parseMaskedInt(classification.alcoholSales)
                  : undefined,
              buildingLimit: parseMaskedInt(buildingLimit),
              businessType: businessType,
              classification: classification.code as BuildingClassification,
              constructionType: constructionType,
              electricPlumbingHVACUpdated: electricPlumbingHVACUpdated || false,
              hasAutomaticSprinklerSystem: hasAutomaticSprinklerSystem,
              lessorsRisk: lessorsRisk,
              limitForBusinessPersonalProperty: parseMaskedInt(limitForBusinessPersonalProperty),
              payroll: parseMaskedInt(payroll) || undefined,
              percentSalesPersonalDevices: percentSalesPersonalDevices || undefined,
              roofUpdated: roofUpdated || false,
              squareFootage: parseMaskedInt(squareFootage),
              storyCount: storyCount,
              totalSales: parseMaskedInt(totalSales) || undefined,
              yearBuilt: parseMaskedInt(yearBuilt as any),
            },
            lessorsRisk: bopBuilding.lessorsRisk
              ? {
                  lessorsRiskTenants: bopBuilding.lessorsRisk.lessorsRiskTenants.map((tenant) => {
                    return {
                      ...tenant,
                      rent: parseMaskedInt(tenant.rent) || undefined,
                      squareFootage: parseMaskedInt(tenant.squareFootage),
                    };
                  }),
                  landlordRequirements: bopBuilding.lessorsRisk.landlordRequirements,
                  tenantResponsibilities: bopBuilding.lessorsRisk.tenantResponsibilities,
                  propertyManagementCompany: bopBuilding.lessorsRisk.propertyManagementCompany,
                  commonOwnershipWithTenants: bopBuilding.lessorsRisk.commonOwnershipWithTenants,
                }
              : undefined,
          };
        }),
        employeeCount: parseMaskedInt(locationDetails.employeeCount),
        partTimeEmployeeCount: parseMaskedInt(locationDetails.partTimeEmployeeCount),
        prefill: bopLocation.locationPrefill,
        propertyDeductible: parseMaskedInt(locationDetails.propertyDeductible),
        isWithinCreditableWaterSupplyForPPC: locationDetails.isWithinCreditableWaterSupplyForPPC,
      };
    });
  }

  private constructAccountPayload(
    insuredAccount: InsuredAccount,
    extras: {
      organizationType?: string;
      baseState?: string;
      yearsInBusiness?: string;
      periodStart?: Date;
    }
  ): QSAccount {
    const { organizationType, baseState, yearsInBusiness, periodStart } = extras;
    const periodStartYear = moment(periodStart).year();

    const account: QSAccount = {
      address: this.extractAddress(insuredAccount),
      baseState: baseState || insuredAccount.state,
      description: insuredAccount.description,
      id: insuredAccount.id,
      insuredEmail: insuredAccount.emailAddress,
      insuredPhone: insuredAccount.phoneNumber.replace(/[^0-9]/g, ''),
      name: insuredAccount.companyName,
      organizationType: organizationType || insuredAccount.organizationType || '',
      website: insuredAccount.website || 'foo.com',
    };

    if (yearsInBusiness && periodStart) {
      account.yearBusinessStarted = periodStartYear - parseMaskedInt(yearsInBusiness);
    }

    return account;
  }

  private constructPolicyPayload(quote: BopPolicy): QSPolicy {
    const bopVersion = quote.policyInfo.bopVersion.toString();

    return {
      coverages: this.constructCoveragesPayload(quote),
      productType: 'BOP',
      productVersion: bopVersion,
      nyMeToo: quote.policyInfo.meToo,
      effectiveDate: moment.utc(quote.periodStart()).format('Y-MM-DD'),
    };
  }

  private constructIrpmQuestionsPayload(quote: BopPolicy): QSIrpmQuestions | undefined {
    // IRPM questions are only relevent for bop v2 and v3.
    if (!isBopVersion2or3(quote.policyInfo.bopVersion)) {
      return undefined;
    }

    return {
      equipmentLeftInVehicle: quote.liabilityCoverages.equipmentLeftInVehicle,
      stressTestsWaterLines: quote.liabilityCoverages.stressTestsWaterLines,
      employeeHandbook: quote.liabilityCoverages.employeeHandbook,
      janitorialServices: quote.liabilityCoverages.janitorialServices,
    };
  }

  private constructWaiverOfSubrogationCoverage(
    wosOptedIn: boolean,
    scheduledWaivers: SubgrogationWaiverName[]
  ): Coverage {
    const payload = {
      name: 'WaiverOfSubrogation',
      value: {
        optedIn: wosOptedIn,
      } as CoverageValue,
    };
    if (scheduledWaivers) {
      payload.value.waiversOfSubrogation = scheduledWaivers;
    }

    return payload;
  }

  private constructBuildingEndorsementPayload(form: BopEndorsementForm) {
    const coveredBuildings: CoveredBuilding[] = get(
      form,
      'coverages.exposures.coveredBuildings',
      []
    );

    return coveredBuildings
      .filter((building) => {
        // Only include modified buildings, i.e. ones that have a guidewireId control enabled.
        return !!building.guidewireId;
      })
      .map((building): BopEndorsementBuilding => {
        return {
          guidewireId: building.guidewireId,
          buildingLimit: parseMaskedInt(building.buildingLimit),
          limitForBusinessPersonalProperty: parseMaskedInt(
            building.limitForBusinessPersonalProperty
          ),
        };
      });
  }

  private constructCoveragesPayload(quote: BopPolicy): Coverage[] {
    const coverages: Coverage[] = [];
    const { bopVersion, baseState } = quote.policyInfo;

    const cyberCoverage = quote.additionalCoverages.cyberLiabilityCoverage;
    if (cyberCoverage && cyberCoverage.optedIn) {
      if (isBopVersion2or3(bopVersion)) {
        const splitLimitState = BOP_V2_CYBER_SPLIT_STATES.includes(baseState);
        if (splitLimitState) {
          coverages.push({
            name: 'CyberLiability',
            value: {
              firstPartyLimit: cyberCoverage.firstPartyLimit as number,
              deductible: cyberCoverage.deductible as number,
            },
          });
        } else {
          coverages.push({
            name: 'CyberLiability',
            value: {
              aggregateLimit: cyberCoverage.aggregateLimit as number,
              deductible: cyberCoverage.deductible as number,
            },
          });
        }
      } else {
        coverages.push({
          name: 'CyberLiability',
          value: {
            aggregateLimit: cyberCoverage.aggregateLimit as number,
            deductible: cyberCoverage.deductible as number,
            retroactiveDate: cyberCoverage.retroactiveDate,
          },
        });
      }
    }

    if (quote.additionalCoverages.hasWaiversOfSubrogation) {
      const wosCoverage = quote.additionalCoverages.waiversOfSubrogation;
      coverages.push(
        this.constructWaiverOfSubrogationCoverage(
          quote.additionalCoverages.hasWaiversOfSubrogation,
          wosCoverage
        )
      );
    }

    const erpCoverage = quote.additionalCoverages.employmentRelatedPracticesLiabilityCoverage;
    if (erpCoverage && erpCoverage.optedIn) {
      if (isBopVersion2or3(bopVersion)) {
        if (BOP_V2_EPLI_SPLIT_STATES.includes(baseState)) {
          coverages.push({
            name: 'EmployeeRelatedPracticesLiability',
            value: {
              aggregateLimitDefense: erpCoverage.defenseLimitV2,
              aggregateLimitIndemnity: erpCoverage.defenseLimitV2,
              deductible: erpCoverage.deductibleV2,
            },
          });
        } else {
          coverages.push({
            name: 'EmployeeRelatedPracticesLiability',
            value: {
              aggregateLimit: erpCoverage.aggregateLimitV2,
              deductible: erpCoverage.deductibleV2,
            },
          });
        }
      } else {
        coverages.push({
          name: 'EmployeeRelatedPracticesLiability',
          value: {
            aggregateLimit: erpCoverage.aggregateLimit,
            deductible: erpCoverage.deductible,
            perEmploymentWrongfulActLimit: erpCoverage.eachEmploymentWrongfulActLimit,
            retroactiveDate: erpCoverage.retroactiveDate,
          },
        });
      }
    }

    const stopGapCoverage = quote.liabilityCoverages.stopGap;
    if (stopGapCoverage) {
      const glLimit = quote.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses;
      coverages.push({
        name: 'StopGapCoverage',
        value: {
          eachAccident: glLimit,
          eachEmployee: glLimit,
          eachAggregate: parseInt(glLimit, 10) * 2,
        },
      });
    }

    const ebCoverage = quote.liabilityCoverages.employeeBenefitsLiabilityCoverage;
    if (ebCoverage && ebCoverage.optedIn) {
      coverages.push({
        name: 'EmployeeBenefits',
        value: {
          aggregateLimit: ebCoverage.aggregateLimit as number,
          deductible: ebCoverage.deductible as number,
          perEmployeeLimit: ebCoverage.eachEmployeeLimit as number,
          retroactiveDate: ebCoverage.retroactiveDate,
        },
      });
    }

    const liquorCoverage = quote.liabilityCoverages.liquorLiability;
    if (liquorCoverage && liquorCoverage.optedIn) {
      coverages.push({
        name: 'LiquorLiability',
        value: {
          aggregateLimit: liquorCoverage.aggregateLimit,
          perCommonCauseLimit: liquorCoverage.eachCommonCauseLimit,
          liquorLicenseNumber: liquorCoverage.liquorLicenseNumber,
        },
      });
    }

    if (quote.liabilityCoverages.printersErrorsAndOmissionsProfessionalLiability) {
      coverages.push({
        name: 'PrintersErrorsAndOmissionsProfessionalLiability',
        value: {
          exposure: parseMaskedInt(
            quote.liabilityCoverages.printersErrorsAndOmissionsSalesOrPayroll
          ),
        },
      });
    }

    coverages.push({
      name: 'CertifiedActsOfTerrorism',
      value: {
        optedIn:
          quote.additionalCoverages.acceptCertifiedActsOfTerrorismCoverage ||
          (quote.policyInfo.bopVersion === 1 && quote.policyInfo.baseState !== 'NY'),
      },
    });

    coverages.push({
      name: 'EmployeeDishonesty',
      value: {
        aggregateLimit: determineLimitForEmployeeDishonesty(quote),
      },
    });

    if (quote.liabilityCoverages.acceptHiredNonOwnedAutoCoverage) {
      coverages.push({
        name: 'HiredNonOwnedAuto',
        value: {
          optedIn: true,
          numberOfDrivers: quote.liabilityCoverages.hnoaNumberOfDrivers || 0,
        },
      });
    }

    if (quote.liabilityCoverages.acceptSnowPlowCompletedOpsCoverage) {
      coverages.push({
        name: 'SnowPlowCompletedOps',
        value: {
          optedIn: true,
        },
      });
    }

    const installationLimit = quote.liabilityCoverages.installationLimit;
    const toolsBlanketLimit = quote.liabilityCoverages.toolsBlanketLimit;
    const toolsPerItemLimit = quote.liabilityCoverages.toolsPerItemLimit;
    const eachCoveredJobSiteLimit = quote.liabilityCoverages.eachCoveredJobSiteLimit;
    const propertyInTransitLimit = quote.liabilityCoverages.propertyInTransitLimit;
    if (installationLimit && toolsBlanketLimit && toolsPerItemLimit) {
      coverages.push({
        name: 'Contractor',
        value: {
          coverageLimit: installationLimit,
          blanketPerItemLimit: toolsPerItemLimit,
          blanketLimit: toolsBlanketLimit,
          deductible: 500,
        },
      });
    } else if (eachCoveredJobSiteLimit && propertyInTransitLimit) {
      coverages.push({
        name: 'Contractor',
        value: {
          perCoveredJobSiteLimit: eachCoveredJobSiteLimit,
          propertyInTransitLimit: parseMaskedInt(propertyInTransitLimit),
        },
      });
    }

    if (quote.liabilityCoverages.barberShopsAndHairSalonsProfessionalLiability) {
      coverages.push({
        name: 'BarberShopsAndHairSalonsProfessionalLiability',
        value: {
          numOperators: quote.liabilityCoverages.barberShopsNumberOfOperators,
        },
      });
    }

    if (quote.liabilityCoverages.beautySalonsProfessionalLiability) {
      coverages.push({
        name: 'BeautySalonsProfessionalLiability',
        value: {
          numOperators: quote.liabilityCoverages.beautySalonsNumberOfOperators,
        },
      });
    }

    coverages.push({
      name: 'DamageToPremises',
      value: {
        occurrenceLimit: quote.liabilityCoverages.damageToPremises,
      },
    });

    coverages.push({
      name: 'PerOccurrence',
      value: {
        occurrenceLimit: quote.liabilityCoverages.limitPerOccurrenceOfLiabilityAndMedicalExpenses,
      },
    });

    coverages.push({
      name: 'PerPersonMedical',
      value: {
        perPersonLimit: quote.liabilityCoverages.limitPerPersonOfMedicalExpenses,
      },
    });

    if (quote.liabilityCoverages.funeralDirectorsProfessionalLiability) {
      coverages.push({
        name: 'FuneralDirectorsProfessionalLiability',
        value: {
          optedIn: true,
        },
      });
    }

    if (quote.liabilityCoverages.veterinariansProfessionalLiability) {
      coverages.push({
        name: 'VeterinariansProfessionalLiability',
        value: {
          numVeterinarians: quote.liabilityCoverages.numberOfVeterinarians,
          onlyTreatsHousePets: quote.liabilityCoverages.veterinariansOnlyTreatHousePets as boolean,
        },
      });
    }

    if (quote.liabilityCoverages.opticalAndHearingAidEstablishmentsProfessionalLiability) {
      coverages.push({
        name: 'OpticalAndHearingAidProfessionalLiability',
        value: {
          opticalAndHearingAidSales: parseMaskedInt(
            quote.liabilityCoverages.opticalAndHearingAidSales
          ),
        },
      });
    }

    return coverages;
  }

  private mapToPricedBopQuote(payload: QSQuoteSubmissionResponse): BopPricedQuote {
    const response = payload.results[0].data as QSQuoteSubmissionData;
    return new BopPricedQuote({
      allCosts: response.allCosts,
      id: response.id,
      totalCost: response.totalCost,
      totalPremium: response.premium,
      totalTaxesAndFees: response.taxesAndFees,
      valid: true,
      reasons: [],
    });
  }

  private extractAddress(addressObject: Address): Address {
    const { addressLine1, addressLine2, city, state, zip } = addressObject;
    return {
      addressLine1: addressLine1,
      addressLine2: addressLine2 || '',
      city: city,
      state: state,
      zip: zip,
    };
  }
}
