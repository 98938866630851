import { omit } from 'lodash';
import { Subscription, of as observableOf } from 'rxjs';
import { Component, Input, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AttuneBopBuildingClassificationService } from 'app/features/attune-bop/services/attune-bop-building-classification.service';
import {
  AVAILABLE_BUSINESS_TYPES,
  AVAILABLE_CONSTRUCTION_TYPES,
  AVAILABLE_NUMBER_OF_STORIES,
  FUNCTIONAL_REPLACEMENT_COST_CUTOFF_YEAR,
  FUNCTIONAL_REPLACEMENT_COST_MESSAGE,
  FUNCTIONAL_REPLACEMENT_COST_STATE_EXCEPTIONS,
  LESSORS_OF_NON_RESIDENTIAL_BUILDINGS,
  LRO_HOW_TO_CLASSIFY_FAQ,
} from 'app/features/attune-bop/models/constants';
import { getControl } from 'app/shared/helpers/form-helpers';
import { NaicsService } from '../../shared/services/naics.service';
import { BOP_GUIDELINE_LINK_MAP } from 'app/features/support/models/support-constants';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from 'app/core/services/feature-flag.service';

// TODO move to bop specific place
@Component({
  selector: 'app-building-exposure-form',
  templateUrl: './building-exposure-form.component.html',
})
export class BuildingExposureFormComponent implements OnInit, OnDestroy {
  @Input() locationIndex: number;
  @Input() buildingIndex: number;
  @Input() buildingExposureFormGroup: UntypedFormGroup;
  @Input() isEligibilityOverride: boolean;
  @Input() displayDeductibleChangedWarning: boolean;
  @Input() buildingLessorsRiskFormGroup: UntypedFormGroup;
  @Input() currentLocationDetails: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() bopVersion: BopVersion;
  @Input() baseState: string | null;
  @ViewChild('typeahead')
  typeahead: ElementRef;

  protected sub: Subscription = new Subscription();

  availableConstructionTypes = AVAILABLE_CONSTRUCTION_TYPES;
  availableNumberOfStories = AVAILABLE_NUMBER_OF_STORIES;
  // TODO(NY) - Remove feature flag after feature is in production.
  lessorsRiskQuestionsEnabled = environment.lroFlowEnabled;
  functionalReplacementCostMessage = FUNCTIONAL_REPLACEMENT_COST_MESSAGE;
  showFunctionalReplacementCostWarning = false;

  initialBuildingLimit: string = '';

  public faqs: (Faq & { isToggled?: boolean })[];

  constructor(
    private buildingClassificationService: AttuneBopBuildingClassificationService,
    private naicsService: NaicsService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    if (this.baseState) {
      const naicsObservable = this.naicsService.getGuidelines(
        this.bopVersion,
        LESSORS_OF_NON_RESIDENTIAL_BUILDINGS.hash,
        this.baseState
      );
      naicsObservable.subscribe(
        (data: AskKodiakProductData) => {
          let construct_faqs = "<ul class='lro-bop-faqs-answer'>";
          for (const guideline of data.guidelines) {
            construct_faqs += '<li>' + guideline + '</li>';
          }
          construct_faqs += '</ul>';
          this.faqs = [
            LRO_HOW_TO_CLASSIFY_FAQ,
            {
              question: 'Guidelines',
              answer: construct_faqs,
            },
          ];
        },
        (_) => {
          this.faqs = [LRO_HOW_TO_CLASSIFY_FAQ];
        }
      );
    } else {
      this.faqs = [LRO_HOW_TO_CLASSIFY_FAQ];
    }

    this.initialBuildingLimit = getControl(this.buildingExposureFormGroup, 'buildingLimit').value;

    this.featureFlagService
      .isEnabled(BOOLEAN_FLAG_NAMES.FUNCTIONAL_REPLACEMENT_COST_WARNING)
      .subscribe((isEnabled) => {
        this.showFunctionalReplacementCostWarning = !!isEnabled;
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getBopGuidelinesLink() {
    return BOP_GUIDELINE_LINK_MAP[this.bopVersion];
  }

  getAvailableBusinessTypes() {
    const currentState = this.getCurrentState();

    if (this.isLRO() && (currentState === 'NJ' || currentState === 'IL')) {
      return omit(AVAILABLE_BUSINESS_TYPES, 'Mercantile');
    }

    return this.bopVersion === 1
      ? omit(AVAILABLE_BUSINESS_TYPES, 'Artisans')
      : AVAILABLE_BUSINESS_TYPES;
  }

  isBusinessTypeExisting() {
    return !!getControl(this.buildingExposureFormGroup, 'businessType').value;
  }

  codeDescription = (classification: BuildingClassification | null): string => {
    if (!classification) {
      return '';
    }
    const description = this.buildingClassificationService.codeDescription(
      (<UntypedFormControl>this.buildingExposureFormGroup.get('businessType')).value,
      classification.descriptionCode
    );
    if (!description) {
      return '';
    }
    return description + ' (' + classification.code + ')';
  };

  queryMethod = (query: string) => {
    const businessTypeFormValue = getControl(this.buildingExposureFormGroup, 'businessType').value;
    const lessorsRisk = getControl(this.buildingExposureFormGroup, 'lessorsRisk').value;
    const state = getControl(this.currentLocationDetails, 'state').value;
    const classifications: BuildingClassification[] =
      this.buildingClassificationService.partialSearch({
        category: businessTypeFormValue,
        part: query,
        state,
        lroOption: lessorsRisk,
        bopVersion: this.bopVersion,
      });

    return observableOf(classifications);
  };

  classification() {
    return getControl(this.buildingExposureFormGroup, 'classification');
  }

  isLRO() {
    return getControl(this.buildingExposureFormGroup, 'lessorsRisk').value;
  }

  getCurrentState() {
    return getControl(this.currentLocationDetails, 'state').value;
  }

  getCurrentYearBuilt() {
    const yearBuilt = getControl(this.buildingExposureFormGroup, 'yearBuilt').value;
    return yearBuilt;
  }

  displayFunctionalReplacementCoverageNote() {
    const currentState = this.getCurrentState();
    if (!FUNCTIONAL_REPLACEMENT_COST_STATE_EXCEPTIONS.includes(currentState)) {
      const fiftyYearsAgo = moment().add(-FUNCTIONAL_REPLACEMENT_COST_CUTOFF_YEAR, 'year').year();
      const yearBuilt = this.getCurrentYearBuilt();
      if (
        yearBuilt &&
        yearBuilt.length === 4 &&
        Number(yearBuilt) < fiftyYearsAgo &&
        this.showFunctionalReplacementCostWarning
      ) {
        return true;
      }
    }

    return false;
  }

  isLROAllowed() {
    const state = getControl(this.currentLocationDetails, 'state').value;
    const businessType = getControl(this.buildingExposureFormGroup, 'businessType').value;

    if (state === 'CA') {
      return false;
    } else if ((state === 'NJ' || state === 'IL') && businessType === 'Mercantile') {
      return false;
    }

    return true;
  }

  buildingLimitReadonly() {
    return (
      getControl(this.currentLocationDetails, 'state').value === 'CA' &&
      this.initialBuildingLimit === '$0'
    );
  }

  buildingLimitIsEnabled() {
    return getControl(this.buildingExposureFormGroup, 'buildingLimit').enabled;
  }

  errorMessage(controlName: string): string {
    const errors = getControl(this.buildingExposureFormGroup, controlName).errors;
    if (!errors) {
      return '';
    }
    const errorKeys = Object.keys(errors);
    for (let i = 0; i < errorKeys.length; i++) {
      const error = errors[errorKeys[i]];
      if (error && error.message) {
        return error.message;
      }
    }
    return '';
  }

  get largestTenantFormGroup() {
    // The first tenant in this form array will be the largest occupant.
    return this.buildingLessorsRiskFormGroup.get('lessorsRiskTenants.0') as UntypedFormGroup;
  }
}
