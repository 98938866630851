import { of as observableOf, Subject, Observable } from 'rxjs';
import { catchError, map, tap, delay, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { API_V3_BASE } from 'app/constants';

import { SentryService } from 'app/core/services/sentry.service';
import { BopEndorsementForm } from 'app/features/attune-bop/models/bop-endorsement';
import { V3QuoteService } from 'app/shared/services/v3-quote-service';
import { cloneDeep } from 'lodash';
import { environment } from 'environments/environment';

@Injectable()
export class AttuneBopEndorseQuoteService {
  protected quotedSuccess$ = new Subject();
  quoteInProgress = false;
  bindInProgress = false;
  bindSuccess$ = new Subject();

  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private v3QuoteService: V3QuoteService
  ) {}

  retrievePolicyChange(policyChangeJobNumber: string): Observable<BaseQuote | null> {
    return this.http
      .get<BaseQuote>(`${API_V3_BASE}/quotes/endorsements/${policyChangeJobNumber}`)
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to retrieve quote for policy changes.', {
            severity: 'error',
            metaData: {
              policyChangeJobNumber,
              underlyingErorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  createPolicyChange(
    policyNumber: string,
    termNumber: string,
    effectiveDate: moment.Moment
  ): Observable<{ jobNumber: string } | null> {
    return this.http
      .post<{ jobNumber: string }>(
        `${API_V3_BASE}/quotes/policies/${policyNumber}/${termNumber}/endorse`,
        {
          policyNumber,
          effectiveDate: effectiveDate.utcOffset('+0000').format('Y-MM-DD'),
        }
      )
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to create endorsement policy change.', {
            severity: 'error',
            metaData: {
              policyNumber,
              underlyingErorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  createPolicyChangeCached(
    policyNumber: string,
    termNumber: string,
    effectiveDate: moment.Moment
  ): Observable<{ jobNumber: string } | null> {
    return this.http
      .post<{ jobNumber: string }>(
        `${API_V3_BASE}/quotes/policies/${policyNumber}/${termNumber}/cached-endorse`,
        {
          policyNumber,
          effectiveDate: effectiveDate.utcOffset('+0000').format('Y-MM-DD'),
        }
      )
      .pipe(
        catchError((error) => {
          this.sentryService.notify('Unable to create endorsement policy change.', {
            severity: 'error',
            metaData: {
              policyNumber,
              underlyingErorMessage: error && error.message,
              underlyingError: error,
            },
          });
          return observableOf(null);
        })
      );
  }

  submitPolicyChange(input: {
    quote: BopEndorsementForm;
    accountNumber: string;
    jobNumber: string;
    policyNumber: string;
    tsRequestId: string;
  }): Observable<PolicyChangeResponse> {
    const { accountNumber, jobNumber, policyNumber, quote, tsRequestId } = input;
    const url = `${API_V3_BASE}/quotes/endorsements/${jobNumber}`;
    const payload = this.v3QuoteService.translateToPolicyChangePayload(quote, policyNumber);

    this.quoteInProgress = true;

    return this.http.put(url, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to submit endorsement policy change.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErorMessage: error && error.message,
            underlyingError: error,
          },
        });
        this.quoteInProgress = false;

        throw error;
      }),
      mergeMap((response: PolicyChangeResponse) => {
        if (response?.jobNumber && environment.lroFlowEnabled) {
          // Checks if there are any lessors risk documents to be updated and updates accordingly
          return this.v3QuoteService
            .updateLessorsRiskDocuments({
              accountId: accountNumber,
              bopQuote: quote,
              quoteNumber: response?.jobNumber,
              tsRequestId: tsRequestId,
            })
            .pipe(map(() => response));
        }
        return observableOf(response);
      }),
      tap(() => {
        this.quotedSuccess$.next(true);
      }),
      delay(3000),
      map((response: PolicyChangeResponse) => {
        this.quoteInProgress = false;
        return response;
      })
    );
  }

  submitZendeskPolicyChange(
    quote: BopEndorsementForm,
    formDiffForZendeskTicket: any,
    jobNumber: string,
    policyNumber: string,
    termNumber: string,
    zendeskTags: string[]
  ) {
    const url = `${API_V3_BASE}/quotes/endorsements/${jobNumber}`;
    const payload = this.v3QuoteService.translateToPolicyChangePayload(
      quote,
      policyNumber,
      zendeskTags
    );

    // Including policy and term number for zendesk ticket
    if (policyNumber) {
      payload.policyNumber = policyNumber;
    }
    if (termNumber) {
      payload.termNumber = termNumber;
    }

    if (formDiffForZendeskTicket) {
      payload.formDiffForZendeskTicket = formDiffForZendeskTicket;
    }

    if (quote.review.policyHasRolloverPolicy && payload.zendeskEndorsements) {
      payload.zendeskEndorsements.accountHasBopRolloverPolicy = true;
    } else if (quote.review.policyHasRolloverQuote && payload.zendeskEndorsements) {
      payload.zendeskEndorsements.accountHasBopRolloverQuote = true;
    }

    return this.http.put(url, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to submit zendesk endorsement policy change.', {
          severity: 'error',
          metaData: {
            payload,
            underlyingErorMessage: error && error.message,
            underlyingError: error,
          },
        });
        throw error;
      }),
      map((response: any) => {
        return response;
      })
    );
  }

  getZendeskEndorsement(policyId: string): Observable<any> {
    return this.http.get(`${API_V3_BASE}/quotes/policy/${policyId}/zendeskEndorsement`).pipe(
      catchError((error) => {
        if (error && error.status === 404) {
          return observableOf(null);
        }

        this.sentryService.notify('Unable to retrieve zendesk ticket for policy change.', {
          severity: 'error',
          metaData: {
            policyId,
            underlyingErorMessage: error && error.message,
            underlyingError: error,
          },
        });
        return observableOf(null);
      })
    );
  }

  bindPolicyChange(jobNumber: string, bindPayload: PolicyChangeBindPayload) {
    const url = `${API_V3_BASE}/quotes/endorsements/${jobNumber}/bind`;
    this.bindInProgress = true;
    const payload = cloneDeep(bindPayload);
    if (payload.zendeskData) {
      const zendeskAttachments = this.v3QuoteService.getAttachmentsForZendeskTicket(
        payload.zendeskData.form
      );
      payload.zendeskData.zendeskAttachments = zendeskAttachments.length
        ? zendeskAttachments
        : undefined;
    }

    return this.http.post<QSQuoteSubmissionResponse>(url, payload).pipe(
      catchError((error) => {
        this.sentryService.notify('Unable to bind endorsement policy change.', {
          severity: 'error',
          metaData: {
            jobNumber,
            underlyingErorMessage: error && error.message,
            underlyingError: error,
          },
        });

        this.bindInProgress = false;
        throw error;
      }),
      tap(() => {
        this.bindSuccess$.next(true);
      }),
      delay(3000),
      map((response) => {
        this.bindInProgress = false;
        const quoteSubmissionData = response.results[0].data;
        if (this.isQSQuoteSubmissionData(quoteSubmissionData)) {
          return {
            policyNumber: quoteSubmissionData.policyId,
            termNumber: quoteSubmissionData.policyTermNumber,
          };
        }
        // TODO: Handle case where an error isn't thrown and quoteSubmissionData is of type QSQuoteSubmissionErrorData?
      })
    );
  }

  isQuoteInProgress() {
    return this.quoteInProgress;
  }

  getQuotedSuccess() {
    return this.quotedSuccess$.asObservable();
  }

  isBindInProgress() {
    return this.bindInProgress;
  }

  getBindSuccess() {
    return this.bindSuccess$.asObservable();
  }

  // Type guard for QSQuoteSubmissionData
  isQSQuoteSubmissionData(
    submissionData: QSQuoteSubmissionData | QSQuoteSubmissionErrorData
  ): submissionData is QSQuoteSubmissionData {
    return (<QSQuoteSubmissionData>submissionData).policyId !== undefined;
  }
}
