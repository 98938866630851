// Libraries
import { Component, isDevMode, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  catchError,
  first,
  filter,
  forkJoin,
  interval,
  map,
  Observable,
  of as observableOf,
  startWith,
  Subscription,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { isEqual, uniq } from 'lodash';

// Components
import { FormDslSteppedFormBaseComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form-base.component';

// Services
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { AttuneWcClassCodesService } from 'app/workers-comp/attune/services/attune-wc-class-code.service';
import { AttuneWCQuoteService } from 'app/workers-comp/attune/services/attune-wc-quote.service';
import { AttuneWcEligibilityService } from 'app/workers-comp/attune/services/attune-wc-eligibility.service';
import {
  DraftQuoteService,
  DraftOrigin,
  DraftQuote,
} from 'app/shared/services/draft-quote.service';

// Models
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { QuoteFormGroup, QuoteFormValue } from 'app/workers-comp/attune/models/quote-form.model';
import {
  GuidewireWCQuoteResponse,
  GetDraftResponse,
  GetQuoteResponse,
} from 'app/workers-comp/attune/models/quote.model';
import {
  AttuneWcEligibilityCheckResponse,
  AttuneWcEligibilityDeclineReason,
} from 'app/workers-comp/attune/models/eligibility.model';

// Constants
import { UNKNOWN_ERROR } from 'app/shared/quote-error-modal/errors';
import {
  ATTUNE_WC_ELIGIBILITY_ERROR,
  ATTUNE_WC_INVALID_FEIN_ERROR,
  ATTUNE_WC_MULTIPLE_RISK_IDS_FOUND_ERROR,
} from 'app/workers-comp/attune/constants';
import { FIVE_SECONDS_IN_MS } from 'app/constants';

// Helpers
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { AttuneEventName, SegmentService } from '../../../../../core/services/segment.service';
import { SentryService } from '../../../../../core/services/sentry.service';
import {
  hasNcciValidationError,
  hasNcciMultipleRiskIdsError,
} from 'app/workers-comp/attune/helpers/errorParsing';
import { isDraftQuoteResponse } from 'app/workers-comp/attune/helpers/typeGuards';

@Component({
  selector: 'app-wc.app-page.app-page__form',
  templateUrl: './attune-wc-quote-form.component.html',
  providers: [AttuneWcQuoteFormService, AttuneWcClassCodesService, AttuneWcEligibilityService],
})
export class AttuneWcQuoteFormComponent
  extends FormDslSteppedFormBaseComponent<AttuneWcQuoteFormService>
  implements OnInit, OnDestroy
{
  constructor(
    public formService: AttuneWcQuoteFormService,
    protected insuredAccountService: InsuredAccountService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected informService: InformService,
    protected eligibilityService: AttuneWcEligibilityService,
    protected quoteService: AttuneWCQuoteService,
    protected amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private segmentService: SegmentService,
    private draftQuoteService: DraftQuoteService
  ) {
    super(formService, route, router);
  }

  // Form
  public form: QuoteFormGroup;

  // Flags
  public isDevMode: boolean = isDevMode();
  public isLoadingData: boolean = false;
  public waitingForEligibilityCheck: boolean = false;
  public eligibilityDeclineReasons: string[] = [];
  public errorModalOpen: boolean = false;
  private quoteStartedEventWasSent = false;

  public accountId: string;
  public insAccount: InsuredAccount;
  private sub: Subscription = new Subscription();

  // Uw issue "messages", these should make a quote fail even when we have a valid priced quote.
  public quoteUwIssues: string[] = [];
  public quoteErrors: string[] = [];
  // priced quote
  private pricedQuote: GuidewireWCQuoteResponse;

  // Edit fields
  public isEditQuote = false;
  public editQuoteNumber: string;

  // Draft fields
  public isDraftQuote = false;
  public draftQuoteId: string | undefined;
  private lastSavedDraftFormValue: QuoteFormValue;

  ngOnInit() {
    super.ngOnInit();
    this.accountId = this.route.snapshot.params['accountId'];
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
    this.setEditDetailsIfApplicable();
    this.setDraftDetailsIfApplicable();
    this.initializeDetails();
    this.formService.setAccountStepVisibility(this.fromAccountCreate());
    // We only show guidelines for new quotes and drafts.
    this.formService.setGuidelinesStepVisibility(!this.isEditQuote);
    this.navigateToCurrentStep();
    zendeskLeftSnap();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    removeZendeskLeftSnap();
    super.ngOnDestroy();
  }

  get isLoading() {
    // This flag is used to show placeholder form "skeleton" screen vs the real form.
    // NOTE: we may want to show another type of loading screen
    // for eligibility checks since they can take 2-3 seconds on a lambda cold start.
    return this.isLoadingData || this.waitingForEligibilityCheck;
  }

  public checkQuotingSuccess(
    response: AttuneWcEligibilityCheckResponse | GuidewireWCQuoteResponse
  ): Observable<boolean> {
    // Check if we got an eligibility decline just before attempting to quote.
    if (this.eligibilityDeclineReasons.length > 0) {
      return observableOf(false);
    }

    if (this.isGWQuoteResponse(response)) {
      // A quote can be valid and have underwritingIssues so we check this before checking the validQuote property.
      if (response?.underwritingIssues && response?.underwritingIssues.length > 0) {
        // In some cases, UW issues are duplicated for quote/bind blocks, here we ensure each entry is unique.
        this.quoteUwIssues = uniq(response.underwritingIssues.map((issue) => issue.message));
        return observableOf(false);
      }

      if (response?.errors && response?.errors.length > 0) {
        this.quoteErrors = response.errors
          .map((error) => error?.message)
          .filter((message) => !!message);
        return observableOf(false);
      }

      if (response.validQuote) {
        this.pricedQuote = response;
        return observableOf(true);
      }
    }

    return observableOf(false);
  }

  public onIncrementedStep(_nextStep: RouteFormStep): void {
    this.updateAccountDescIfNecessary();
    this.amplitudeService.track({
      eventName: 'eligibility_attempt',
      detail: 'attune_wc',
    });
    this.waitingForEligibilityCheck = true;
    this.sub.add(
      this.eligibilityService
        .fetchEligibilityCheck(this.formService.form, this.accountId, this.tsRequestId, false)
        .subscribe((res) => {
          this.waitingForEligibilityCheck = false;
          if (!res.acceptRisk) {
            this.eligibilityDeclineReasons = this.mapEligibilityReasons(res.declineReasons);
            this.deleteDraftIfApplicable();
            this.openErrorModal();
          } else {
            this.amplitudeService.track({
              eventName: 'eligibility_success',
              detail: 'attune_wc',
            });
            this.navigateToCurrentStep();
          }
        })
    );
  }

  public sendForm(): Observable<
    AttuneWcEligibilityCheckResponse | GuidewireWCQuoteResponse | HttpErrorResponse
  > {
    this.amplitudeService.track({
      eventName: 'eligibility_attempt',
      detail: 'attune_wc',
    });
    this.sendSegmentEvent('Quote Attempted');
    this.waitingForEligibilityCheck = true;
    return this.eligibilityService
      .fetchEligibilityCheck(this.formService.form, this.accountId, this.tsRequestId, true)
      .pipe(
        switchMap((res: AttuneWcEligibilityCheckResponse) => {
          this.waitingForEligibilityCheck = false;
          if (!res.acceptRisk) {
            this.eligibilityDeclineReasons = this.mapEligibilityReasons(res.declineReasons);
            return observableOf(res);
          } else {
            this.amplitudeService.track({
              eventName: 'eligibility_success',
              detail: 'attune_wc',
            });
            this.amplitudeService.track({
              eventName: 'quote_attempt',
              detail: 'attune_wc',
            });
            return this.quoteService.requestQuote({
              // We need to cast because angular typed forms will always include partials.
              quoteForm: this.formService.form.value as QuoteFormValue,
              insuredAccount: this.insAccount,
              tsRequestId: this.tsRequestId,
            });
          }
        })
      );
  }

  public handleSuccessfulQuote(): void {
    this.amplitudeService.track({
      eventName: 'quote_success',
      detail: 'attune_wc',
    });
    this.deleteDraftIfApplicable();
    // We're asserting that quote is non-null because quote wouldn't be a success otherwise
    this.insuredAccountService.cachebust();
    this.router.navigate([
      'accounts',
      this.accountId,
      'attune',
      'workers-comp',
      'quotes',
      this.pricedQuote.quoteNumber,
    ]);
  }
  public loadInitialData(): void {}

  public handleFailedQuote(): void {
    this.openErrorModal();
    this.deleteDraftIfApplicable();
  }

  public populateIneligibleLocation() {
    // TODO
  }

  public populateKnownGoodHappyPathValues() {
    // TODO
  }

  public getErrorList() {
    if (this.eligibilityDeclineReasons.length > 0) {
      return this.eligibilityDeclineReasons;
    }

    if (this.quoteUwIssues.length > 0) {
      return this.quoteUwIssues;
    }

    return [];
  }

  public getErrorType() {
    if (this.eligibilityDeclineReasons.length > 0 || this.quoteUwIssues.length > 0) {
      return ATTUNE_WC_ELIGIBILITY_ERROR;
    }

    if (hasNcciValidationError(this.quoteErrors)) {
      return ATTUNE_WC_INVALID_FEIN_ERROR;
    }

    if (hasNcciMultipleRiskIdsError(this.quoteErrors)) {
      return ATTUNE_WC_MULTIPLE_RISK_IDS_FOUND_ERROR;
    }

    return UNKNOWN_ERROR;
  }

  public handleErrorModalClose(result: { close: boolean; retry: boolean }) {
    if (!result.close && !result.retry) {
      this.goBackToAccount();
    }

    if (result.close) {
      this.errorModalOpen = false;
      if (hasNcciValidationError(this.quoteErrors)) {
        // If they click on "update FEIN", we should send them back to the appropriate page.
        this.handleNavigateToSlug('basic-info');
      }
      this.resetQuoteErrorList();
      // This ensures that when the quote is re-submitted, it will have a different request id from the original attempt since this is technically a different quote from the GW perspective.
      this.updateTsRequestId();
    }

    if (result.retry) {
      this.formService.stepForward();
    }
  }

  public setEditDetailsIfApplicable() {
    const url = this.route.snapshot.url;
    this.isEditQuote = url.map((segment) => segment.path).includes('edit');

    if (this.isEditQuote) {
      this.editQuoteNumber = this.route.snapshot.params['quoteNumber'];
    }
  }

  public setDraftDetailsIfApplicable() {
    const url = this.route.snapshot.url;
    this.isDraftQuote = url.map((segment) => segment.path).includes('drafts');

    if (this.isDraftQuote) {
      this.draftQuoteId = this.route.snapshot.params['draftId'];
    }
  }

  public setupDraftSubscriptions() {
    // If this is a new quote, we want to create a draft to save progress.
    // If the account has a draft already but they have navigated to the new quote form flow, fetch the existing draft.
    if (!this.isDraftQuote && !this.isEditQuote) {
      this.getExistingOrCreateDraft();
    }
    this.updateDraftOnInterval();
  }

  public fromAccountCreate() {
    return !!(this.route.snapshot && this.route.snapshot.queryParams['from-account-create']);
  }

  private sendSegmentEvent(eventName: AttuneEventName) {
    let insuredAddress: object | undefined;
    let classCode: object | undefined;
    if (eventName === 'Quote Started') {
      this.quoteStartedEventWasSent = true;
      // Note: class_code, which is vendor specific, is not available on "Quote Started"
      insuredAddress = {
        addressLine1: this.insAccount.addressLine1,
        addressLine2: this.insAccount.addressLine2,
        city: this.insAccount.city,
        state: this.insAccount.state,
        zip: this.insAccount.zip,
      };
    } else if (eventName === 'Quote Attempted') {
      const firstLocationFormGroup = this.formService.getLocation('1');
      const firstLocationClassCode = firstLocationFormGroup?.value?.classCodes?.[0];
      classCode = {
        business_type: firstLocationClassCode?.description,
        classification: firstLocationClassCode?.classCode,
      };
      insuredAddress = firstLocationFormGroup?.value.address;
    }
    this.segmentService.track({
      event: eventName,
      properties: {
        product: 'wc',
        carrier: 'attune',
        class_code: classCode,
        naics_code: this.insAccount.naicsCode,
        primary_state: this.insAccount?.state,
        insured_address: insuredAddress,
        insured_email: this.insAccount.emailAddress,
        business_name: this.insAccount.companyName,
      },
    });
  }

  private mapEligibilityReasons(reasons: AttuneWcEligibilityDeclineReason[]) {
    return reasons.map((reason) => {
      return reason.reason;
    });
  }

  private setInitialAccountDetails(insuredAccount: InsuredAccount) {
    this.formService.setInitialAccountDetails(insuredAccount);
  }

  public initializeDetails() {
    this.isLoadingData = true;
    this.sub.add(
      forkJoin([
        this.initializeAccountDetails(),
        // This can be a draft quote or a completed quote to edit
        this.getParentQuoteIfApplicable(),
      ]).subscribe({
        next: ([insuredAccount, parentQuoteIfApplicable]) => {
          if (parentQuoteIfApplicable) {
            const isDraft = isDraftQuoteResponse(parentQuoteIfApplicable);
            this.formService.patchFormForEditOrDraft(
              parentQuoteIfApplicable,
              insuredAccount,
              isDraft ? 'draft' : 'edit'
            );
            if (isDraft) {
              this.lastSavedDraftFormValue = parentQuoteIfApplicable;
              // We want continued drafts to use the same tsRequestId as when they were created.
              this.updateTsRequestId(parentQuoteIfApplicable.draftTsRequestId);
            }
          }
          this.isLoadingData = false;
          this.setupDraftSubscriptions();
        },
        error: (error) => {
          this.isLoadingData = false;
          this.displayErrorToast(error.amplitudeEventName);
        },
      })
    );
  }
  private initializeAccountDetails() {
    // insuredAccountService.get will never emit an error
    // instead the error is published to the insuredError subject.
    // We need to first subscribe to insuredAccountService.insuredError before calling insuredAccountService.get
    // A proper refactor of the insuredAccountService.get method to propagate errors intuitively will require a more substantial refactor.
    // Once that refactor is completed we can remove this extra code to handle the error and return getAndSetAccountDetails$

    const insuredAccountError$ = this.insuredAccountService.insuredError.asObservable();
    const getAndSetAccountDetails$ = this.insuredAccountService.get(this.accountId).pipe(
      tap((insuredAccount: InsuredAccount) => {
        this.insAccount = insuredAccount;
        if (!this.quoteStartedEventWasSent) {
          this.sendSegmentEvent('Quote Started');
        }
        this.setInitialAccountDetails(insuredAccount);
      })
    );
    // We add a startsWith to ensure at least one emission.
    return insuredAccountError$.pipe(
      startWith(null),
      switchMap((errorOrNull) => {
        if (errorOrNull !== null) {
          return throwError(() => {
            return {
              error: errorOrNull,
              amplitudeEventName: 'account_fetch_error',
            };
          });
        }
        return getAndSetAccountDetails$;
      }),
      first()
    );
  }

  private displayErrorToast(amplitudeEventName: string) {
    this.amplitudeService.track({
      detail: 'attune_wc',
      eventName: amplitudeEventName || 'account_fetch_error',
    });
    this.informService.minorErrorToast(
      'We encountered an error while loading information about this account. Please try refreshing the page.',
      null,
      'Failed to retrieve account information.',
      'Retry',
      () => {
        this.initializeDetails();
      },
      0
    );
  }

  private isGWQuoteResponse(
    payload: GuidewireWCQuoteResponse | AttuneWcEligibilityCheckResponse
  ): payload is GuidewireWCQuoteResponse {
    return typeof payload === 'object' && 'validQuote' in payload;
  }

  private openErrorModal() {
    this.errorModalOpen = true;

    if (this.eligibilityDeclineReasons.length > 0) {
      this.amplitudeService.track({
        eventName: 'eligibility_decline',
        detail: 'attune_wc',
      });
      this.saveEligibilityDeclineDraftQuote(this.eligibilityDeclineReasons);
    } else if (this.quoteUwIssues.length > 0) {
      this.amplitudeService.track({
        eventName: 'quote_uw_issue',
        detail: 'attune_wc',
      });
    } else {
      this.amplitudeService.track({
        eventName: 'quote_error',
        detail: 'attune_wc',
      });
    }
  }

  private getParentQuoteIfApplicable(): Observable<GetQuoteResponse | GetDraftResponse | null> {
    if (this.isEditQuote && this.editQuoteNumber) {
      return this.getQuoteForEdit();
    }
    if (this.isDraftQuote && this.draftQuoteId) {
      return this.getQuoteForDraft(this.draftQuoteId);
    }
    return observableOf(null);
  }

  private getQuoteForEdit() {
    return this.quoteService.getQuote(this.editQuoteNumber).pipe(
      catchError((error) => {
        return throwError(() => {
          return { error: error, amplitudeEventName: 'edit_quote_fetch_error' };
        });
      })
    );
  }

  private getQuoteForDraft(draftId: string): Observable<GetDraftResponse | null> {
    return this.draftQuoteService.getQuote(this.accountId, draftId).pipe(
      map((response) => {
        return (response?.formData as GetDraftResponse) || null;
      }),
      catchError((error) => {
        return throwError(() => {
          return { error: error, amplitudeEventName: 'draft_quote_fetch_error' };
        });
      })
    );
  }

  private goBackToAccount() {
    this.insuredAccountService.cachebust();
    this.router.navigate(['/accounts', this.accountId]);
  }

  private resetQuoteErrorList() {
    this.quoteErrors = [];
  }

  private updateTsRequestId(requestId?: string) {
    if (requestId) {
      this.tsRequestId = requestId;
    } else {
      this.generateRequestId();
    }
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }

  private saveEligibilityDeclineDraftQuote(declineReasons: string[]) {
    const formValueForDraft = this.getFormValueForDraft();
    this.sub.add(
      this.draftQuoteService
        .create({
          accountId: this.accountId,
          formValue: formValueForDraft,
          product: 'wc',
          pasSource: 'attune_gw',
          origin: DraftOrigin.EligibilityDecline,
          declineReasons: declineReasons,
          portalViewable: false,
        })
        .subscribe()
    );
  }

  private getExistingOrCreateDraft() {
    const formValueForDraft = this.getFormValueForDraft();
    this.sub.add(
      this.draftQuoteService
        .get(this.accountId)
        .pipe(
          map((draftQuotes) =>
            // Check for existing Attune WC draft.
            draftQuotes.find((draft) => draft.pasSource === 'attune_gw' && draft.product === 'wc')
          ),
          switchMap((draftOrUndefined) => {
            // Return existing draft if it exists, or create a draft.
            return draftOrUndefined
              ? observableOf(draftOrUndefined)
              : this.draftQuoteService.create({
                  accountId: this.accountId,
                  origin: DraftOrigin.QuoteFlow,
                  pasSource: 'attune_gw',
                  product: 'wc',
                  portalViewable: true,
                  formValue: formValueForDraft,
                });
          })
        )
        .subscribe((resp: DraftQuote | { id: string }) => {
          if (resp?.id) {
            this.draftQuoteId = resp.id;
            this.lastSavedDraftFormValue =
              ((resp as DraftQuote)?.formData as GetDraftResponse) || formValueForDraft;
          }
        })
    );
  }

  private deleteDraftIfApplicable() {
    if (this.draftQuoteId) {
      this.sub.add(
        this.draftQuoteService.delete(this.accountId, this.draftQuoteId).subscribe(() => {
          this.draftQuoteId = undefined;
        })
      );
    }
  }

  private getFormValueForDraft(): GetDraftResponse {
    return {
      ...(this.formService.form.value as QuoteFormValue),
      draftTsRequestId: this.tsRequestId,
    };
  }

  private updateDraftOnInterval() {
    let updatedDraftFormData: GetDraftResponse;
    this.sub.add(
      interval(FIVE_SECONDS_IN_MS)
        .pipe(
          filter(() => {
            updatedDraftFormData = this.getFormValueForDraft();
            return (
              !!this.draftQuoteId && !isEqual(this.lastSavedDraftFormValue, updatedDraftFormData)
            );
          }),
          switchMap(() => {
            this.lastSavedDraftFormValue = updatedDraftFormData;
            return this.draftQuoteService.update(
              this.accountId,
              this.draftQuoteId as string,
              updatedDraftFormData
            );
          })
        )
        .subscribe()
    );
  }

  private updateAccountDescIfNecessary(): void {
    const description = this.formService.form.get('basicInfo.accountDescOps')?.value;

    if (description && this.insAccount && description !== this.insAccount.description) {
      const updatedAccount = new InsuredAccount({
        ...this.insAccount,
        description,
      });
      this.sub.add(
        this.insuredAccountService
          .edit(updatedAccount)
          .pipe(
            catchError((error) => {
              // Log error but don't block progression
              this.sentryService.notify('Failed to update account description', {
                severity: 'error',
                metaData: {
                  accountId: updatedAccount.id,
                  description,
                  error,
                },
              });
              return observableOf(null);
            })
          )
          .subscribe((response) => {
            // Update local account reference
            if (response) {
              this.insAccount = updatedAccount;
              this.insuredAccountService.cachebust();
            }
          })
      );
    }
  }
}
