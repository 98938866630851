import * as moment from 'moment';
import { concat, flatten } from 'lodash';
import { US_DATE_MASK } from 'app/constants';
import { InsuredAccount } from '../../insured-account/models/insured-account.model';

export const effectiveDateToPeriodDate = (effectiveDateString?: string): Date => {
  // PeriodStart / PeriodEnd should be UTC >= 5AM because guidewire converts anything less than 5AM to previous day 5:01 UTC
  return moment.utc(effectiveDateString, US_DATE_MASK).hour(5).startOf('hour').toDate();
};

export const getLatestBopPolicyExpirationDate = (
  insuredAccount: InsuredAccount
): moment.Moment | null => {
  const { bopV3Policies, bopV2Policies, bopV1Policies } = insuredAccount;
  const allPolicies = concat(bopV3Policies, bopV2Policies, bopV1Policies);
  const allTerms = flatten(allPolicies.map(({ terms }) => terms));
  const latestTerm = allTerms
    .filter(({ status }) => {
      return ['in force', 'scheduled', 'expired'].includes(status.toLowerCase());
    })
    .sort((term1, term2) => {
      return term1.policyExpirationDate < term2.policyExpirationDate ? 1 : -1;
    })[0];

  return latestTerm?.policyExpirationDate || null;
};
