<div class="js-cyber-liability-coverage">
  <app-form-field-radio
    inputId="quote-cyberLiabilityCoverage"
    [form]="form"
    [submitted]="submitted"
    questionText="Cyber Coverage"
    [options]="getCyberQuestionOptions()"
    nameOfFormControl="selectedCyberCoverage"
  >
  </app-form-field-radio>

  <div class="form-subsection">
    <!-- Coverage endorsement controls -->
    <ng-container *ngIf="productType() === 'endorsement'">
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-aggregateLimit"
        questionText="Aggregate Limit"
        [form]="form"
        [submitted]="submitted"
        [options]="availableAggregateLimits()"
        nameOfFormControl="aggregateLimit"
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-firstPartyLimit"
        questionText="First Party Limit"
        [form]="form"
        [submitted]="submitted"
        [options]="availableFirstPartyLimits()"
        [tooltipText]="splitLimitTooltipText()"
        nameOfFormControl="firstPartyLimit"
      >
      </app-form-field-radio>
      <p class="small-text gray-text">The deductible for cyber claims is {{ cyberDeductible() }}</p>
    </ng-container>
    <!-- Standalone cyber controls -->
    <ng-container *ngIf="productType() === 'coalition-cyber'">
      <app-form-field-dropdown-search
        inputId="naicsCode"
        labelText="Description of Business"
        typeaheadPlaceholderText="Search descriptions"
        [submitted]="submitted"
        [queryMethod]="coalitionCyberIndustryQueryMethod"
        [formatter]="coalitionCyberIndustryFormatter"
        [showToolTip]="false"
        [queryableResults]="coalitionCyberMappedIndustries"
        [targetFormControl]="form.get(companyIndustryControl)"
      >
      </app-form-field-dropdown-search>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-hasTechEOControl"
        [form]="form"
        [submitted]="submitted"
        questionText="Will the company have an active technology errors and omissions policy concurrent with this insurance policy?"
        [options]="yesNoOptions"
        [nameOfFormControl]="hasTechEOControl"
      >
      </app-form-field-radio>
      <app-form-field-text
        inputId="quote-cyberLiabilityCoverage-annualRevenue"
        labelText="Annual Revenue"
        [form]="form"
        [submitted]="submitted"
        [nameOfFormControl]="annualRevenueControl"
        appMoneyMask="WITHOUT_DECIMAL"
        placeholder="$"
      >
      </app-form-field-text>
      <app-form-field-text
        inputId="quote-cyberLiabilityCoverage-netRevenue"
        labelText="Net Revenue"
        [form]="form"
        [submitted]="submitted"
        [nameOfFormControl]="netRevenueControl"
        appMoneyMask="WITHOUT_DECIMAL"
        placeholder="$"
      >
      </app-form-field-text>
      <div *ngIf="getRevenueError()" class="validation">
        <div class="validation-message">Net revenue cannot be greater than annual revenue.</div>
      </div>
      <app-form-field-radio-traditional
        inputId="quote-cyberLiabilityCoverage-standaloneAggregateLimit"
        [form]="form"
        [nameOfFormControl]="aggregateLimitControl"
        labelText="Limit and retention (deductible)"
        subLabelText="These values and coverages can be customized further after this quote is submitted."
        [options]="standaloneCyberLimits"
        [showLabelWithoutValue]="false"
        [optionPillTags]="standaloneCyberLimitPillTags"
        [showErrorText]="true"
        [submitted]="submitted"
      ></app-form-field-radio-traditional>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-awareOfPriorClaims"
        [form]="form"
        [submitted]="submitted"
        questionText="During the past three years, did the company experience a cyber incident, claim or loss, whether insured or not, which could have been covered under a policy similar to the proposed insurance? This includes but is not limited to any:"
        additionalQuestionText="
          <ul class='bulleted-list'>
            <li>Actual or reasonably suspected data breach or security failure, including notifying consumers or third parties of data breach or security failure</li>
            <li>Claims or complaints with respect to privacy injury, breach of information or network security, unauthorized disclosure of information, defamation, or content infringement</li>
            <li>Government action, investigation, or subpoena regarding any alleged violation of a privacy law or regulation</li>
            <li>Actual or attempted extortion demand with respect to its data or computer systems</li>
          </ul>"
        [options]="yesNoOptions"
        [nameOfFormControl]="awareOfPriorClaimsControl"
      >
      </app-form-field-radio>
      <div class="form-subsection">
        <app-form-field-text
          inputId="quote-cyberLiabilityCoverage-awareOfPriorClaimsCount"
          labelText="How many claims in total?"
          [form]="form"
          [submitted]="submitted"
          [nameOfFormControl]="awareOfPriorClaimsCountControl"
          errorText="Please enter a value greater than 0."
        >
        </app-form-field-text>
        <app-form-field-radio
          inputId="quote-cyberLiabilityCoverage-awareOfPriorClaimsAmount"
          [form]="form"
          [submitted]="submitted"
          questionText="What is the total claims payout amount?"
          [options]="priorClaimsAmountOptions"
          [nameOfFormControl]="awareOfPriorClaimsAmountControl"
        >
        </app-form-field-radio>
        <div class="form-subsection">
          <app-form-field-text
            inputId="quote-cyberLiabilityCoverage-awareOfPriorClaimsAmountOver500K"
            labelText="Please enter the value below"
            [form]="form"
            [submitted]="submitted"
            [nameOfFormControl]="awareOfPriorClaimsAmountOver500KControl"
            appMoneyMask="WITHOUT_DECIMAL"
            placeholder="$"
            [showErrorText]="true"
            errorText="Please enter a value greater than $500,000."
          >
          </app-form-field-text>
        </div>
        <app-form-field-radio
          inputId="quote-cyberLiabilityCoverage-priorClaims"
          [form]="form"
          [submitted]="submitted"
          questionText="Within the last 3 years, has the company suffered any cyber incidents resulting in any single claim in excess of $25,000?"
          [options]="yesNoOptions"
          [nameOfFormControl]="priorClaimsControl"
        >
        </app-form-field-radio>
        <app-form-field-textarea
          inputId="quote-cyberLiabilityCoverage-priorClaimsExplanation"
          [submitted]="submitted"
          [form]="form"
          labelText="Please provide details of all the cyber incidents and claims."
          [nameOfFormControl]="priorClaimsExplanationControl"
        >
        </app-form-field-textarea>
      </div>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-dualControl5k"
        [form]="form"
        [submitted]="submitted"
        questionText="Does the company require a secondary means of communication to validate the authenticity of funds transfers (ACH, wire, etc.) requests before processing a request in excess of $5,000?"
        [options]="dualControlOptions"
        [nameOfFormControl]="dualControl5kControl"
        tooltipText="An example of this best practice would be the business calling the requester back at a previously established phone number (ie, not using contact information taken directly from the email) to confirm that the request is legitimate before processing."
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-administrativeDualCtrl"
        [form]="form"
        [submitted]="submitted"
        questionText="Does the company require a secondary means of communication to validate the authenticity of any request to change banking details (ACH, wire, payroll distribution, etc.)?"
        [options]="dualControlOptions"
        [nameOfFormControl]="administrativeDualCtrlControl"
        tooltipText="An example of this best practice would be the business calling the requester back at a previously established phone number (ie, not using contact information taken directly from the email) to confirm that the request is legitimate before processing."
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-mfaEmail"
        [form]="form"
        [submitted]="submitted"
        questionText="Does the company enforce Multi-Factor Authentication (MFA) for email?"
        [options]="mfaRemoteAccessOptions"
        [nameOfFormControl]="mfaEmailControl"
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-mfaRemoteAccess"
        [form]="form"
        [submitted]="submitted"
        questionText="Does the company enforce Multi-Factor Authentication (MFA) for Remote Desktop Protocol (RDP), RDWeb, RD Gateway, or other remote access?"
        [options]="mfaRemoteAccessOptions"
        [nameOfFormControl]="mfaRemoteAccessControl"
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-mfaVpn"
        [form]="form"
        [submitted]="submitted"
        questionText="Does the company enforce Multi-Factor Authentication (MFA) for Virtual Private Network (VPN) access?"
        [options]="mfaVpnOptions"
        [nameOfFormControl]="mfaVpnControl"
      >
      </app-form-field-radio>
      <app-form-field-radio
        inputId="quote-cyberLiabilityCoverage-mfaOther"
        [form]="form"
        [submitted]="submitted"
        questionText="Does the company enforce Multi-Factor Authentication (MFA) for network / cloud administration or other privileged user accounts?"
        [options]="mfaOtherAccountsOptions"
        [nameOfFormControl]="mfaOtherControl"
      >
      </app-form-field-radio>
      <div>
        <p>Is the company engaged in any of the following businesses?</p>
        <small class="form-field-sub-label">Check all that apply.</small>
        <app-form-field-checkbox
          [form]="form.get(engagedIndustriesControl)"
          labelText="Adult Content"
          inputId="quote-cyberLiabilityCoverage-engagedIndustryAdult"
          [nameOfFormControl]="engagedIndustryAdult"
          [submitted]="submitted"
          cssClass="form-field__no-margin mt-1"
        ></app-form-field-checkbox>
        <app-form-field-checkbox
          [form]="form.get(engagedIndustriesControl)"
          labelText="Cryptocurrency or Blockchain"
          inputId="quote-cyberLiabilityCoverage-engagedIndustryCrypto"
          [nameOfFormControl]="engagedIndustryCrypto"
          [submitted]="submitted"
          cssClass="form-field__no-margin mt-1"
        ></app-form-field-checkbox>
        <app-form-field-checkbox
          [form]="form.get(engagedIndustriesControl)"
          labelText="Gambling"
          inputId="quote-cyberLiabilityCoverage-engagedIndustryGambling"
          [nameOfFormControl]="engagedIndustryGambling"
          [submitted]="submitted"
          cssClass="form-field__no-margin mt-1"
        ></app-form-field-checkbox>
        <app-form-field-checkbox
          [form]="form.get(engagedIndustriesControl)"
          labelText="Payment Processing"
          inputId="quote-cyberLiabilityCoverage-engagedIndustryMsp"
          [nameOfFormControl]="engagedIndustryMsp"
          [submitted]="submitted"
          cssClass="form-field__no-margin mt-1"
        ></app-form-field-checkbox>
        <app-form-field-checkbox
          [form]="form.get(engagedIndustriesControl)"
          labelText="Managed Service Provider (MSP), Managed Security Service Provider (MSSP), or remote network administration services provider"
          inputId="quote-cyberLiabilityCoverage-engagedIndustryPaymentProcessing"
          [nameOfFormControl]="engagedIndustryPaymentProcessing"
          [submitted]="submitted"
          cssClass="form-field__no-margin mt-1"
        ></app-form-field-checkbox>
      </div>
    </ng-container>
  </div>
</div>
