import { StateCode } from '../../../shared/States';

/**
 * Some states have unique coverage/credit questions that are only asked for that state. Some will be shared among multiple states.
 */
import { FormControl, FormGroup } from '@angular/forms';

export type CoveragesCreditsFormGroup = FormGroup<{
  consecutiveYearsOfCoverage: FormControl<string | null>;
  threeYearLossRatio: FormControl<string | null>;
  lostTimeClaims: FormControl<string | null>;
  percentageMedicalClaims: FormControl<string | null>;
  payrollPercentageUninsuredContractors: FormControl<string | null>;
  numberOfFulltimeW2Employees: FormControl<string | null>;
  safeWorkplace: FormGroup<{
    lowExperienceMod5Years: FormControl<boolean | null>;
    returnToWorkProgram: FormControl<boolean | null>;
    designatedHealthProviders: FormControl<boolean | null>;
    safetyProgram: FormControl<boolean | null>;
    promptClaimsReporting: FormControl<boolean | null>;
    drugScreening: FormControl<boolean | null>;
  }>;
  nonNCCIExperienceModifiers: nonNCCIExperienceModifiers;
}>;

export interface CoveragesCreditsFormValue {
  consecutiveYearsOfCoverage?: string;
  threeYearLossRatio?: string;
  lostTimeClaims?: string;
  percentageMedicalClaims?: string;
  payrollPercentageUninsuredContractors?: string;
  numberOfFulltimeW2Employees?: string;
  safeWorkplace?: {
    lowExperienceMod5Years: boolean;
    returnToWorkProgram: boolean;
    designatedHealthProviders: boolean;
    safetyProgram: boolean;
    promptClaimsReporting: boolean;
    drugScreening: boolean;
  };
  nonNCCIExperienceModifiers: {
    MI?: ExperienceModFormValue;
    NC?: ExperienceModFormValue;
    PA?: ExperienceModFormValue;
  };
}

export type CoverageCreditsKeys = keyof CoveragesCreditsFormGroup['controls'];

export type StateCoverageMapper = {
  [K in CoverageCreditsKeys]: StateCode[];
};
// Keep list of states in alphabetical order.
export const COVERAGE_QUESTIONS_STATE_MAPPER: StateCoverageMapper = {
  consecutiveYearsOfCoverage: ['MI'],
  threeYearLossRatio: ['MI'],
  lostTimeClaims: ['MI'],
  percentageMedicalClaims: ['MI'],
  payrollPercentageUninsuredContractors: ['MI'],
  numberOfFulltimeW2Employees: ['MI'],
  safeWorkplace: ['MI'],
  nonNCCIExperienceModifiers: ['MI', 'NC', 'PA'],
};

export type nonNCCIExperienceModifiers = FormGroup<{
  MI: ExperienceModFormGroup;
  NC: ExperienceModFormGroup;
  PA: ExperienceModFormGroup;
}>;

export type ExperienceModFormGroup = FormGroup<{
  riskId: FormControl<string | null>;
  experienceMod: FormControl<string | null>;
}>;

export interface ExperienceModFormValue {
  riskId: string | null;
  experienceMod: string | null;
}
