<section class="form-animation">
  <h1 class="form-header-below-link">Coverages & Credits</h1>
  <app-form-field-text
    inputId="consecutive-years-coverage"
    [form]="form"
    [submitted]="submitted()"
    labelText="How many consecutive years of workers' compensation coverage has the insured had?"
    nameOfFormControl="consecutiveYearsOfCoverage"
    appNumberMask
  ></app-form-field-text>
  <app-form-field-text
    inputId="lost-time-claims"
    [form]="form"
    [submitted]="submitted()"
    labelText="How many lost-time (indemnity) claims occurred in the last experience rating period?"
    nameOfFormControl="lostTimeClaims"
    appNumberMask
  ></app-form-field-text>

  <app-form-field-select
    inputId="three-year-loss-history"
    [form]="form"
    [submitted]="submitted()"
    labelText="What is the insured's 3-year loss ratio?"
    nameOfFormControl="threeYearLossRatio"
    [availableOptions]="threeYearLossRatioOptions"
  >
  </app-form-field-select>

  <app-form-field-select
    inputId="percentage-medical-claims"
    [form]="form"
    [submitted]="submitted()"
    labelText="During the previous experience rating period, what percentage represents the total losses from medical-only claims in relation to the policy premium?"
    nameOfFormControl="percentageMedicalClaims"
    [availableOptions]="percentageMedicalClaimsOptions"
  >
  </app-form-field-select>
  <app-form-field-select
    inputId="payroll-percentage-uninsured-contractors"
    [form]="form"
    [submitted]="submitted()"
    labelText="What percentage of the payroll consists of uninsured subcontractors or 1099 contractors?"
    nameOfFormControl="payrollPercentageUninsuredContractors"
    [availableOptions]="percentUninsuredContractorOptions"
  >
  </app-form-field-select>
  <app-form-field-text
    inputId="number-ft-W2-employees"
    [form]="form"
    [submitted]="submitted()"
    labelText="How many full-time W2 employees, excluding owners, does the business employ?"
    nameOfFormControl="numberOfFulltimeW2Employees"
    appNumberMask
  ></app-form-field-text>

  <section *ngIf="form.get('safeWorkplace') as safeWorkplace">
    <app-form-field-radio
      inputId="lowExperienceMod5Years"
      [form]="safeWorkplace"
      [submitted]="submitted()"
      questionText="The insured has been in the same general business for at least five years prior to application of the credit and have maintained an experience modification averaging less than 1.10 over the same five year period."
      nameOfFormControl="lowExperienceMod5Years"
    >
    </app-form-field-radio>
    <app-form-field-radio
      inputId="returnToWorkProgram"
      [form]="safeWorkplace"
      [submitted]="submitted()"
      questionText="The insured has a return to work program to encourage early return to work for injured employees."
      nameOfFormControl="returnToWorkProgram"
    >
    </app-form-field-radio>
    <app-form-field-radio
      inputId="designatedHealthProviders"
      [form]="safeWorkplace"
      [submitted]="submitted()"
      questionText="The insured has agreed to use designated health care providers during the first ten days of treatment, when appropriate and in the best interest of the injured employee’s health."
      nameOfFormControl="designatedHealthProviders"
    >
    </app-form-field-radio>
    <app-form-field-radio
      inputId="safetyProgram"
      [form]="safeWorkplace"
      [submitted]="submitted()"
      questionText="The insured has implemented a safety program that includes at least elements of a written safety policy, active safety committee, injury review board and supervisory accountability or losses."
      nameOfFormControl="safetyProgram"
    >
    </app-form-field-radio>
    <app-form-field-radio
      inputId="promptClaimsReporting"
      [form]="safeWorkplace"
      [submitted]="submitted()"
      questionText="The insured has established a program to ensure prompt first reporting of claims that will result in at least 50% of all claims to be reported within seven working days of the injury date."
      nameOfFormControl="promptClaimsReporting"
    >
    </app-form-field-radio>
    <app-form-field-radio
      inputId="drugScreening"
      [form]="safeWorkplace"
      [submitted]="submitted()"
      questionText="The insured has implemented a program requiring prospective employees to submit a drug screening prior to commencing employment."
      nameOfFormControl="drugScreening"
    >
    </app-form-field-radio>
  </section>

  <section *ngIf="experienceModsFormGroup">
    <ng-container *ngFor="let stateCode of experienceModsFormGroup.controls | keys">
      <app-attune-wc-experience-mod
        [form]="experienceModsFormGroup.get(stateCode)"
        [submitted]="submitted()"
        [state]="stateCode"
      ></app-attune-wc-experience-mod>
    </ng-container>
  </section>
</section>
