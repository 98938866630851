import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import {
  of as observableOf,
  merge as observableMerge,
  combineLatest,
  Observable,
  Subject,
} from 'rxjs';
import * as moment from 'moment';
import { get as lodashGet, flatMap, omit, times, uniq, values } from 'lodash';
import {
  enableDisableControl,
  getControl,
  minDateExceededValidator,
  rangeValidator,
  getAddress,
  patchControl,
  maxDateExceededValidator,
  validatorWithMessage,
  getFormArray,
  removeAllFromFormArray,
  renewalEffectiveDateValidator,
} from 'app/shared/helpers/form-helpers';
import { US_DATE_MASK } from 'app/constants';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import {
  INELIGIBLE_STATES,
  BUILDING_LIMIT_NOT_AVAILABLE_BUSINESS_TYPES,
  QuestionCode,
  UW_QUESTIONS,
  UWQuestion,
  REQUIRED_UW_QUESTIONS_BY_BUILDING_CODE,
  STOP_GAP_PER_OCCURRENCE_THRESHOLD,
  INCLUDED_STOP_GAP_STATES,
  EXCESS_LIABILITY_PER_OCCURRENCE_THRESHOLD,
  EXCLUDED_V2_EXCESS_STATES,
  EXCLUDED_V2_EXCESS_CODES,
  isBopVersion2or3,
  CLASS_CODES_WITH_SMALL_DEVICE_UW_QUESTION,
} from 'app/features/attune-bop/models/constants';
import {
  uniqueAddressValidator,
  validateAgentLicenseNumber,
} from 'app/features/attune-bop/models/form-validators';
import { BopQuoteFormValue, BopQuotePayload } from '../models/bop-policy';
import { ExcessLiabilityMixin } from 'app/features/attune-bop/mixins/excess-liability-form.mixin';
import { LocationFormMixin } from 'app/features/attune-bop/mixins/location-form.mixin';
import { SampleAccountType } from 'app/shared/sample-accounts/sample-accounts';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import { shouldGetBopV2, shouldGetMeToo } from 'app/shared/helpers/account-helpers';
import {
  CoalitionCyberQuestion,
  CyberQuestionEnablementFlags,
  CoalitionCyberFormDataFormatted,
} from '../../coalition/models/cyber-typings.model';
import { FeatureFlagService } from '../../../core/services/feature-flag.service';
import { ProductAvailability } from '../../digital-carrier/models/types';
import { AccountSummaryView } from '../../insured-account/services/insured-account-summary.service';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/services/user.service';
import { SegmentService } from 'app/core/services/segment.service';
import { AttuneBopQuoteLiabilityCoveragesFormService } from './quote-services/liability-coverages-form.service';
import { AttuneBopQuoteAdditionalCoveragesFormService } from './quote-services/additional-coverages-form.service';

const emptyAddress = {
  addressLine1: '',
  addressLine2: null,
  city: '',
  state: '',
  zip: '',
};

// List of state capitals for happy-path lookup. Quoting will fail if the city/state/zip do not match up
const happyPathCityLookup: Record<string, [string, string]> = {
  AL: ['Montgomery', '36101'],
  AK: ['Juneau', '99801'],
  AZ: ['Phoenix', '85001'],
  AR: ['Little Rock', '72201'],
  CA: ['Sacramento', '94203'],
  CO: ['Denver', '80201'],
  CT: ['Hartford', '06101'],
  DC: ['Washington', '20011'],
  DE: ['Dover', '19901'],
  FL: ['Tallahassee', '32301'],
  GA: ['Atlanta', '30301'],
  HI: ['Honolulu', '96801'],
  ID: ['Boise', '83701'],
  IL: ['Springfield', '62701'],
  IN: ['Indianapolis', '46201'],
  IA: ['Des Moines', '50301'],
  KS: ['Topeka', '66601'],
  KY: ['Frankfort', '40601'],
  LA: ['Baton Rouge', '70801'],
  ME: ['Augusta', '04330'],
  MD: ['Annapolis', '21401'],
  MA: ['Boston', '02108'],
  MI: ['Lansing', '48901'],
  MN: ['St. Paul', '55101'],
  MS: ['Jackson', '39201'],
  MO: ['Jefferson City', '65101'],
  MT: ['Helena', '59601'],
  NE: ['Lincoln', '68501'],
  NV: ['Carson City', '89701'],
  NH: ['Concord', '03301'],
  NJ: ['Trenton', '08601'],
  NM: ['Santa Fe', '87501'],
  NY: ['Albany', '12201'],
  NC: ['Raleigh', '27601'],
  ND: ['Bismarck', '58501'],
  OH: ['Columbus', '43201'],
  OK: ['Oklahoma City', '73101'],
  OR: ['Salem', '97301'],
  PA: ['Harrisburg', '17101'],
  RI: ['Providence', '02901'],
  SC: ['Columbia', '29201'],
  SD: ['Pierre', '57501'],
  TN: ['Nashville', '37201'],
  TX: ['Austin', '73301'],
  UT: ['Salt Lake City', '84101'],
  VT: ['Montpelier', '05601'],
  VA: ['Richmond', '23218'],
  WA: ['Olympia', '98501'],
  WV: ['Charleston', '25301'],
  WI: ['Madison', '53701'],
  WY: ['Cheyenne', '82001'],
};

const baseStateLocationValidator = (locationsAddresses: UntypedFormArray) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const states = locationsAddresses.value.map((loc: Address) => {
      return loc && loc.state;
    });
    if (INELIGIBLE_STATES.includes(control.value)) {
      return {
        baseStateLocation: {
          value: control.value,
          validationMessage: `We currently do not offer BOP for these base states: ${INELIGIBLE_STATES.join(
            ', '
          )}.`,
        },
      };
    }
    if (states.includes(control.value)) {
      return null;
    }
    return {
      baseStateLocation: {
        value: control.value,
        validationMessage: `The base state is not included in the list of account locations: ${states.join(
          ', '
        )}.`,
      },
    };
  };
};

class FormService extends FormDslSteppedFormBaseService {
  protected _showGuidelinesStep = false;
  protected _showNewAccountStep = false;

  // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
  lessorsRiskPageFeatureFlaggedOn = environment.lroFlowEnabled;
  allowedCSXSproducerCodes = environment.allowedCSXSproducerCodes;

  public fillInHappyPath(state = 'TN', excess = false): void {
    const bopVersion = shouldGetBopV2(state) ? 2 : 1;
    const meToo = shouldGetMeToo(state);

    this.form.patchValue({
      account: true,
      guidelines: true,
      policyInfo: {
        numberOfLocations: 1,
        yearsInBusiness: 5,
        organizationType: 'partnership',
        baseState: state,
        bopVersion: bopVersion,
        meToo: meToo,
        locationAddresses: [
          {
            addressLine1: '123 Main Street',
            addressLine2: 'Floor 2',
            city: happyPathCityLookup[state][0],
            state: state,
            zip: happyPathCityLookup[state][1],
          },
        ],
      },
      liabilityCoverages: {
        damageToPremises: '50000',
        limitPerOccurrenceOfLiabilityAndMedicalExpenses: 1000000,
        limitPerPersonOfMedicalExpenses: 5000,
        employeeBenefitsLiabilityCoverage: {
          optedIn: false,
          retroactiveDate: '01/01/1917',
          eachEmployeeLimit: 1000000,
          aggregateLimit: 1000000,
          deductible: 1000,
        },
        employeeHandbook: true,
        janitorialServices: false,
      },
      additionalCoverages: {
        limitForEmployeeDishonesty: 25000,
        acceptCertifiedActsOfTerrorismCoverage: true,
        hasAdditionalInsuredBusinesses: false,
        employmentRelatedPracticesLiabilityCoverage: {
          optedIn: false,
          retroactiveDate: '03/06/2018',
          eachEmploymentWrongfulActLimit: 10000,
          aggregateLimit: 10000,
          deductible: 5000,
        },
        cyberLiabilityCoverage: {
          optedIn: false,
          selectedCyberCoverage: 'none',
          aggregateLimit: 50000,
          deductible: 1000,
        },
      },
      locations: [
        {
          locationDetails: {
            addressLine1: '123 Main Street',
            addressLine2: 'Floor 2',
            annualRevenue: '1000000',
            city: happyPathCityLookup[state][0],
            state: state,
            zip: happyPathCityLookup[state][1],
            employeeCount: '42',
          },
          locationPrefill: {
            bcegsCommercialCode: '04',
            bopTerritoryCode: '005',
            caDOIZone: null,
            capRisk: 5,
            commEarthquakeTerritoryCode: '21',
            earthquakeMMI100Year: '3.0',
            earthquakeSoilType: 'Soft to Firm Rock(stiff soil)',
            enhancedCrimeFlag: false,
            distToEffectiveCoastRangeCode: '18',
            floodZone: null,
            groupIICode: null,
            groupIICountyName: null,
            hail_risk_3: 7,
            hail_srisk_3: 10,
            hailDamageScore: 1,
            hailRiskScore: 4,
            isoCountyName: null,
            isoDistToCoastRangeCode: '16',
            latitude: '37.229225',
            longitude: '-80.413438',
            ppc: '5',
            roofConditionRating: null,
            wildfireRiskScore: 0,
          },
          buildings: [
            {
              exposure: {
                businessType: 'Office',
                classification: {
                  code: {
                    code: '63631',
                    descriptionCode: 'AccountingServicesCPAs',
                  },
                },
                limitForBusinessPersonalProperty: '$101,000',
                percentSalesPersonalDevices: null,
                constructionType: 'FireResistive',
                storyCount: '1',
                squareFootage: '2000',
                hasAutomaticSprinklerSystem: true,
                buildingLimit: '$0',
                totalSales: '$100,000',
                payroll: '$300,000',
                yearBuilt: 2022,
                roofUpdated: false,
                electricPlumbingHVACUpdated: true,
              },
              coverage: {
                businessIncomeAndExtraExpensesIndemnityInMonths: 12,
                equipmentBreakdownCoverageOptedIn: true,
                utilityServicesTimeElement: 0,
                utilityServicesDirectDamage: 0,
                debrisRemoval: 25000,
                ordinanceLawCoverageValue: 5000,
                windCoverageOptedIn: false,
                windDeductible: 2500,
                windDeductiblePercent: '1%',
                windLossMitigation: {
                  SCBCCompliant: false,
                  level: 'A',
                  roofToWallConnection: 'ToenAils',
                  openingProtection: 'None',
                  doorStrength: 'RSWD',
                  secondaryWaterResistance: false,
                  roofShape: 'Flat',
                  roofAndDeckAttachment: 'Wood',
                },
              },
            },
          ],
        },
      ],
    });

    if (excess) {
      this.form.patchValue({
        excessLiabilityOptIn: true,
        excessLiability: {
          excessLiabilityLimit: 3000000,
        },
      });
    } else {
      this.form.patchValue({
        excessLiabilityOptIn: false,
      });
    }
  }

  showInitialSteps(showNewAccount: boolean, showGuidelines: boolean) {
    this._showNewAccountStep = showNewAccount;
    this._showGuidelinesStep = showGuidelines;
    this.syncAllSteps();
    // nav to the guideline step
    if (showGuidelines) {
      const guidelineStep = showNewAccount ? 1 : 0;
      this.stepWithoutValidation(this.steps[guidelineStep]);
    }
  }

  bopVersion(): BopVersion {
    const bopVersionControl: AbstractControl | null = this.get('policyInfo.bopVersion');
    return bopVersionControl ? bopVersionControl.value : null;
  }

  setBOPVersion(version: BopVersion) {
    const bopVersionControl = this.get('policyInfo.bopVersion') as UntypedFormControl;
    if (!bopVersionControl) {
      throw new Error('Unable to update bop version');
    }
    bopVersionControl.patchValue(version);
  }

  meToo(): boolean {
    const meTooControl: AbstractControl | null = this.get('policyInfo.meToo');
    return meTooControl ? meTooControl.value : false;
  }

  setMeToo(meToo: boolean) {
    const meTooControl = this.get('policyInfo.meToo') as UntypedFormControl;
    if (!meTooControl) {
      throw new Error('Unable to update me too status');
    }
    meTooControl.patchValue(meToo);
  }

  setAgentName(agentName: string) {
    const agentNameFormControl = getControl(this.form, 'policyInfo.agentName');
    if (!agentNameFormControl) {
      throw new Error('Unable to update agent name');
    }

    // We do not want to patch the agent name if the user has already entered a name OR if we patched the name via an edit
    if (!agentNameFormControl.value) {
      patchControl(agentNameFormControl, agentName);
    }
  }

  generateSteps(): RouteFormStep[] {
    // BOP Form Requires LocationFormMixin
    const locationAddressesValue = (<any>this).getLocationAddresses().value;

    // Steps are generated once before the form is initialized
    const locationSteps = !this.form
      ? []
      : flatMap(locationAddressesValue, (_address, index: number) => {
          return [
            {
              args: {},
              displayName: `Location ${index + 1}`,
              slug: `location-${index + 1}`,
              parent: `location-${index + 1}`,
              formPath: `locations.${index}.locationDetails`,
            },
            {
              args: {},
              displayName: `Building Details`,
              slug: `building-${index + 1}-1`,
              parent: `location-${index + 1}`,
              substep: true,
              formPath: `locations.${index}.buildings.0.exposure`,
            },
            this.buildingLessorsRiskStep(index),
            {
              args: {},
              displayName: `Building Coverage`,
              slug: `building-${index + 1}-1-coverages`,
              parent: `location-${index + 1}`,
              substep: true,
              formPath: `locations.${index}.buildings.0.coverage`,
            },
          ];
        });

    const isStep = (step: RouteFormStep | null): step is RouteFormStep => {
      return step !== null;
    };

    return [
      this.newAccountStep(),
      this.guidelinesStep(),
      {
        args: {},
        displayName: `Policy Info`,
        slug: 'policy-info',
        parent: 'policy-info',
        formPath: 'policyInfo',
      },
      ...locationSteps,
      {
        args: {},
        displayName: `Liability Coverages`,
        slug: 'liability-coverages',
        parent: 'liability-coverages',
        formPath: 'liabilityCoverages',
      },
      {
        args: {},
        displayName: `Additional Coverages`,
        slug: 'additional-coverages',
        parent: 'additional-coverages',
        formPath: 'additionalCoverages',
      },
      this.excessLiabilityStep(),
    ].filter(isStep);
  }

  private newAccountStep(): RouteFormStep | null {
    if (this._showNewAccountStep) {
      return {
        args: {},
        displayName: 'Account',
        slug: 'account',
        parent: 'account',
        formPath: 'account',
      };
    }
    return null;
  }

  private guidelinesStep(): RouteFormStep | null {
    if (this._showGuidelinesStep) {
      this.get<UntypedFormControl>('guidelines').setValidators(Validators.requiredTrue);
      return {
        args: {},
        displayName: 'Guidelines',
        slug: 'guidelines',
        parent: 'guidelines',
        formPath: 'guidelines',
      };
    }
    return null;
  }

  private excessLiabilityStep(): RouteFormStep | null {
    // Steps are generated once before the form is initialized
    if (!this.form) {
      return null;
    }
    const excessLiabilityOptInControl = this.get<UntypedFormControl>('excessLiabilityOptIn');
    const showExcessLiabilityStep =
      excessLiabilityOptInControl.enabled && excessLiabilityOptInControl.value;

    if (showExcessLiabilityStep) {
      return {
        args: {},
        displayName: `Excess Liability`,
        slug: 'excess-liability',
        formPath: 'excessLiability',
        parent: 'excess-liability',
      };
    }
    return null;
  }

  private buildingLessorsRiskStep(locationIndex: number): RouteFormStep | null {
    // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
    if (!this.lessorsRiskPageFeatureFlaggedOn) {
      return null;
    }

    // Steps are generated once before the form is initialized
    if (!this.form) {
      return null;
    }

    const lessorsRiskFormPath = `locations.${locationIndex}.buildings.0.exposure.lessorsRisk`;
    const lessorsRiskFormControl = this.get<UntypedFormControl>(lessorsRiskFormPath);

    if (!lessorsRiskFormControl) {
      return null;
    }

    const showLessorsRiskStep = lessorsRiskFormControl.enabled && lessorsRiskFormControl.value;
    if (showLessorsRiskStep) {
      return {
        args: {},
        displayName: "Lessors' Risk Details",
        slug: `building-${locationIndex + 1}-1-lessors-risk`,
        parent: `location-${locationIndex + 1}`,
        substep: true,
        formPath: `locations.${locationIndex}.buildings.0.lessorsRisk`,
      };
    }
    return null;
  }
}

const FormServiceWithMixins = LocationFormMixin(
  ExcessLiabilityMixin(FormService),
  'policyInfo.locationAddresses',
  'locations'
);

@Injectable()
export class AttuneBopQuoteFormService extends FormServiceWithMixins implements OnDestroy {
  form: UntypedFormGroup;
  submitted = false;
  uwQuestions: UWQuestion[];
  showHnoaUwQuestions = false;

  // Properties for Cyber upsell
  public accountSummarySubject: Subject<AccountSummaryView> = new Subject<AccountSummaryView>();
  industryId$: Observable<number | null>;
  // END Properties for Cyber upsell

  constructor(
    private formBuilder: UntypedFormBuilder,
    private featureFlagService: FeatureFlagService,
    private userService: UserService,
    private segmentService: SegmentService,
    protected liabilityCoveragesFormService: AttuneBopQuoteLiabilityCoveragesFormService,
    protected additionalCoveragesFormService: AttuneBopQuoteAdditionalCoveragesFormService
  ) {
    super();
    this.initializeForm();
    this.syncAllSteps();
    this.subscribeToSubFormServiceUpdates();
  }

  subscribeToSubFormServiceUpdates() {
    this.liabilityCoveragesFormService.showHnoaUwQuestions$.subscribe((showHnoaUwQuestions) => {
      this.showHnoaUwQuestions = showHnoaUwQuestions;
    });
    this.industryId$ = this.additionalCoveragesFormService.industryIdSubject.asObservable();
  }

  ngOnDestroy() {
    super.ngOnDestroy(); // Clean up mixin subscriptions
  }

  lockEffectiveDateForRenewals(previousPolicyEndDateMoment: moment.Moment) {
    const effectiveDateControl = getControl(this.form, 'policyInfo.effectiveDate');
    effectiveDateControl.addValidators(renewalEffectiveDateValidator(previousPolicyEndDateMoment));
  }

  initializeForm() {
    const today = moment().startOf('day');
    const effectiveDate = moment().format(US_DATE_MASK);

    const locations = new UntypedFormArray([]);

    const stopGap = this.formBuilder.control({ value: false, disabled: true }, Validators.required);

    const locationAddressesFormArray = new UntypedFormArray([]);

    const oneYearFromToday: moment.Moment = moment().add(1, 'years');

    const policyInfo = this.formBuilder.group(
      {
        effectiveDate: [
          effectiveDate,
          [
            Validators.required,
            minDateExceededValidator(today, 'day'),
            validatorWithMessage(
              maxDateExceededValidator(oneYearFromToday),
              'Maximum effective date allowed is one year away.'
            ),
          ],
        ],
        bopVersion: null,
        meToo: false,
        baseState: [
          '',
          [Validators.required, baseStateLocationValidator(locationAddressesFormArray)],
        ],
        yearsInBusiness: [null, [Validators.required, rangeValidator(0, 999)]],
        locationAddresses: locationAddressesFormArray,
        organizationType: ['', Validators.required],
        irpmVersion: null,
        ratingModelVersion: null,
        agentName: [{ value: '', disabled: true }, Validators.required],
        agentLicenseNumber: [
          { value: '', disabled: true },
          [Validators.required, validateAgentLicenseNumber],
        ],
      },
      {
        validator: uniqueAddressValidator,
      }
    );

    const effectiveDateControl = getControl(policyInfo, 'effectiveDate');
    // Pass dependencies from other subforms into the initialisation as appropriate; should be limited to foundational data like locations, base state, effective date, etc.
    const liabilityCoverages = this.liabilityCoveragesFormService.initializeForm(
      locations,
      effectiveDateControl
    );
    const additionalCoverages = this.additionalCoveragesFormService.initializeForm();

    const excessLiabilityOptIn = this.formBuilder.control(
      { value: false, disabled: true },
      Validators.required
    );
    const excessLiability = this.createExcessLiabilityFormGroup();
    // This gets left in the parent service since it requires multiple subforms
    this.showOrHideOccurrenceSubfields(liabilityCoverages, excessLiabilityOptIn, stopGap);

    const baseStateControl = getControl(policyInfo, 'baseState');
    this.setupExcessObservables(baseStateControl, excessLiability);

    locationAddressesFormArray.valueChanges.subscribe(() => {
      baseStateControl.updateValueAndValidity({
        emitEvent: false,
      });
    });

    const account = this.formBuilder.control(null);
    const guidelines = this.formBuilder.control(null);

    // Main BOP Quote FormGroup
    this.form = this.formBuilder.group({
      account,
      guidelines,
      policyInfo,
      locations,
      liabilityCoverages,
      additionalCoverages,
      excessLiabilityOptIn,
      excessLiability,
    });

    this.setupExposureObservables();

    this.getBopExposure$().subscribe((bopExposure: BopExposureInfo) => {
      this.updateFloridaAgentFields(bopExposure);
      this.updateEBLC(bopExposure);
      this.updateLocationUnderwritingQuestions(bopExposure);
      this.updateXSQuestions(bopExposure);
      this.updateBuildingLimit(bopExposure);
      this.updateBuildingLROStep(bopExposure);

      // Since NY is the only state that still uses BOPv1, the form should always be set to NY Me Too if BOP is V1
      this.setMeToo(bopExposure.bopVersion === 1 && bopExposure.baseState === 'NY');
      this.liabilityCoveragesFormService.bopExposureSubject.next(bopExposure);
      this.additionalCoveragesFormService.bopExposureSubject.next(bopExposure);
    });
  }

  yesToAllPreHnoaQuestions(): boolean {
    return this.liabilityCoveragesFormService.yesToAllPreHnoaQuestions();
  }

  patchSampleQuoteInfo(sampleType: SampleAccountType) {
    const formValues = OnboardingService.getSampleQuoteInfo(sampleType);
    this.form.patchValue(formValues);
  }

  showOrHideOccurrenceSubfields(
    liabilityCoverages: UntypedFormGroup,
    excessLiabilityOptIn: UntypedFormControl,
    stopGapControl: UntypedFormControl
  ) {
    const liabilityAndMedicalExpensesLimitControl = getControl(
      liabilityCoverages,
      'limitPerOccurrenceOfLiabilityAndMedicalExpenses'
    );
    combineLatest(
      observableMerge(
        liabilityAndMedicalExpensesLimitControl.valueChanges,
        observableOf(liabilityAndMedicalExpensesLimitControl.value)
      ),
      this.getBopExposure$(),
      this.userService.getUser()
    ).subscribe(([liabilityAndMedicalExpensesLimitValue, bopExposure, user]) => {
      if (stopGapControl) {
        const stopGapState = bopExposure.locations.some((loc: BopLocation) => {
          return INCLUDED_STOP_GAP_STATES.includes(loc.locationDetails.state);
        });
        enableDisableControl(
          stopGapControl,
          isBopVersion2or3(bopExposure.bopVersion) &&
            liabilityAndMedicalExpensesLimitValue >= STOP_GAP_PER_OCCURRENCE_THRESHOLD &&
            stopGapState
        );
      }

      if (this.allowedCSXSproducerCodes.includes(user.producer)) {
        if (liabilityAndMedicalExpensesLimitValue < EXCESS_LIABILITY_PER_OCCURRENCE_THRESHOLD) {
          excessLiabilityOptIn.disable();
          return;
        }

        if (bopExposure.bopVersion === 1) {
          excessLiabilityOptIn.enable();
          return;
        }
        enableDisableControl(
          excessLiabilityOptIn,
          bopExposure.locations.every((location: BopLocation) => {
            if (EXCLUDED_V2_EXCESS_STATES.includes(location.locationDetails.state)) {
              return false;
            }
            return location.buildings.every((building: BopBuilding) => {
              if (!building.exposure.classification.code) {
                return true;
              }
              return !EXCLUDED_V2_EXCESS_CODES.includes(building.exposure.classification.code.code);
            });
          })
        );
      }
    });
  }

  setupExcessObservables(baseStateControl: UntypedFormControl, excessLiability: UntypedFormGroup) {
    baseStateControl.valueChanges.subscribe((state) => {
      this.updateCoverageOptionsByBaseState(state, excessLiability);
    });

    getControl(excessLiability, 'excessLiabilityCoverageIsScheduled').valueChanges.subscribe(
      (optedIn) => optedIn && this.patchEmployersLiabilityCoverage(excessLiability)
    );
  }

  patchEmployersLiabilityCoverage(excessLiability: UntypedFormGroup) {
    excessLiability.patchValue({
      excessLiabilityPerAccidentCoverage: values(this.excessLiabilityPerAccidentCoverageOptions)[0],
      excessLiabilityPerDiseaseCoverage: values(this.excessLiabilityPerDiseaseCoverageOptions)[0],
      excessLiabilityPerPolicyCoverage: values(this.excessLiabilityPerPolicyCoverageOptions)[0],
    });
  }

  getUWQuestionCodes(buildingClassificationStrings: string[]) {
    const questionCodes: QuestionCode[] = buildingClassificationStrings
      .reduce((acc: Array<QuestionCode>, c) => {
        return acc.concat(REQUIRED_UW_QUESTIONS_BY_BUILDING_CODE[c]);
      }, [])
      .filter(Boolean);

    return uniq(questionCodes);
  }

  getUWQuestions(questionCodes: QuestionCode[]): UWQuestion[] {
    return questionCodes.reduce((acc: Array<UWQuestion>, qCode) => {
      return acc.concat(UW_QUESTIONS[qCode]);
    }, []);
  }

  removeLocation(index: number) {
    this.removeLocationFromForm(index);
    this.syncAllSteps();
  }

  addLocation() {
    this.addLocationForm(false);
    this.syncAllSteps();
  }

  addBuilding(locationIndex: number): UntypedFormGroup {
    const building = this.createBuilding();
    const buildingsControl = this.get<UntypedFormArray>(['locations', locationIndex, 'buildings']);

    const locationStateControl = this.get<UntypedFormArray>([
      'locations',
      locationIndex,
      'locationDetails',
      'state',
    ]);
    buildingsControl.push(building);
    this.newBuildingSubscriptions(building, locationStateControl);
    return building;
  }

  removeAllLocations() {
    removeAllFromFormArray(getFormArray(this.form, 'policyInfo.locationAddresses'));
    removeAllFromFormArray(getFormArray(this.form, 'locations'));
  }

  updateLocationUnderwritingQuestions(bopExposure: BopExposureInfo) {
    // Underwriting has requested that we add a few questions when a specific classcode is selected.
    if (isBopVersion2or3(bopExposure.bopVersion)) {
      bopExposure.locations.forEach((location, locationIndex) => {
        location.buildings.forEach((building, buildingIndex) => {
          if (!building.exposure.classification.code) {
            return;
          }

          const personalDeviceSalesControl = getControl(
            this.form,
            `locations.${locationIndex}.buildings.${buildingIndex}.exposure.percentSalesPersonalDevices`
          );
          const hasPersonalDeviceClassCode = CLASS_CODES_WITH_SMALL_DEVICE_UW_QUESTION.includes(
            building.exposure.classification.code.code
          );

          enableDisableControl(
            personalDeviceSalesControl,
            hasPersonalDeviceClassCode && !building.exposure.lessorsRisk
          );
        });
      });
    }
  }

  isContractorEnhancement() {
    return this.additionalCoveragesFormService.isContractorEnhancement(this.form.value.locations);
  }

  updateNaicsCode(naicsCode: string) {
    this.additionalCoveragesFormService.naicsCodeSubject.next(naicsCode);
  }

  updateEBLC(bopExposure: BopExposureInfo) {
    const baseState = bopExposure.baseState;
    const eblcControl: UntypedFormControl = this.get(
      'liabilityCoverages.employeeBenefitsLiabilityCoverage.optedIn'
    );

    if (!baseState) {
      return;
    }
    if (baseState === 'NY' && !this.meToo()) {
      enableDisableControl(eblcControl, false);
    } else {
      enableDisableControl(eblcControl, true);
    }
  }

  updateXSQuestions(bopExposure: BopExposureInfo) {
    this.uwQuestions = this.getUWQuestions(
      this.getUWQuestionCodes(
        this.liabilityCoveragesFormService.getAllClassificationCodes(bopExposure)
      )
    );
    const excessLiabilityUwQuestions: UntypedFormGroup = <UntypedFormGroup>(
      this.form.get('excessLiability.uwQuestions')
    );

    Object.keys(excessLiabilityUwQuestions).forEach((excessLiabilityUwQuestion) => {
      if (
        this.uwQuestions.some((uwQuestion) => {
          return uwQuestion.questionID === excessLiabilityUwQuestion;
        })
      ) {
        return;
      }
      excessLiabilityUwQuestions.removeControl(excessLiabilityUwQuestion);
    });

    this.uwQuestions.forEach((q) => {
      excessLiabilityUwQuestions.addControl(
        q.questionID,
        new UntypedFormControl(null, Validators.required)
      );
    });
  }

  updateBuildingLimit(bopExposure: BopExposureInfo) {
    bopExposure.locations.forEach((location: BopLocation, locationIndex: number) => {
      location.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
        const exposure = this.get<UntypedFormGroup>([
          'locations',
          locationIndex,
          'buildings',
          buildingIndex,
          'exposure',
        ]);
        const buildingLimitField = getControl(exposure, 'buildingLimit');
        if (buildingLimitField) {
          const disableBuildingLimit =
            BUILDING_LIMIT_NOT_AVAILABLE_BUSINESS_TYPES.includes(building.exposure.businessType) &&
            !building.exposure.lessorsRisk;
          enableDisableControl(buildingLimitField, !disableBuildingLimit);
        }
      });
    });
  }

  updateBuildingLROStep(bopExposure: BopExposureInfo) {
    // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
    if (!this.lessorsRiskPageFeatureFlaggedOn) {
      return;
    }

    bopExposure.locations.forEach((location: BopLocation, locationIndex: number) => {
      location.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
        const lroFormGroup = this.get<UntypedFormGroup>([
          'locations',
          locationIndex,
          'buildings',
          buildingIndex,
          'lessorsRisk',
        ]);
        const nullIfNoChange = enableDisableControl(lroFormGroup, building.exposure.lessorsRisk);

        if (nullIfNoChange !== null) {
          // The LRO step is an optional step and if the form is toggled we want to re-sync.
          this.syncAllSteps();
        }
      });
    });
  }

  getValue(): BopQuoteFormValue {
    return this.form.value;
  }

  getValueWithoutStandaloneCyberFields(): BopQuoteFormValue {
    const cyberLiabilityWithoutUpsellFields = {
      ...omit(this.getValue().additionalCoverages.cyberLiabilityCoverage, [
        'productType',
        CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
        CoalitionCyberQuestion.HAS_TECH_EO,
        CoalitionCyberQuestion.COMPANY_REVENUE,
        CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
        CoalitionCyberQuestion.AGGREGATE_LIMIT,
        CoalitionCyberQuestion.DEFAULT_RETENTION,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
        CoalitionCyberQuestion.PRIOR_CLAIMS,
        CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
        CoalitionCyberQuestion.DUAL_CONTROL_5K,
        CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
        CoalitionCyberQuestion.MFA_EMAIL,
        CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
        CoalitionCyberQuestion.MFA_VPN,
        CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
        CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
      ]),
    } as BopQuoteFormValue['additionalCoverages']['cyberLiabilityCoverage'];

    // If the standalone product is selected, we mark the optedIn value as 'false'
    // so that we do not attempt to add the coverage as part of the BOP policy.
    if (this.isCoalitionCyberSelected()) {
      cyberLiabilityWithoutUpsellFields.optedIn = false;
      cyberLiabilityWithoutUpsellFields.selectedCyberCoverage = 'coalition-cyber';
    }

    return {
      ...this.getValue(),
      additionalCoverages: {
        ...this.getValue().additionalCoverages,
        cyberLiabilityCoverage: cyberLiabilityWithoutUpsellFields,
      },
    };
  }

  patchForm(
    bopFormInputs: DeepPartial<BopQuotePayload> | DeepPartial<BopQuoteFormValue> | null,
    excessFormInput?: BopQuotePayload['excessLiability'] | null
  ) {
    if (bopFormInputs) {
      // There are 4 FormArrays currently in the form. Each of these needs to be properly populated with FormGroups
      // so that the patch will patch correctly.

      // FormArray: form.locations
      const locations = bopFormInputs.locations || [];
      if (locations.length > 0) {
        this.removeAllLocations();
      }
      times(locations.length, () => this.addLocation());

      // FormArray: form.locations.buildings
      locations.forEach((loc: BopLocation, locIndex: number) => {
        // New locations default to having one building and at least one is required
        times(loc.buildings.length - 1, () => this.addBuilding(locIndex));
      });

      // Add as many LRO tenant form controls as needed to each building.
      locations.forEach((loc: BopLocation, locIndex: number) => {
        loc.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
          if (building.lessorsRisk) {
            const lessorsriskTenants = building.lessorsRisk.lessorsRiskTenants;
            const rentRollFiles = building.lessorsRisk.rentRollFiles;
            // The first tenant control is already added when the building is created.
            times(lessorsriskTenants.length - 1, () => {
              const tenantFormArray = this.get<UntypedFormArray>(
                `locations.${locIndex}.buildings.${buildingIndex}.lessorsRisk.lessorsRiskTenants`
              );
              this.addLessorsRiskTenant(tenantFormArray);
            });

            // Create a placeholder rent roll file form control to be patched.
            times(rentRollFiles.length, () => {
              const rentRollFormArray = this.get<UntypedFormArray>(
                `locations.${locIndex}.buildings.${buildingIndex}.lessorsRisk.rentRollFiles`
              );
              this.addRentRollFile(rentRollFormArray);
            });
          }
        });
      });

      // FormArray: form.policyInfo.locationAddresses
      // This is a copy of the Address data in form.locations.locationDetails which is the standard
      const locationAddresses: Address[] = locations.map((location) => {
        const completeAddress = { ...emptyAddress, ...lodashGet(location, 'locationDetails', {}) };
        return getAddress(completeAddress);
      });

      // FormArray: form.additionalCoverages.additionalInsuredBusinesses
      const formInputAdditionalInsureds =
        bopFormInputs?.additionalCoverages?.additionalInsuredBusinesses || [];
      this.additionalCoveragesFormService.resetAdditionalInsuredsFormArrayLength(
        formInputAdditionalInsureds.length
      );

      // Note: patch values in same order as quote flow
      // Guidelines step
      this.form.patchValue({
        guidelines: true,
      });
      // policy-info step
      this.form.patchValue({
        policyInfo: {
          ...bopFormInputs.policyInfo,
          locationAddresses,
        },
      });
      // location+building steps
      this.form.patchValue({
        locations: locations,
      });
      // liability-coverages
      if (bopFormInputs.liabilityCoverages) {
        this.form.patchValue({
          liabilityCoverages: bopFormInputs.liabilityCoverages,
        });
      }
      // additional-coverages
      this.form.patchValue({
        additionalCoverages: bopFormInputs.additionalCoverages,
      });
      // excess-liability
      this.form.patchValue({
        excessLiabilityOptIn: !!excessFormInput,
        excessLiability: excessFormInput ? excessFormInput : bopFormInputs.excessLiability,
      });
    }
  }

  updateFloridaAgentFields(bopExposure: BopExposureInfo) {
    const agentNameFormControl = getControl(this.form, 'policyInfo.agentName');
    const agentLicenseNumFormControl = getControl(this.form, 'policyInfo.agentLicenseNumber');

    // This information is only required for Florida base state quotes and only on quote submissions.
    enableDisableControl(agentNameFormControl, bopExposure.baseState === 'FL');
    enableDisableControl(agentLicenseNumFormControl, bopExposure.baseState === 'FL');
  }

  get epliOptions() {
    return this.additionalCoveragesFormService.epliOptions;
  }

  // Methods for Cyber upsell
  updateQuestionEnablementFlags(flags: CyberQuestionEnablementFlags) {
    this.additionalCoveragesFormService.cyberIndustryFlagsSubject.next(flags);
  }

  isCoalitionCyberSelected() {
    return this.additionalCoveragesFormService.isCoalitionCyberSelected();
  }

  getCyberAdmittedEarlyDeclineReasons() {
    return this.additionalCoveragesFormService.getCyberAdmittedEarlyDeclineReasons();
  }

  getStandaloneCyberFields(): CoalitionCyberFormDataFormatted {
    // Get fields explicitly set in the cyber form group, and those with defaultable values
    const bopQuoteForm: BopQuoteFormValue = this.form.value;
    const effectiveDate = bopQuoteForm.policyInfo.effectiveDate as string;
    return this.additionalCoveragesFormService.getStandaloneCyberFields(
      effectiveDate,
      bopQuoteForm.locations
    );
  }

  setProductAvailabilities(availabilities: ProductAvailability[]) {
    this.additionalCoveragesFormService.productAvailabilitiesSubject.next(availabilities);
  }
  // END Methods for Cyber upsell

  debugSaveFormData() {
    window.localStorage.setItem('BOPFormData', JSON.stringify(this.form.value));
    window.localStorage.setItem(
      'BOPStepData',
      JSON.stringify({
        steps: this.steps,
        currentStep: this.currentStep,
      })
    );
  }

  debugLoadFormData() {
    const formDataStorage = window.localStorage.getItem('BOPFormData');
    const stepDataStorage = window.localStorage.getItem('BOPStepData');

    if (!formDataStorage || !stepDataStorage) {
      return;
    }

    const formData = JSON.parse(formDataStorage);
    const stepData = JSON.parse(stepDataStorage);

    this.form.patchValue(formData);

    this.steps = stepData.steps;

    const currentStep = this.steps.find(
      (step: RouteFormStep) => step.slug === stepData.currentStep.slug
    );
    if (currentStep) {
      this.currentStep = currentStep;
    }
  }
}
