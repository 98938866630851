import { merge } from 'lodash';
import { API_V3_BASE } from 'app/constants';
import { ContactPurposeConstants } from 'app/shared/helpers/insured-contact-helpers';
import { AppError } from 'app/shared/quote-error-modal/errors';
import { US_STATES_DICT } from 'app/shared/services/us-state.service';

import {
  QSAttuneWcPaymentPlan,
  PaymentOptionsSelectionName,
  AttuneWcPaymentPlanDetails,
} from 'app/workers-comp/attune/models/payment-plans.model';

const ATTUNE_WC_BASE_API = `${API_V3_BASE}/quotes/attune/workers-comp`;
export const ATTUNE_WC_ELIGIBILITY_CHECK_API = `${ATTUNE_WC_BASE_API}/eligibility-checkpoint`;
export const ATTUNE_WC_QUOTE_API = `${ATTUNE_WC_BASE_API}`;
export const ATTUNE_WC_CLASSCODES_API = `${ATTUNE_WC_BASE_API}/class-codes`;

export const DEFAULT_EMP_LIABILITY_LIMIT = '100_500_100';

export const EMP_LIABILITY_LIMITS_OPTIONS: { [key: string]: string } = {
  [DEFAULT_EMP_LIABILITY_LIMIT]: '$100k / $500k / $100k',
  '500_500_500': '$500k / $500k / $500k',
  '1000_1000_1000': '$1M / $1M / $1M',
};

export const DEFAULT_DEDUCTIBLE_OPTION = '0';
export const DEFAULT_DEDUCTIBLE_OPTIONS: { [key: string]: string } = {
  [DEFAULT_DEDUCTIBLE_OPTION]: 'None',
  '500': '$500',
  '1000': '$1,000',
  '1500': '$1,500',
  '2000': '$2,000',
  '2500': '$2,500',
};

export const DEDUCTIBLE_OPTIONS_BY_STATE: { [key: string]: { [key: string]: string } } = {
  MI: {
    [DEFAULT_DEDUCTIBLE_OPTION]: 'None',
    '500': '$500',
    '750': '$750',
    '1000': '$1,000',
    '1500': '$1,500',
    '2000': '$2,000',
    '2500': '$2,500',
  },
  OK: {
    [DEFAULT_DEDUCTIBLE_OPTION]: 'None',
    '1000': '$1,000',
    '2000': '$2,000',
    '3000': '$3,000',
    '4000': '$4,000',
    '5000': '$5,000',
  },
  PA: {
    [DEFAULT_DEDUCTIBLE_OPTION]: 'None',
    '1000': '$1,000',
    '5000': '$5,000',
  },
};

// Flattened object of all deductible options for use in prettifying the deductible value.
export const ALL_DEDUCTIBLE_OPTIONS = merge(
  {},
  ...Object.values(DEDUCTIBLE_OPTIONS_BY_STATE),
  DEFAULT_DEDUCTIBLE_OPTIONS
);

export const NO_DEDUCTIBLE_STATES = ['MS'];
export const NO_DEDUCTIBLE_STATES_FULL_NAMES = NO_DEDUCTIBLE_STATES.map(
  (state) => US_STATES_DICT[state]
);

export const WAIVER_OF_SUB_OPTIONS: { [key: string]: string } = {
  blanket: 'blanket',
  specific: 'specific',
};

export const THREE_YEAR_LOSS_RATIO_OPTONS: { [key: string]: string } = {
  NewBusiness: 'New Business',
  ZeroToTwentyFive: '0%-25%',
  TwentySixToThirtyFive: '26%-35%',
  ThirtySixToForty: '36%-40%',
  FortyOneToFifty: '41%-50%',
  FiftyOneToHundred: '51%-100%',
};

export const PERCENT_MEDICAL_CLAIMS_OPTIONS: { [key: string]: string } = {
  NewBusiness: 'New Business',
  ZeroToTwenty: '0%-20%',
  TwentyOneToThirty: '21%-30%',
  ThirtyOneToForty: '31%-40%',
  FortyOneToFifty: '41%-50%',
  FiftyOneToHundred: '51%-100%',
};

export const PERCENT_UNINSURED_CONTRACTOR_OPTIONS: { [key: string]: string } = {
  ZeroToTen: '0%-10%',
  ElevenToTwenty: '11%-20%',
  TwentyToThirtyFive: '21%-35%',
  ThritySixToFifty: '36%-50%',
  FiftyOneToHundred: '51%-100%',
};

export const ATTUNE_WC_ELIGIBILITY_ERROR: AppError = {
  body: 'This quote is not eligible for this program.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Ineligible Quote',
  image: 'assets/img/ineligible.png',
};

export const ATTUNE_WC_INVALID_FEIN_ERROR: AppError = {
  body: 'Please correct the FEIN, or contact the Customer Care Team if you believe this is an error.',
  displayBack: true,
  displayClose: true,
  displayRetry: false,
  closeButtonText: 'Update FEIN',
  hideDialogBox: true,
  header: 'Incorrect FEIN',
  image: 'assets/img/issue.png',
};

export const ATTUNE_WC_MULTIPLE_RISK_IDS_FOUND_ERROR: AppError = {
  body: 'Multiple Risk IDs are linked to the entered FEIN. Please reach out to Customer Care to proceed with this submission.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  hideDialogBox: true,
  header: 'Multiple Risk IDs found',
  image: 'assets/img/issue.png',
};

export const ATTUNE_WC_BIND_FEIN_BLOCK: AppError = {
  body: 'We are unable to bind this quote. There is already an in-force Workers Compensation policy for this insured.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Ineligible Quote',
  image: 'assets/img/ineligible.png',
};

export const ATTUNE_WC_BIND_ERROR: AppError = {
  body: "We're having trouble issuing this policy. Please try again or contact the Customer Care Team if you continue to have this issue.",
  displayBack: true,
  displayRetry: false,
  displayClose: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const DEFAULT_INSTALLMENT_FEE_AFTER_DOWN_PAYMENT = 5;

export const DEFAULT_PAYMENT_PLAN: QSAttuneWcPaymentPlan = 'ONE_PAY';

export type AttuneWcPaymentPlanOptions = Partial<
  Record<PaymentOptionsSelectionName, QSAttuneWcPaymentPlan>
>;

export const ATTUNE_WC_PAYMENT_PLAN_OPTIONS: Record<
  Exclude<PaymentOptionsSelectionName, 'Pay By Pay Billing'>,
  QSAttuneWcPaymentPlan
> = {
  'One-time payment': DEFAULT_PAYMENT_PLAN,
  'Ten Payments, 10% Down': 'TEN_PAY_TEN_DOWN',
  'Ten Payments, 25% Down': 'TEN_PAY_25_DOWN',
};

export const ATTUNE_WC_PAYMENT_PLAN_MAPPING: Readonly<
  Record<QSAttuneWcPaymentPlan, AttuneWcPaymentPlanDetails>
> = {
  ONE_PAY: {
    downPaymentPercentage: 1,
    premiumThreshold: 0,
    numberOfPayments: 1,
  },
  TEN_PAY_TEN_DOWN: {
    downPaymentPercentage: 0.1,
    premiumThreshold: 0,
    numberOfPayments: 10,
  },
  TEN_PAY_25_DOWN: {
    downPaymentPercentage: 0.25,
    premiumThreshold: 0,
    numberOfPayments: 10,
  },
  // Number of payments is set to 1 so that we can display the total estimated premium.
  // The insured will not actually be charged this amount all at once as they are paying monthly according to their payroll/exposure.
  ADP_PAY_BY_PAY: {
    downPaymentPercentage: 1,
    premiumThreshold: 0,
    numberOfPayments: 1,
  },
};

/**
 * Keys represent the GW name for the insured contact role. Keys are the UI-friendly label.
 */
export const ATTUNE_WC_INSURED_CONTACT_OPTIONS: ContactPurposeConstants = {
  BillingContact: 'Billing',
  AuditContact:
    'Audit <a class="insured-contact-label-link" href="https://resources.attuneinsurance.com/documents/wc-audit-overview---for-policyholders" target="_blank">Learn more</a>',
  LossControlContact:
    'Loss Control <a class="insured-contact-label-link" href="https://resources.attuneinsurance.com/documents/loss-control-procedures" target="_blank">Learn more</a>',
} as const;

export const EXECUTIVE_ELECTION_ATTESTATION = `I attest that the insured (my client) has complied with the applicable workers' compensation laws of the states shown in item 3.A. of the policy information page, and I will maintain and make available upon request all required documentation in the Agency file.`;

export const STRONGLY_PREFERRED_HAZARD_GROUPS: Readonly<WcHazardGroup[]> = ['A', 'B'];

export const PREFERRED_HAZARD_GROUPS: Readonly<WcHazardGroup[]> = ['C', 'D', 'E'];

export const HIGH_HAZARD_GROUPS: Readonly<WcHazardGroup[]> = ['F', 'G'];

export const INELIGIBLE_INDUSTRIES_FOR_GUIDELINES = [
  'Staffing companies, day labor, and temp agencies',
  'Occupational disease exposures',
  'Blasting',
  'Athletic sports',
  'Aviation',
  'Mining',
  'Logging & Tree Trimming/Removal from above ground level',
  'High hazard manufacturing',
  'Roofing contractors or roofing-adjacent work, including any outdoor work over 2 stories (e.g., Holiday Light Installation)',
  'Trucking and freight forwarding',
  'Motorcycle repair, sales, and related entities',
];

export const COVERAGE_RESTRICTIONS_FOR_GUIDELINES = [
  'USL&H and other Federal Coverages (FELA, etc.)',
  'PEOs or accounts whose coverage was through a PEO at any point within the previous year (unless the customer has their own Risk ID separate from the PEO they are leaving)',
];
export const SAFETY_REVIEW_EMAIL_TEMPLATE_URL =
  'https://docs.google.com/document/d/1lwh2aKvTDY8ZjKBhAjMDlNrXYhi55U5m2bgBrUwJvvI/edit?tab=t.0';
export const SAFETY_REVIEW_OVERVIEW_AGENTS_URL =
  'https://resources.attuneinsurance.com/documents/agents---wc-safety-review-process';
export const SAFETY_REVIEW_OVERVIEW_POLICYHOLDERS_URL =
  'https://resources.attuneinsurance.com/documents/policyholders---safety-review-process';

export const PAY_BY_PAY_PLAN_NAMES = ['pay by pay billing'];
export const PAY_BY_PAY_PLAN_IDS = ['ADP_PAY_BY_PAY'];
