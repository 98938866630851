import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable,
  Subscription,
} from 'rxjs';

import { filter, switchMap } from 'rxjs/operators';
// Libraries
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
// Services
import { LimitsService } from 'app/shared/services/limits.service';
// Models
import { APIData, APIDataStatus, WindOption } from 'app/shared/models/api-data';
// Helpers
import { getControl, shouldShowInvalid } from 'app/shared/helpers/form-helpers';
import {
  REQUIRED_BOPV2_WIND_COVERAGE_STATES,
  REQUIRED_BOP_WIND_COVERAGE_STATES_MAP,
  REQUIRED_WIND_LOSS_MITIGATION_STATES,
  WIND_LOSS_MITIGATION_QUESTIONS,
  WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE,
} from 'app/features/attune-bop/models/constants';

const WIND_LOSS_QUESTION_TEXT = `Does the insured have evidence that building construction or
the retrofitting measure has been implemented according to applicable standards for the
South Carolina Windstorm Loss Mitigation Program?`;

const notNull = (x: string | null): x is string => x !== null;
const notUndefined = (x: string | undefined): x is string => x !== undefined;

@Component({
  selector: 'app-catastrophe-coverages',
  templateUrl: './catastrophe-coverages.component.html',
})
export class CatastropheCoveragesComponent implements OnInit, OnDestroy {
  @Input() building: UntypedFormGroup;
  @Input() exposure: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() endorsementJobNumber: string;
  @Input() bopVersion: BopVersion;
  @Input() prefix: string;
  @Output() openWindDeclineModal: EventEmitter<string> = new EventEmitter<string>();
  windOptions: Array<any> = [];
  windDeductibleMap: any = {};
  _state = new BehaviorSubject<string | null>(null);
  _constructionType = new BehaviorSubject<string | null>(null);
  _propertyDeductible = new BehaviorSubject<string | null>(null);
  _milesToOcean = new BehaviorSubject<string | null>(null);
  _businessType = new BehaviorSubject<string | null>(null);
  _lessorsRisk = new BehaviorSubject<boolean | null>(null);
  windDeductibleControl: UntypedFormControl;
  windDeductibleSub: Subscription;
  windDeductiblePercentControl: UntypedFormControl;
  windLossMitigationPresentText = WIND_LOSS_QUESTION_TEXT;
  wlmQuestions = WIND_LOSS_MITIGATION_QUESTIONS;
  windLossMitigation: UntypedFormGroup;
  wlmSub: Subscription;
  requiredWindCoverageStates: string[];
  requiredV2WindCoverageStates = REQUIRED_BOPV2_WIND_COVERAGE_STATES;

  constructor(private limitsService: LimitsService, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.requiredWindCoverageStates = REQUIRED_BOP_WIND_COVERAGE_STATES_MAP[this.bopVersion];
    this.windDeductibleControl = <UntypedFormControl>this.building.get('windDeductible');
    this.windDeductiblePercentControl = <UntypedFormControl>(
      this.building.get('windDeductiblePercent')
    );

    this.windLossMitigation = <UntypedFormGroup>this.building.get('windLossMitigation');

    const windOptionsData: Observable<APIData> = observableCombineLatest(
      this._state.pipe(filter(notNull)),
      this._constructionType.pipe(filter(notNull)),
      this._lessorsRisk,
      this._propertyDeductible.pipe(filter(notNull)),
      this._businessType.pipe(filter(notNull)),
      this._milesToOcean.pipe(filter(notNull), filter(notUndefined))
    ).pipe(
      switchMap(([state, construction, lessorsRisk, deductible, businessType, milesToOcean]) => {
        return this.limitsService.requestWindOptions({
          state,
          constructionType: construction,
          isLessor: lessorsRisk,
          jobNumber: this.endorsementJobNumber,
          propertyDeductible: deductible,
          propertyType: businessType,
          milesToOcean,
        });
      })
    );

    // create list of options for select list
    // 🚨 Renders the Component on 2nd Run 🚨
    windOptionsData.subscribe((response: APIData) => {
      if (
        this.state &&
        this.requiredWindCoverageStates.includes(this.state) &&
        response.status === APIDataStatus.ERROR
      ) {
        this.openWindDeclineModal.emit(this.state);
      }
      this.ref.detectChanges(); // update page on status changes
      const windOptions = (<Array<WindOption>>response.data).map(this.mapWindOptionToSelectOptions);
      this.windOptions = windOptions;

      this.windDeductibleMap = _.reduce(
        <Array<WindOption>>response.data,
        (map: any, windOption: any) => {
          map[windOption.PercentDeductible] = windOption.DollarAmountDeductible;
          return map;
        },
        {}
      );

      this.handleWindPercentageValue(this.windDeductiblePercentControl.value);
    });

    this.windDeductibleSub = this.windDeductiblePercentControl.valueChanges.subscribe((percent) => {
      this.handleWindPercentageValue(percent);
    });

    this.handleWindLossMitigationSetting(
      getControl(this.building, 'windLossMitigationPresent').value
    );
    this.wlmSub = getControl(this.building, 'windLossMitigationPresent').valueChanges.subscribe(
      (wlmPresent) => {
        this.handleWindLossMitigationSetting(wlmPresent);
      }
    );

    if (this.state && this.requiredWindCoverageStates.includes(this.state)) {
      (<UntypedFormControl>this.building.get('windCoverageOptedIn')).patchValue(true);
    }
  }

  ngOnDestroy() {
    this.windDeductibleSub.unsubscribe();
    this.wlmSub.unsubscribe();
  }

  public handleWindPercentageValue(percent: string) {
    if (
      !percent &&
      this.windDeductiblePercentControl.value !== WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE
    ) {
      this.windDeductiblePercentControl.setValue(WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE);
    }

    this.setWindDeductibleToAvailableOption();

    this.windDeductibleControl.setValue(
      this.windDeductibleMap[this.windDeductiblePercentControl.value]
    );
  }

  private handleWindLossMitigationSetting(wlmPresent: boolean) {
    if (wlmPresent) {
      getControl(this.building, 'windLossMitigation').enable();
    } else {
      getControl(this.building, 'windLossMitigation').disable();
    }
  }

  @Input()
  get state() {
    return this._state.getValue();
  }
  set state(value: string | null) {
    this._state.next(value);
  }

  @Input()
  get constructionType() {
    return this._constructionType.getValue();
  }
  set constructionType(value: string | null) {
    this._constructionType.next(value);
  }

  @Input()
  get propertyDeductible() {
    return this._propertyDeductible.getValue();
  }
  set propertyDeductible(value: string | null) {
    this._propertyDeductible.next(value);
  }

  @Input()
  get milesToOcean() {
    return this._milesToOcean.getValue();
  }
  set milesToOcean(value: string | null) {
    this._milesToOcean.next(value);
  }

  @Input()
  get businessType() {
    return this._businessType.getValue();
  }
  set businessType(value: string | null) {
    this._businessType.next(value);
  }

  @Input()
  get lessorsRisk() {
    return this._lessorsRisk.getValue();
  }
  set lessorsRisk(value: boolean | null) {
    this._lessorsRisk.next(value);
  }

  get windOptionsStatus(): APIDataStatus {
    const status = this.limitsService.windOptionsStatus({
      state: this._state.getValue(),
      constructionType: this._constructionType.getValue(),
      isLessor: this._lessorsRisk.getValue(),
      propertyDeductible: this._propertyDeductible.getValue(),
      propertyType: this._businessType.getValue(),
      milesToOcean: this._milesToOcean.getValue(),
    });

    return status;
  }

  mapWindOptionToSelectOptions = (option: WindOption) => {
    let description = option.PercentDeductible;
    if (description === WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE) {
      description = 'Not Applicable';
    }
    return { value: option.PercentDeductible, description: description };
  };

  showWindCoverageOptIn() {
    if (!this.state) {
      return true;
    }
    return !this.requiredWindCoverageStates.includes(this.state);
  }

  defaultWindLossMitigationAnswers() {
    if (!this.state) {
      return false;
    }

    return (
      this.building.value.windCoverageOptedIn &&
      REQUIRED_WIND_LOSS_MITIGATION_STATES.includes(this.state)
    );
  }

  showWindLossMitigationType2() {
    return parseInt(getControl(this.exposure, 'storyCount').value, 10) > 3;
  }

  shouldShowInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.building, this.submitted);
  }

  private setWindDeductibleToAvailableOption() {
    if (!_.find(this.windOptions, ['value', this.windDeductiblePercentControl.value])) {
      const windDeductibleValue = this.windOptions[0] ? this.windOptions[0].value : null;
      if (windDeductibleValue && windDeductibleValue !== this.windDeductiblePercentControl.value) {
        this.windDeductiblePercentControl.patchValue(windDeductibleValue);
      }
    }
  }
}
