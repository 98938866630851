import { Component } from '@angular/core';
import * as _ from 'lodash';
import { timer } from 'rxjs';
import { BopPricedQuote } from '../../models/bop-priced-quote';
import { AttuneBopQuoteRequestService } from 'app/features/attune-bop/services/attune-bop-quote-request.service';
import { handleQuoteError } from 'app/features/attune-bop/models/quoting-helpers';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { Params, Router } from '@angular/router';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  DECLINED_ERROR,
  INELIGIBLE_ERROR,
  UNKNOWN_ERROR,
  TIMEOUT_ERROR,
  ISSUE_ERROR,
  ISO_LOCATION_ERROR,
  TECH_MORATORIUM_ERROR,
} from 'app/shared/quote-error-modal/errors';
import { RewardsService } from 'app/shared/services/rewards.service';
import { ActionName } from 'app/shared/rewards/rewards-types';
import { HttpErrorResponse } from '@angular/common/http';
import { SentryService } from 'app/core/services/sentry.service';
import { OnboardingQueryParams, OnboardingService } from 'app/shared/services/onboarding.service';
import { FullstoryService } from 'app/core/services/fullstory.service';
import {
  FrontendQuote,
  isQuotedQuoteResponse,
  isQuoteErrorResponse,
  isUnavailableResponse,
  QuoteResponse,
} from '../../../digital-carrier/models/types';
import { UpsellEarlyDeclineKey } from '../../../coalition/models/cyber-typings.model';
import {
  COALITION_BROKER_OF_RECORD_REFERRAL_REASON,
  DUPLICATE_BROKER_ERROR_MESSAGE,
} from '../../../coalition/models/cyber-constants.model';
import { BundleService } from '../../../bundle/services/bundle.service';
import { DigitalCarrierQuoteService } from '../../../digital-carrier/services/digital-carrier-quote.service';
import { filter, switchMap } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'app-bop-update-quote',
  templateUrl: './attune-bop-quote-update.component.html',
})
export class AttuneBopQuoteUpdateComponent {
  quoteResultModalOpen: boolean;
  quoteTimeout: boolean;
  quoteIssue: boolean;
  quoteIssueMessage: string;
  quoteError: boolean;
  loadingBopPolicyPage: boolean;
  loading: boolean;
  declinedQuote: BopPricedQuote | undefined;
  underWritingDeclineReasons: string[] = [];
  quotingFailReasons: string[] = [];
  isoLocationError: boolean;
  accountId: string;
  underwritingErrors: string[] = [];
  constructor(
    protected insuredAccountService: InsuredAccountService,
    protected requestQuoteService: AttuneBopQuoteRequestService,
    protected amplitudeService: AmplitudeService,
    protected fullstoryService: FullstoryService,
    protected router: Router,
    protected rewardsService: RewardsService,
    protected sentryService: SentryService,
    protected bundleService: BundleService,
    protected quoteService: DigitalCarrierQuoteService,
    protected onboardingService: OnboardingService
  ) {}

  getErrorType(bopVersion?: BopVersion) {
    const isTechMoratorium = this.getErrorList()
      ? this.getErrorList().find((n) =>
          n.includes(
            'We are experiencing a technical issue with a third-party provider in this state'
          )
        )
      : false;
    if (isTechMoratorium) {
      return TECH_MORATORIUM_ERROR;
    }
    if (this.quoteTimeout) {
      return TIMEOUT_ERROR;
    }
    if (this.quoteIssue) {
      ISSUE_ERROR.message = this.quoteIssueMessage;
      return ISSUE_ERROR;
    }
    // If BOP version is undefined, fall back to the most common version for guideline links in error modals
    const fallbackBopVersion = 2;
    if (this.declinedQuote) {
      return DECLINED_ERROR(bopVersion || fallbackBopVersion);
    }
    if (this.underwritingErrors.length) {
      return DECLINED_ERROR(bopVersion || fallbackBopVersion);
    }
    if (this.underWritingDeclineReasons.length) {
      return INELIGIBLE_ERROR(bopVersion || fallbackBopVersion);
    }
    if (this.isoLocationError) {
      return ISO_LOCATION_ERROR;
    }
    return UNKNOWN_ERROR;
  }

  handleQuoteResultModalClose(result: { close: boolean; retry: boolean }, accountId: string) {
    if (!result.close && !result.retry) {
      this.router.navigate(['accounts', accountId]);
    }
    if (result.close) {
      this.quoteResultModalOpen = false;
    }

    if (result.close && !result.retry) {
      this.router.navigate(['/accounts', accountId]);
    }
  }

  getErrorList() {
    if (this.declinedQuote) {
      return _.uniq(this.declinedQuote.reasons);
    }
    if (this.quoteIssue) {
      return _.uniq(this.quotingFailReasons);
    }
    if (this.underWritingDeclineReasons.length) {
      return _.uniq(this.underWritingDeclineReasons);
    }
    if (this.underwritingErrors.length) {
      return _.uniq(this.underwritingErrors);
    }
    return [];
  }

  logFailureInFullstory() {
    datadogRum.addAction('BOP quote failure', {
      accountID: this.accountId,
    });
  }

  updateQuote(
    resp: any,
    accountId?: string,
    configurations?: {
      isSampleQuote?: boolean;
      isRolloverQuote?: boolean;
      parentQuoteId?: string;
      secondaryQuoteResponse?: QuoteResponse | null;
      secondaryQuoteEarlyDeclineReasons?: UpsellEarlyDeclineKey[] | null;
    }
  ) {
    const isRolloverQuote = configurations && configurations.isRolloverQuote;
    const parentQuoteId = configurations && configurations.parentQuoteId;
    if (resp.status >= 300 || resp instanceof HttpErrorResponse) {
      // Quote has unusual response: error, 40x, 50x, etc

      this.requestQuoteService.hideQuoteGame();
      this.quoteResultModalOpen = true;
      if (resp.status === 504 || resp.status === 0) {
        // NOTE: In AWS environments, load balancer 504 comes through w/ status => 0
        this.quoteTimeout = true;

        this.logFailureInFullstory();

        this.amplitudeService.track({
          eventName: 'bop_timeout',
          detail: JSON.stringify(resp.error),
          useLegacyEventName: true,
        });
      } else if (resp.status === 500) {
        // Error from Service Proxy
        this.amplitudeService.track({
          eventName: 'bop_error',
          detail: JSON.stringify(resp.error),
          useLegacyEventName: true,
        });
        const respErrorMsg = _.get(resp, 'error.error.message', '');
        this.quoteIssue = true;
        handleQuoteError(respErrorMsg, (responseError: string) => {
          if (responseError) {
            this.quoteIssueMessage = respErrorMsg;

            this.logFailureInFullstory();

            if (isRolloverQuote) {
              this.amplitudeService.track({
                eventName: 'rollover_requote_error',
                detail: 'bop',
              });
            } else {
              this.amplitudeService.track({
                eventName: 'bop_error_message',
                detail: responseError,
                useLegacyEventName: true,
              });
            }
          }
        });
      } else if (resp.status === 409) {
        // Error from Quote Service
        const errorResponse = resp.error as QSQuoteSubmissionResponse;
        const errorDetails = errorResponse.results[0].data as QSQuoteSubmissionErrorData;
        const errors: string[] = errorDetails.errors;

        const hasUnderwritingErrors = errors.some((uwError) =>
          uwError.includes('UnderwritingIssue')
        );
        if (hasUnderwritingErrors) {
          this.underwritingErrors = errors;
          this.amplitudeService.track({
            eventName: 'decline',
            detail: JSON.stringify(errors),
            useLegacyEventName: true,
          });
        } else {
          if (isRolloverQuote) {
            this.amplitudeService.track({
              eventName: 'rollover_requote_error',
              detail: 'bop',
            });
          } else {
            this.amplitudeService.track({
              eventName: 'bop_v3_error',
              detail: JSON.stringify(errors),
              useLegacyEventName: true,
            });
          }
          this.quoteIssue = true;
          this.quotingFailReasons = errors;
          if (this.quotingFailReasons.length > 0) {
            this.amplitudeService.track({
              eventName: 'bop_v3_error_message',
              detail: JSON.stringify(errors),
              useLegacyEventName: true,
            });
          }
        }
        this.logFailureInFullstory();
      } else {
        this.quoteError = true;
        if (isRolloverQuote) {
          this.amplitudeService.track({
            eventName: 'rollover_requote_error',
            detail: 'bop',
          });
        } else {
          this.amplitudeService.track({
            eventName: 'bop_error',
            detail: JSON.stringify(resp.error),
            useLegacyEventName: true,
          });
        }
        this.logFailureInFullstory();
      }
    } else {
      // service-quote 200 (successful quote) OR service-proxy 200 (can be a decline)
      if (isRolloverQuote && parentQuoteId) {
        this.insuredAccountService.archive(parentQuoteId).subscribe();
      }
      const pricedQuote = resp as BopPricedQuote;
      this.insuredAccountService.cachebust();

      if (!!pricedQuote && pricedQuote.valid) {
        this.requestQuoteService.showQuoteGameSuccess();
        if (isRolloverQuote) {
          this.amplitudeService.track({
            eventName: 'requote_rollover_success',
            detail: 'bop',
          });
        } else {
          this.amplitudeService.track({
            eventName: 'quote',
            detail: 'bop',
            useLegacyEventName: true,
          });
        }
        // Give a bit of time for the user to see the success message.
        timer(3000).subscribe(() => {
          this.requestQuoteService.hideQuoteGame();

          // Add any relevant params to the redirect based on the configuration
          const queryParams: Params = {
            isNewQuote: 'true',
          };
          const isSampleQuote = configurations && configurations.isSampleQuote;
          if (isSampleQuote) {
            queryParams[OnboardingQueryParams.FROM_QUOTE] = 'true';
          }

          const secondaryQuoteResponse = configurations && configurations.secondaryQuoteResponse;
          if (secondaryQuoteResponse) {
            if (
              isUnavailableResponse(secondaryQuoteResponse) &&
              secondaryQuoteResponse.unavailableReasons.includes(
                COALITION_BROKER_OF_RECORD_REFERRAL_REASON
              )
            ) {
              this.sentryService.notify(
                'Coalition Cyber: Submit quote response from bop up sell / cross sell contains broker of record error, redirecting user to Help Center form',
                {
                  severity: 'info',
                  metaData: { response: secondaryQuoteResponse, product: 'cyber_admitted' },
                }
              );
              queryParams.secondaryQuoteBrokerOfRecordError = true;
            } else if (
              isQuoteErrorResponse(secondaryQuoteResponse) &&
              secondaryQuoteResponse.errors.includes(DUPLICATE_BROKER_ERROR_MESSAGE)
            ) {
              this.sentryService.notify(
                'Coalition Cyber: Submit quote response from bop up sell / cross sell did not have draft status due to Duplicate broker error',
                {
                  severity: 'info',
                  metaData: { response: secondaryQuoteResponse, product: 'cyber_admitted' },
                }
              );
              queryParams.secondaryQuoteDuplicateBrokerError = true;
            }
            // Set the secondary quote ID on the query params so we have access to it on the quote details pane
            queryParams.secondaryQuoteId = secondaryQuoteResponse.uuid;
          }
          const secondaryQuoteEarlyDeclineReasons =
            configurations && configurations.secondaryQuoteEarlyDeclineReasons;
          if (secondaryQuoteEarlyDeclineReasons) {
            queryParams.secondaryQuoteEarlyDeclineReasons = secondaryQuoteEarlyDeclineReasons;
          }

          if (
            accountId &&
            !secondaryQuoteEarlyDeclineReasons &&
            !!pricedQuote &&
            pricedQuote.valid &&
            configurations?.secondaryQuoteResponse &&
            isQuotedQuoteResponse(configurations.secondaryQuoteResponse)
          ) {
            const cyberUuid = configurations.secondaryQuoteResponse.uuid;
            this.quoteService
              .getQuoteDetails(pricedQuote.id, 'pasId')
              .pipe(
                filter((bopQuote) => bopQuote !== null),
                switchMap((bopQuote: FrontendQuote) =>
                  this.bundleService.createBundle(accountId, bopQuote.uuid, cyberUuid)
                )
              )
              .subscribe((bundleResponse) => {
                if (bundleResponse) {
                  this.router.navigate([
                    'accounts',
                    accountId,
                    'bundle',
                    bundleResponse.bundleUuid,
                  ]);
                } else {
                  this.router.navigate(['accounts', accountId]);
                }
              });
          } else {
            this.router.navigate(['accounts', accountId, 'bop', 'policies', pricedQuote.id], {
              queryParams,
            });
          }

          if (!isSampleQuote) {
            this.rewardsService.submitRewardAction({
              actionName: ActionName.QUOTE_FOR_ACCOUNT,
              data: {
                insuredAccountId: accountId,
                product: 'bop',
                carrierName: 'attune_gw',
                bopQuoteId: pricedQuote.id,
              },
            });
          }
        });
      } else if (
        Array.isArray(resp.reasons) &&
        (resp.reasons.includes('Could NOT retrieve info from ISO Location Service') ||
          resp.reasons.includes(
            'ISO Location Service is currently unavailable, please retry later.'
          ))
      ) {
        this.quoteResultModalOpen = true;
        this.isoLocationError = true;
        this.amplitudeService.track({
          eventName: 'bop_quote_iso_error',
          detail: 'bop',
          useLegacyEventName: true,
        });
        this.requestQuoteService.hideQuoteGame();
      } else {
        // Decline or other issue (Validation) in service-proxy
        let declinedReasons = '';

        if (resp && resp.reasons) {
          for (let i = 0; i < resp.reasons.length - 1; i++) {
            declinedReasons += resp.reasons[i] + '|';
          }

          declinedReasons += resp.reasons[resp.reasons.length - 1];
        }

        const validationReasons = _.get(
          resp,
          'return.SubmissionResponse.SubmissionResults.Entry.0.ValIssues.Entry'
        );
        if (validationReasons && validationReasons.length) {
          declinedReasons += validationReasons.map((entry: any) => entry.Reason);
        }

        this.logFailureInFullstory();
        if (isRolloverQuote) {
          this.amplitudeService.track({
            eventName: 'rollover_requote_decline',
            detail: 'bop',
          });
        } else {
          this.amplitudeService.track({
            detail: declinedReasons,
            eventName: 'decline',
            useLegacyEventName: true,
          });
        }

        this.quoteResultModalOpen = true;
        this.declinedQuote = pricedQuote;
        this.requestQuoteService.hideQuoteGame();
      }
    }
  }
}
