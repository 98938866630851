import { Component, Input } from '@angular/core';

import {
  ATTUNE_WC_APPETITE_GUIDE,
  BOOK_BALANCE_GUIDE_LINK,
  BROKER_ENDORSEMENTS_GUIDE_LINK,
  INSURED_BILLING_GUIDE,
} from '../../features/support/models/support-constants';

@Component({
  selector: 'app-additional-documents',
  templateUrl: './additional-documents.component.html',
})
export class AdditionalDocumentsComponent {
  @Input() isAttuneWc = false;
  @Input() paneType: 'quote' | 'policy';
  @Input() isPayByPayPolicy = false;

  attuneWcGuidelines = ATTUNE_WC_APPETITE_GUIDE;
  attuneWcBookBalanceGuide = BOOK_BALANCE_GUIDE_LINK;
  brokerEndorsementsGuide = BROKER_ENDORSEMENTS_GUIDE_LINK;
  insuredBillingGuide = INSURED_BILLING_GUIDE;

  constructor() {}
}
