import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { SpoilageClass, ClassRestrictedCoverage } from 'app/features/attune-bop/models/constants';
import {
  buildingClassifications,
  BuildingClassificationInfo,
  BuildingClassificationCategory,
} from 'app/features/attune-bop/models/building-classifications';

interface ClassificationIndex {
  descriptionMap: { [code: string]: BuildingClassificationInfo };
}

const RESTAURANT_CATEGORIES: BuildingClassificationCategory[] = [
  'RestaurantCasualDining',
  'RestaurantFineDining',
  'RestaurantLimitedCooking',
  'RestaurantFastFood',
];

function findByType(category: string): BuildingClassification[] {
  const classifications: ClassificationIndex = codesList[category];
  if (!classifications) {
    return [];
  }

  return Object.values(classifications.descriptionMap).map(fixtureToFormClassification);
}

const codesList: { [category: string]: ClassificationIndex } = _.chain(buildingClassifications)
  .groupBy((classification: BuildingClassificationInfo) => {
    return classification.category;
  })
  .mapValues((classifications: BuildingClassificationInfo[]) => {
    return {
      descriptionMap: _.keyBy(classifications, (code: BuildingClassificationInfo) => {
        return code.descriptionCode;
      }),
    };
  })
  .value();

/**
 * Methods that rely on the internal `codes` private field should be placed
 * within this class.
 */
@Injectable()
export class AttuneBopBuildingClassificationService {
  constructor() {}

  public static restaurantCodes: string[] = (() => {
    const classCodes: string[] = [];
    RESTAURANT_CATEGORIES.forEach((category: string) => {
      const codes = findByType(category).map((classCode: BuildingClassification) => {
        return classCode.code;
      });
      classCodes.push(...codes);
    });

    return classCodes;
  })();

  codeDescription(category: string, code: string): string | undefined {
    const classifications: ClassificationIndex = codesList[category];
    if (!classifications) {
      return undefined;
    }

    const classificationFixture: BuildingClassificationInfo = classifications.descriptionMap[code];
    if (!classificationFixture) {
      return undefined;
    }
    return classificationFixture.description;
  }

  partialSearch({
    category,
    part,
    state,
    lroOption = false,
    bopVersion = 1,
  }: {
    category: string;
    part: string;
    state: string;
    lroOption: boolean;
    bopVersion: BopVersion;
  }): BuildingClassification[] {
    const classifications: ClassificationIndex = codesList[category];

    if (!classifications) {
      return [];
    }

    return this.searchByPart({
      part,
      classifications,
      lessorsRiskOnly: lroOption,
      bopVersion,
      state,
    });
  }

  private searchByPart({
    part,
    classifications,
    lessorsRiskOnly,
    bopVersion,
    state,
  }: {
    part: string;
    classifications: ClassificationIndex;
    lessorsRiskOnly: boolean;
    bopVersion: BopVersion;
    state: string;
  }): BuildingClassification[] {
    const searchResults = Object.values(classifications.descriptionMap)
      .filter((classification: BuildingClassificationInfo) => {
        const stringMatches =
          classification.description.toLocaleLowerCase().includes(part.toLocaleLowerCase()) ||
          classification.code.includes(part);
        const isInvalidForBopVersion =
          (bopVersion === 1 && classification.excludeFromBopV1) ||
          (bopVersion !== 1 && classification.bopV1Only);
        const isValidInState = !classification.excludeInStates?.includes(state);

        return (
          stringMatches &&
          lroMatches(classification, lessorsRiskOnly) &&
          !isInvalidForBopVersion &&
          isValidInState
        );
      })
      .map(fixtureToFormClassification);
    return _.sortBy(searchResults, 'descriptionCode');
  }
}

/**
 * Methods that DO NOT rely on the internal `codes` private field should be placed
 * here.
 */
function fixtureToFormClassification(fixture: BuildingClassificationInfo): BuildingClassification {
  return {
    code: fixture.code,
    descriptionCode: fixture.descriptionCode,
  };
}

function lroMatches(classification: BuildingClassificationInfo, lessorsRiskOnly: boolean): boolean {
  return classification.lroOptions.includes(lessorsRiskOnly ? '10orless' : 'Over10');
}

export function validBuildingClassification(
  { descriptionCode, code }: BuildingClassification,
  lessorsRiskOnly: boolean
): boolean {
  return !!buildingClassifications.find((classification) => {
    return (
      classification.descriptionCode === descriptionCode &&
      classification.code === code &&
      lroMatches(classification, lessorsRiskOnly)
    );
  });
}

export function findClassification(
  category: string,
  descriptionCode: string
): BuildingClassificationInfo | null {
  if (category === '' || descriptionCode === '') {
    return null;
  }

  const fixture: BuildingClassificationInfo | undefined = buildingClassifications.find(
    (classification: BuildingClassificationInfo) => {
      return (
        classification.category === category && classification.descriptionCode === descriptionCode
      );
    }
  );
  return fixture || null;
}

// NOTE: This is an eager search by description - will return first result if class appears with multiple categories
export function findFixtureByDescriptionCode(
  descriptionCode: string
): BuildingClassificationInfo | null {
  if (descriptionCode === '') {
    return null;
  }

  const fixture: BuildingClassificationInfo | undefined = buildingClassifications.find(
    (classification: BuildingClassificationInfo) => {
      return classification.descriptionCode === descriptionCode;
    }
  );
  return fixture || null;
}

export const hasRestrictedCoverage = (
  descriptionCode: string,
  coverage: ClassRestrictedCoverage
): boolean => {
  const fixture: BuildingClassificationInfo | null = findFixtureByDescriptionCode(descriptionCode);
  return fixture !== null && fixture.classRestrictedCoverages.includes(coverage);
};

export const isContractor = (descriptionCode: string): boolean => {
  const classification: BuildingClassificationInfo | null =
    findFixtureByDescriptionCode(descriptionCode);
  return classification !== null && classification.category === 'Contractor';
};

export const isRestaurant = (descriptionCode: string): boolean => {
  const classification: BuildingClassificationInfo | null =
    findFixtureByDescriptionCode(descriptionCode);
  return classification !== null && RESTAURANT_CATEGORIES.includes(classification.category);
};

export const getSpoilageClass = (descriptionCode: string): SpoilageClass | null => {
  const fixture: BuildingClassificationInfo | null = findFixtureByDescriptionCode(descriptionCode);
  return fixture && fixture.spoilageClass ? fixture.spoilageClass : 'Other';
};
