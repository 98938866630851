import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxStripeModule } from 'ngx-stripe';

import { AppComponent } from 'app/app.component';
import { routing } from 'app/app.routes';
import { LoginModule } from 'app/shared/login/login.module';
import { NavigationModule } from 'app/core/components/left-navigation/navigation.module';
import { QuoteFormModule } from 'app/shared/quote-form/quote-form.module';
import { SharedModule } from 'app/shared/shared.module';
import { UnsubscribeModule } from 'app/shared/unsubscribe/unsubscribe.module';

import { ActivityPageComponent } from 'app/features/activity/pages/activity-page/activity-page.component';
import { AlertService } from 'app/core/services/alert.service';
import { InsuredAccountSummaryService } from 'app/features/insured-account/services/insured-account-summary.service';
import { Always } from 'app/core/guards/always.guard';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AttuneWcBasicInfoPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/basic-info/attune-wc-basic-info-page.component';
import { AttuneWcGuidelinesPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/guidelines/attune-wc-guidelines-page.component';
import { AttuneWcExecutivesPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/executive-elections/attune-wc-executives-page.component';
import { AttuneWcCoveragesCreditsPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/coverages-credits/attune-wc-coverages-credits-page.component';
import { AttuneWcClassCodeComponent } from 'app/workers-comp/attune/components/form-pages/quote/location/attune-wc-class-code.component';
import { AttuneWcExperienceModComponent } from './workers-comp/attune/components/form-pages/quote/coverages-credits/attune-wc-experience-mod.component';
import { AttuneWcLocationPageComponent } from 'app/workers-comp/attune/components/form-pages/quote/location/attune-wc-location-page.component';
import { AttuneWcQuoteFormComponent } from 'app/workers-comp/attune/components/form/quote/attune-wc-quote-form.component';
import { AttuneWcSubmissionSummaryComponent } from 'app/workers-comp/attune/components/submission-summary/attune-wc-submission-summary.component';
import { AttuneWcClassCodesService } from 'app/workers-comp/attune/services/attune-wc-class-code.service';
import { AttuneWcQuoteFormService } from 'app/workers-comp/attune/services/attune-wc-quote-form.service';
import { AttuneWCQuoteService } from 'app/workers-comp/attune/services/attune-wc-quote.service';
import { PreferenceService } from './shared/services/preference.service';
import { AttuneWcBindFormService } from './workers-comp/attune/services/attune-wc-bind-form.service';
import { AttuneWcBindQuoteComponent } from 'app/workers-comp/attune/components/form/bind/attune-wc-bind-quote.component';
import { AttuneWcBindPolicyDetailsComponent } from 'app/workers-comp/attune/components/form-pages/bind/policy-details/attune-wc-bind-policy-details.component';
import { AttuneWcBindPaymentDetailsComponent } from 'app/workers-comp/attune/components/form-pages/bind/payment-details/attune-wc-bind-payment-details.component';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { ActivityListBaseComponent } from 'app/features/activity/components/activity-list-base/activity-list-base.component';
import { BillingApiService } from 'app/shared/insured-account/billing-api.service';
import { InvoicesListService } from 'app/features/invoices/services/invoices-list.service';
import { InvoicesPageComponent } from 'app/features/invoices/pages/invoices-page/invoices-page.component';
import { BillingToastService } from 'app/shared/services/billing-toast.service';
import { AttuneBopBindFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-bind-form-page/attune-bop-bind-form-page.component';
import { BindGoalsService } from 'app/shared/services/bind-goals.service';
import { AttuneBopBindExcessQuoteUMUIMComponent } from 'app/features/attune-bop/components/attune-bop-bind-excess-umuim/attune-bop-bind-excess-umuim.component';
import { AttuneBopQuoteDraftFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-draft-form-page/attune-bop-quote-draft-form-page.component';
import { AttuneBopQuoteEditFormComponent } from 'app/features/attune-bop/components/attune-bop-quote-edit-form/attune-bop-quote-edit-form.component';
import { AttuneBopQuoteEditFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-edit-form-page/attune-bop-quote-edit-form-page.component';
import { AttuneBopQuoteDetailsPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-details-page/attune-bop-quote-details-page.component';
import { AttuneBopPriceReductionEstimateComponent } from 'app/features/attune-bop/components/attune-bop-price-reduction-estimate/attune-bop-price-reduction-estimate.component';
import { AttuneBopQuoteNewFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-quote-new-form-page/attune-bop-quote-new-form-page.component';
import { AttuneBopQuoteFormService } from 'app/features/attune-bop/services/attune-bop-quote-form.service';
import { AttuneBopQuoteLiabilityCoveragesFormService } from 'app/features/attune-bop/services/quote-services/liability-coverages-form.service';
import { AttuneBopQuoteService } from 'app/features/attune-bop/services/attune-bop-quote.service';
import { AttuneBopSubmissionDocumentPageComponent } from 'app/features/attune-bop/pages/attune-bop-submission-document-page/attune-bop-submission-document-page.component';
import { AttuneBopQuoteUpdateComponent } from 'app/features/attune-bop/components/attune-bop-quote-update/attune-bop-quote-update.component';
import { SentryService } from 'app/core/services/sentry.service';
import { AttuneBopBuildingClassificationService } from 'app/features/attune-bop/services/attune-bop-building-classification.service';
import { AttuneBopBuildingVerificationService } from 'app/features/attune-bop/services/attune-bop-building-verification.service';
import { AttuneBopBundleBindFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-bundle-bind-form-page/attune-bop-bundle-bind-form-page.component';
import { ActivityListCancellationComponent } from 'app/features/activity/components/activity-list-cancellation/activity-list-cancellation.component';
import { CarrierService } from 'app/bop/services/carrier.service';
import { ClasscodeMaskDirective } from 'app/workers-comp/employers/directives/classcode-mask.directive';
import { ActivityRecentBindsNearYouComponent } from 'app/features/activity/components/activity-recent-binds-near-you/activity-recent-binds-near-you.component';
import { CommissionInfoComponent } from './shared/settings/commission/commission-info.component';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { CoalitionCyberRiskProfileComponent } from './features/coalition/components/coalition-cyber-risk-profile/coalition-cyber-risk-profile.component';
import { DevModeOnly } from 'app/core/guards/dev-mode-only.guard';
import { DeviceTokenService } from 'app/core/services/device-token.service';
import { DraftQuoteService } from 'app/shared/services/draft-quote.service';
import { AttuneBopEligibilityService } from 'app/features/attune-bop/services/attune-bop-eligibility.service';
import { AttuneBopEndorseFaqsPanelComponent } from 'app/features/attune-bop/components/attune-bop-endorse-faqs-panel/attune-bop-endorse-faqs-panel.component';
import { AttuneBopEndorseFormPageComponent } from 'app/features/attune-bop/pages/attune-bop-endorse-form-page/attune-bop-endorse-form-page.component';
import { AttuneBopEndorseQuoteFormService } from 'app/features/attune-bop/services/attune-bop-endorse-quote-form.service';
import { AttuneBopEndorseQuoteService } from 'app/features/attune-bop/services/attune-bop-endorse-quote.service';
import { environment } from '../environments/environment';
import { AttuneBopExcessQuotePayloadService } from 'app/features/attune-bop/services/attune-bop-excess-quote-payload.service';
import { AttuneBopExcessQuoteService } from 'app/features/attune-bop/services/attune-bop-excess-quote.service';
import { EligibilityService } from 'app/shared/services/eligibility.service';
import { FeatureFlagEnabledGuard } from 'app/core/guards/feature-flag-enabled.guard';
import { FeatureFlagService } from 'app/core/services/feature-flag.service';
import { FileUploadService } from 'app/shared/services/file-upload.service';
import { FinancialInfoComponent } from 'app/shared/settings/financial-info.component';
import { FormDslMultiStepEditTestFormComponent } from 'app/shared/form-dsl-test/form-dsl-multi-step-edit-test-form.component';
import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';
import { FormDslMultiStepTestFormComponent } from 'app/shared/form-dsl-test/form-dsl-multi-step-test-form.component';
import { FormDslSearchService } from 'app/shared/form-dsl/services/form-dsl-search.service';
import { FormDslTestComponent } from 'app/shared/form-dsl-test/form-dsl-test.component';
import { FormDslTestFormService } from 'app/shared/form-dsl-test/form-dsl-test-form.service';
import { DigitalCarrierQuoteDetailsComponent } from 'app/features/digital-carrier/pages/digital-carrier-quote-details/digital-carrier-quote-details.component';
import { FullstoryService } from 'app/core/services/fullstory.service';
import { HiscoxGlQuotePageComponent } from 'app/features/hiscox/pages/hiscox-gl-quote-page/hiscox-gl-quote-page.component';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { GWService } from 'app/bop/services/gw.service';
import { SteppedFormRedirectPageComponent } from 'app/shared/stepped-form/stepped-form-account-redirect-page.component';
import { HabAdditionalCoveragesPageComponent } from 'app/hab/components/form-pages/hab-additional-coverages-page.component';
import { HabBindLossRunsPageComponent } from 'app/hab/components/form-pages/hab-bind-loss-runs-page.component';
import { HabBindPaymentDetailsComponent } from 'app/hab/components/form-pages/hab-bind-payment-details.component';
import { HabBindPolicyDetailsComponent } from 'app/hab/components/form-pages/hab-bind-policy-details.component';
import { HabBindQuoteComponent } from 'app/hab/components/form/hab-bind-quote.component';
import { HabBindQuoteService } from 'app/hab/services/hab-bind-quote.service';
import { HabBuildingPageComponent } from 'app/hab/components/form-pages/hab-building-page.component';
import { HabGeneralLiabilityPageComponent } from 'app/hab/components/form-pages/hab-general-liability-page.component';
import { HabGuidelinesPageComponent } from 'app/hab/components/form-pages/hab-guidelines-page.component';
import { HabLossHistoryPageComponent } from 'app/hab/components/form-pages/hab-loss-history-page.component';
import { HabPolicyDetailsPageComponent } from 'app/hab/components/form-pages/hab-policy-details-page.component';
import { HabPolicyPaneComponent } from 'app/hab/components/policy-pane/hab-policy-pane.component';
import { HabQuoteFormComponent } from 'app/hab/components/form/hab-quote-form.component';
import { HabQuoteFormService } from 'app/hab/services/hab-quote-form.service';
import { HabSectionedGuidelinesComponent } from 'app/hab/components/sectioned-guidelines/hab-sectioned-guidelines.component';
import { SupportHelpCenterPageComponent } from 'app/features/support/pages/support-help-center-page/support-help-center-page.component';
import { SupportPageComponent } from 'app/features/support/pages/support-page/support-page.component';
import { SupportHelpCenterFormComponent } from './features/support/components/support-help-center-form/support-help-center-form.component';
import { HiscoxBindFormService } from 'app/features/hiscox/services/hiscox-bind-form.service';
import { HiscoxBindPaymentDetailsPageComponent } from 'app/features/hiscox/pages/hiscox-bind-payment-details-page/hiscox-bind-payment-details-page.component';
import { HiscoxBindPaymentPlanPageComponent } from 'app/features/hiscox/pages/hiscox-bind-payment-plan-page/hiscox-bind-payment-plan-page.component';
import { HiscoxBindPageComponent } from 'app/features/hiscox/pages/hiscox-bind-page/hiscox-bind-page.component';
import { HiscoxQuoteFormComponent } from 'app/features/hiscox/components/hiscox-quote-form/hiscox-quote-form.component';
import { HiscoxQuoteDetailsPageComponent } from 'app/features/hiscox/pages/hiscox-quote-details-page/hiscox-quote-details-page.component';
import { HiscoxQuoteFormService } from 'app/features/hiscox/services/hiscox-quote-form.service';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { HTMLElementDirective } from 'app/shared/directives/html-element.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { InvoicesInsuredPageComponent } from 'app/features/invoices/pages/invoices-insured-page/invoices-insured-page.component';
import { InternalToolsService } from 'app/shared/services/internal-tools.service';
import { Interceptor } from 'app/core/interceptors/http.interceptor';
import { InvoicesBannerService } from 'app/features/invoices/services/invoices-banner.service';
import { InvoicesPolicyPageComponent } from 'app/features/invoices/pages/invoices-policy-page/invoices-policy-page.component';
import { InvoicesMetadataComponent } from 'app/features/invoices/components/invoices-metadata/invoices-metadata.component';
import { InvoicesPaymentService } from 'app/features/invoices/services/invoices-payment.service';
import { InvoicesService } from 'app/features/invoices/services/invoices.service';
import { LibertyMutualBindFormService } from 'app/features/liberty-mutual/services/liberty-mutual-bind-form.service';
import { LibertyMutualBindQuoteService } from 'app/features/liberty-mutual/services/liberty-mutual-bind-quote.service';
import { LibertyMutualBopFormComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bop-form/liberty-mutual-bop-form.component';
import { LibertyMutualBindQuoteFormComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bind-quote-form/liberty-mutual-bind-quote-form.component';
import { LibertyMutualBindPaymentPlanComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bind-payment-plan/liberty-mutual-bind-payment-plan.component';
import { LibertyMutualBindPaymentDetailsComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-bind-payment-details/liberty-mutual-bind-payment-details.component';
import { LibertyMutualCpspQuoteFormComponent } from 'app/features/liberty-mutual/pages/liberty-mutual-cpsp-quote-form/liberty-mutual-cpsp-quote-form.component';
import { LimitsService } from 'app/shared/services/limits.service';
import { SupportLiveChatPageComponent } from 'app/features/support/pages/support-live-chat-page/support-live-chat-page.component';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { ManageSettingsComponent } from 'app/shared/settings/settings.component';
import { NaicsService } from 'app/shared/services/naics.service';
import { NewInformationalService } from 'app/shared/services/new-informational.service';
import { OnboardingClassificationPageComponent } from 'app/features/onboarding/pages/onboarding-classification-page/onboarding-classification-page.component';
import { OnboardingPageComponent } from 'app/features/onboarding/pages/onboarding-page/onboarding-page.component';
import { OnboardingFormService } from 'app/features/onboarding/services/onboarding-form.service';
import { OnboardingIntroductionPageComponent } from 'app/features/onboarding/pages/onboarding-introduction-page/onboarding-introduction-page.component';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import { InvoicesPaginationService } from './features/invoices/services/invoices-pagination.service';
import { PasswordLinkService } from 'app/core/services/password-link.service';
import { PasswordResetRedirectComponent } from 'app/shared/login/password-reset-redirect.component';
import { InvoicesPaymentPlanService } from './features/invoices/services/invoices-payment-plan.service';
import { InvoicesPaymentPlanFormComponent } from './features/invoices/components/invoices-payment-plan-form/invoices-payment-plan-form.component';
import { InvoicesPaymentReceiptPageComponent } from 'app/features/invoices/pages/invoices-payment-receipt-page/invoices-payment-receipt-page.component';
import { ActivityListPendingCancellationComponent } from 'app/features/activity/components/activity-list-pending-cancellation/activity-list-pending-cancellation.component';
import { HiscoxPlQuotePageComponent } from 'app/features/hiscox/pages/hiscox-pl-quote-page/hiscox-pl-quote-page.component';
import { ActivityOverviewPageComponent } from 'app/features/activity/pages/activity-overview-page/activity-overview-page.component';
import { PolicyAutomationLandingService } from 'app/bop/services/policy-automation-landing.service';
import { PrefillService } from 'app/shared/services/prefill.service';
import { ProductFeedbackService } from 'app/shared/services/product-feedback.service';
import { AttuneBopQuoteFormDebuggerComponent } from 'app/features/attune-bop/components/attune-bop-quote-form-debugger/attune-bop-quote-form-debugger.component';
import { HiscoxQuoteFormSidebarInspectionComponent } from 'app/features/hiscox/components/hiscox-quote-form-sidebar-inspection/hiscox-quote-form-sidebar-inspection.component';
import { AttuneBopQuotePayloadService } from 'app/features/attune-bop/services/attune-bop-quote-payload.service';
import { QuoteSidebarComponent } from 'app/shared/quote-sidebar/quote-sidebar.component';
import { QuoteSteppedSidebarComponent } from 'app/shared/quote-stepped-sidebar/quote-stepped-sidebar.component';
import { HiscoxQuoteFormSidebarComponent } from 'app/features/hiscox/components/hiscox-quote-form-sidebar/hiscox-quote-form-sidebar.component';
import { LibertyMutualQuoteFormSidebarComponent } from 'app/features/liberty-mutual/components/liberty-mutual-quote-form-sidebar/liberty-mutual-quote-form-sidebar.component';
import { ActivityListQuotesComponent } from 'app/features/activity/components/activity-list-quotes/activity-list-quotes.component';
import { RecentActivitySummaryService } from 'app/shared/services/recent-activity-summary.service';
import { ActivityIncentiveReferralsComponent } from 'app/features/activity/components/activity-incentive-referrals/activity-incentive-referrals.component';
import { ReferralsService } from 'app/shared/services/referrals.service';
import { AttuneBopReplacementCoverageDocumentPageComponent } from 'app/features/attune-bop/pages/attune-bop-replacement-coverage-document-page/attune-bop-replacement-coverage-document-page.component';
import { ActivityListRenewalsComponent } from 'app/features/activity/components/activity-list-renewals/activity-list-renewals.component';
import { AttuneBopQuoteRequestService } from 'app/features/attune-bop/services/attune-bop-quote-request.service';
import { ResetPasswordService } from 'app/core/services/reset-password.service';
import { RewardsChecklistComponent } from 'app/features/rewards/components/rewards-checklist/rewards-checklist.component';
import { RewardsPageComponent } from 'app/features/rewards/pages/rewards-page/rewards-page.component';
import { RewardsService } from 'app/shared/services/rewards.service';
import { ActivityRolloverPageComponent } from 'app/features/activity/pages/activity-rollover-page/activity-rollover-page.component';
import { InvoicesSchedulePageComponent } from 'app/features/invoices/pages/invoices-schedule-page/invoices-schedule-page.component';
import { ActivityIncentiveStreaksComponent } from './features/activity/components/activity-incentive-streaks/activity-incentive-streaks.component';
import { SupportEnhancementPageComponent } from 'app/features/support/pages/support-enhancement-page/support-enhancement-page.component';
import { SupportEnhancementService } from 'app/features/support/services/support-enhancement.service';
import { SupportNewsfeedPageComponent } from 'app/features/support/pages/support-newsfeed-page/support-newsfeed-page.component';
import { SupportNewsfeedService } from 'app/features/support/services/support-newsfeed.service';
import { TeamSettingsComponent } from 'app/shared/settings/team-settings.component';
import { InvoicesTermsAndConditionsPageComponent } from 'app/features/invoices/pages/invoices-terms-and-conditions-page/invoices-terms-and-conditions-page.component';
import { UserApiService } from 'app/core/services/user-api-service';
import { UserService } from 'app/core/services/user.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import { UWAlertService } from 'app/shared/services/uw-alert.service';
import { V3QuoteService } from 'app/shared/services/v3-quote-service';
import { VersionCheckService } from 'app/shared/services/version-check.service';
import { WcBindFormService } from 'app/workers-comp/employers/services/workers-comp-bind-form.service';
import { WcBindQuoteComponent } from 'app/workers-comp/employers/components/wc-bind-quote.component';
import { WcClassCodesService } from 'app/workers-comp/employers/services/workers-comp-class-codes.service';
import { WcClassificationComponent } from 'app/workers-comp/employers/components/quote-form/wc-classification.component';
import { WcLocationFormComponent } from 'app/workers-comp/employers/components/quote-form/wc-location-form.component';
import { WcLossFormComponent } from 'app/workers-comp/employers/components/quote-form/wc-loss-form.component';
import { WcPolicyInfoFormComponent } from 'app/workers-comp/employers/components/quote-form/wc-policy-info-form.component';
import { WcPolicyPaneComponent } from 'app/workers-comp/employers/components/wc-policy-pane.component';
import { WcQuoteFormComponent } from 'app/workers-comp/employers/components/wc-quote-form.component';
import { WcQuoteFormService } from 'app/workers-comp/employers/services/workers-comp-form.service';
import { WcQuoteService } from 'app/workers-comp/employers/services/workers-comp-quote.service';
import { WcQuoteSubmissionTranslator } from 'app/workers-comp/employers/services/workers-comp-quote-submission-translator.service';
import { WcUnderwritingFormComponent } from 'app/workers-comp/employers/components/quote-form/wc-underwriting-form.component';
import { ZendeskApiService } from 'app/shared/services/zendesk-api.service';
import { ZendeskService } from 'app/shared/services/zendesk.service';
import { ZendeskWidgetService } from 'app/shared/services/zendesk-widget.service';
import { AttuneBopEstimatedReplacementCostModalComponent } from './features/attune-bop/components/attune-bop-estimated-replacement-cost-modal/attune-bop-estimated-replacement-cost-modal.component';
import { CoalitionCyberBindFormComponent } from './features/coalition/components/coalition-cyber-bind-form/coalition-cyber-bind-form.component';
import { CoalitionCyberBindFormContainerComponent } from './features/coalition/components/coalition-cyber-bind-form-container/coalition-cyber-bind-form-container.component';
import { PolicyAutomationLandingComponent } from 'app/bop/components/policy-automation-landing/policy-automation-landing.component';
import { EndorseLandingComponent } from 'app/bop/components/policy-automation-landing/endorse-landing.component';
import { CancelLandingComponent } from 'app/bop/components/policy-automation-landing/cancel-landing.component';
import { BundleBindSelectionFormComponent } from './features/bundle/components/bundle-bind-selection-form/bundle-bind-selection-form.component';
import { AttuneBopCyberPriceComparisonTableComponent } from './features/attune-bop/components/attune-bop-cyber-price-comparison-table/attune-bop-cyber-price-comparison-table.component';
import { ActivityIncentiveCyberBindsComponent } from './features/activity/components/activity-incentive-cyber-binds/activity-incentive-cyber-binds.component';
import { CyberBindsRewardsService } from './shared/services/cyber-binds-rewards.service';
import { BundleService } from './features/bundle/services/bundle.service';
import { BundleQuoteDetailsComponent } from './features/bundle/pages/bundle-quote-details/bundle-quote-details.component';
import { DropdownSubsectionComponent } from './features/bundle/components/bundle-dropdown-subsection/dropdown-subsection.component';
import { WcQuotePaneComponent } from './workers-comp/employers/components/wc-quote-pane.component';
import { UserAttributesService } from './core/services/user-attributes.service';
import { AppetiteCheckerPageComponent } from './features/appetite-checker/pages/appetite-checker-page/appetite-checker-page.component';
import { AppetiteSearchResultsComponent } from './features/appetite-checker/components/appetite-checker-search-results/appetite-search-results.component';
import { HiscoxBindServicingInfoPageComponent } from './features/hiscox/pages/hiscox-bind-servicing-info-page/hiscox-bind-servicing-info-page.component';
import { CombinedCommissionStatementsTableComponent } from './shared/settings/commission/combined-commission-statements-table.component';
import { IncentivesNewUsersService } from './shared/incentives/services/incentives-new-users.service';
import { CommissionStatementComponent } from './shared/settings/commission/commission-statement.component';
import { RewardsActivityComponent } from './features/rewards/components/rewards-activity/rewards-activity.component';
import { CoalitionCyberQuoteFormComponent } from './features/coalition/components/coalition-cyber-quote-form/coalition-cyber-quote-form.component';
import { AiCheckerPageComponent } from './features/ai-checker/pages/ai-checker-page/ai-checker-page.component';
import { AttuneWcEligibilityService } from './workers-comp/attune/services/attune-wc-eligibility.service';
import { AttuneWcQuotePaneComponent } from './workers-comp/attune/components/quote-details-pane/attune-wc-quote-pane.component';
import { CallbackComponent } from './callback.component';
import { SegmentService } from './core/services/segment.service';
import { FaqSearchComponent } from './features/support/components/faq-search/faq-search.component';
import { DocumentService } from './features/documents/services/document.service';
import { DocumentDownloadComponent } from './features/documents/components/document-download/document-download.component';
import { BundleQuoteReviewModalComponent } from './shared/bundle-quote-review-modal/bundle-quote-review-modal.component';
import { RenewalPolicyDetailsPaneComponent } from './shared/insured-account/renewal-policy-details-pane.component';
import { BoundPolicyDetailsPaneComponent } from './shared/insured-account/bound-policy-details-pane.component';
import { PostBindServicingInformationComponent } from './shared/post-bind-servicing-information/post-bind-servicing-information.component';
import { InsuredAccountBillingCardComponent } from './features/insured-account/components/insured-account-billing-card/insured-account-billing-card.component';
import { AccountEditFormComponent } from './features/insured-account/components/insured-account-edit-form/account-edit-form.component';
import { EditQuoteNameComponent } from './shared/insured-account/edit-quote-name.component';
import { DashboardLossRunsUploadComponent } from './shared/file-upload/dashboard-loss-runs-upload.component';
import { InsuredAccountComponent } from './features/insured-account/pages/insured-account/insured-account.component';
import { InsuredAccountListComponent } from './features/insured-account/components/insured-account-list/insured-account-list.component';
import { InsuredAccountNewFormComponent } from './features/insured-account/pages/insured-account-form/insured-account-new-form.component';
import { InsuredAccountQuotesComponent } from './features/insured-account/pages/insured-account-quotes/insured-account-quotes.component';
import { InsuredAccountNewQuoteComponent } from './features/insured-account/pages/insured-account-new-quote/insured-account-new-quote.component';
import { InvoiceRedirectPageComponent } from './features/invoices/pages/invoices-redirect-page/invoice-redirect-page.component';
import { IncentivesNewUsersComponent } from './shared/incentives/components/incentives-new-users.component';
import { IncentivesNewUsersProgressComponent } from './shared/incentives/components/incentives-new-users-progress.component';
import { PolicyDetailsComponent } from './shared/insured-account/policy-details.component';
import { PolicyDetailsPaneComponent } from './shared/insured-account/policy-details-pane.component';
import { PolicyDetailsRedirectComponent } from './shared/insured-account/policy-details-redirect.component';
import { InsuredAccountProductCardComponent } from './features/insured-account/components/insured-account-product-card/insured-account-product-card.component';
import { NeptuneFloodDetailsComponent } from './features/neptune/components/neptune-flood-details/neptune-flood-details.component';
import { NeptuneFloodHelperModalComponent } from './features/neptune/components/neptune-flood-helper-modal/neptune-flood-helper-modal.component';
import { QuoteServicingInformationComponent } from './shared/quote-servicing-information/quote-servicing-information.component';
import { BookMixInsightsComponent } from './features/activity/components/activity-book-mix-insights/activity-book-mix-insights.component';
import { BookMixProgressComponent } from './features/activity/components/activity-book-mix-progress/activity-book-mix-progress.component';
import { ActivityBookMixProgressBarComponent } from './features/activity/components/activity-book-mix-progress-bar/activity-book-mix-progress-bar.component';
import { ActivityPoliciesDropDrownListComponent } from './features/activity/components/activity-policies-drop-drown-list/activity-policies-drop-drown-list.component';
import { BookMixService } from 'app/shared/services/book-mix.service';
import { InsuredAccountProductEligibilityCardComponent } from './features/insured-account/components/insured-account-product-eligibility-card/insured-account-product-eligibility-card.component';
import { AttuneBopQuoteAdditionalCoveragesFormService } from './features/attune-bop/services/quote-services/additional-coverages-form.service';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    InsuredAccountBillingCardComponent,
    AccountEditFormComponent,
    EditQuoteNameComponent,
    DashboardLossRunsUploadComponent,
    InsuredAccountComponent,
    InsuredAccountListComponent,
    InsuredAccountNewFormComponent,
    InsuredAccountQuotesComponent,
    InsuredAccountNewQuoteComponent,
    InvoiceRedirectPageComponent,
    IncentivesNewUsersComponent,
    IncentivesNewUsersProgressComponent,
    PolicyDetailsComponent,
    PolicyDetailsPaneComponent,
    PolicyDetailsRedirectComponent,
    InsuredAccountProductCardComponent,
    InsuredAccountProductEligibilityCardComponent,
    NeptuneFloodDetailsComponent,
    NeptuneFloodHelperModalComponent,
    QuoteServicingInformationComponent,
    PostBindServicingInformationComponent,
    ActivityPageComponent,
    AppComponent,
    AppetiteCheckerPageComponent,
    AppetiteSearchResultsComponent,
    AttuneWcBasicInfoPageComponent,
    AttuneWcGuidelinesPageComponent,
    AttuneWcBindQuoteComponent,
    AttuneWcBindPaymentDetailsComponent,
    AttuneWcBindPolicyDetailsComponent,
    AttuneWcClassCodeComponent,
    AttuneWcExperienceModComponent,
    AttuneWcExecutivesPageComponent,
    AttuneWcLocationPageComponent,
    AttuneWcQuoteFormComponent,
    AttuneWcCoveragesCreditsPageComponent,
    AttuneWcSubmissionSummaryComponent,
    AttuneWcQuotePaneComponent,
    ActivityListBaseComponent,
    InvoicesPageComponent,
    AttuneBopBindFormPageComponent,
    AttuneBopBindExcessQuoteUMUIMComponent,
    ActivityIncentiveCyberBindsComponent,
    AttuneBopQuoteDraftFormPageComponent,
    AttuneBopQuoteEditFormComponent,
    AttuneBopQuoteEditFormPageComponent,
    AttuneBopQuoteDetailsPageComponent,
    AttuneBopPriceReductionEstimateComponent,
    AttuneBopQuoteNewFormPageComponent,
    AttuneBopSubmissionDocumentPageComponent,
    AttuneBopQuoteUpdateComponent,
    AttuneBopBundleBindFormPageComponent,
    BundleBindSelectionFormComponent,
    BundleQuoteDetailsComponent,
    BundleQuoteReviewModalComponent,
    DropdownSubsectionComponent,
    CallbackComponent,
    CoalitionCyberQuoteFormComponent,
    CancelLandingComponent,
    ActivityListCancellationComponent,
    ClasscodeMaskDirective,
    ActivityRecentBindsNearYouComponent,
    CommissionInfoComponent,
    CommissionStatementComponent,
    AttuneBopCyberPriceComparisonTableComponent,
    DigitalCarrierQuoteDetailsComponent,
    DocumentDownloadComponent,
    AttuneBopEndorseFaqsPanelComponent,
    FaqSearchComponent,
    AttuneBopEndorseFormPageComponent,
    EndorseLandingComponent,
    AttuneBopEstimatedReplacementCostModalComponent,
    FinancialInfoComponent,
    FormDslMultiStepEditTestFormComponent,
    FormDslSteppedFormComponent,
    FormDslMultiStepTestFormComponent,
    FormDslTestComponent,
    HiscoxGlQuotePageComponent,
    SteppedFormRedirectPageComponent,
    HabAdditionalCoveragesPageComponent,
    HabBindLossRunsPageComponent,
    HabBindPaymentDetailsComponent,
    HabBindPolicyDetailsComponent,
    HabBindQuoteComponent,
    HabBuildingPageComponent,
    HabGeneralLiabilityPageComponent,
    HabGuidelinesPageComponent,
    HabLossHistoryPageComponent,
    HabPolicyDetailsPageComponent,
    HabPolicyPaneComponent,
    HabQuoteFormComponent,
    HabSectionedGuidelinesComponent,
    SupportHelpCenterPageComponent,
    SupportPageComponent,
    SupportHelpCenterFormComponent,
    PolicyAutomationLandingComponent,
    HiscoxBindPaymentDetailsPageComponent,
    HiscoxBindPaymentPlanPageComponent,
    HiscoxBindPageComponent,
    HiscoxQuoteFormComponent,
    HiscoxQuoteDetailsPageComponent,
    HTMLElementDirective,
    InvoicesInsuredPageComponent,
    InvoicesPolicyPageComponent,
    InvoicesMetadataComponent,
    SupportLiveChatPageComponent,
    ManageSettingsComponent,
    OnboardingClassificationPageComponent,
    OnboardingPageComponent,
    OnboardingIntroductionPageComponent,
    PasswordResetRedirectComponent,
    InvoicesPaymentPlanFormComponent,
    InvoicesPaymentReceiptPageComponent,
    ActivityListPendingCancellationComponent,
    HiscoxPlQuotePageComponent,
    ActivityOverviewPageComponent,
    AttuneBopQuoteFormDebuggerComponent,
    HiscoxQuoteFormSidebarInspectionComponent,
    QuoteSidebarComponent,
    QuoteSteppedSidebarComponent,
    HiscoxQuoteFormSidebarComponent,
    ActivityListQuotesComponent,
    ActivityIncentiveReferralsComponent,
    ActivityListRenewalsComponent,
    RenewalPolicyDetailsPaneComponent,
    BoundPolicyDetailsPaneComponent,
    AttuneBopReplacementCoverageDocumentPageComponent,
    RewardsChecklistComponent,
    RewardsPageComponent,
    RewardsActivityComponent,
    ActivityRolloverPageComponent,
    InvoicesSchedulePageComponent,
    ActivityIncentiveStreaksComponent,
    SupportEnhancementPageComponent,
    SupportNewsfeedPageComponent,
    TeamSettingsComponent,
    InvoicesTermsAndConditionsPageComponent,
    WcBindQuoteComponent,
    WcClassificationComponent,
    WcLocationFormComponent,
    WcLossFormComponent,
    WcPolicyInfoFormComponent,
    WcPolicyPaneComponent,
    WcQuotePaneComponent,
    WcQuoteFormComponent,
    WcUnderwritingFormComponent,
    LibertyMutualBopFormComponent,
    LibertyMutualBindQuoteFormComponent,
    LibertyMutualBindPaymentPlanComponent,
    LibertyMutualBindPaymentDetailsComponent,
    LibertyMutualQuoteFormSidebarComponent,
    LibertyMutualCpspQuoteFormComponent,
    HiscoxBindServicingInfoPageComponent,
    CoalitionCyberRiskProfileComponent,
    CoalitionCyberBindFormComponent,
    CoalitionCyberBindFormContainerComponent,
    CombinedCommissionStatementsTableComponent,
    AiCheckerPageComponent,
    BookMixInsightsComponent,
    BookMixProgressComponent,
    ActivityBookMixProgressBarComponent,
    ActivityPoliciesDropDrownListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LoginModule,
    NavigationModule,
    NgbModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    QuoteFormModule,
    ReactiveFormsModule,
    routing,
    SharedModule,
    UnsubscribeModule,
  ],
  providers: [
    AlertService,
    InsuredAccountSummaryService,
    DocumentService,
    Always,
    AmplitudeService,
    AttuneWcBindFormService,
    AttuneWcClassCodesService,
    AttuneWCQuoteService,
    AttuneWcQuoteFormService,
    AuthenticationService,
    AuthGuard,
    BillingApiService,
    InvoicesListService,
    BillingToastService,
    BindGoalsService,
    AttuneBopQuoteFormService,
    AttuneBopQuoteLiabilityCoveragesFormService,
    AttuneBopQuoteAdditionalCoveragesFormService,
    AttuneBopQuoteService,
    SentryService,
    AttuneBopBuildingClassificationService,
    AttuneBopBuildingVerificationService,
    CarrierService,
    CurrentUserService,
    CyberBindsRewardsService,
    DevModeOnly,
    DeviceTokenService,
    DraftQuoteService,
    AttuneBopEligibilityService,
    AttuneBopEndorseQuoteFormService,
    AttuneBopEndorseQuoteService,
    AttuneWcEligibilityService,
    AttuneBopExcessQuotePayloadService,
    AttuneBopExcessQuoteService,
    EligibilityService,
    FeatureFlagEnabledGuard,
    FeatureFlagService,
    FileUploadService,
    FormDslSteppedFormService,
    FormDslSearchService,
    FormDslTestFormService,
    FullstoryService,
    GWBindService,
    GWService,
    HabBindQuoteService,
    HabQuoteFormService,
    HiscoxBindFormService,
    HiscoxQuoteFormService,
    HiscoxQuoteService,
    HttpClientModule,
    InformService,
    InsuredAccountService,
    InternalToolsService,
    BundleService,
    InvoicesBannerService,
    InvoicesPaginationService,
    InvoicesPaymentPlanService,
    IncentivesNewUsersService,
    InvoicesPaymentService,
    InvoicesService,
    LibertyMutualBindFormService,
    LibertyMutualBindQuoteService,
    LimitsService,
    LocalStorageService,
    NaicsService,
    PreferenceService,
    NewInformationalService,
    OnboardingFormService,
    OnboardingService,
    OrganizationTypeService,
    InvoicesPaginationService,
    PasswordLinkService,
    InvoicesPaymentPlanService,
    PolicyAutomationLandingService,
    PrefillService,
    ProductFeedbackService,
    AttuneBopQuotePayloadService,
    RecentActivitySummaryService,
    ReferralsService,
    AttuneBopQuoteRequestService,
    ResetPasswordService,
    RewardsService,
    SegmentService,
    SupportEnhancementService,
    SupportNewsfeedService,
    UserApiService,
    UserAttributesService,
    UserService,
    UsStateService,
    UWAlertService,
    V3QuoteService,
    VersionCheckService,
    WcBindFormService,
    WcClassCodesService,
    WcQuoteFormService,
    WcQuoteService,
    WcQuoteSubmissionTranslator,
    ZendeskService,
    ZendeskApiService,
    ZendeskWidgetService,
    BookMixService,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
    },
    { provide: 'Window', useValue: window },
  ],
})
export class AppModule {}
