import { Injectable } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  CoalitionCyberFormDataRaw,
  CoalitionCyberQuestion,
  CyberQuestionEnablementFlags,
  EngagedIndustriesNestedQuestion,
  FirstPartyCoverageNestedQuestion,
  NAICS_CODE,
  UpsellEarlyDeclineKey,
} from '../../../coalition/models/cyber-typings.model';
import { validateNetProfitIsLessThanCompanyRevenue } from '../../../coalition/models/cyber-validators.model';
import {
  AVAILABLE_BUSINESS_TYPES,
  BOP_V2_CYBER_OPTIONS,
  BOP_V2_CYBER_SPLIT_STATES,
  BOP_V2_DEDUCTIBLE_OPTIONS,
  BOP_V2_EPLI_AGG_LIMIT_OPTIONS,
  BOP_V2_EPLI_AGG_LIMIT_OPTIONS_HIGH,
  BOP_V2_EPLI_AGG_LIMITS_HIGH,
  BOP_V2_EPLI_AGG_LIMITS_NORMAL,
  BOP_V2_EPLI_DEFAULT_OPTIONS,
  BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS,
  BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS_HIGH,
  BOP_V2_EPLI_HIGH_AGG_LIMIT_STATES,
  BOP_V2_EPLI_HIGH_SPLIT_LIMIT_STATES,
  BOP_V2_EPLI_INELIGIBLE_STATES,
  BOP_V2_EPLI_NORMAL_SPLIT_LIMIT_STATES,
  BOP_V2_EPLI_SPLIT_LIMITS_HIGH,
  BOP_V2_EPLI_SPLIT_LIMITS_NORMAL,
  BOP_V2_EPLI_SPLIT_STATES,
  BOP_V2_STATES_WITHOUT_2500_EPLI_DEDUCTIBLE,
  BOP_V3_EPLI_INELIGIBLE_STATES,
  CyberProductType,
  EPLI_EXCEPTIONS,
  EpliOptions,
  EXCLUDED_V2_CYBER_CODES,
} from '../../models/constants';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  startWith,
  merge as observableMerge,
  Subject,
  map,
  distinctUntilChanged,
  Observable,
} from 'rxjs';
import { AdditionalInsuredsMixin } from '../../mixins/additional-insureds.mixin';
import { BaseFormService } from '../../../../shared/form-dsl/services/form-dsl-stepped-form-base.service';
import {
  clampControl,
  enableDisableControl,
  getControl,
  getFormArray,
  getFormGroup,
  removeAllFromFormArray,
  validateCurrencyGreaterThanValue,
  validateCurrencyGreaterThanZero,
} from '../../../../shared/helpers/form-helpers';
import {
  ADMITTED_MAX_REVENUE,
  CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M,
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  ESSENTIAL_COVERAGES,
  ESSENTIAL_DEFAULT_RETENTION_INDEX,
  MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
  MOST_POPULAR_COVERAGES,
  MOST_POPULAR_DEFAULT_RETENTION_INDEX,
  NAICS_CODES_TO_INDUSTRY_IDS,
} from '../../../coalition/models/cyber-constants.model';
import { ClassCodeSelection, ProductAvailability } from '../../../digital-carrier/models/types';
import { entries, isEqual, omit, pick, pickBy, times } from 'lodash';
import { parseMoney } from '../../../../shared/helpers/number-format-helpers';
import {
  formatFormFieldValues,
  getEmployeeCountValue,
  removeIneligibleCyberCoverages,
} from '../../../coalition/models/cyber-form-helpers.model';

@Injectable()
export class AttuneBopQuoteAdditionalCoveragesFormService extends AdditionalInsuredsMixin(
  BaseFormService
) {
  public industryIdSubject: BehaviorSubject<number | null> = new BehaviorSubject(null);
  public naicsCodeSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
  public bopExposureSubject: BehaviorSubject<BopExposureInfo | null> = new BehaviorSubject(null);
  private isEligibleForCyberSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly baseState$: Observable<string>;
  private readonly bopVersion$: Observable<BopVersion>;
  private readonly locations$: Observable<BopLocation[]>;
  private readonly isEligibleForCyber$: Observable<boolean>;

  public cyberIndustryFlagsSubject: BehaviorSubject<CyberQuestionEnablementFlags | null> =
    new BehaviorSubject(null);
  public productAvailabilitiesSubject: Subject<ProductAvailability[]> = new Subject<
    ProductAvailability[]
  >();

  public epliOptions: EpliOptions = BOP_V2_EPLI_DEFAULT_OPTIONS;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    const bopExposure$ = this.bopExposureSubject
      .asObservable()
      .pipe(filter((bopExposure): bopExposure is BopExposureInfo => bopExposure !== null));
    this.baseState$ = bopExposure$.pipe(
      map(({ baseState }) => baseState),
      filter((baseState): baseState is string => baseState !== null),
      distinctUntilChanged()
    );
    this.bopVersion$ = bopExposure$.pipe(
      map(({ bopVersion }) => bopVersion),
      filter((bopVersion): bopVersion is BopVersion => bopVersion !== null),
      distinctUntilChanged()
    );
    this.locations$ = bopExposure$.pipe(
      map(({ locations }) => locations),
      distinctUntilChanged((prev, cur) => isEqual(prev, cur))
    );
    this.isEligibleForCyber$ = this.isEligibleForCyberSubject
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  public initializeForm() {
    this.createAdditionalCoveragesForm();
    this.subscribeValueChanges();

    return this.form;
  }

  private createAdditionalCoveragesForm() {
    this.form = this.formBuilder.group({
      employmentRelatedPracticesLiabilityCoverage: this.formBuilder.group({
        optedIn: [false, Validators.required],
        aggregateLimitV2: [25000, Validators.required],
        defenseLimitV2: [12500, Validators.required],
        deductibleV2: [5000, [Validators.required, this.epliDeductibleV2Validator()]],
        perLocationPartTimeEmployees: this.formBuilder.array([0]), // FIXME: Validate <= numEmployees
      }),
      acceptCertifiedActsOfTerrorismCoverage: [false, Validators.required],
      limitForEmployeeDishonesty: [5000, Validators.required],
      hasAdditionalInsuredBusinesses: [false, Validators.required],
      additionalInsuredBusinesses: this.formBuilder.array([], Validators.required),
      cyberLiabilityCoverage: this.cyberLiabilityFormGroup(),
      hasWaiversOfSubrogation: [false, Validators.required],
    });
  }

  private cyberLiabilityFormGroup() {
    return this.formBuilder.group(
      {
        optedIn: [false, Validators.required],
        selectedCyberCoverage: ['none', Validators.required],
        // Controls for coverage endorsement
        aggregateLimit: [50000, Validators.required],
        firstPartyLimit: [25000, Validators.required],
        deductible: [1000, Validators.required],
        // Controls for standalone Coalition policy
        [CoalitionCyberQuestion.COMPANY_INDUSTRY_ID]: [null, Validators.required],
        [CoalitionCyberQuestion.HAS_TECH_EO]: [null, Validators.required],
        [CoalitionCyberQuestion.COMPANY_REVENUE]: [
          null,
          [Validators.required, validateCurrencyGreaterThanZero],
        ],
        [CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE]: [
          null,
          [Validators.required, validateCurrencyGreaterThanZero],
        ],
        [CoalitionCyberQuestion.AGGREGATE_LIMIT]: [null, Validators.required],
        [CoalitionCyberQuestion.DEFAULT_RETENTION]: [null, Validators.required],
        [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS]: [null, Validators.required],
        [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT]: [null, Validators.required],
        [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT]: [null, Validators.required],
        [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K]: [
          null,
          [Validators.required, validateCurrencyGreaterThanValue(500_000)],
        ],
        [CoalitionCyberQuestion.PRIOR_CLAIMS]: [null, Validators.required],
        [CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION]: [null, Validators.required],
        [CoalitionCyberQuestion.DUAL_CONTROL_5K]: [null, Validators.required],
        [CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL]: [null, Validators.required],
        [CoalitionCyberQuestion.MFA_EMAIL]: [null, Validators.required],
        [CoalitionCyberQuestion.MFA_REMOTE_ACCESS]: [null, Validators.required],
        [CoalitionCyberQuestion.MFA_VPN]: [null, Validators.required],
        [CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS]: [null, Validators.required],
        [CoalitionCyberQuestion.ENGAGED_INDUSTRIES]: this.formBuilder.group({
          [EngagedIndustriesNestedQuestion.ADULT]: [null],
          [EngagedIndustriesNestedQuestion.CRYPTO_BLOCKCHAIN]: [null],
          [EngagedIndustriesNestedQuestion.GAMBLING]: [null],
          [EngagedIndustriesNestedQuestion.MSP_MSSP_RNASP]: [null],
          [EngagedIndustriesNestedQuestion.PAYMENT_PROCESSING]: [null],
        }),
      },
      {
        validators: [validateNetProfitIsLessThanCompanyRevenue()],
      }
    );
  }

  private epliDeductibleV2Validator() {
    const formSvc = this;
    return (control: UntypedFormControl): null | ValidationErrors => {
      if (Object.values(formSvc.epliOptions.deductibleV2).includes(control.value)) {
        return null;
      }
      return { invalidValue: 'Please select one of the available deductibles' };
    };
  }

  private subscribeValueChanges() {
    const additionalInsuredBusinessesFormArray = this.additionalInsuredBusinesses();
    const hasAdditionalInsuredBusinessesControl = getControl(
      this.form,
      'hasAdditionalInsuredBusinesses'
    );
    hasAdditionalInsuredBusinessesControl.valueChanges
      .pipe(startWith(hasAdditionalInsuredBusinessesControl.value))
      .subscribe((hasAddlInsuredBusinesses) => {
        if (hasAddlInsuredBusinesses && additionalInsuredBusinessesFormArray.length === 0) {
          this.addAdditionalInsuredBusiness(additionalInsuredBusinessesFormArray);
        }
        enableDisableControl(additionalInsuredBusinessesFormArray, hasAddlInsuredBusinesses);
      });

    this.setBopExposureListeners();
    this.setUpEPLIV2DeductibleObservables();
    this.setCyberControlGroupListeners();
  }

  private setBopExposureListeners() {
    combineLatest([this.bopVersion$, this.baseState$, this.locations$]).subscribe(
      ([bopVersion, baseState, locations]) => {
        this.updateEPLI(bopVersion, baseState, locations);
      }
    );
    this.locations$.subscribe((locations) => {
      this.updateCyberLiability(locations);
    });
  }

  private setCyberControlGroupListeners() {
    const cyberLiabilityCoverageGroup = this.cyberCoverageGroup();

    const aggregateLimitControl = getControl(cyberLiabilityCoverageGroup, 'aggregateLimit');
    const firstPartyLimitControl = getControl(cyberLiabilityCoverageGroup, 'firstPartyLimit');
    const deductibleControl = getControl(cyberLiabilityCoverageGroup, 'deductible');
    const cyberIndustryIdControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    );
    const standaloneAggregateLimitControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AGGREGATE_LIMIT
    );
    const defaultRetentionControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.DEFAULT_RETENTION
    );
    const awareOfPriorClaimsControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS
    );

    awareOfPriorClaimsControl.valueChanges.subscribe((awareOfPriorClaims) => {
      const priorClaimsQuestions = [
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
        CoalitionCyberQuestion.PRIOR_CLAIMS,
        CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
      ].map((controlName) => getControl(cyberLiabilityCoverageGroup, controlName));
      priorClaimsQuestions.forEach((control) => {
        enableDisableControl(
          control,
          awareOfPriorClaimsControl.enabled && awareOfPriorClaims === 'Yes'
        );
      });
    });

    const awareOfPriorClaimsAmountControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT
    );
    const awareOfPriorClaimsAmountOver500kControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K
    );
    awareOfPriorClaimsAmountControl.valueChanges.subscribe((claimsAmount) => {
      enableDisableControl(
        awareOfPriorClaimsAmountOver500kControl,
        awareOfPriorClaimsAmountControl.enabled && claimsAmount === '>500,000'
      );
    });

    // Set listeners on the NAICS code and industry ID control, so we can fetch industry data as the values change.
    observableMerge(
      this.naicsCodeSubject.pipe(
        filter((naicsCode) => !!naicsCode),
        map((naicsCode: string) => NAICS_CODES_TO_INDUSTRY_IDS[naicsCode]),
        distinctUntilChanged()
      ),
      cyberIndustryIdControl.valueChanges.pipe(
        filter((industryId: number) => cyberIndustryIdControl.enabled && !!industryId)
      )
    ).subscribe((industryId) => {
      this.industryIdSubject.next(industryId);
    });

    // The user cannot select a default retention directly; it is defaulted based on the chosen aggregate limit
    standaloneAggregateLimitControl.valueChanges.subscribe((limit: number | null) => {
      if (limit === ESSENTIAL_AGGREGATE_LIMIT_INDEX) {
        defaultRetentionControl.patchValue(ESSENTIAL_DEFAULT_RETENTION_INDEX);
      } else if (limit === MOST_POPULAR_AGGREGATE_LIMIT_INDEX) {
        defaultRetentionControl.patchValue(MOST_POPULAR_DEFAULT_RETENTION_INDEX);
      } else {
        defaultRetentionControl.patchValue(null);
      }
    });
    this.setUpCyberStandaloneAndEndorsementControlSubscriptions(cyberLiabilityCoverageGroup);

    this.setUpCyberOptionsUpdateSubscription({
      aggregateLimitControl,
      firstPartyLimitControl,
      deductibleControl,
    });
  }

  private setUpCyberStandaloneAndEndorsementControlSubscriptions(
    cyberLiabilityCoverageGroup: UntypedFormGroup
  ) {
    const cyberIsAvailable$ = this.productAvailabilitiesSubject.pipe(
      map((availabilities) => {
        return availabilities.some(({ pasSource, product, classCodeSelection }) => {
          return (
            pasSource === 'coalition' &&
            product === 'cyber_admitted' &&
            classCodeSelection === ClassCodeSelection.ALL
          );
        });
      })
    );
    const cyberOptInControl = getControl(cyberLiabilityCoverageGroup, 'optedIn');

    const aggregateLimitControl = getControl(cyberLiabilityCoverageGroup, 'aggregateLimit');
    const firstPartyLimitControl = getControl(cyberLiabilityCoverageGroup, 'firstPartyLimit');
    const deductibleControl = getControl(cyberLiabilityCoverageGroup, 'deductible');
    const cyberProductTypeControl = getControl(
      cyberLiabilityCoverageGroup,
      'selectedCyberCoverage'
    );
    const cyberIndustryIdControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    );
    const hasTechEoControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.HAS_TECH_EO
    );
    const companyNetRevenueControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE
    );

    combineLatest([
      this.baseState$,
      cyberProductTypeControl.valueChanges.pipe(startWith(cyberProductTypeControl.value)),
      this.cyberIndustryFlagsSubject,
      this.naicsCodeSubject,
      cyberIsAvailable$,
      this.isEligibleForCyber$,
    ]).subscribe(
      ([
        baseState,
        cyberProductType,
        industryFlags,
        naicsCode,
        cyberIsAvailable,
        isEligibleForCyber,
      ]: [
        string,
        CyberProductType,
        CyberQuestionEnablementFlags | null,
        string | null,
        boolean,
        boolean
      ]) => {
        const requiredStandaloneControls = [
          CoalitionCyberQuestion.COMPANY_REVENUE,
          CoalitionCyberQuestion.AGGREGATE_LIMIT,
          CoalitionCyberQuestion.DEFAULT_RETENTION,
          CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
          CoalitionCyberQuestion.DUAL_CONTROL_5K,
          CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
          CoalitionCyberQuestion.MFA_EMAIL,
          CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
          CoalitionCyberQuestion.MFA_VPN,
          CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
          CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
        ].map((controlName) => getControl(cyberLiabilityCoverageGroup, controlName));
        const standaloneProductControls = [
          ...requiredStandaloneControls,
          cyberIndustryIdControl,
          hasTechEoControl,
          companyNetRevenueControl,
        ];
        const coverageEndorsementControls = [
          deductibleControl,
          aggregateLimitControl,
          firstPartyLimitControl,
        ];
        if (cyberIsAvailable && isEligibleForCyber) {
          // Do not emit event, so as to not trigger a circular run of the combineLatest subscription
          cyberProductTypeControl.enable({ emitEvent: false });
          if (cyberProductType === 'endorsement') {
            // Enable coverage endorsement controls
            deductibleControl.enable();
            const isSplitLimitState = BOP_V2_CYBER_SPLIT_STATES.includes(baseState);
            enableDisableControl(aggregateLimitControl, !isSplitLimitState);
            enableDisableControl(firstPartyLimitControl, isSplitLimitState);
            // Disable standalone product controls
            standaloneProductControls.forEach((control) => enableDisableControl(control, false));

            // The optedIn flag is how Guidewire handles the endorsement, so set it true for the endorsement case
            cyberOptInControl.patchValue(true);
          } else if (cyberProductType === 'coalition-cyber') {
            // Enable standalone product controls
            requiredStandaloneControls.forEach((control) => enableDisableControl(control, true));
            enableDisableControl(cyberIndustryIdControl, !naicsCode);
            if (industryFlags) {
              const { require_tech_eo, use_gross_profit_net_revenue } = industryFlags;
              enableDisableControl(hasTechEoControl, require_tech_eo);
              enableDisableControl(companyNetRevenueControl, use_gross_profit_net_revenue);
            }
            // Disable coverage endorsement controls
            coverageEndorsementControls.forEach((control) => enableDisableControl(control, false));

            // The optedIn flag is how Guidewire handles the endorsement, so set it false for non-endorsement cases
            cyberOptInControl.patchValue(false);
          } else if (cyberProductType === 'none') {
            // Disable all controls
            coverageEndorsementControls.forEach((control) => enableDisableControl(control, false));
            standaloneProductControls.forEach((control) => enableDisableControl(control, false));

            // The optedIn flag is how Guidewire handles the endorsement, so set it false for non-endorsement cases
            cyberOptInControl.patchValue(false);
          }
        } else {
          // Disable all controls
          coverageEndorsementControls.forEach((control) => enableDisableControl(control, false));
          standaloneProductControls.forEach((control) => enableDisableControl(control, false));
          enableDisableControl(cyberProductTypeControl, false);
        }
      }
    );
  }

  private updateCyberLiability(locations: BopLocation[]) {
    const buildingCanHaveCyber = (building: BopBuilding) => {
      if (!building.exposure.classification.code) {
        return true;
      }
      return !EXCLUDED_V2_CYBER_CODES.includes(building.exposure.classification.code.code);
    };

    const classificationCanHaveCyber = locations.every((location) => {
      return location.buildings.every(buildingCanHaveCyber);
    });
    this.isEligibleForCyberSubject.next(classificationCanHaveCyber);
  }

  /**
   * Sets up a subscription for cyber endorsement controls to update default aggregate and first party limits based on state differences
   * and automatically selects the relevant deductible for a limit.
   */
  private setUpCyberOptionsUpdateSubscription(controls: {
    aggregateLimitControl: FormControl;
    firstPartyLimitControl: FormControl;
    deductibleControl: FormControl;
  }) {
    const { aggregateLimitControl, firstPartyLimitControl, deductibleControl } = controls;
    combineLatest([
      aggregateLimitControl.valueChanges.pipe(startWith(aggregateLimitControl.value)),
      firstPartyLimitControl.valueChanges.pipe(startWith(firstPartyLimitControl.value)),
      this.baseState$,
    ])
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)))
      .subscribe(([aggregateLimit, firstPartyLimit, baseState]) => {
        if (!baseState) {
          return;
        }
        const isSplitLimitState = BOP_V2_CYBER_SPLIT_STATES.includes(baseState);
        const cyberOptions = BOP_V2_CYBER_OPTIONS[baseState] || BOP_V2_CYBER_OPTIONS.default;
        // Ensure that default aggregate limit is valid for this state
        if (cyberOptions.aggregateLimitOptions) {
          const currentOptionValid = cyberOptions.aggregateLimitOptions.some(
            (option) => option.limit === aggregateLimit
          );
          if (!currentOptionValid) {
            // If the current option is not valid, patch the control to default to the lowest valid limit.
            aggregateLimitControl.patchValue(cyberOptions.aggregateLimitOptions[0].limit);
          }
        }
        // Ensure that default first party limit is valid for this state
        if (cyberOptions.firstPartyLimitOptions) {
          const currentOptionValid = cyberOptions.firstPartyLimitOptions.some(
            (option) => option.limit === firstPartyLimit
          );
          if (!currentOptionValid) {
            // If the current option is not valid, patch the control to default to the lowest valid limit.
            firstPartyLimitControl.patchValue(cyberOptions.firstPartyLimitOptions[0].limit);
          }
        }

        // Update the deductible (not selected by a user). Specific deductibles are coupled with each limit.
        let deductibleToUse: number | undefined;
        if (isSplitLimitState) {
          deductibleToUse = cyberOptions.firstPartyLimitOptions?.find(
            (limit) => limit.limit === firstPartyLimit
          )?.deductible;
        } else {
          deductibleToUse = cyberOptions.aggregateLimitOptions?.find(
            (limit) => limit.limit === aggregateLimit
          )?.deductible;
        }

        if (deductibleToUse) {
          deductibleControl.patchValue(deductibleToUse);
        }
      });
  }

  public isCoalitionCyberSelected() {
    const productTypeControl = getControl(
      this.form,
      'cyberLiabilityCoverage.selectedCyberCoverage'
    );

    return (
      !!productTypeControl &&
      productTypeControl.enabled &&
      productTypeControl.value === 'coalition-cyber'
    );
  }

  public getCyberAdmittedEarlyDeclineReasons(): UpsellEarlyDeclineKey[] {
    const cyberCoverageGroup = this.cyberCoverageGroup();

    const companyRevenueControl = getControl(
      cyberCoverageGroup,
      CoalitionCyberQuestion.COMPANY_REVENUE
    );
    const hasTechEoControl = getControl(cyberCoverageGroup, CoalitionCyberQuestion.HAS_TECH_EO);

    const earlyDeclineReasons: UpsellEarlyDeclineKey[] = [];

    if (
      companyRevenueControl.enabled &&
      parseMoney(companyRevenueControl.value) > ADMITTED_MAX_REVENUE
    ) {
      earlyDeclineReasons.push('exceedsMaxRevenue');
    }
    if (hasTechEoControl.enabled && hasTechEoControl.value === 'No') {
      earlyDeclineReasons.push('techEORequired');
    }

    return earlyDeclineReasons;
  }

  public getStandaloneCyberFields(effectiveDate: string, locations: BopLocation[]) {
    const cyberCoverageGroup = this.cyberCoverageGroup();

    const cyberPayload: CoalitionCyberFormDataRaw = pick(cyberCoverageGroup.value, [
      CoalitionCyberQuestion.HAS_TECH_EO,
      CoalitionCyberQuestion.COMPANY_REVENUE,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
      CoalitionCyberQuestion.AGGREGATE_LIMIT,
      CoalitionCyberQuestion.DEFAULT_RETENTION,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
      CoalitionCyberQuestion.PRIOR_CLAIMS,
      CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
      CoalitionCyberQuestion.DUAL_CONTROL_5K,
      CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
      CoalitionCyberQuestion.MFA_EMAIL,
      CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
      CoalitionCyberQuestion.MFA_VPN,
      CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
      CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
    ]);

    const naicsCode = this.naicsCodeSubject.getValue();
    if (naicsCode) {
      cyberPayload[NAICS_CODE] = naicsCode;
    } else {
      cyberPayload.company_industry_id =
        cyberCoverageGroup.value[CoalitionCyberQuestion.COMPANY_INDUSTRY_ID];
    }
    // For upsell, submit with no domain
    cyberPayload.domain_names = '';

    const aggregateLimit = getControl(
      cyberCoverageGroup,
      CoalitionCyberQuestion.AGGREGATE_LIMIT
    ).value;
    // Coverages are selected based on which aggregate limit the user selected:
    // * Essential bundle for 100K limit
    // * Most Popular bundle for 250K limit
    const { first_party_coverages, third_party_coverages } =
      aggregateLimit === ESSENTIAL_AGGREGATE_LIMIT_INDEX
        ? ESSENTIAL_COVERAGES
        : MOST_POPULAR_COVERAGES;

    // Since we are only supporting Admitted quotes, we must not include Surplus-specific coverages
    const NON_ADMITTED_COVERAGES = [
      FirstPartyCoverageNestedQuestion.BREACH_RESPONSE,
      FirstPartyCoverageNestedQuestion.TECH_EO,
    ];

    cyberPayload.first_party_coverages = omit(
      first_party_coverages,
      NON_ADMITTED_COVERAGES
    ) as Record<FirstPartyCoverageNestedQuestion, boolean>;
    cyberPayload.third_party_coverages = third_party_coverages;

    // Use default Underwriting question answers
    if (parseMoney(cyberPayload.company_revenue as string) < 1000000) {
      entries(CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M).forEach(([key, val]) => {
        cyberPayload[key as CoalitionCyberQuestion] = val;
      });
    }

    cyberPayload.effective_date = effectiveDate;
    cyberPayload.address = pick(locations[0].locationDetails, [
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zip',
    ]);
    cyberPayload.company_employee_count = getEmployeeCountValue(locations);

    const formData = formatFormFieldValues(cyberPayload);
    // Remove coverages that are invalid based on the industry flags
    const industryFlags = this.cyberIndustryFlagsSubject.getValue();
    if (industryFlags) {
      return removeIneligibleCyberCoverages(formData, industryFlags);
    }

    return formData;
  }

  private setUpEPLIV2DeductibleObservables() {
    const epliFormGroup = getFormGroup(this.form, 'employmentRelatedPracticesLiabilityCoverage');
    const aggregateLimitV2Control: UntypedFormControl = getControl(
      epliFormGroup,
      'aggregateLimitV2'
    );
    const defenseLimitV2Control: UntypedFormControl = getControl(epliFormGroup, 'defenseLimitV2');
    const deductibleV2Control: UntypedFormControl = getControl(epliFormGroup, 'deductibleV2');

    combineLatest([
      this.baseState$,
      aggregateLimitV2Control.valueChanges.pipe(startWith(aggregateLimitV2Control.value)),
      defenseLimitV2Control.valueChanges.pipe(startWith(defenseLimitV2Control.value)),
    ]).subscribe(([baseState, aggregateLimit, defenseLimit]: [string, number, number]) => {
      const availableDeductibles = [5000];
      if (!BOP_V2_STATES_WITHOUT_2500_EPLI_DEDUCTIBLE.includes(baseState)) {
        availableDeductibles.push(2500);
      }
      if (BOP_V2_EPLI_SPLIT_STATES.includes(baseState)) {
        if (defenseLimit >= 50000) {
          availableDeductibles.push(10000);
        }
        if (defenseLimit >= 125000) {
          availableDeductibles.push(25000);
        }
      } else {
        if (aggregateLimit >= 100000) {
          availableDeductibles.push(10000);
        }
        if (aggregateLimit >= 250000) {
          availableDeductibles.push(25000);
        }
      }
      this.epliOptions.deductibleV2 = pickBy(BOP_V2_DEDUCTIBLE_OPTIONS, (deductibleVal) => {
        return availableDeductibles.includes(deductibleVal);
      });
      clampControl(deductibleV2Control, Object.values(this.epliOptions.deductibleV2));
    });
  }

  private updateEPLI(bopVersion: BopVersion, baseState: string, locations: BopLocation[]) {
    const epliControl: UntypedFormControl = getControl(
      this.form,
      'employmentRelatedPracticesLiabilityCoverage.optedIn'
    );

    const classificationCannotHaveEPLI = locations.some((location) => {
      if (!location.buildings[0].exposure.classification.code) {
        return false;
      }
      return (
        location.buildings[0].exposure.lessorsRisk === false &&
        EPLI_EXCEPTIONS.includes(location.buildings[0].exposure.classification.code.code)
      );
    });

    const isBopV2StateIneligibleForEPLI =
      bopVersion === 2 && BOP_V2_EPLI_INELIGIBLE_STATES.includes(baseState);
    const isBopV3StateIneligibleForEPLI =
      bopVersion === 3 && BOP_V3_EPLI_INELIGIBLE_STATES.includes(baseState);
    if (
      classificationCannotHaveEPLI ||
      isBopV2StateIneligibleForEPLI ||
      isBopV3StateIneligibleForEPLI
    ) {
      enableDisableControl(epliControl, false);
    } else {
      enableDisableControl(epliControl, true);
    }

    this.updateEPLIControls(locations.length, baseState);
  }

  private updateEPLIControls(numLocations: number, baseState: string) {
    const aggregateLimitV2: UntypedFormControl = getControl(
      this.form,
      'employmentRelatedPracticesLiabilityCoverage.aggregateLimitV2'
    );
    const defenseLimitV2: UntypedFormControl = getControl(
      this.form,
      'employmentRelatedPracticesLiabilityCoverage.defenseLimitV2'
    );
    const deductibleV2: UntypedFormControl = getControl(
      this.form,
      'employmentRelatedPracticesLiabilityCoverage.deductibleV2'
    );
    const perLocationPartTimeEmployees: UntypedFormArray = getFormArray(
      this.form,
      'employmentRelatedPracticesLiabilityCoverage.perLocationPartTimeEmployees'
    );

    enableDisableControl(deductibleV2, true);
    enableDisableControl(perLocationPartTimeEmployees, true);

    if (BOP_V2_EPLI_HIGH_SPLIT_LIMIT_STATES.includes(baseState)) {
      enableDisableControl(aggregateLimitV2, false);
      enableDisableControl(defenseLimitV2, true);
      this.epliOptions.defenseLimitV2 = BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS_HIGH;
      clampControl(defenseLimitV2, BOP_V2_EPLI_SPLIT_LIMITS_HIGH);
    } else if (BOP_V2_EPLI_NORMAL_SPLIT_LIMIT_STATES.includes(baseState)) {
      enableDisableControl(aggregateLimitV2, false);
      enableDisableControl(defenseLimitV2, true);
      this.epliOptions.defenseLimitV2 = BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS;
      clampControl(defenseLimitV2, BOP_V2_EPLI_SPLIT_LIMITS_NORMAL);
    } else if (BOP_V2_EPLI_HIGH_AGG_LIMIT_STATES.includes(baseState)) {
      enableDisableControl(aggregateLimitV2, true);
      enableDisableControl(defenseLimitV2, false);
      this.epliOptions.aggregateLimitV2 = BOP_V2_EPLI_AGG_LIMIT_OPTIONS_HIGH;
      clampControl(aggregateLimitV2, BOP_V2_EPLI_AGG_LIMITS_HIGH);
    } else {
      // Aggregate state, rest
      enableDisableControl(aggregateLimitV2, true);
      enableDisableControl(defenseLimitV2, false);
      this.epliOptions.aggregateLimitV2 = BOP_V2_EPLI_AGG_LIMIT_OPTIONS;
      clampControl(aggregateLimitV2, BOP_V2_EPLI_AGG_LIMITS_NORMAL);
    }

    while (perLocationPartTimeEmployees.controls.length < numLocations) {
      perLocationPartTimeEmployees.controls.push(new UntypedFormControl(0));
    }
    while (perLocationPartTimeEmployees.controls.length > numLocations) {
      perLocationPartTimeEmployees.controls.pop();
    }
  }

  public resetAdditionalInsuredsFormArrayLength(newLength: number) {
    const formArray = this.additionalInsuredBusinesses();
    removeAllFromFormArray(formArray);
    times(newLength, () => {
      this.addAdditionalInsuredBusiness(formArray);
    });
  }

  public cyberCoverageGroup() {
    return getFormGroup(this.form, 'cyberLiabilityCoverage');
  }

  public hasAdditionalInsuredBusinesses() {
    return getControl(this.form, 'hasAdditionalInsuredBusinesses');
  }

  public additionalInsuredBusinesses() {
    return getFormArray(this.form, 'additionalInsuredBusinesses');
  }

  public isContractorEnhancement(locations: BopLocation[]) {
    if (locations.length === 0) {
      return false;
    }
    // MPC only checks if first building is LRO when deciding if it should add the contractor's enhancement,
    // so we want to be consistent with that
    if (locations[0].buildings[0].exposure.lessorsRisk) {
      return false;
    }
    return locations.some((location: BopLocation) => {
      return location.buildings.some((building: BopBuilding) => {
        return building.exposure.businessType === AVAILABLE_BUSINESS_TYPES.Contractor;
      });
    });
  }
}
