import * as moment from 'moment';
import { combineLatest, startWith, distinctUntilChanged } from 'rxjs';
import { cloneDeep, times, isEqual, uniq, groupBy, some, intersection } from 'lodash';
import { Injectable } from '@angular/core';
import {
  FormBuilder,
  Validators,
  ValidationErrors,
  FormArray,
  FormControl,
  AbstractControl,
  FormGroup,
} from '@angular/forms';

// Services
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';

// Validators
import {
  enableDisableControl,
  feinValidator,
  maxDateExceededValidator,
  minDateExceededValidator,
  rangeValidator,
  zipCodeValidator,
} from 'app/shared/helpers/form-helpers';
import {
  validateAddressIsNotPO,
  validateNoSpecialCharactersInAddress,
} from 'app/features/attune-bop/models/form-validators';
import { riskIdExperienceModValidator, riskIdValidator } from '../helpers/form-validations';

// Models
import {
  BasicInfoValue,
  BasicsInfoFormGroup,
  ClassCodeFormGroup,
  ExecutiveElectionFormGroup,
  ExecutiveElectionsValue,
  GuidelinesFormGroup,
  LocationFormGroup,
  LocationValue,
  PartialLocationFormValue,
  QuoteFormGroup,
  QuoteFormValue,
  SpecificWaiverFormGroup,
  WaiverOfSubrogationFormGroup,
} from 'app/workers-comp/attune/models/quote-form.model';
import {
  CoveragesCreditsFormGroup,
  CoverageCreditsKeys,
  COVERAGE_QUESTIONS_STATE_MAPPER,
} from 'app/workers-comp/attune/models/state-coverages-form.model';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';

// Constants
import { PLACEHOLDER_ORG_TYPE, US_DATE_MASK } from 'app/constants';
import {
  DEFAULT_EMP_LIABILITY_LIMIT,
  DEFAULT_DEDUCTIBLE_OPTION,
  NO_DEDUCTIBLE_STATES,
  DEFAULT_DEDUCTIBLE_OPTIONS,
  DEDUCTIBLE_OPTIONS_BY_STATE,
} from 'app/workers-comp/attune/constants';
import { AvailableExecElectionInputs } from 'app/workers-comp/attune/typings';
import { StateCode } from 'app/shared/States';

export const ACCOUNT_FORM_STEP = {
  args: {},
  displayName: 'Account',
  slug: 'account',
  parent: 'account',
  formPath: 'account',
};

export const GUIDELINES_FORM_STEP = {
  args: {},
  displayName: 'Guidelines',
  slug: 'guidelines',
  parent: 'guidelines',
  formPath: 'guidelines',
};

@Injectable()
export class AttuneWcQuoteFormService extends FormDslSteppedFormBaseService {
  // NOTE: When referencing the form in components, we should use this.formService.form
  // unless the page component has its own reference to the typed form.
  // Otherwise, this.form on the component will refer to an untyped formgroup on the base class.
  public form: QuoteFormGroup;
  private today = moment();

  // Flags
  public submitted = false;
  private shouldAddAccountStep = false;
  private shouldAddGuidelinesStep = false;
  public showExecutiveElectionsWarning = false;
  public showDeductibleChangedWarning = false;
  public isOrgTypeReadonly = false;

  // Form control names
  private readonly classCodesFormControlName = 'classCodes';

  // Shared data
  public availableExecTitlesByState: {
    [state: string]: string[];
  } = {};
  public uniqueLocationStates: string[] = [];
  // Deductible options are dependent on changes to location states.
  public deductibleOptions = DEFAULT_DEDUCTIBLE_OPTIONS;

  // Saving inputs used to fetch exec elections to avoid re-fetching unnecessarily.
  private availableExecElectionInputs: AvailableExecElectionInputs;

  constructor(private formBuilder: FormBuilder, private orgTypeService: OrganizationTypeService) {
    super();
    this.initializeForm();
    this.syncAllSteps();
  }

  /**
   * Setup Methods
   */
  public initializeForm() {
    const guidelines = this.createGuidelinesFormGroup();
    const basicInfo = this.createBasicInfoFormGroup();
    const locations = this.formBuilder.array<LocationFormGroup>([]);
    const executiveElections = this.createExecutiveElectionsFormGroup();

    this.form = this.formBuilder.group({
      guidelines,
      basicInfo,
      locations,
      executiveElections: executiveElections,
      coveragesAndCredits: this.createStateCoveragesCreditsFormGroup(),
    });

    // Initialize form with one location.
    this.addLocation();

    // This sets up a subscription on basicInfo + locations to be used for form-state dependent logic.
    this.setupFormSubscriptions();
  }

  public generateSteps(): RouteFormStep[] {
    const isStep = (step: RouteFormStep | null): step is RouteFormStep => {
      return step !== null;
    };

    const numLocations = this.form ? this.locationsFormArray().controls.length : 1;
    const locationSteps = times(numLocations, (index) => {
      const oneIndexedLocationNumber = index + 1;
      return {
        displayName: `Location ${oneIndexedLocationNumber}`,
        formPath: `locations.${index}`,
        slug: `location/${oneIndexedLocationNumber}`,
        parent: `location/${oneIndexedLocationNumber}`,
        args: {},
      };
    });

    return [
      this.newAccountStep(),
      this.guidelinesStep(),
      {
        displayName: 'Basic info',
        formPath: 'basicInfo',
        slug: 'basic-info',
        parent: 'basic-info',
        args: {},
      },
      ...locationSteps,
      this.execElectionStepIfApplicable(),
      this.coveragesAndCreditsStepIfApplicable(),
    ].filter(isStep);
  }

  public fillInHappyPath(): void {}

  public setAccountStepVisibility(shouldDisplay: boolean): void {
    this.shouldAddAccountStep = shouldDisplay;
    this.syncAllSteps();
  }

  private newAccountStep(): RouteFormStep | null {
    if (this.shouldAddAccountStep) {
      return ACCOUNT_FORM_STEP;
    }
    return null;
  }

  public setGuidelinesStepVisibility(shouldDisplay: boolean): void {
    this.shouldAddGuidelinesStep = shouldDisplay;
    this.syncAllSteps();
    if (shouldDisplay) {
      this.stepWithoutValidation(GUIDELINES_FORM_STEP);
    }
  }

  private guidelinesStep(): RouteFormStep | null {
    if (this.shouldAddGuidelinesStep) {
      return GUIDELINES_FORM_STEP;
    }
    return null;
  }

  private execElectionStepIfApplicable(): RouteFormStep | null {
    if (!this.availableExecTitlesByState) {
      return null;
    }

    // Check if there are any possible elections to be made to determine if we should show the form step.
    const flatAvailExecTitles = Object.values(this.availableExecTitlesByState).reduce(
      (acc, cur) => acc.concat(cur),
      []
    );
    if (flatAvailExecTitles.length > 0) {
      return {
        displayName: 'Executive elections',
        formPath: 'executiveElections',
        slug: 'executive-elections',
        parent: 'executive-elections',
        args: {},
      };
    }
    return null;
  }

  private coveragesAndCreditsStepIfApplicable(): RouteFormStep | null {
    if (!this.form) {
      return null;
    }
    const coveragesAndCreditsFormGroup = this.getCoveragesCreditsFormGroup();
    const hasQuestionsEnabled = Object.keys(coveragesAndCreditsFormGroup.controls).some(
      (coverageQuestion) => {
        const coverageQuestionControl = coveragesAndCreditsFormGroup.get(coverageQuestion);
        return coverageQuestionControl?.enabled;
      }
    );

    if (hasQuestionsEnabled) {
      return {
        displayName: 'Coverages & Credits',
        formPath: 'coveragesAndCredits',
        slug: 'coverages-credits',
        parent: 'coverages-credits',
        args: {},
      };
    }
    return null;
  }

  /**
   * End of setup methods
   */

  /**
   *  Form state subscriptions
   */

  setupFormSubscriptions() {
    const basicInfo = this.form.get('basicInfo') as BasicsInfoFormGroup;
    const locations = this.form.get('locations') as FormArray<LocationFormGroup>;

    const basicInfo$ = basicInfo.valueChanges.pipe(startWith(basicInfo.value));
    const locations$ = locations.valueChanges.pipe(startWith(locations.value));
    combineLatest([basicInfo$, locations$])
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)))
      .subscribe(([basicInfoFormVal, locationsFormVal]) => {
        // Add form-state dependent functionality here.
        this.deductibleOptions = this.getDeductibleOptions(locationsFormVal);
        this.uniqueLocationStates = this.getUniqueLocationStates(locationsFormVal);
        this.adjustDeductibleForStateIfNecessary(basicInfoFormVal.deductible, locationsFormVal);
        this.setupExecutiveElectionsData(locationsFormVal, basicInfoFormVal.organizationType);
        this.enableDisableStateCoveragesCreditsControls(locationsFormVal);
      });
  }

  /**
   * End of form state subscriptions
   */

  /**
   * Edit quote methods
   */

  patchFormForEditOrDraft(
    parentQuote: QuoteFormValue,
    insuredAccount: InsuredAccount,
    draftOrEdit: 'draft' | 'edit'
  ) {
    const { guidelines, basicInfo, locations, executiveElections, coveragesAndCredits } =
      parentQuote;

    // If FEIN or orgType exist at the account-level, use them instead of the parentQuote since they are more up-to-date.
    const feinToUse = insuredAccount.fein || basicInfo.employerIdentificationNumber;
    const orgTypeToUse =
      insuredAccount.organizationType && insuredAccount.organizationType !== PLACEHOLDER_ORG_TYPE
        ? insuredAccount.organizationType
        : basicInfo.organizationType;

    this.setupGuidelinesAndPatchForEdit(draftOrEdit === 'edit', guidelines?.attestation);
    this.setupBasicInfoAndPatchForEdit(basicInfo, feinToUse, orgTypeToUse);
    this.setupLocationsAndPatchForEdit(locations);
    // Note: Order matters here, patching executives should come after basics + locations.
    this.setupExecutivesAndPatchForEdit(executiveElections.elections, locations, orgTypeToUse);
    this.form.patchValue({
      coveragesAndCredits: coveragesAndCredits,
    });
  }

  private setupGuidelinesAndPatchForEdit(
    isEdit: boolean,
    hasAcceptedAttestation: boolean | undefined
  ) {
    if (isEdit) {
      // On edits we skip the guidelines step.
      this.form.patchValue({
        guidelines: {
          attestation: true,
        },
      });
      // If this is a draft and the user has already accepted guidelines, we want to patch that in.
    } else if (typeof hasAcceptedAttestation === 'boolean') {
      this.form.patchValue({
        guidelines: {
          attestation: hasAcceptedAttestation,
        },
      });
    }
  }

  private setupBasicInfoAndPatchForEdit(
    basicInfo: BasicInfoValue,
    feinToUse: string,
    orgTypeToUse: string
  ) {
    if (
      basicInfo.waiverOfSubrogation?.hasWaiverOfSubrogation &&
      basicInfo.waiverOfSubrogation?.waiverType === 'specific'
    ) {
      // Clear initial specific waiver when form is initialized.
      this.specificWaiversFormArray().clear();
      basicInfo.waiverOfSubrogation?.specificWaivers?.forEach((_waiver) =>
        this.addSpecificWaiver()
      );
    }

    const effectiveDateToUse = this.getEffectiveDateBeforePatch(basicInfo.effectiveDate);
    // Patch basics form.
    this.form.patchValue({
      basicInfo: {
        ...basicInfo,
        effectiveDate: effectiveDateToUse,
        organizationType: orgTypeToUse,
        employerIdentificationNumber: feinToUse,
      },
    });
  }

  private setupLocationsAndPatchForEdit(locations: LocationValue[]) {
    // Clear initial location created when form is initialized.
    this.locationsFormArray().clear();
    locations.forEach((location, index: number) => {
      this.addLocation();
      const addedLocationFormGroup = this.locationsFormArray().at(index);
      const classCodesFormArray = this.classCodeFormArray(addedLocationFormGroup);
      // Clear initial classCode created when form is intialized.
      classCodesFormArray.clear();
      location.classCodes.forEach((_classCode) => {
        this.addClassCode(addedLocationFormGroup);
      });
    });
    // Patch locations form.
    this.form.patchValue({
      locations: locations,
    });
  }

  private setupExecutivesAndPatchForEdit(
    executiveElections: ExecutiveElectionsValue[],
    locations: LocationValue[],
    orgType: string
  ) {
    if (!executiveElections) {
      return;
    }

    // Setup the available elections for the locations and orgType.
    this.setupExecutiveElectionsData(locations, orgType);

    const originalExecutiveElections = cloneDeep(executiveElections);
    const filteredElections = originalExecutiveElections.filter((election) => {
      const locationNum = election.locationNum;
      const state = this.getLocationState(locationNum);
      if (!state) {
        return false;
      }
      // It is possible orgType changed on the account after the parent quote was quoted.
      // Remove invalid elections;
      return this.isExecElectionValidBeforePatch(election, state);
    });

    if (originalExecutiveElections.length !== filteredElections.length) {
      // If any invalid elections were removed, surface a warning to the user.
      this.showExecutiveElectionsWarning = true;
    }
    // Add exec election formGroup to form array.
    filteredElections.forEach(() => this.addExecutiveElection());
    this.form.patchValue({
      executiveElections: {
        // We do not patch the attestation - we want the broker to confirm that every time they edit the quote.
        elections: filteredElections,
      },
    });
  }

  private isExecElectionValidBeforePatch(execElection: ExecutiveElectionsValue, stateCode: string) {
    const availableExecutiveTitles = this.availableExecTitlesByState[stateCode] || [];
    const availableElection = availableExecutiveTitles.find((execTitle) => {
      return execTitle === execElection.title;
    });

    if (!availableElection) {
      return false;
    }

    return true;
  }

  private getEffectiveDateBeforePatch(parentQuoteEffDate: string) {
    const isParentQuoteEffDateAfterToday = moment(parentQuoteEffDate, US_DATE_MASK)
      .startOf('day')
      .isAfter(this.today.startOf('day'));

    if (isParentQuoteEffDateAfterToday) {
      return parentQuoteEffDate;
    }
    return this.today.format(US_DATE_MASK);
  }

  /**
   * End of edit quote methods
   */

  /**
   * Guidelines methods
   */

  private createGuidelinesFormGroup(): GuidelinesFormGroup {
    return this.formBuilder.group({
      attestation: this.formBuilder.control<boolean | null>(null, Validators.requiredTrue),
    });
  }

  /**
   * End of guidelines methods
   */

  /**
   * Basic Info methods
   */

  private createBasicInfoFormGroup(): BasicsInfoFormGroup {
    const threeMonthsFromToday = this.today.clone().add(3, 'months');
    const currentYear = this.today.year();
    const yearsSince1776 = currentYear - 1776;

    return this.formBuilder.group({
      effectiveDate: this.formBuilder.control<string | null>(this.today.format(US_DATE_MASK), [
        Validators.required,
        minDateExceededValidator(this.today, 'day'),
        maxDateExceededValidator(threeMonthsFromToday),
      ]),
      employerIdentificationNumber: this.formBuilder.control<string | null>(null, [
        Validators.required,
        feinValidator,
      ]),
      yearsInBusiness: this.formBuilder.control<string | null>(null, [
        Validators.required,
        rangeValidator(0, yearsSince1776),
      ]),
      employersLiabilityLimits: this.formBuilder.control<string | null>(
        DEFAULT_EMP_LIABILITY_LIMIT,
        Validators.required
      ),
      deductible: this.formBuilder.control<string | null>(
        DEFAULT_DEDUCTIBLE_OPTION,
        Validators.required
      ),
      organizationType: this.formBuilder.control<string | null>(null, Validators.required),
      numberOfLocations: this.formBuilder.control<number | null>(1, [
        Validators.required,
        Validators.max(6),
      ]),
      bankruptcyNotDischarged: this.formBuilder.control<boolean | null>(null, Validators.required),
      lessThan100PercentOwnership: this.formBuilder.control<boolean | null>(
        null,
        Validators.required
      ),
      waiverOfSubrogation: this.createWaiverOfSubrogationFormGroup(),
      accountDescOps: this.formBuilder.control<string | null>(null, [
        Validators.required,
        Validators.maxLength(170),
      ]),
    });
  }

  private createWaiverOfSubrogationFormGroup(): WaiverOfSubrogationFormGroup {
    const hasWaiverOfSubrogation = this.formBuilder.control<boolean | null>(
      false,
      Validators.required
    );
    const waiverType = this.formBuilder.control<string | null>('', Validators.required);
    const specificWaivers = this.formBuilder.array<SpecificWaiverFormGroup>([]);
    waiverType.disable();
    specificWaivers.disable();

    // Enable the dependent form control if a broker wants to add a waiver of sub.
    hasWaiverOfSubrogation.valueChanges.subscribe((val) => {
      if (val) {
        return waiverType.enable();
      } else {
        waiverType.disable();
        specificWaivers.disable();
      }
    });

    waiverType.valueChanges.subscribe((val) => {
      // There are two types of waivers. Blanket waivers do not have any dependent forms, its just a yes/no.
      // If the broker wants to add a specific waiver, that can be a list of waivers.
      if (val === 'specific') {
        specificWaivers.enable();
        if (this.specificWaiversFormArray().length === 0) {
          this.addSpecificWaiver();
        }
      } else {
        specificWaivers.disable();
      }
    });

    return this.formBuilder.group({
      hasWaiverOfSubrogation: hasWaiverOfSubrogation,
      waiverType: waiverType,
      specificWaivers: specificWaivers,
    });
  }

  private createSpecificWaiver() {
    return this.formBuilder.group({
      address: this.createAddressForm(),
      nameOrOrg: this.formBuilder.control<string | null>(null, Validators.required),
      description: this.formBuilder.control<string | null>(null, Validators.required),
    });
  }

  public specificWaiversFormArray() {
    return this.form.get(
      'basicInfo.waiverOfSubrogation.specificWaivers'
    ) as FormArray<SpecificWaiverFormGroup>;
  }

  public removeSpecificWaiver(index: number) {
    this.specificWaiversFormArray().removeAt(index);
  }

  public addSpecificWaiver() {
    this.specificWaiversFormArray().push(this.createSpecificWaiver());
  }

  /**
   * End of basic info methods
   */

  /**
   *  Location methods
   */

  public locationsFormArray() {
    return this.form.get('locations') as FormArray<LocationFormGroup>;
  }

  public getLocation(locationNumber: string) {
    return this.locationsFormArray().controls.find((location) => {
      return location.value.locationNum === locationNumber;
    });
  }

  public getLocationState(locationNumber: string): string | null {
    const location = this.getLocation(locationNumber);
    return location?.value.address?.state || null;
  }

  public addLocation() {
    this.locationsFormArray().push(this.createNewLocation());
    this.syncAllSteps();
  }

  public removeLastLocation() {
    const locations = this.locationsFormArray();
    locations.removeAt(locations.length - 1);
    this.syncAllSteps();
  }

  private createNewLocation() {
    // locationNum is 1-indexed. We make it a string to be consistent w/ the locationNum on executive elections
    // since our form field components coerce number values to strings.
    const locationNum = String(this.locationsFormArray().length + 1);

    const locationFormGroup = this.formBuilder.group({
      address: this.createAddressForm(),
      [this.classCodesFormControlName]: this.formBuilder.array([this.createNewClassCode()], {
        validators: [this.createClassCodesValidator()],
      }),
      locationNum: this.formBuilder.control(locationNum),
    });

    return locationFormGroup;
  }

  private createAddressForm() {
    return this.formBuilder.group({
      addressLine1: this.formBuilder.control<string | null>(null, [
        Validators.required,
        validateAddressIsNotPO,
        validateNoSpecialCharactersInAddress,
      ]),
      addressLine2: this.formBuilder.control<string | null>(null, [
        validateAddressIsNotPO,
        validateNoSpecialCharactersInAddress,
      ]),
      city: this.formBuilder.control<string | null>(null, Validators.required),
      state: this.formBuilder.control<string | null>(null, Validators.required),
      zip: this.formBuilder.control<string | null>(null, [Validators.required, zipCodeValidator]),
    });
  }

  private getUniqueLocationStates(locations: PartialLocationFormValue[]) {
    return uniq(
      locations
        .map((location) => location?.address?.state)
        .filter((state): state is string => !!state)
    );
  }

  /**
   *  End of Location methods
   */

  /**
   *  Class code methods
   */

  private createNewClassCode() {
    const numberOfEmployeesControl = this.formBuilder.control<string | null>(null, [
      Validators.required,
    ]);

    const payrollControl = this.formBuilder.control<string | null>(null, [Validators.required]);

    numberOfEmployeesControl.valueChanges.subscribe((value) => {
      if (value === '0') {
        payrollControl.setValidators([Validators.required]);
      } else {
        payrollControl.setValidators([Validators.required, rangeValidator(1)]);
      }
      payrollControl.updateValueAndValidity();
    });

    return this.formBuilder.group({
      classCode: this.formBuilder.control<string | null>(null, Validators.required),
      description: this.formBuilder.control<string | null>(null),
      numberOfEmployees: numberOfEmployeesControl,
      payroll: payrollControl,
    });
  }

  public classCodeFormArray(location: LocationFormGroup) {
    return location.get(this.classCodesFormControlName) as FormArray<ClassCodeFormGroup>;
  }

  public addClassCode(location: LocationFormGroup) {
    this.classCodeFormArray(location).push(this.createNewClassCode());
  }

  public removeClassCode(location: LocationFormGroup, index: number) {
    this.classCodeFormArray(location).removeAt(index);
  }

  public clearClassCodes(location: LocationFormGroup) {
    // We only want to clear classCode + description because this is called on location state changes.
    this.classCodeFormArray(location).controls.forEach((classCodeGroup) => {
      classCodeGroup.patchValue({
        classCode: null,
        description: null,
      });
    });
  }

  private createClassCodesValidator() {
    return (formArray: FormArray<ClassCodeFormGroup>): ValidationErrors | null => {
      const selectedClassCodes = formArray.value.filter(
        (classCodeGroup) => !!classCodeGroup.classCode && classCodeGroup.description
      );
      // Group classcodes by code-description hash to detect dupes.
      const groupedClassCodes = groupBy(
        selectedClassCodes,
        (classCodeGroup) => `${classCodeGroup.classCode}-${classCodeGroup.description}`
      );
      const hasDuplicates = some(groupedClassCodes, (group) => group.length > 1);

      if (hasDuplicates) {
        return {
          duplicateClassCodes: {
            validationMessage: 'Duplicate class codes are not allowed on a single location.',
          },
        };
      }
      return null;
    };
  }

  /**
   * End of class code methods
   */

  /**
   * Executive election methods
   */

  private clearExecutiveElections() {
    return this.getExecutiveElectionsFormArray().clear();
  }

  private toggleAttestationOnElections(
    execElectionsFormArr: FormArray<ExecutiveElectionFormGroup>,
    attestationControl: FormControl<boolean | null>
  ): void {
    execElectionsFormArr.valueChanges.subscribe((elecElections) => {
      // If isIncluded is a non-null value it means that an executive election was made
      // that differs from the state default. This requires the attestation to be checked.
      const execElectionMade = elecElections.some((executive) => executive.isIncluded !== null);

      const wasEnabled = enableDisableControl(attestationControl, execElectionMade);

      if (wasEnabled) {
        // If the form control went from disabled -> enabled, reset the attestation.
        attestationControl.setValue(false);
      }
    });
  }

  private createExecutiveElectionValidator() {
    return (formGroup: ExecutiveElectionFormGroup): ValidationErrors | null => {
      const executiveElection = formGroup.value;

      const { locationNum, title } = executiveElection;
      if (!locationNum || !title) {
        return null;
      }

      const state = this.getLocationState(locationNum);

      if (!state) {
        return {
          invalidElection: `Something went wrong. Please try re-selecting location.`,
        };
      }

      const availExecTitles = this.availableExecTitlesByState[state] || [];

      const executiveElectionType = availExecTitles.find((execTitle) => execTitle === title);

      if (executiveElectionType === undefined) {
        return {
          invalidElection: `${title} is an invalid officer type for this state.`,
        };
      }

      return null;
    };
  }

  public getExecutiveElectionsFormArray() {
    return this.form.get('executiveElections.elections') as FormArray<ExecutiveElectionFormGroup>;
  }

  public addExecutiveElection() {
    this.getExecutiveElectionsFormArray().push(this.createNewExecutiveElection());
  }

  public removeExecutiveElection(index: number) {
    this.getExecutiveElectionsFormArray().removeAt(index);
  }

  private createNewExecutiveElection() {
    const defaultLocationNum =
      this.locationsFormArray().length === 1
        ? this.locationsFormArray().at(0).value.locationNum
        : null;

    return this.formBuilder.group(
      {
        firstName: this.formBuilder.control<string | null>(null, Validators.required),
        lastName: this.formBuilder.control<string | null>(null, Validators.required),
        ownership: this.formBuilder.control<string | null>(null, [
          Validators.required,
          rangeValidator(0, 100),
        ]),
        title: this.formBuilder.control<string | null>(null, Validators.required),
        isIncluded: this.formBuilder.control<boolean | null>(null, [Validators.required]),
        locationNum: this.formBuilder.control<string | null>(defaultLocationNum || null, [
          Validators.required,
        ]),
      },
      {
        validators: [this.createExecutiveElectionValidator()],
      }
    );
  }

  private createExecutiveElectionsFormGroup() {
    const attestationControl = this.formBuilder.control<boolean | null>(
      false,
      Validators.requiredTrue
    );
    const electionsFormArray = this.formBuilder.array<ExecutiveElectionFormGroup>([]);
    // The attestation control is disabled by defaul.
    attestationControl.disable();
    this.toggleAttestationOnElections(electionsFormArray, attestationControl);

    return this.formBuilder.group({
      attestation: attestationControl,
      elections: electionsFormArray,
    });
  }

  private getAvailExecElectionsForStates(states: string[], orgType: string) {
    const availExecutiveTitles: { [state: string]: string[] } = {};
    states.forEach((state) => {
      const executives = this.orgTypeService.getExecTypes(state, orgType);
      if (executives.length > 0) {
        availExecutiveTitles[state] = executives.map((exec) => exec.name);
      }
    });

    return availExecutiveTitles;
  }

  public setupExecutiveElectionsData(
    locations: PartialLocationFormValue[],
    orgType: string | null | undefined
  ) {
    const uniqueLocationStates = this.getUniqueLocationStates(locations);
    if (uniqueLocationStates.length === 0 || !orgType) {
      return;
    }

    const availableExecElectionInputs: AvailableExecElectionInputs = {
      orgType: orgType,
      states: uniqueLocationStates,
    };

    // If the list of unique states change or the orgType changes, we re-fetch the available executive titles for each state on the policy.
    if (!isEqual(this.availableExecElectionInputs, availableExecElectionInputs)) {
      this.availableExecElectionInputs = availableExecElectionInputs;
      const updatedAvailableElections = this.getAvailExecElectionsForStates(
        uniqueLocationStates,
        orgType
      );

      // Check if the available elections have changed. If so, clear any existing elections and surface a warning.
      if (!isEqual(this.availableExecTitlesByState, updatedAvailableElections)) {
        this.availableExecTitlesByState = updatedAvailableElections;
        if (this.getExecutiveElectionsFormArray().length > 0) {
          this.showExecutiveElectionsWarning = true;
          this.clearExecutiveElections();
        }
      }
      this.syncAllSteps();
    }

    // NO-OP
  }
  /**
   * End of Executive election methods
   */

  /**
   *  Setting initial account details methods
   */

  public setInitialAccountDetails(insuredAccount: InsuredAccount) {
    this.setOrgType(insuredAccount);
    this.setFirstLocationAddress(insuredAccount);
    this.setOperationsDescription(insuredAccount);
  }

  private setOrgType(insuredAccount: InsuredAccount) {
    const orgTypeFormControl = this.form.get('basicInfo.organizationType');
    const orgType = insuredAccount.organizationType;
    if (!orgTypeFormControl || !orgType || orgType === PLACEHOLDER_ORG_TYPE) {
      return;
    }
    orgTypeFormControl.patchValue(orgType);

    if (insuredAccount.policiesWithTerms.length > 0) {
      // If this account already has a policy, we don't want to allow changes to orgType.
      this.isOrgTypeReadonly = true;
    }
  }

  private setFirstLocationAddress(insuredAccount: InsuredAccount) {
    const firstLocationAddressForm = this.form.get('locations.0.address');
    // Patch the first location address if the user has not already interacted w/ the form.
    if (firstLocationAddressForm && !firstLocationAddressForm.dirty) {
      firstLocationAddressForm.patchValue({
        addressLine1: insuredAccount.addressLine1,
        addressLine2: insuredAccount.addressLine2,
        city: insuredAccount.city,
        state: insuredAccount.state,
        zip: insuredAccount.zip,
      });
    }
  }

  private setOperationsDescription(insuredAccount: InsuredAccount) {
    const descriptionControl = this.form.get('basicInfo.accountDescOps');
    if (descriptionControl && insuredAccount.description) {
      descriptionControl.patchValue(insuredAccount.description);
    }
  }

  /**
   * End of setting initial account details methods
   */

  /**
   * Coverage-related methods
   */

  getDeductibleOptions(locations: PartialLocationFormValue[]) {
    const uniqueLocationStates = this.getUniqueLocationStates(locations);
    // TODO - needs to be revisted for multi-state policies. Currently we only allow one unique state.
    const state = uniqueLocationStates[0];
    return DEDUCTIBLE_OPTIONS_BY_STATE[state] || DEFAULT_DEDUCTIBLE_OPTIONS;
  }

  adjustDeductibleForStateIfNecessary(
    deductible: string | null | undefined,
    locations: PartialLocationFormValue[]
  ) {
    const uniqueLocationStates = this.getUniqueLocationStates(locations);
    const hasNoDeductibleState = uniqueLocationStates.some((state) =>
      NO_DEDUCTIBLE_STATES.includes(state)
    );

    // Check if the first unique state has specific options and if the selected option is invalid.
    // TODO - this needs to be reconsidered for multi-state policies.
    const state = uniqueLocationStates[0];
    if (!state) {
      return;
    }

    const deductibleOptions = DEDUCTIBLE_OPTIONS_BY_STATE[state] || DEFAULT_DEDUCTIBLE_OPTIONS;

    const hasDeductibleInNoDeductibleState = hasNoDeductibleState && deductible !== '0';
    const hasInvalidDeductible = !deductibleOptions[deductible || ''];

    // If the user has selected a deductible for a no deductible state or invalid deductible option for state, we revert it to no deductible and surface a warning to the user.
    if (hasDeductibleInNoDeductibleState || hasInvalidDeductible) {
      const deductibleControl = this.form.get('basicInfo.deductible');
      deductibleControl?.patchValue(DEFAULT_DEDUCTIBLE_OPTION);
      this.showDeductibleChangedWarning = true;
    }
  }

  /**
   * End of coverage related methods
   */

  /**
   * State/Jurisdiction-specific coverage/credit questions
   */

  getCoveragesCreditsFormGroup() {
    return this.form.get('coveragesAndCredits') as CoveragesCreditsFormGroup;
  }

  private createStateCoveragesCreditsFormGroup(): CoveragesCreditsFormGroup {
    return this.formBuilder.group({
      consecutiveYearsOfCoverage: this.formBuilder.control<string | null>(null, [
        Validators.required,
        rangeValidator(0, 200),
      ]),
      threeYearLossRatio: this.formBuilder.control<string | null>(null, [Validators.required]),
      lostTimeClaims: this.formBuilder.control<string | null>(null, [Validators.required]),
      percentageMedicalClaims: this.formBuilder.control<string | null>(null, [Validators.required]),
      payrollPercentageUninsuredContractors: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      numberOfFulltimeW2Employees: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      safeWorkplace: this.formBuilder.group({
        lowExperienceMod5Years: this.formBuilder.control<boolean | null>(null, [
          Validators.required,
        ]),
        returnToWorkProgram: this.formBuilder.control<boolean | null>(null, [Validators.required]),
        designatedHealthProviders: this.formBuilder.control<boolean | null>(null, [
          Validators.required,
        ]),
        safetyProgram: this.formBuilder.control<boolean | null>(null, [Validators.required]),
        promptClaimsReporting: this.formBuilder.control<boolean | null>(null, [
          Validators.required,
        ]),
        drugScreening: this.formBuilder.control<boolean | null>(null, [Validators.required]),
      }),
      nonNCCIExperienceModifiers: this.formBuilder.group({
        MI: this.createExperienceModFormGroup('MI'),
        NC: this.createExperienceModFormGroup('NC'),
        PA: this.createExperienceModFormGroup('PA'),
      }),
    });
  }

  enableDisableStateCoveragesCreditsControls(locations: PartialLocationFormValue[]) {
    const uniqueLocationStates = this.getUniqueLocationStates(locations);
    const coveragesCreditsFormGroup = this.getCoveragesCreditsFormGroup();
    Object.keys(coveragesCreditsFormGroup.controls).forEach(
      (coverageQuestion: CoverageCreditsKeys) => {
        const questionControl = coveragesCreditsFormGroup.get(coverageQuestion);
        const applicableStates = COVERAGE_QUESTIONS_STATE_MAPPER[coverageQuestion] || [];
        this.enableDisableControlBasedOnStates(
          questionControl,
          applicableStates,
          uniqueLocationStates
        );
        // Handle nested controls for some form groups.
        if (coverageQuestion === 'nonNCCIExperienceModifiers') {
          this.enableDisableExperienceModControls(uniqueLocationStates);
        }
      }
    );
    this.syncAllSteps();
  }

  private enableDisableControlBasedOnStates(
    control: AbstractControl | null,
    applicableStates: StateCode[],
    uniqueLocationStates: string[]
  ) {
    if (!control) {
      return;
    }

    if (intersection(applicableStates, uniqueLocationStates).length > 0) {
      control.enable();
    } else {
      control.disable();
    }
  }

  private enableDisableExperienceModControls(uniqueLocationStates: string[]) {
    const experienceModFormGroup = this.form.get('coveragesAndCredits.nonNCCIExperienceModifiers');
    if (experienceModFormGroup instanceof FormGroup) {
      Object.keys(experienceModFormGroup.controls).forEach((stateControlKey: StateCode) => {
        const control = experienceModFormGroup.get(stateControlKey);
        this.enableDisableControlBasedOnStates(control, [stateControlKey], uniqueLocationStates);
      });
    }
  }

  private createExperienceModFormGroup(stateCode: string) {
    return this.formBuilder.group(
      {
        riskId: this.formBuilder.control<string | null>(null, riskIdValidator(stateCode)),
        experienceMod: this.formBuilder.control<string | null>(null, rangeValidator(0.25, 4, true)),
      },
      {
        validators: [riskIdExperienceModValidator],
      }
    );
  }

  /**
   * End of State/Jurisdiction-specific questions
   */

  /**
   *  Local-dev helper methods
   */
  debugSaveFormData() {
    window.localStorage.setItem('AttuneWCFormData', JSON.stringify(this.form.value));
    window.localStorage.setItem(
      'AttuneWCFormStepData',
      JSON.stringify({
        steps: this.steps,
        currentStep: this.currentStep,
      })
    );
  }

  debugLoadFormData() {
    const formDataStorage = window.localStorage.getItem('AttuneWCFormData');
    const stepDataStorage = window.localStorage.getItem('AttuneWCFormStepData');

    if (!formDataStorage || !stepDataStorage) {
      return;
    }

    const formData = JSON.parse(formDataStorage);
    const stepData = JSON.parse(stepDataStorage);

    this.form.patchValue(formData);

    this.steps = stepData.steps;

    const currentStep = this.steps.find(
      (step: RouteFormStep) => step.slug === stepData.currentStep.slug
    );
    if (currentStep) {
      this.currentStep = currentStep;
    }
  }
  /**
   * End of local-dev helper methods
   */
}
