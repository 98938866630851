import { Injectable } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import {
  rangeValidator,
  requireCheckboxesToBeCheckedValidator,
  percentValidator,
  enableDisableControl,
  getControl,
  getFormGroup,
} from 'app/shared/helpers/form-helpers';
import { US_DATE_MASK } from 'app/constants';
import {
  ALL_PROF_LIABILITIES,
  AVAILABLE_BUSINESS_TYPES,
  AVAILABLE_LIABILITIES_BY_BUILDING_CLASSIFICATION,
  BARBER_SHOP_CLASS_CODE,
  EACH_COVERED_JOB_SITE_LIMIT_DEFAULT,
  HNOA_AVAILABLE_CLASS_CODES,
  HNOA_AVAILABLE_STATES,
  HNOA_NOT_AVAILABLE_BUSINESS_TYPES,
  HNOA_UW_QUESTIONS,
  KEEP_V1_CONTRACTOR_CONTROL_STATES,
  LIQUOR_LIABILITY_COVERAGE_EXCLUSION_STATES,
  LIQUOR_UW_EFFECTIVE_DATE,
  NAIL_SALON_CLASS_CODE,
  OperationsCoverage,
  PLUMBER_CLASS_CODES_WITH_UW_QUESTION,
  PROF_LIABILITY_FIELD_NAMES,
  SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM,
} from 'app/features/attune-bop/models/constants';
import {
  locationsHaveLiquorSalesValidator,
  perOccurrenceLimitForLiquorLiability,
  validateLiquorLicenseNumber,
} from 'app/features/attune-bop/models/form-validators';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import {
  locationsHaveLroBuildings,
  classificationHasByobAlcohol,
  classificationsHaveBeverageStore,
  classificationsHaveLiquor,
} from '../../models/classification-helpers';
import { get, flatMap, uniq } from 'lodash';
import { parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import { BaseFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';

@Injectable()
export class AttuneBopQuoteLiabilityCoveragesFormService extends BaseFormService {
  public bopExposureSubject: BehaviorSubject<BopExposureInfo | null> = new BehaviorSubject(null);
  private effectiveDateControl: UntypedFormControl;

  private showHnoaUwQuestionsSubject = new BehaviorSubject(false);
  showHnoaUwQuestions$ = this.showHnoaUwQuestionsSubject.asObservable();

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  public initializeForm(locations: UntypedFormArray, effectiveDateControl: UntypedFormControl) {
    this.effectiveDateControl = effectiveDateControl;
    this.createLiabilityCoveragesForm(locations);
    this.subscribeValueChanges(locations);
    return this.form;
  }

  private createLiabilityCoveragesForm(locations: UntypedFormArray) {
    const limitPerOccurrenceOfLiabilityAndMedicalExpenses = this.formBuilder.control(
      1000000,
      Validators.required
    );

    this.form = this.formBuilder.group({
      damageToPremises: [50000, Validators.required],
      limitPerOccurrenceOfLiabilityAndMedicalExpenses,
      limitPerPersonOfMedicalExpenses: [5000, Validators.required],
      opticalAndHearingAidEstablishmentsProfessionalLiability: [false, Validators.required],
      opticalAndHearingAidSales: [{ value: 0, disabled: true }, Validators.required],
      liquorLiability: this.formBuilder.group({
        optedIn: [
          { value: null, disabled: true },
          [
            Validators.required,
            locationsHaveLiquorSalesValidator(locations),
            perOccurrenceLimitForLiquorLiability(
              limitPerOccurrenceOfLiabilityAndMedicalExpenses,
              1000000
            ),
          ],
        ],
        eachCommonCauseLimit: [1000000, Validators.required],
        aggregateLimit: [2000000, Validators.required],
        liquorLicenseNumber: [
          { value: '', disabled: false },
          [Validators.required, validateLiquorLicenseNumber],
        ],
      }),
      printersErrorsAndOmissionsProfessionalLiability: [false, Validators.required],
      printersErrorsAndOmissionsSalesOrPayroll: [{ value: 0, disabled: true }, Validators.required],
      funeralDirectorsProfessionalLiability: [false, Validators.required],
      barberShopsAndHairSalonsProfessionalLiability: [false, Validators.required],
      barberShopsNumberOfOperators: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      beautySalonsProfessionalLiability: [false, Validators.required],
      beautySalonsDescriptionOfAdditionalServices: [
        { value: 'Beauty Parlors and Hair Styling Salons including Nail Salons', disabled: true },
      ],
      beautySalonsNumberOfOperators: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      veterinariansProfessionalLiability: [false, Validators.required],
      numberOfVeterinarians: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      veterinariansOnlyTreatHousePets: [{ value: true, disabled: true }, Validators.required],
      // Contractors (older form)
      acceptSnowPlowCompletedOpsCoverage: [false, Validators.required],
      eachCoveredJobSiteLimit: [EACH_COVERED_JOB_SITE_LIMIT_DEFAULT.toString()],
      propertyInTransitLimit: [
        '5000',
        [Validators.required, Validators.min(5000), Validators.max(100000)],
      ],
      // Contractors (newer form)
      installationLimit: [5000, Validators.required],
      toolsBlanketLimit: [3000, Validators.required],
      toolsPerItemLimit: [500, Validators.required],
      equipmentLeftInVehicle: [null, Validators.required],
      stressTestsWaterLines: [null, Validators.required],
      employeeHandbook: [null, Validators.required],
      janitorialServices: [null, Validators.required],
      stopGap: [{ value: false, disabled: true }, Validators.required],
      employeeBenefitsLiabilityCoverage: this.formBuilder.group({
        optedIn: [false, Validators.required],
        retroactiveDate: [moment().format(US_DATE_MASK), Validators.required],
        eachEmployeeLimit: [1000000, Validators.required],
        aggregateLimit: [1000000, Validators.required],
        deductible: [1000, Validators.required],
      }),
      // the following 5 HNOA UW questions are not sent to the backend and only used to to possibly prevent the frontend from sending hnoaNumberOfDrivers
      hnoaOver18: [false, Validators.required],
      hnoaCertificateOfInsurance: [false, Validators.required],
      hnoa3rdPartyDelivery: [false, Validators.required],
      hnoaMotorVehicles: [false, Validators.required],
      hnoaLocationRadius: [false, Validators.required],
      acceptHiredNonOwnedAutoCoverage: [false, Validators.required],
      hnoaNumberOfDrivers: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      eligibilityUWQuestions: this.getEligibilityUWFormGroup(),
    });
  }

  private getEligibilityUWFormGroup() {
    return this.formBuilder.group({
      wholesalerAndMercantileOperations: this.formBuilder.group(
        {
          importingGoods: [false, Validators.required],
          manufacturing1: [false, Validators.required],
          repackaging: [false, Validators.required],
          sellingWithInsuredsBranding: [false, Validators.required],
          sellingProductsManufacturedByOthers: [false, Validators.required],
          distributingProducts: [false, Validators.required],
          otherOperationsNotMentioned: [false, Validators.required],
          otherOperationsText: [{ value: '', disabled: true }, Validators.required],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      liveEntertainmentActivities: this.formBuilder.group(
        {
          barGames: [false, Validators.required],
          dancing: [false, Validators.required],
          incidentalMusic: [false, Validators.required],
          karaoke: [false, Validators.required],
          DJs: [false, Validators.required],
          otherActivitiesNotMentioned: [false, Validators.required],
          otherActivitiesText: [{ value: '', disabled: true }, Validators.required],
          noneOfTheAbove: [false, Validators.required],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      nailSalonBeautyParlorBarberShopServices: this.formBuilder.group(
        {
          medispaOrCosmeticServices: this.formBuilder.group({
            botox: [false, Validators.required],
            chemicalPeels: [false, Validators.required],
            coolsculpting: [false, Validators.required],
            dermabrasion: [false, Validators.required],
            eyelashExtensions: [false, Validators.required],
            hairTransplant: [false, Validators.required],
            laserHairRemoval: [false, Validators.required],
            laserSkinResurfacing: [false, Validators.required],
            skinTanning: [false, Validators.required],
            surgicalCosmeticRemedies: [false, Validators.required],
            tattooOrMicroblading: [false, Validators.required],
          }),
          pedicuresManicures: [false, Validators.required],
          hairStyling: [false, Validators.required],
          beardShave: [false, Validators.required],
          makeupArtistry: [false, Validators.required],
          waxing: [false, Validators.required],
          massages: [false, Validators.required],
          otherServicesNotMentioned: [false, Validators.required],
          otherServicesText: [{ value: '', disabled: true }, Validators.required],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      totalSubcontractorCostsPercentage: [null, [Validators.required, percentValidator]],
      liquorLiabilityUWQuestions: this.formBuilder.group({
        liquorLicenseSuspendedInLast10Years: [null, Validators.required],
        patronAgeCheckUnder40: [null, Validators.required],
        identificationCheckedElectronically: [{ value: null, disabled: true }],
        yearsOfLiquorExperience: [null, [Validators.required, rangeValidator(0, 999)]],
        requireAlcoholTraining: [null, Validators.required],
        yearsLicenseIssuedFor: [null, [Validators.required, rangeValidator(0, 999)]],
        liquorSalesOrLowerPrice: [null, Validators.required],
      }),
    });
  }

  private subscribeValueChanges(locations: UntypedFormArray) {
    /* We are checking for a change in the per occurence limit to see if we need to re-check
    validation for liquor liability. */
    (<UntypedFormControl>(
      this.form.get('limitPerOccurrenceOfLiabilityAndMedicalExpenses')
    )).valueChanges.subscribe((_value) => {
      const optedIn = <UntypedFormControl>this.form.get('liquorLiability.optedIn');
      if (optedIn) {
        optedIn.updateValueAndValidity();
      }
    });

    (<UntypedFormControl>this.form.get('liquorLiability.optedIn'))?.valueChanges.subscribe(
      (liquorLiabililityOption) => {
        const licenseControl = this.form.get('liquorLiability.liquorLicenseNumber');
        const liquorUWQuestionsControl = this.form.get(
          'eligibilityUWQuestions.liquorLiabilityUWQuestions'
        );

        if (licenseControl) {
          enableDisableControl(
            licenseControl,
            this.shouldShowLiquorLicense(liquorLiabililityOption, locations.value)
          );
        }

        if (liquorUWQuestionsControl) {
          enableDisableControl(
            liquorUWQuestionsControl,
            this.shouldShowLiquorLiabilityUWQuestions(liquorLiabililityOption, locations.value)
          );
        }
      }
    );

    getControl(this.form, 'acceptHiredNonOwnedAutoCoverage').valueChanges.subscribe(
      (hnoaValue: boolean) => {
        const numDriversControl = getControl(this.form, 'hnoaNumberOfDrivers');
        enableDisableControl(numDriversControl, hnoaValue);
        HNOA_UW_QUESTIONS.forEach((ctrlName) => {
          const ctrl = getControl(this.form, ctrlName);
          enableDisableControl(ctrl, hnoaValue && this.showHnoaUwQuestionsSubject.value);
        });
      }
    );

    const eligibilityUWQuestionsGroup = getFormGroup(this.form, 'eligibilityUWQuestions');
    this.initEligibilityUWNoneOfTheAboveSubscription(eligibilityUWQuestionsGroup);
    this.initEligibilityUWCheckboxSubscriptions(eligibilityUWQuestionsGroup);

    Object.keys(PROF_LIABILITY_FIELD_NAMES).forEach(
      (professionalLiabilityFieldName: OperationsCoverage) => {
        const profLiabField = getControl(this.form, professionalLiabilityFieldName);
        profLiabField.valueChanges.subscribe((plValue: boolean) => {
          const childFieldNames = PROF_LIABILITY_FIELD_NAMES[professionalLiabilityFieldName];
          childFieldNames.forEach((childFieldName: string) => {
            const childField = getControl(this.form, childFieldName);
            enableDisableControl(childField, plValue);
          });
        });
      }
    );
    this.setBopExposureListeners();
  }

  private setBopExposureListeners() {
    this.bopExposureSubject
      .asObservable()
      .pipe(filter((bopExposureInfo): bopExposureInfo is BopExposureInfo => !!bopExposureInfo))
      .subscribe((bopExposure) => {
        this.updateHNOA(bopExposure);
        this.updateContractorFields(bopExposure);
        this.updateBOPEligibilityUWQuestions(bopExposure);
        this.updateBOPUnderwritingQuestions(bopExposure);
        this.updateProfessionalLiabilities(bopExposure);
        this.updateLiquorControls(bopExposure);
      });
  }

  private shouldShowLiquorLicense(liquorOptedIn: boolean, locations: BopLocation[]): boolean {
    const isAfterLiquorUWEffectiveDate = moment(this.effectiveDateControl.value).isSameOrAfter(
      LIQUOR_UW_EFFECTIVE_DATE
    );

    const hasLRO = locationsHaveLroBuildings(locations);

    return liquorOptedIn && isAfterLiquorUWEffectiveDate && !hasLRO;
  }

  private shouldShowLiquorLiabilityUWQuestions(
    liquorOptedIn: boolean,
    locations: BopLocation[]
  ): boolean {
    const allClassifications = flatMap(locations, (loc) => {
      return loc.buildings.map((building: BopBuilding) => building.exposure.classification);
    });

    const isAfterLiquorUWEffectiveDate = moment(this.effectiveDateControl.value).isSameOrAfter(
      LIQUOR_UW_EFFECTIVE_DATE
    );

    const allHaveBYOCode = allClassifications.every((classification) => {
      return classificationHasByobAlcohol(classification.code);
    });

    const hasBeverageStoreCode = allClassifications.some((classification) => {
      return classificationsHaveBeverageStore(classification.code);
    });

    const hasLRO = locationsHaveLroBuildings(locations);

    return (
      liquorOptedIn &&
      isAfterLiquorUWEffectiveDate &&
      !hasLRO &&
      !allHaveBYOCode &&
      !hasBeverageStoreCode
    );
  }

  private initEligibilityUWNoneOfTheAboveSubscription(
    eligibilityUWQuestionsGroup: UntypedFormGroup
  ) {
    const formGroup = getFormGroup(eligibilityUWQuestionsGroup, 'liveEntertainmentActivities');
    const noneOfTheAboveControl = getControl(formGroup, 'noneOfTheAbove');
    const aboveControls = Object.values(formGroup.controls).filter(
      (control) => control.value === false && control !== noneOfTheAboveControl
    );
    noneOfTheAboveControl.valueChanges.subscribe((checkboxVal) => {
      if (checkboxVal) {
        aboveControls.forEach((control) => control.setValue(false));
      }
    });
    aboveControls.forEach((control) => {
      control.valueChanges.subscribe((checkboxVal) => {
        if (checkboxVal) {
          noneOfTheAboveControl.setValue(false);
        }
      });
    });
  }

  private initEligibilityUWCheckboxSubscriptions(eligibilityUWQuestionsGroup: UntypedFormGroup) {
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'wholesalerAndMercantileOperations',
      'otherOperationsNotMentioned',
      'otherOperationsText'
    );
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'liveEntertainmentActivities',
      'otherActivitiesNotMentioned',
      'otherActivitiesText'
    );
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'nailSalonBeautyParlorBarberShopServices',
      'otherServicesNotMentioned',
      'otherServicesText'
    );
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'liquorLiabilityUWQuestions',
      'patronAgeCheckUnder40',
      'identificationCheckedElectronically'
    );
  }

  private updateHNOA(bopExposure: BopExposureInfo) {
    const showHnoaUwQuestions: boolean = bopExposure.locations.every(
      (location: BopLocation) =>
        HNOA_AVAILABLE_STATES.includes(location.locationDetails.state) &&
        location.buildings.some((building: BopBuilding) => {
          if (!building.exposure.classification.code) {
            return false;
          }
          return HNOA_AVAILABLE_CLASS_CODES.includes(building.exposure.classification.code.code);
        })
    );
    this.showHnoaUwQuestionsSubject.next(showHnoaUwQuestions);
    const shouldEnable =
      this.getExposureFieldValue(bopExposure, 'lessorsRisk') ||
      !HNOA_NOT_AVAILABLE_BUSINESS_TYPES.includes(
        this.getExposureFieldValue(bopExposure, 'businessType')
      ) ||
      showHnoaUwQuestions;
    const hnoaControl = getControl(this.form, 'acceptHiredNonOwnedAutoCoverage');
    enableDisableControl(hnoaControl, shouldEnable);
    const numDriversControl = getControl(this.form, 'hnoaNumberOfDrivers');
    enableDisableControl(numDriversControl, hnoaControl.value === true);
    const shouldShowHnoaControl = showHnoaUwQuestions && hnoaControl.value === true;
    HNOA_UW_QUESTIONS.forEach((ctrlName) => {
      const ctrl = getControl(this.form, ctrlName);
      enableDisableControl(ctrl, shouldShowHnoaControl);
    });
  }

  private updateContractorFields(bopExposure: BopExposureInfo) {
    const isContractor = bopExposure.locations.some((location) => {
      return location.buildings.some((building) => {
        return (
          !building.exposure.lessorsRisk &&
          building.exposure.businessType === AVAILABLE_BUSINESS_TYPES.Contractor
        );
      });
    });

    // NOTE: Snow Plow / Completed Ops is available for all contractors EXCEPT NY (BOP-258)
    const snowPlowControl = getControl(this.form, 'acceptSnowPlowCompletedOpsCoverage');
    if (isContractor && bopExposure.baseState !== 'NY') {
      enableDisableControl(snowPlowControl, true);
    } else {
      enableDisableControl(snowPlowControl, false);
    }

    if (isContractor) {
      const useV1ContractorControls =
        !!bopExposure.baseState &&
        KEEP_V1_CONTRACTOR_CONTROL_STATES.includes(bopExposure.baseState);
      this.enableDisableContractorsV1(useV1ContractorControls);
      this.enableDisableContractorsV2(!useV1ContractorControls);
    } else {
      // Disable all Contractors' Controls
      this.enableDisableContractorsV1(false);
      this.enableDisableContractorsV2(false);
    }
  }

  private enableDisableContractorsV1(enabled: boolean) {
    const eachJobLimitControl = getControl(this.form, 'eachCoveredJobSiteLimit');
    const propertyInTransitControl = getControl(this.form, 'propertyInTransitLimit');

    enableDisableControl(eachJobLimitControl, enabled);
    enableDisableControl(propertyInTransitControl, enabled);
  }

  private enableDisableContractorsV2(enabled: boolean) {
    const installationLimitControl = getControl(this.form, 'installationLimit');
    const toolsBlanketLimitControl = getControl(this.form, 'toolsBlanketLimit');
    const toolsPerItemLimitControl = getControl(this.form, 'toolsPerItemLimit');

    enableDisableControl(installationLimitControl, enabled);
    enableDisableControl(toolsBlanketLimitControl, enabled);
    enableDisableControl(toolsPerItemLimitControl, enabled);
  }

  private updateBOPEligibilityUWQuestions(bopExposure: BopExposureInfo) {
    const questionsToEnableDisable = {
      wholesalerAndMercantileOperations: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk &&
            ['Wholesaler', 'Mercantile'].includes(building.exposure.businessType)
        )
      ),
      liveEntertainmentActivities: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk &&
            ['RestaurantCasualDining', 'RestaurantFineDining'].includes(
              building.exposure.businessType
            )
        )
      ),
      nailSalonBeautyParlorBarberShopServices: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk &&
            [NAIL_SALON_CLASS_CODE, BARBER_SHOP_CLASS_CODE].includes(
              building.exposure.classification.code?.code || ''
            )
        )
      ),
      totalSubcontractorCostsPercentage: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk && building.exposure.businessType === 'Contractor'
        )
      ),
    };
    const eligibilityUWQuestionsGroup = getFormGroup(this.form, 'eligibilityUWQuestions');
    Object.entries(questionsToEnableDisable).forEach(([question, enabled]) => {
      const questionControl = getControl(eligibilityUWQuestionsGroup, question);
      enableDisableControl(questionControl, enabled);
    });
  }

  private updateBOPUnderwritingQuestions(bopExposure: BopExposureInfo) {
    // Underwriting has requested that we add a few questions when a specific classcode is selected.
    const allClassifications = this.getAllClassificationCodes(bopExposure, true);
    const hasUWPlumberClassification = allClassifications.some((classification) =>
      PLUMBER_CLASS_CODES_WITH_UW_QUESTION.includes(classification)
    );

    // We care if ANY location has a AOP deductible that is < 2500.
    const hasLocationWithQualifyingDeductible = bopExposure.locations.some((location) => {
      return Number(location.locationDetails.propertyDeductible) < 2500;
    });
    // We want to find the total square footage of all locations and buildings
    const totalSquareFootage = bopExposure.locations.reduce((sum, location) => {
      const buildingSquareFootage = location.buildings.reduce((buildingSum, building) => {
        return buildingSum + parseMaskedInt(building.exposure.squareFootage);
      }, 0);
      return sum + buildingSquareFootage;
    }, 0);
    const hasLRO = locationsHaveLroBuildings(bopExposure.locations);

    const equipmentOvernightFormControl = getControl(this.form, 'equipmentLeftInVehicle');

    const stressTestedLinesFormControl = getControl(this.form, 'stressTestsWaterLines');

    const employeeHandbookFormControl = getControl(this.form, 'employeeHandbook');

    const janitorialServicesFormControl = getControl(this.form, 'janitorialServices');

    enableDisableControl(
      equipmentOvernightFormControl,
      hasUWPlumberClassification && hasLocationWithQualifyingDeductible
    );

    // For this question we do not care about the deductible.
    enableDisableControl(stressTestedLinesFormControl, hasUWPlumberClassification);

    enableDisableControl(
      employeeHandbookFormControl,
      totalSquareFootage < SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM && !hasLRO
    );

    enableDisableControl(
      janitorialServicesFormControl,
      totalSquareFootage < SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM && !hasLRO
    );
  }

  private updateProfessionalLiabilities(bopExposure: BopExposureInfo) {
    const allClassifications = this.getAllClassificationCodes(bopExposure);
    const availableCoverages = uniq(
      flatMap(allClassifications, (classification) => {
        return AVAILABLE_LIABILITIES_BY_BUILDING_CLASSIFICATION[classification];
      })
    );

    let hasWackyFLCase = false;

    ALL_PROF_LIABILITIES.forEach((liabilityFormKey: OperationsCoverage) => {
      const liabilityControl = getControl(this.form, liabilityFormKey);

      let shouldEnable = false;
      if (availableCoverages.includes(liabilityFormKey)) {
        shouldEnable = true;

        // Wacky exception for FL Beauty Salons - https://hamiltonusa.atlassian.net/browse/BOP-1535
        // https://www.dropbox.com/s/h8znyprj5o52nkh/barber_professional_liability.png?dl=0
        if (
          (liabilityFormKey === OperationsCoverage.BEAUTY_SALON ||
            liabilityFormKey === OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON) &&
          bopExposure.baseState === 'FL'
        ) {
          hasWackyFLCase = true;
          return;
        }
      }

      enableDisableControl(liabilityControl, shouldEnable);
    });

    if (hasWackyFLCase) {
      const barberControl = getControl(this.form, OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON);
      const beautyControl = getControl(this.form, OperationsCoverage.BEAUTY_SALON);

      enableDisableControl(barberControl, true);
      enableDisableControl(beautyControl, false);
    }
  }

  // NOTE: there is another liquor aggregate listener in bop-quote-form-component
  private updateLiquorControls(bopExposureInfo: BopExposureInfo) {
    const locations = bopExposureInfo.locations;
    const baseState: any = bopExposureInfo.baseState;

    const liquorControl = getControl(this.form, 'liquorLiability.optedIn');

    const liquorEachCauseLimit = getControl(this.form, 'liquorLiability.eachCommonCauseLimit');
    const liquorAggregateControl = getControl(this.form, 'liquorLiability.aggregateLimit');

    const liquorLiabilityLicenseControl = getControl(
      this.form,
      'liquorLiability.liquorLicenseNumber'
    );

    const liquorLiabilityUWQuestionsControl = getControl(
      this.form,
      'eligibilityUWQuestions.liquorLiabilityUWQuestions'
    );

    const allClassifications = flatMap(locations, (loc) => {
      return loc.buildings.map((building: BopBuilding) => building.exposure.classification);
    });

    const liquorCoverageAvailable =
      classificationsHaveLiquor(allClassifications) &&
      !LIQUOR_LIABILITY_COVERAGE_EXCLUSION_STATES.includes(baseState);

    const liquorDefaultsToYes = classificationsHaveLiquor(allClassifications, true);

    const liquorOptedIn = this.form.value?.liquorLiability?.optedIn || false;

    if (!liquorCoverageAvailable) {
      // Liquor coverage is not available
      enableDisableControl(liquorControl, false);
      enableDisableControl(liquorAggregateControl, false);
      enableDisableControl(liquorEachCauseLimit, false);
      enableDisableControl(liquorLiabilityLicenseControl, false);
      enableDisableControl(liquorLiabilityUWQuestionsControl, false);
      return;
    } else {
      // Liquor Coverage is Available
      const modified = enableDisableControl(liquorControl, true);
      enableDisableControl(liquorAggregateControl, true);

      enableDisableControl(
        liquorLiabilityUWQuestionsControl,
        this.shouldShowLiquorLiabilityUWQuestions(liquorOptedIn, locations)
      );

      enableDisableControl(
        liquorLiabilityLicenseControl,
        this.shouldShowLiquorLicense(liquorOptedIn, locations)
      );

      // when liquor license is shown but UW questions are not, do not require liquor license number
      const shouldNotRequireLiquorLicenseNumber =
        this.shouldShowLiquorLicense(liquorOptedIn, locations) &&
        !this.shouldShowLiquorLiabilityUWQuestions(liquorOptedIn, locations);
      if (shouldNotRequireLiquorLicenseNumber) {
        liquorLiabilityLicenseControl.setValidators([validateLiquorLicenseNumber]);
      }

      // Illinois doesn't collect ECC, so it's disabled in IL
      enableDisableControl(liquorEachCauseLimit, baseState !== 'IL');

      if (modified) {
        if (liquorDefaultsToYes) {
          // Liquor Coverage defaults to true for codes where liquor sales are required
          this.form.patchValue({
            liquorLiability: { optedIn: true },
          });
        } else {
          this.form.patchValue({
            liquorLiability: { optedIn: false },
          });
        }
      }
    }
  }

  private initCheckboxControlledTextField(
    form: UntypedFormGroup,
    path: string,
    checkboxName: string,
    textFieldName: string
  ) {
    const formGroup = getFormGroup(form, path);
    const checkboxControl = getControl(formGroup, checkboxName);
    const textControl = getControl(formGroup, textFieldName);
    combineLatest([
      formGroup.statusChanges.pipe(
        map((status) => status !== 'DISABLED'),
        distinctUntilChanged()
      ),
      checkboxControl.valueChanges,
    ]).subscribe(([isFormGroupEnabled, checkboxValue]) => {
      if (isFormGroupEnabled) {
        enableDisableControl(textControl, checkboxValue);
      }
    });
  }

  // FIRST classification only [!]
  private getExposureFieldValue(bopExposure: BopExposureInfo, exposureField: string) {
    return get(bopExposure, `locations.0.buildings.0.exposure.${exposureField}`);
  }

  public yesToAllPreHnoaQuestions(): boolean {
    return HNOA_UW_QUESTIONS.every((ctrlName) => getControl(this.form, ctrlName).value);
  }

  public getAllClassificationCodes(bopExposure: BopExposureInfo, filterLessorsRiskCodes = false) {
    const allClassifications: string[] = [];
    bopExposure.locations.forEach((location: BopLocation) => {
      location.buildings.forEach((building: BopBuilding) => {
        if (!building.exposure.classification.code) {
          return;
        }
        const classCode = building.exposure.classification.code.code;
        if (!filterLessorsRiskCodes || (filterLessorsRiskCodes && !building.exposure.lessorsRisk)) {
          allClassifications.push(classCode);
        }
      });
    });
    return allClassifications;
  }
}
