export const EXCESS_BUSINESS_TYPE = 'CommercialUmbrella';

export const NOT_TAKEN_CANCELLATION_REASONS = ['Policy not-taken'];

export const UNDERWRITING_CANCELLATION_REASONS = [
  'Fraud',
  'Violation of health, safety, fire, or codes',
  'Vacant; below occupancy limit',
  'Underwriting reasons',
  'Suspension or revocation of license or permits',
  'Substantial change in risk or increase in hazard',
  'Participation in wrap-up complete',
  'Non-report of payroll or failure to cooperate',
  'Non disclosure of losses or underwriting information',
  'No longer eligible for group or program',
  'Loss of reinsurance',
  'Failure to cooperate',
  'Failure to comply with terms and conditions',
  'Failure to comply with safety recommendations',
  'Criminal conduct by the insured',
  'Condemned/unsafe',
  'LossHistory',
  'Operations characteristics',
  'Products characteristics',
  'Does not meet program/product requirements',
  'Requested coverages/limits not available',
  'Required information not provided',
  'AMBEST Rating for ULCarrier not eligible',
];

export const PAYMENT_CANCELLATION_REASONS = ['Payment not received', 'Payment history'];

export const INSURED_CANCELLATION_REASONS = [
  'Out of business/sold',
  'No employees/operation',
  "Insured's request > 30 days of policy effective date (No Finance co)",
  "Insured's request - (finance co. nonpay) > 30 days of policy effective date",
  "Insured's request < or =30 days of policy effective date (No Finance co)",
  "Insured's request - (Finance co. nonpay) < or = 30 days of policy effective date",
  'Business closed',
  'Business sold',
];

export const ROLLOVER_FAQS: Faq[] = [
  {
    question: 'Who is the carrier?',
    answer:
      'Accredited Surety and Casualty Company, Inc. (“Accredited”) is a wholly-owned subsidiary of Randall & Quilter Investment Holdings Ltd. Accredited is an A- rated company (Excellent) by A.M. Best and admitted insurer licensed in all 50 states and the District of Columbia. <a href="http://blog.attuneinsurance.com/meet-accredited/" target="_blank">Learn more.</a>',
  },
  {
    question: 'How does billing work for the new  BOP+ product?',
    answer:
      'At this time, there are no changes to the billing flow. Any insured enrolled in automatic payments will stay enrolled for their new policy term. We will continue to bill the insured directly and accept credit/debit cards and banking ACH as a payment form. <a href="https://attunehelp.zendesk.com/hc/en-us/articles/360032230751-How-does-Attune-bill-" target="_blank"> Learn more.</a>',
  },
  {
    question: 'Who is handling claims?',
    answer:
      'North American Risk Services (NARS) is the third-party administrator for the Attune BOP+ program. Dedicated to providing the best possible results for your clients, NARS has experience handling claims for insurers, brokerages, and managing general agencies. <a href="http://blog.attuneinsurance.com/nars-our-new-claims-tpa/" target="_blank">Learn more.</a> ',
  },
  {
    question: 'How do I transition my clients to BOP+?',
    answer:
      'With our “auto-bind” feature, we’re making it easy to transition your clients from Blackboard to Accredited. <a href="https://blog.attuneinsurance.com/transitioning-your-clients-to-bop/" target="_blank">Here’s a blog post with more information on what you can expect</a>.',
  },
];

export function getV3RolloverFaqs(accountsUrl: string): Faq[] {
  return [
    {
      question: 'Who is the carrier?',
      answer:
        "Your clients' coverage is transitioning to Fortegra. Fortegra, a multinational specialty insurer headquartered in Jacksonville, Florida, prides itself on an A.M. Best Financial Strength Rating of “A-” (Excellent), highlighting their reliability and financial stability.",
      isToggled: true,
    },
    {
      question: 'When will the transition happen?',
      answer:
        'We will be rolling out our Fortegra BOP on a state by state basis, starting with new business and then transitioning existing business at renewal. If you have an existing policy in an eligible Fortegra state, you will receive an email with detailed instructions about our automated transition process.',
    },
    {
      question: 'How do I transition my clients?',
      answer: `We're making it easy to transition your clients from Accredited to Fortegra. Your client's policy will be automatically bound 35 days prior to the effective date. You can <a href="${accountsUrl}"">view a full quote</a>, review coverages, and make any necessary changes.`,
    },
    {
      question: 'How does billing work?',
      answer:
        'At this time, there are no changes to the billing flow. Any insured enrolled in automatic payments will stay enrolled for their new policy term. We will continue to bill the insured directly and accept credit/debit cards and banking ACH as a payment form.',
    },
    {
      question: 'Who is handling claims?',
      answer:
        'Fortegra has selected NARS as its third-party administrator for claims. This means a seamless transition for you and your client. New losses can still be reported directly to NARS via phone or email. NARS has a dedicated team of experienced claim professionals ready to promptly handle, investigate, and resolve claims.',
    },
  ];
}

export const FOUNDATIONAL_PLUS_INFO_PAGE =
  'https://resources.attuneinsurance.com/documents/foundational-plus-info-page';
