import * as moment from 'moment';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  merge as observableMerge,
  of as observableOf,
  Subscription,
} from 'rxjs';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidationErrors,
  ValidatorFn,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';

import {
  DEBRIS_REMOVAL_LIMIT_DEFAULT,
  isBopVersion2or3,
  MINIMUM_LRO_BUILDING_LIMIT,
  REQUIRED_WIND_LOSS_MITIGATION_STATES,
  UTILITY_SERVICES_INELIGIBLE_STATES,
  WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE,
} from 'app/features/attune-bop/models/constants';
import {
  getControl,
  getFormArray,
  enableDisableControl,
  rangeValidator,
  validateCurrencyGreaterThanZero,
  zipCodeValidator,
} from 'app/shared/helpers/form-helpers';
import {
  classificationCanHaveAlcohol,
  classificationHasSpoilageCoverage,
  classificationServesAlcohol,
} from 'app/features/attune-bop/models/classification-helpers';
import {
  findClassification,
  validBuildingClassification,
} from 'app/features/attune-bop/services/attune-bop-building-classification.service';
import { formatMoneyNoCents, parseMaskedInt } from 'app/shared/helpers/number-format-helpers';
import {
  checkForFailedUpload,
  checkForMinimumBppLimit,
  knownBuildingClassificationDescription,
  locationStateBopVersionAsyncValidator,
  locationStateValidator,
  validateAddressIsNotPO,
  validateNoSpecialCharactersInAddress,
  validateTenantName,
} from 'app/features/attune-bop/models/form-validators';
import { BaseFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { BuildingClassificationCategory } from '../models/building-classifications';
import { AttuneBopFortegraEnabledStates } from '../../../shared/services/typings';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';

const EXCLUDED_V2_EB_CODES = ['50928', '75961', '75971'];
const ARTISAN_LOI_CLASS_CODES = ['99978', '99987', '99999'];
const formBuilder = new UntypedFormBuilder();

const createBPPValidator = (exposure: UntypedFormGroup): ValidatorFn => {
  // Note: Because this BBP validator relies on these other form controls,
  // we have to update the validity when they change
  getControl(exposure, 'businessType').valueChanges.subscribe(() => {
    getControl(exposure, 'limitForBusinessPersonalProperty').updateValueAndValidity({
      onlySelf: true,
      emitEvent: false,
    });
  });
  getControl(exposure, 'lessorsRisk').valueChanges.subscribe(() => {
    getControl(exposure, 'limitForBusinessPersonalProperty').updateValueAndValidity({
      onlySelf: true,
      emitEvent: false,
    });
  });

  return (control: AbstractControl): ValidationErrors | null => {
    const bppLimit = control.value;
    const businessType: BuildingClassificationCategory = getControl(exposure, 'businessType').value;
    const isLessorsRisk = getControl(exposure, 'lessorsRisk').value;

    return checkForMinimumBppLimit(bppLimit, businessType, isLessorsRisk);
  };
};

const createBuildingValidator = (exposure: UntypedFormGroup): ValidatorFn => {
  // Note: Because this builsing limit validator relies on these other form controls,
  // we have to update the validity when they change
  getControl(exposure, 'lessorsRisk').valueChanges.subscribe(() => {
    getControl(exposure, 'buildingLimit').updateValueAndValidity({
      onlySelf: true,
      emitEvent: false,
    });
  });
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null) {
      return null;
    }
    const input = parseInt(String(control.value).replace(/\$|,/g, ''), 10);
    if (isNaN(input)) {
      return {
        buildingLimitValidator: {
          value: control.value,
        },
      };
    }
    const isLessorsRisk = getControl(exposure, 'lessorsRisk').value;
    if (!isLessorsRisk) {
      // Note: If not lessors risk, then tenants aren't required to insure the building
      return null;
    }
    if (input < MINIMUM_LRO_BUILDING_LIMIT) {
      return {
        buildingLimitValidator: {
          value: control.value,
          message: `Our Lessors' Risk program requires a minimum building limit of ${formatMoneyNoCents(
            MINIMUM_LRO_BUILDING_LIMIT.toString()
          )}`,
        },
      };
    }
    return null;
  };
};

const largestTenantValidator = (tenantFormArray: UntypedFormArray) => {
  return (control: AbstractControl) => {
    if (tenantFormArray.disabled) {
      return null;
    }
    const firstTenant = tenantFormArray.at(0);

    if (firstTenant.invalid) {
      return {
        largestTenantMissingInfo: "Primary tenant info is required for Lessors' Risk",
      };
    }
  };
};

const lroTenantSquareFootageValidator = (
  bldgSqFtFormControl: UntypedFormControl,
  rentRollFiles: UntypedFormArray
) => {
  return (control: UntypedFormArray) => {
    const hasValidDocumentAttached = rentRollFiles.value.some((document: FileUpload) => {
      return document.status === 'done';
    });

    // If the user has uploaded a rent roll document, we do not need them to fill out the tenant table.
    if (hasValidDocumentAttached) {
      return null;
    }

    const totalSquareFootageForTenants = control.value.reduce((prev: number, curr: LROTenant) => {
      const currentSquareFootage = parseMaskedInt(curr.squareFootage);
      if (currentSquareFootage) {
        return prev + currentSquareFootage;
      }
      return prev;
    }, 0);

    const squareFootageIsEqual =
      totalSquareFootageForTenants === parseMaskedInt(bldgSqFtFormControl.value);

    if (squareFootageIsEqual) {
      return null;
    }
    return {
      squareFootageDoesNotMatch: {
        validationMessage:
          'Entered square footage for tenants must be equal to the building square footage',
      },
    };
  };
};

/**
 * LocationFormMixin
 *
 * Functionality for forms which have _2_ lists of Locations:
 *
 * 1. One list that appears first on a single page [locationAddresses], and is editable.
 *    Default: `form.locationAddresses`
 *    BOP form: `form.policyInfo.locationAddresses`
 *
 * 2. Second, it manages the form list for BOP Locations, which contain Buildings (1) & Classifications (1)
 *    Default: `form.locations`
 *
 * This exposes an observable this.getBopExposure$() for:
 *  - in this form, to manage interrelated fields WITHIN the exposure / location pages like payroll for Contractors
 *  - to forms using this mixin, to manage dependent fields like available optional coverages / limits
 *
 */
export const LocationFormMixin = <T extends Constructor<BaseFormService>>(
  BaseClass: T,
  locationAddressesPath?: string,
  locationsPath?: string,
  baseStatePath?: string,
  bopVersionPath?: string
) => {
  return class extends BaseClass {
    protected readonly locationAddressesFormPath = locationAddressesPath || 'locationAddresses';
    protected readonly locationsFormPath = locationsPath || 'locations';
    protected readonly baseStateFormPath = baseStatePath || 'policyInfo.baseState';
    protected readonly bopVersionFormPath = bopVersionPath || 'policyInfo.bopVersion';
    protected locationDependentLimits = new BehaviorSubject({});

    protected insuredAccount$ = new BehaviorSubject<InsuredAccount | null>(null);

    private _setupExposureObservablesCalled = false;
    private _bopExposure$ = new BehaviorSubject({
      baseState: null,
      bopVersion: null,
      locations: [],
    });
    private _locationsSub = new Subscription();
    private attuneBopV3EnabledStates$ = new BehaviorSubject<AttuneBopFortegraEnabledStates | null>(
      null
    );

    protected ngOnDestroy() {
      this._locationsSub.unsubscribe();
    }

    protected getLocationAddresses(): UntypedFormArray {
      if (!this.form) {
        return new UntypedFormArray([]);
      }

      return this.get<UntypedFormArray>(this.locationAddressesFormPath);
    }

    // Once locations & policyInfo.baseState controls are available on form,
    // this sets up a subject for subsequent pages e.g. Coverages to display different controls
    protected setupExposureObservables(/* stateCode?: string */) {
      if (this._setupExposureObservablesCalled) {
        throw new Error('setupExposureObservables should only be called once!');
      } else {
        this._setupExposureObservablesCalled = true;
      }

      const baseStateControl = this.get<UntypedFormControl>(this.baseStateFormPath);
      const currentBaseState = baseStateControl.value;
      const currentState = observableMerge(
        observableOf(currentBaseState),
        baseStateControl.valueChanges
      );

      const bopVersionControl = this.get<UntypedFormControl>(this.bopVersionFormPath);
      const initialVersion = bopVersionControl ? bopVersionControl.value : null;
      const bvOvservable = observableMerge(
        observableOf(initialVersion),
        bopVersionControl.valueChanges
      );

      combineLatest(
        currentState,
        bvOvservable,
        this.get<UntypedFormControl>(this.locationsFormPath).valueChanges
      )
        .pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
        .subscribe(([baseState, bopVersion, locations]) => {
          this._bopExposure$.next({ baseState, bopVersion, locations });
        });

      this.getBopExposure$().subscribe((bopExposure: BopExposureInfo) => {
        for (let i = 0; i < bopExposure.locations.length; i++) {
          const locationControl = this.get<UntypedFormArray>(this.locationsFormPath).at(
            i
          ) as UntypedFormGroup;
          const partTimeEmployeeCountControl = getControl(
            locationControl,
            'locationDetails.partTimeEmployeeCount'
          );
          enableDisableControl(
            partTimeEmployeeCountControl,
            isBopVersion2or3(bopExposure.bopVersion)
          );
          for (let j = 0; j < bopExposure.locations[i].buildings.length; j++) {
            const curBuilding = bopExposure.locations[i].buildings[j];

            const buildingControl = locationControl.get(`buildings.${j}`) as UntypedFormGroup;

            this.updateUtilityServiceControls(bopExposure.locations[i], buildingControl);
            this.updateEmployeeBenefitsControls(curBuilding, bopExposure, buildingControl);
            this.updateSpoilageControls(curBuilding, bopExposure, buildingControl);

            if (curBuilding.exposure.classification.code === null) {
              continue;
            }
            const equipmentBreakdownControl = getControl(
              locationControl,
              `buildings.${i}.coverage.equipmentBreakdownCoverageOptedIn`
            );
            if (equipmentBreakdownControl === null) {
              continue;
            }
            enableDisableControl(
              equipmentBreakdownControl,
              bopExposure.bopVersion === 1 ||
                curBuilding.exposure.lessorsRisk === true ||
                (curBuilding.exposure.classification &&
                  curBuilding.exposure.classification.code &&
                  !EXCLUDED_V2_EB_CODES.includes(curBuilding.exposure.classification.code.code))
            );
          }
        }
      });
    }

    protected setBopV3EnabledStates(v3EnabledStates: AttuneBopFortegraEnabledStates | null) {
      this.attuneBopV3EnabledStates$.next(v3EnabledStates);
    }

    setupInsuredAccount(account: InsuredAccount) {
      this.insuredAccount$.next(account);
    }

    getBopExposure$() {
      return this._bopExposure$.asObservable();
    }

    removeLocationFromForm(index: number) {
      this.get<UntypedFormArray>(this.locationsFormPath).removeAt(index);
      this.get<UntypedFormArray>(this.locationAddressesFormPath).removeAt(index);
    }

    addLocationForm(isEndorsementLocation: boolean): UntypedFormGroup {
      const newLocation = formBuilder.group({
        locationDetails: formBuilder.group({
          addressLine1: [null],
          addressLine2: [null],
          annualRevenue: [null, [Validators.required, validateCurrencyGreaterThanZero]],
          city: [null],
          state: [null],
          zip: [null],
          employeeCount: [null, Validators.required],
          partTimeEmployeeCount: [{ value: 0, disabled: true }, Validators.required],
          propertyDeductible: [null, Validators.required],
          isWithinCreditableWaterSupplyForPPC: [
            { value: null, disabled: true },
            Validators.required,
          ],
        }),
        locationPrefill: {},
        buildings: new UntypedFormArray([this.createBuilding()]),
      });
      this.get<UntypedFormArray>(this.locationsFormPath).push(newLocation);
      const addressLocation = this.createAddressLocation(isEndorsementLocation);
      this.get<UntypedFormArray>(this.locationAddressesFormPath).push(addressLocation);

      // Sync addressLocation to location.locationDetails
      addressLocation.valueChanges.subscribe((address) => {
        newLocation.patchValue({
          locationDetails: address,
        });
      });

      this.newBuildingSubscriptions(
        getControl(newLocation, 'buildings.0'),
        getControl(newLocation, 'locationDetails.state')
      );

      return newLocation;
    }

    createAddressLocation(isEndorsementLocation: boolean): UntypedFormGroup {
      return formBuilder.group({
        addressLine1: [
          null,
          [
            Validators.required,
            validateNoSpecialCharactersInAddress,
            validateAddressIsNotPO,
            Validators.maxLength(60),
          ],
        ],
        addressLine2: [null, [validateNoSpecialCharactersInAddress, Validators.maxLength(60)]],
        city: [null, Validators.required],
        state: [
          null,
          [Validators.required, locationStateValidator],
          isEndorsementLocation
            ? locationStateBopVersionAsyncValidator(
                this.getBopExposure$(),
                this.attuneBopV3EnabledStates$.asObservable(),
                this.insuredAccount$.asObservable()
              )
            : null,
        ],
        zip: [null, [Validators.required, zipCodeValidator]],
      });
    }

    createBuilding(): UntypedFormGroup {
      const now: moment.Moment = moment();

      // Lessors risk form group
      const lessorsRiskTenants = formBuilder.array([]);
      // Initialize this form array with at least one tenant. The parent formgroup will be disabled for non-LRO.
      this.addLessorsRiskTenant(lessorsRiskTenants, 'Occupied');
      const rentRollFiles = formBuilder.array([], checkForFailedUpload);
      const lessorsRisk = formBuilder.group({
        lessorsRiskTenants: lessorsRiskTenants,
        landlordRequirements: formBuilder.group({
          writtenLease: [false],
          tenantInsuranceValidated: [false],
          landlordAdditionalInsured: [false],
          landlordLimits: [false],
          primaryNonContributory: [false],
          waiverOfSubrogation: [false],
        }),
        tenantResponsibilities: formBuilder.group({
          propertyTaxes: [false],
          propertyInsurance: [false],
          propertyMaintenance: [false],
        }),
        propertyManagementCompany: [false, Validators.required],
        commonOwnershipWithTenants: [false, Validators.required],
        rentRollFiles: rentRollFiles,
      });

      lessorsRisk.disable();

      // Exposure form group
      const exposure = formBuilder.group(
        {
          businessType: [null, Validators.required],
          constructionType: [null, Validators.required],
          storyCount: [null, Validators.required],
          squareFootage: [null, [Validators.required, rangeValidator(1)]],
          hasAutomaticSprinklerSystem: [null, Validators.required],
          buildingLimit: ['$0'], // Note: validators added later
          limitForBusinessPersonalProperty: ['$0'], // Note: validators added later
          lessorsRisk: [false, Validators.required],
          totalSales: [{ value: null, disabled: true }, [Validators.required, rangeValidator(1)]],
          percentSalesPersonalDevices: [
            { value: null, disabled: true },
            [Validators.required, rangeValidator(0, 100)],
          ],
          payroll: [{ value: null, disabled: true }, [Validators.required, rangeValidator(1)]],
          yearBuilt: [
            null,
            [
              Validators.required,
              rangeValidator(1800, now.year()),
              Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            ],
          ],
          roofUpdated: [null, Validators.required],
          electricPlumbingHVACUpdated: [null, Validators.required],
        },
        {
          validators: [largestTenantValidator(lessorsRiskTenants)],
        }
      );

      // We need to add this validator after the exposure form is created to get access to building sq footage.
      lessorsRiskTenants.setValidators(
        lroTenantSquareFootageValidator(getControl(exposure, 'squareFootage'), rentRollFiles)
      );

      getControl(exposure, 'limitForBusinessPersonalProperty').setValidators(
        createBPPValidator(exposure)
      );

      getControl(exposure, 'buildingLimit').setValidators(createBuildingValidator(exposure));

      getControl(exposure, 'yearBuilt').valueChanges.subscribe((yearBuilt: number) => {
        const electricPlumbingHVACUpdated = getControl(exposure, 'electricPlumbingHVACUpdated');
        const roofUpdated = getControl(exposure, 'roofUpdated');
        electricPlumbingHVACUpdated.disable();
        roofUpdated.disable();
        if (!yearBuilt || yearBuilt < 1656) {
          return;
        }
        const yearsOfBuilding = moment().year() - yearBuilt;
        if (yearsOfBuilding >= 20) {
          roofUpdated.enable();
        }
        if (yearsOfBuilding >= 30) {
          electricPlumbingHVACUpdated.enable();
        }
      });

      const classification = formBuilder.group({
        code: [null, [Validators.required, knownBuildingClassificationDescription(exposure)]],
        alcoholSales: [{ value: null, disabled: true }, [Validators.required, rangeValidator(1)]],
      });

      exposure.addControl('classification', classification);

      /**
         XS Underwriting Questions
      getControl(classification, 'code').valueChanges.subscribe((changedClassification) => {
      });
      */

      // Coverage form group
      const coverage = formBuilder.group({
        businessIncomeAndExtraExpensesIndemnityInMonths: [12, Validators.required],
        equipmentBreakdownCoverageOptedIn: [true, Validators.required],
        utilityServicesTimeElement: [0, Validators.required],
        utilityServicesDirectDamage: [0, Validators.required],
        debrisRemoval: [DEBRIS_REMOVAL_LIMIT_DEFAULT, Validators.required],
        spoilage: [0, Validators.required],
        ordinanceLawCoverageOptedIn: [false, Validators.required],
        ordinanceLawCoverageValue: [5000, Validators.required],
        windCoverageOptedIn: [true, Validators.required],
        windDeductible: [null, Validators.required],
        windDeductiblePercent: [null, Validators.required],
        windLossMitigationPresent: [false],
        windLossMitigation: formBuilder.group({
          SCBCCompliant: [false, Validators.required],
          level: ['A', Validators.required],
          roofToWallConnection: ['ToenAils', Validators.required],
          openingProtection: ['None', Validators.required],
          doorStrength: ['RSWD', Validators.required],
          secondaryWaterResistance: [false, Validators.required],
          roofShape: ['Flat', Validators.required],
          roofAndDeckAttachment: ['Wood', Validators.required],
        }),
      });

      const windCoverageOptedInControl = getControl(coverage, 'windCoverageOptedIn');
      const windDeductibleControl = getControl(coverage, 'windDeductible');
      const windDeductiblePercentControl = getControl(coverage, 'windDeductiblePercent');

      windCoverageOptedInControl.valueChanges.subscribe((optedIn: boolean) => {
        if (optedIn) {
          windDeductiblePercentControl.enable();
          windDeductibleControl.enable();
        } else {
          windDeductiblePercentControl.disable();
          windDeductibleControl.disable();
        }
      });

      windDeductiblePercentControl.valueChanges.subscribe((windPercent: string) => {
        /**
         * The wind deductible is required for all inputs that aren't N/A.
         *
         * Equivalently, if the wind percent is set to N/A, then the wind deductible isn't
         * required and we need to update the form controls to ensure it's not mandatory.
         */
        if (windPercent === WIND_DEDUCTIBLE_PERCENT_NOT_APPLICABLE) {
          windDeductibleControl.clearValidators();
        } else {
          windDeductibleControl.setValidators(Validators.required);
        }
        windDeductibleControl.updateValueAndValidity({ emitEvent: false });
      });

      return formBuilder.group({ exposure, coverage, lessorsRisk });
    }

    addLessorsRiskTenant(
      tenantFormArray: UntypedFormArray,
      occupancyStatus: OccupiedStatus | null = null
    ): UntypedFormGroup {
      const tenant = formBuilder.group({
        occupancyStatus: [occupancyStatus, Validators.required],
        tenantName: [null, [Validators.required, validateTenantName]],
        descriptionOfBusiness: [null, Validators.required],
        squareFootage: [null, [Validators.required, rangeValidator(1)]],
        rent: [null],
        guidewireId: [null],
      });

      tenantFormArray.push(tenant);
      return tenant;
    }

    removeLessorsRiskTenant(tenantFormArray: UntypedFormArray, index: number) {
      return tenantFormArray.removeAt(index);
    }

    addRentRollFile(rentRollFormArray: UntypedFormArray): UntypedFormControl {
      const rentRollFile = formBuilder.control({
        name: '',
        status: '',
        s3Key: '',
        s3Bucket: '',
      });
      rentRollFormArray.push(rentRollFile);
      return rentRollFile;
    }

    protected newBuildingSubscriptions(building: AbstractControl, locationState: AbstractControl) {
      const exposure = <UntypedFormGroup>building.get(['exposure']);
      const coverage = <UntypedFormGroup>building.get(['coverage']);
      const lessorsRisk = <UntypedFormGroup>building.get(['lessorsRisk']);
      const largestLessorsRiskTenant = <UntypedFormGroup>(
        lessorsRisk.get(['lessorsRiskTenants', '0'])
      );
      const classification = <UntypedFormGroup>building.get(['exposure', 'classification']);

      // Business type resets the selection on classification
      this._locationsSub.add(
        getControl(exposure, 'businessType').valueChanges.subscribe((_businessType: string) => {
          classification.reset();
        })
      );

      // LRO subscriptions
      this._locationsSub.add(
        getControl(exposure, 'lessorsRisk').valueChanges.subscribe((isLessorsRisk: boolean) => {
          // LRO change keeps classification if it's available as a tenant, resets if not
          this.clearClassificationIfInvalid(classification, isLessorsRisk);
        })
      );

      // This subscription ensures that the exposure form group's validity is re-checked when the largest tenant form group value changes.
      this._locationsSub.add(
        largestLessorsRiskTenant.valueChanges.subscribe((_val) => {
          exposure.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        })
      );
      // This subscription syncs the largest tenant's description with the description from the classification selected.
      this._locationsSub.add(
        classification.valueChanges.subscribe((value) => {
          const businessType = getControl(exposure, 'businessType').value;
          if (value?.code && businessType) {
            // We want to use the formatted description rather than the descriptionCode.
            const classCodeDetails = findClassification(businessType, value.code.descriptionCode);
            return largestLessorsRiskTenant.patchValue({
              descriptionOfBusiness: classCodeDetails?.description,
            });
          }

          return largestLessorsRiskTenant.patchValue({
            descriptionOfBusiness: null,
          });
        })
      );

      this._locationsSub.add(
        getControl(lessorsRisk, 'rentRollFiles').valueChanges.subscribe((_value) => {
          const tenantFormArray = getFormArray(lessorsRisk, 'lessorsRiskTenants');
          tenantFormArray.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        })
      );
      // End of LRO subscriptions

      // Sales fields - payroll for Contractors, totalSales and alcoholSales for Restaurants
      this.updateSalesFields(exposure.value, exposure);
      this._locationsSub.add(
        exposure.valueChanges.subscribe((exposureValue) => {
          this.updateSalesFields(exposureValue, exposure);
        })
      );

      this.updateOrdinanceAndLaw(getControl(exposure, 'buildingLimit').value, coverage);
      this._locationsSub.add(
        getControl(exposure, 'buildingLimit').valueChanges.subscribe((value) => {
          this.updateOrdinanceAndLaw(value, coverage);
        })
      );

      this._locationsSub.add(
        locationState.valueChanges.subscribe((state: string | null) => {
          if (state) {
            enableDisableControl(
              getControl(coverage, 'windLossMitigationPresent'),
              REQUIRED_WIND_LOSS_MITIGATION_STATES.includes(state)
            );
            getControl(coverage, 'windLossMitigation').enable();
          } else {
            getControl(coverage, 'windLossMitigationPresent').disable();
            getControl(coverage, 'windLossMitigation').disable();
          }

          if (state === 'CA') {
            getControl(exposure, 'lessorsRisk').setValue(false);
          } else if (state === 'NJ' || state === 'IL') {
            const businessType = getControl(exposure, 'businessType').value;
            if (businessType === 'Mercantile') {
              getControl(exposure, 'lessorsRisk').setValue(false);
            }
          }
        })
      );
    }

    clearClassificationIfInvalid = (
      classification: UntypedFormGroup,
      lessorsRiskValue: boolean
    ) => {
      const codeControl = getControl(classification, 'code');
      if (
        codeControl &&
        codeControl.value &&
        !validBuildingClassification(codeControl.value, lessorsRiskValue)
      ) {
        codeControl.reset();
      }
    };

    updateSalesFields(expValue: BopBuildingExposure, exposure: UntypedFormGroup) {
      const payrollField = getControl(exposure, 'payroll');
      const totalSalesField = getControl(exposure, 'totalSales');

      const classCodeControl = getControl(exposure, 'classification.code');
      const alcoholSalesField = getControl(exposure, 'classification.alcoholSales');
      let isArtisanWithSalesExposureBasis = expValue.businessType === 'Artisans';
      if (expValue.classification && expValue.classification.code) {
        isArtisanWithSalesExposureBasis =
          expValue.businessType === 'Artisans' &&
          !ARTISAN_LOI_CLASS_CODES.includes(expValue.classification.code.code);
      }

      if (expValue.lessorsRisk) {
        enableDisableControl(payrollField, false);
        enableDisableControl(totalSalesField, false);
      } else if (expValue.businessType === 'Contractor') {
        enableDisableControl(payrollField, true);
        enableDisableControl(totalSalesField, false);
      } else if (
        expValue.businessType &&
        (expValue.businessType.startsWith('Restaurant') || isArtisanWithSalesExposureBasis)
      ) {
        enableDisableControl(payrollField, false);
        enableDisableControl(totalSalesField, true);
      } else {
        // Non-LRO Offices, Mercantile etc
        enableDisableControl(payrollField, false);
        enableDisableControl(totalSalesField, false);
      }

      // Alcohol field
      if (expValue.lessorsRisk) {
        enableDisableControl(alcoholSalesField, false);
      } else if (classificationServesAlcohol(classCodeControl.value)) {
        enableDisableControl(alcoholSalesField, true);
        alcoholSalesField.setValidators([Validators.required, rangeValidator(1)]);
      } else if (classificationCanHaveAlcohol(classCodeControl.value)) {
        enableDisableControl(alcoholSalesField, true);
        alcoholSalesField.clearValidators();
      } else {
        enableDisableControl(alcoholSalesField, false);
      }
    }

    updateOrdinanceAndLaw(buildingLimit: string, coverage: UntypedFormGroup): void {
      const ordinanceOptIn = getControl(coverage, 'ordinanceLawCoverageOptedIn');

      if (parseMaskedInt(buildingLimit) === 0) {
        ordinanceOptIn.disable();
      } else {
        ordinanceOptIn.enable();
      }
    }

    updateEmployeeBenefitsControls(
      building: BopBuilding,
      bopExposure: BopExposureInfo,
      buildingGroup: UntypedFormGroup
    ) {
      if (building.exposure.classification.code === null) {
        return;
      }
      const equipmentBreakdownControl = getControl(
        buildingGroup,
        `coverage.equipmentBreakdownCoverageOptedIn`
      );
      if (equipmentBreakdownControl === null) {
        return;
      }
      enableDisableControl(
        equipmentBreakdownControl,
        bopExposure.bopVersion === 1 ||
          building.exposure.lessorsRisk ||
          !EXCLUDED_V2_EB_CODES.includes(building.exposure.classification.code.code)
      );
    }

    updateSpoilageControls(
      building: BopBuilding,
      bopExposure: BopExposureInfo,
      buildingGroup: UntypedFormGroup
    ) {
      const spoilageControl = getControl(buildingGroup, 'coverage.spoilage');
      const lessorsRisk: boolean = building.exposure.lessorsRisk;
      const classification: BuildingClassification | null = building.exposure.classification.code;
      if (!classification || lessorsRisk) {
        // If the building is Lessor' risk only we don't want to show the spoilage coverage option
        return enableDisableControl(spoilageControl, false);
      }

      enableDisableControl(spoilageControl, classificationHasSpoilageCoverage(classification));
      if (spoilageControl.value === null) {
        spoilageControl.patchValue(0);
      }
    }

    updateUtilityServiceControls(location: BopLocation, buildingGroup: UntypedFormGroup) {
      if (UTILITY_SERVICES_INELIGIBLE_STATES.includes(location.locationDetails.state)) {
        // Zero out utility service coverages for ineligible states
        // Do not directly disable the controls since GWPC requires a numeric value, not null
        // Controls are hidden by state in UtilityServicesCoverageComponent
        const directDamageControl = getControl(
          buildingGroup,
          'coverage.utilityServicesDirectDamage'
        );
        const timeElementControl = getControl(buildingGroup, 'coverage.utilityServicesTimeElement');
        directDamageControl.patchValue(0);
        timeElementControl.patchValue(0);
      }
    }
  };
};
