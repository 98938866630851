import { Component, Input } from '@angular/core';
import {
  EpliOptions,
  BOP_V1_EPLI_AVAILABLE_DEDUCTIBLES,
  BOP_V1_EPLI_EACH_ACT_LIMITS,
} from '../../models/constants';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-employment-practices-liability',
  templateUrl: './attune-bop-questions-employment-practices-liability.component.html',
})
export class AttuneBopQuestionsEmploymentPracticesLiabilityComponent {
  @Input() submitted: boolean;
  @Input() form: UntypedFormGroup;
  @Input() epliV2Options: EpliOptions;

  availableLimitsEachAct = BOP_V1_EPLI_EACH_ACT_LIMITS;
  availableDeductibles = BOP_V1_EPLI_AVAILABLE_DEDUCTIBLES;

  epliEnabled() {
    const optinControl = this.form.get('optedIn');
    return optinControl && optinControl.enabled;
  }

  onlyOneDeductibleOption() {
    return Object.keys(this.epliV2Options.deductibleV2).length === 1;
  }

  getDeductibleError() {
    const control = this.form.get('deductibleV2');
    return control && control.errors && control.errors['invalidValue'];
  }
}
