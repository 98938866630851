import { Component, Input } from '@angular/core';

import { BookMix } from 'app/shared/services/book-mix.service';
import { AttuneProduct } from 'app/features/digital-carrier/models/types';
import {
  NON_PREFERRED_PREFERENCE_LEVELS,
  PREFERRED_PREFERENCE_LEVELS,
  STRONGLY_PREFERRED_PREFERENCE_LEVELS,
} from '../../../attune-bop/models/constants';
import { FOUNDATIONAL_PLUS_INFO_PAGE } from '../../models/constants';

@Component({
  selector: 'app-activity-book-mix-progress',
  templateUrl: './activity-book-mix-progress.component.html',
})
export class BookMixProgressComponent {
  @Input() productType: AttuneProduct['product'];
  @Input() productBookMix: BookMix;

  FOUNDATIONAL_PLUS_INFO_PAGE = FOUNDATIONAL_PLUS_INFO_PAGE;

  get totalTargetPreferredPoliciesCount() {
    const targetPercentagePreferred =
      this.productBookMix.producerCodeBookConfig.targetPercentage / 100;
    const totalPoliciesNeeded = this.nonPreferredCount / (1 - targetPercentagePreferred);
    const additionalPreferredPoliciesInterim = totalPoliciesNeeded * targetPercentagePreferred;
    return Math.max(0, Math.ceil(additionalPreferredPoliciesInterim));
  }

  get preferredCount() {
    const preferredPreferenceLevels = [
      ...PREFERRED_PREFERENCE_LEVELS,
      ...STRONGLY_PREFERRED_PREFERENCE_LEVELS,
    ];
    return preferredPreferenceLevels.reduce((acc, cur) => {
      return acc + this.productBookMix.producerCodeBookInfo?.[cur]?.numPolicies || 0;
    }, 0);
  }

  get nonPreferredCount() {
    return NON_PREFERRED_PREFERENCE_LEVELS.reduce((acc, cur) => {
      return acc + this.productBookMix.producerCodeBookInfo?.[cur]?.numPolicies || 0;
    }, 0);
  }

  get productTypeName() {
    return this.productType;
  }

  getSectionHeading() {
    return this.productType === 'bop' ? "Businessowners' Policy" : "Workers' Compensation";
  }

  getPercentPreferred() {
    return Math.ceil((this.preferredCount / (this.preferredCount + this.nonPreferredCount)) * 100);
  }

  getPercentNonPreferred() {
    return Math.ceil(
      (this.nonPreferredCount / (this.preferredCount + this.nonPreferredCount)) * 100
    );
  }
}
