import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SentryService } from '../../../core/services/sentry.service';
import { Observable } from 'rxjs';
import {
  DEFAULT_RETRY_COUNT_FOR_BACKOFF,
  retryWithBackoff,
} from '../../../shared/helpers/api-helpers';

import * as FileSaver from 'file-saver';

export type DOCUMENT_TYPES = 'pdf' | 'xls' | 'xlsx' | 'zip';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getDocument(
    url: string,
    fileName: string,
    documentType: DOCUMENT_TYPES,
    description?: string
  ): Observable<Blob> {
    return this.http
      .get(url, {
        headers: { Accept: `application/${documentType}` },
        responseType: 'blob',
      })
      .pipe(
        tap((data) => {
          if (data === null || data?.size === 0) {
            throw new Error('Document data empty');
          }
          FileSaver.saveAs(data, fileName);
        }),
        catchError((error) => {
          this.sentryService.notify(`Unable to get document ${description || fileName}`, {
            severity: 'error',
            metaData: {
              downloadLink: url,
              fileName,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        })
      );
  }

  headDocument(url: string): Observable<HttpResponse<any>> {
    return this.http
      .head(url, {
        observe: 'response',
      })
      .pipe(
        // catch non-2xx errors
        catchError((error) => {
          this.sentryService.notify(`Unable to get document headers`, {
            severity: 'error',
            metaData: {
              downloadLink: url,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
          throw error;
        }),
        // Treat a 201 as a failed HEAD request, since it means the document is not yet ready
        tap((res) => {
          if (!(res && res.status === 200)) {
            throw new Error();
          }
        })
      );
  }

  pollDocument(
    url: string,
    maxTries = DEFAULT_RETRY_COUNT_FOR_BACKOFF
  ): Observable<HttpResponse<any>> {
    return this.headDocument(url).pipe(retryWithBackoff(maxTries));
  }
}
